import { sendStartNewSTTRequestToParentWindow, sendStopSTTRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import { create } from "zustand";

interface TranscriptBlock {
  text: string;
  id: string;
  isFinal?: boolean;
}

type WhisperSLTState = {
  isSTTActive: boolean;
  isManualAddingMode: boolean;
  isSTTToggledOn: boolean;
  isWhisperSLTSupported: boolean;
  isRealTimeTranscriptSupported: boolean;
  transcriptChunk: TranscriptBlock[];
  transcriptData: TranscriptBlock[];
  sttError: string | null;
  isSTTSessionStarted: boolean;
  showSmartLiveTextView: boolean;
  isGettingSTTMediaPermission: boolean;
  showPermissionGuideInTextView: boolean;
  isSTTMoreButtonClicked: boolean;
  isSTTSocketConnected: boolean;
};

type WhisperSLTActions = {
  startSTT: (documentKey?: string) => void;
  stopSTT: () => void;
  setIsManualAddingMode: (isManualAddingMode: boolean) => void;
  setIsSTTToggledOn: (isSTTToggledOn: boolean) => void;
  setIsWhisperSLTSupported: (isWhisperSLSupported: boolean) => void;
  setIsRealTimeTranscriptSupported: (isRealTimeTranscriptSupported: boolean) => void;
  setTranscriptChunk: (transcriptChunk: TranscriptBlock) => void;
  resetTranscriptChunk: () => void;
  setTranscriptData: (newTranscriptBlock: TranscriptBlock) => void;
  setSttError: (sttError: string | null) => void;
  setIsSTTSessionStarted: (isSTTSessionStarted: boolean) => void;
  setShowSmartLiveTextView: (showSmartLiveTextView: boolean) => void;
  setIsGettingSTTMediaPermission: (isGettingSTTMediaPermission: boolean) => void;
  setShowPermissionGuideInTextView: (showPermissionGuideInTextView: boolean) => void;
  setIsSTTMoreButtonClicked: (isSTTMoreButtonClicked: boolean) => void;
  setIsSTTSocketConnected: (isSTTSocketConnected: boolean) => void;
};

type WhisperSLTStoreType = WhisperSLTState & WhisperSLTActions;

const useWhisperSLTStore = create<WhisperSLTStoreType>()((set) => ({
  transcriptData: [],
  setTranscriptData: (newTranscriptBlock: TranscriptBlock) => {
    set((state) => ({ transcriptData: [...state.transcriptData, newTranscriptBlock] }));
  },
  isWhisperSLTSupported: false,
  setIsWhisperSLTSupported: (isWhisperSLTSupported: boolean) => {
    set((state) => ({ isWhisperSLTSupported }));
  },
  isSTTActive: false,
  startSTT: (documentKey) => {
    sendStartNewSTTRequestToParentWindow(documentKey);
    set((state) => ({ isSTTActive: true }));
  },
  stopSTT: () => {
    sendStopSTTRequestToParentWindow();
    set((state) => ({ isSTTActive: false }));
  },
  isManualAddingMode: false,
  setIsManualAddingMode: (isManualAddingMode: boolean) => {
    set((state) => ({ isManualAddingMode }));
  },
  isSTTToggledOn: false,
  setIsSTTToggledOn: (isSTTToggledOn: boolean) => {
    set((state) => ({ isSTTToggledOn }));
  },
  sttError: null,
  setSttError: (sttError: string | null) => {
    set((state) => ({ sttError }));
  },
  isSTTSessionStarted: false,
  setIsSTTSessionStarted: (isSTTSessionStarted: boolean) => {
    set((state) => ({ isSTTSessionStarted }));
  },
  showSmartLiveTextView: false,
  setShowSmartLiveTextView: (showSmartLiveTextView: boolean) => {
    set((state) => ({ showSmartLiveTextView }));
  },
  isGettingSTTMediaPermission: false,
  setIsGettingSTTMediaPermission: (isGettingSTTMediaPermission: boolean) => {
    set((state) => ({ isGettingSTTMediaPermission }));
  },
  showPermissionGuideInTextView: false,
  setShowPermissionGuideInTextView: (showPermissionGuideInTextView: boolean) => {
    set((state) => ({ showPermissionGuideInTextView }));
  },
  isSTTMoreButtonClicked: false,
  setIsSTTMoreButtonClicked: (isSTTMoreButtonClicked: boolean) => {
    set((state) => ({ isSTTMoreButtonClicked }));
  },
  isSTTSocketConnected: false,
  setIsSTTSocketConnected: (isSTTSocketConnected: boolean) => {
    set((state) => ({ isSTTSocketConnected }));
  },
  transcriptChunk: [],
  setTranscriptChunk: (transcriptChunk: TranscriptBlock) => {
    set((state) => ({ transcriptChunk: [...state.transcriptChunk, transcriptChunk] }));
  },
  resetTranscriptChunk: () => {
    set((state) => ({ transcriptChunk: [] }));
  },
  isRealTimeTranscriptSupported: false,
  setIsRealTimeTranscriptSupported: (isRealTimeTranscriptSupported: boolean) => {
    set((state) => ({ isRealTimeTranscriptSupported }));
  },
}));

export default useWhisperSLTStore;
