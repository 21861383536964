import slidAxios from "./base";
import { handleAuthErrors, isAuthError } from "./errorUtils";
const study_challenge_endpoint = `study-challenge/`;
const study_challenge_reminder_endpoint = `study-challenge-reminder/`;

/*
    This function is used to fetch the study challenge data from the database.
    It returns an array of study challenges with the latest one being the first element
*/
export const ServerInterface_fetchStudyChallengeData = async ({}) => {
  const config = {
    params: {
      client_date: new Date().toLocaleDateString("pt-br").split("/").reverse().join("-"), // local YYYY-MM-DD format
    },
  };
  let studyChallengeData = {};
  try {
    const updatedUserDataResponse = await slidAxios.get(study_challenge_endpoint, config);
    studyChallengeData = updatedUserDataResponse.data;
  } catch (error) {
    console.error(error);
    if (!studyChallengeData) studyChallengeData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        case 405:
          studyChallengeData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          studyChallengeData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          studyChallengeData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      // NETWORK_ERROR
      studyChallengeData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      // request sent but no response
      studyChallengeData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return studyChallengeData;
  }
};

/*
    This function is used to update the study challenge data in the database.
    Usually for incrementing the learning time as the user is studying
*/
export const ServerInterface_patchStudyChallengeData = async ({ data }) => {
  const payload = data;
  let updatedStudyChallengeData = {};
  try {
    const updatedUserDataResponse = await slidAxios.patch(study_challenge_reminder_endpoint, payload);
    updatedStudyChallengeData = updatedUserDataResponse.data;
  } catch (error) {
    console.error(error);
    if (!updatedStudyChallengeData) updatedStudyChallengeData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        case 405:
          updatedStudyChallengeData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          updatedStudyChallengeData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 400:
          switch (error.response.data.message) {
            case "Missing or invalid required fields":
              updatedStudyChallengeData.error_message = "STUDY_CHALLENGE_MISSING_OR_INVALID_FIELDS";
              break;
            case "Invalid study time value, should be an integer":
              updatedStudyChallengeData.error_message = "STUDY_CHALLENGE_INVALID_STUDY_TIME";
              break;
            default:
              updatedStudyChallengeData.error_message = "STUDY_CHALLENGE_UPDATE_ERROR";
              break;
          }
          break;
        case 404:
          switch (error.response.data.message) {
            case "No study challenge with given name found":
              updatedStudyChallengeData.error_message = "STUDY_CHALLENGE_NOT_FOUND";
              break;
            case "No study challenge reminder found for the given date":
              updatedStudyChallengeData.error_message = "STUDY_CHALLENGE_REMINDER_NOT_FOUND";
              break;
            default:
              updatedStudyChallengeData.error_message = "STUDY_CHALLENGE_UPDATE_ERROR";
              break;
          }
          break;
        default:
          updatedStudyChallengeData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      // NETWORK_ERROR
      updatedStudyChallengeData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      // request sent but no response
      updatedStudyChallengeData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return updatedStudyChallengeData;
  }
};
