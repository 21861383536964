// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const SvgWrapper = styled(Icon)``;

export const SurveyOptionIcon = () => {
  return (
    <SvgWrapper width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="9" stroke="#BEC5CD" strokeWidth="2" />
    </SvgWrapper>
  );
};
