// auth 2.0
import React, { useState, useRef, memo } from "react";
import styles from "./DocumentItem.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { updateDocument, setIsEditorNoteLoading } from "redux/actions/vdocsActions";
import { sendAmplitudeData } from "utils/amplitude";
import { alertRenameDocument, alertRenameDocumentError } from "components/alerts";
import { moveToFolderHandler, onRemoveNote } from "components/ViewNote/utils";
import { useMediaQuery } from "react-responsive";
import { Typography15, Icon } from "@slid/slid-ips";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";
import { isTouchDevice } from "utils/utils";
import styled from "styled-components";
import NoteListViewItemOptionMenu from "./NoteListViewItemOptionMenu";

const DocumentItem = memo(({ title, documentKey, currentDocumentKey, onUpdateDocument }) => {
  const dispatch = useAppDispatch();
  const lang = useAppSelector((state) => state.slidGlobal.lang);
  const { editorWrapperClassName } = useAppSelector((state) => state.vdocs);
  const [showOptionEditDropdown, setShowOptionEditDropdown] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(title);
  const [isDocumentItemContainerHover, setIsDocumentItemContainerHover] = useState(false);
  const [optionPositionValue, setOptionPositionValue] = useState(0);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();

  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const ref = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const optionEditIconWrapperRef = useRef(null);
  const isVideoNote = location.pathname.split("/")[1] === "vdocs";

  const handleUpdateDocumentName = async ({ title, documentKey }) => {
    const isUpdatingCurrentDocument = documentKey === currentDocumentKey;
    const oldTitle = currentTitle;
    setCurrentTitle(title);
    let updatedDocumentData = await dispatch(
      updateDocument({
        documentKey: documentKey,
        title: title,
        fetchCurrentDocument: isUpdatingCurrentDocument,
      })
    );
    if (updatedDocumentData.error_message) {
      setCurrentTitle(oldTitle);
      alertRenameDocumentError({ target: isMobile ? "body" : `.${editorWrapperClassName}` });
      return;
    }
    onUpdateDocument();
  };

  const onClickRenameDocument = () => {
    setShowOptionEditDropdown(false);
    if (!confirmPrivilege(SlidFeatures.noteTitleUpdate)) return showInsufficientPrivilegeModal();
    alertRenameDocument({ target: isMobile ? "body" : `.${editorWrapperClassName}`, documentTitle: title }).then((result) => {
      if (result.isConfirmed) {
        handleUpdateDocumentName({
          title: result.value,
          documentKey: documentKey,
        });
      }
    });
  };

  const onClickRemoveDocument = async () => {
    setShowOptionEditDropdown(false);
    if (!confirmPrivilege(SlidFeatures.noteDeletion)) return showInsufficientPrivilegeModal();
    await onRemoveNote({
      target: isMobile ? "body" : `.${editorWrapperClassName}`,
      title,
      documentKey,
      history,
      location: isVideoNote ? "Video note page" : "Plain note page",
      from: "single selection",
    });
  };

  const onClickMoveToFolder = () => {
    setShowOptionEditDropdown(false);
    moveToFolderHandler({
      documentKey: documentKey,
      target: isMobile ? "body" : `.${editorWrapperClassName}`,
      location: isVideoNote ? "Video note page" : "Plain note page",
    });
  };

  return (
    <>
      <div
        className={`${styles[`document-item-container`]} ${currentDocumentKey === documentKey ? styles[`active`] : ""}`}
        onClick={async (event) => {
          event.stopPropagation();
          // only push history when different document is clicked
          if (history.location.pathname.split("/")[2] !== documentKey) {
            //show editor note loading state if changing to different note
            dispatch(setIsEditorNoteLoading(true));
            history.push(`./${documentKey}`);
            sendAmplitudeData(`Click note from list`);
          }
        }}
        draggable={!isTouchDevice()}
        onMouseEnter={() => {
          if (isTouchDevice()) return;
          setIsDocumentItemContainerHover(true);
        }}
        onMouseLeave={() => {
          if (isTouchDevice()) return;
          setIsDocumentItemContainerHover(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
        }}
        onDragStart={(event) => {
          if (isTouchDevice()) return;
          event.stopPropagation();
          event.dataTransfer.setData("documentKey", documentKey);
        }}
        ref={ref}
        title={title}
        slid-cy={`document-item-${documentKey}`}
      >
        <div className={`d-flex align-items-center`}>
          <Icon icon={`recentNote20`} color={`--gray9`} />
          <Typography15
            weight={400}
            text={title === "" ? (lang === "ko" ? "제목없는 노트" : "Untitled") : title}
            color="--gray17"
            style={{ width: "80%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", marginLeft: "4px" }}
          />
          {documentKey !== "new" ? (
            <div className={styles[`option-edit-icon-wrapper`]}>
              <OptionEditIconContainer
                showOptionEditIcon={isDocumentItemContainerHover || showOptionEditDropdown}
                onClick={(event) => {
                  event.stopPropagation();
                  // Set dropdown option menu's top position related to edit-icon
                  setOptionPositionValue(optionEditIconWrapperRef.current.getBoundingClientRect().top);
                  setShowOptionEditDropdown(true);
                }}
                slid-cy={`document-item-option-toggle-btn-${documentKey}`}
                ref={optionEditIconWrapperRef}
              >
                <Icon icon={`more28`} color={`--gray9`} />
              </OptionEditIconContainer>
            </div>
          ) : null}
        </div>
      </div>
      {showOptionEditDropdown && (
        <NoteListViewItemOptionMenuWrapper onClick={() => setShowOptionEditDropdown(false)}>
          <NoteListViewItemOptionMenu
            onRemoveDocument={onClickRemoveDocument}
            onRenameDocument={onClickRenameDocument}
            documentKey={documentKey}
            optionPositionValue={optionPositionValue}
            onMoveToFolder={onClickMoveToFolder}
          />
        </NoteListViewItemOptionMenuWrapper>
      )}
    </>
  );
});

export default DocumentItem;

const NoteListViewItemOptionMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 3;
`;

const OptionEditIconContainer = styled.div`
  display: flex;
  visibility: ${(props) => (isTouchDevice() || props.showOptionEditIcon ? "inital" : "hidden")};
  align-items: center;
  justify-content: center;
`;
