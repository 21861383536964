import React from "react";
import styled from "styled-components";
import { Typography15 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import BaseModalContainer from "./BaseModalContainer";
import ModalPortal from "./ModalPortal";
import animationData from "components/animations/loading_animation.json";
import Lottie from "react-lottie";
import { LoadingModalProps } from "types/modal";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const LoadingModal = ({ text, closable, target = "body" }: LoadingModalProps) => {
  const { t } = useTranslation(["Modal"]);

  const getModalText = () => {
    switch (text) {
      case "save":
        return t("LoadingModalSaving");
      case "delete":
        return t("LoadingModalDeleting");
      case "move":
        return t("LoadingModalMoving");
      default:
        return "";
    }
  };

  return (
    <ModalPortal target={target}>
      <BaseModalContainer closable={closable}>
        <ModalContent>
          <Lottie options={defaultOptions} height={80} width={80} />
          <Typography15 text={getModalText()} color={`--gray17`} weight={700} />
        </ModalContent>
      </BaseModalContainer>
    </ModalPortal>
  );
};

const ModalContent = styled.div`
  z-index: 9999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  display: flex;
  width: 376px;
  padding: 87px 52px 78px 52px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

export default LoadingModal;
