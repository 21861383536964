import styled from "styled-components";
import React from "react";
import store from "redux/store";

const PermissionGrantedAlertMessage: React.FC = () => {
  const lang = store.getState().slidGlobal.lang;
  return (
    <>
      <PermissionGrantedMessage>
        <PermissionGrantedMessageText>{lang === "ko" ? `권한이 허용되었어요.` : `Permission granted.`} </PermissionGrantedMessageText>
        <PermissionGrantedMessageText>
          {lang === "ko" ? `이제 클립 녹화를 사용해보세요!` : `Try the video snippet now!`}
          {/*eslint-disable-next-line*/}
          <span>✨</span>
        </PermissionGrantedMessageText>
      </PermissionGrantedMessage>
    </>
  );
};

const PermissionGrantedMessage = styled.div`
  width: 360px;
  background-color: var(--gray13);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const PermissionGrantedMessageText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: var(--gray1);
`;

export default PermissionGrantedAlertMessage;
