import React from "react";
import ModalPortal from "./ModalPortal";
import BaseModalContainer from "./BaseModalContainer";
import styled from "styled-components";
import { Button, Typography16, Typography17, Typography34 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { useModalStore } from "store/useModalStore";

const MobileAppInstallModal = () => {
  const { t } = useTranslation("Modal");
  const { hideModal } = useModalStore();

  return (
    <ModalPortal target={"body"}>
      <BaseModalContainer callback={() => {}} closable={false}>
        <ModalContainer>
          <Typography34 text={t("MobileAppInstallModal.title", { keySeparator: "." })} color={"--gray18"} weight={700} textAlign="center" />
          <QRCodeWrapper>
            <QRCodeItem>
              <QRCodeImage src={"/src/assets/slid_mobile_app/slid_mobile_QR_google.png"} />
              <Typography16 text={"Play Store"} color={"--gray14"} weight={700} textAlign="center" />
            </QRCodeItem>
            <QRCodeItem>
              <QRCodeImage src={"/src/assets/slid_mobile_app/slid_mobile_QR_apple.png"} />
              <Typography16 text={"App Store"} color={"--gray14"} weight={700} textAlign="center" />
            </QRCodeItem>
          </QRCodeWrapper>
          <Typography17 text={t("MobileAppInstallModal.description", { keySeparator: "." })} color={"--gray18"} marginTop="8px" textAlign="center" />
          <ButtonWrapper>
            <Button
              text={t("MobileAppInstallModal.primaryButton", { keySeparator: "." })}
              callback={() => {
                hideModal();
              }}
              appearance="primary"
              size="medium"
            />
          </ButtonWrapper>
        </ModalContainer>
      </BaseModalContainer>
    </ModalPortal>
  );
};

const ModalContainer = styled.div`
  z-index: 9999999;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 56px 48px;
  box-shadow: var(--boxShadow3);
  gap: 48px;
`;

const QRCodeWrapper = styled.div`
  display: flex;
  padding: 42px 83px 39px 83px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: var(--blue2);
  gap: 34px;
`;

const QRCodeItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 144px;
`;

const QRCodeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export default MobileAppInstallModal;
