import { Icon, Typography13 } from "@slid/slid-ips";
import { setSearchCategories } from "redux/actions/searchActions";
import { trackEvent } from "utils/eventTracking";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import styled from "styled-components";
import { eventTypes } from "types/eventTracking";
import { SearchCategoryType } from "types/search";

interface SearchCategoryButtonProps {
  category: SearchCategoryType;
  resultNumber: number;
  location: "My notes" | "search modal";
}

const SearchCategoryButton = ({ category, resultNumber, location }: SearchCategoryButtonProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Search");
  const { searchCategories } = useAppSelector((state) => state.search);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isSeleted, setIsSeleted] = useState<boolean>(false);

  // Set searchCategories when isSeleted is updated
  useEffect(() => {
    if (searchCategories.selectedCategories.includes(category)) {
      setIsSeleted(true);
    } else {
      setIsSeleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCategories.selectedCategories]);

  const handleClickCategory = () => {
    if (!resultNumber) return;
    trackEvent({
      eventType: eventTypes.click.SEARCH_FILTER_BUTTON,
      eventProperties: {
        location: location,
        result: category,
        from: location === "search modal" ? "My notes" : "",
      },
    });

    // if the category is not selected, add the category to selectedCategories
    if (!searchCategories.selectedCategories.includes(category)) {
      dispatch(
        setSearchCategories({
          selectedCategories: searchCategories.selectedCategories.concat(category),
          categories: [
            // put existing selected categories first
            ...searchCategories.selectedCategories,
            category,
            ...searchCategories.categories.filter((item: SearchCategoryType) => !searchCategories.selectedCategories.includes(item) && item !== category),
          ],
        })
      );
    } else {
      dispatch(
        setSearchCategories({
          selectedCategories: searchCategories.selectedCategories.filter((item: SearchCategoryType) => item !== category),
          categories: [...searchCategories.categories.filter((item: SearchCategoryType) => item !== category), category],
        })
      );
    }
  };

  return (
    <Wrapper isSeleted={isSeleted} isHovered={isHovered} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={handleClickCategory}>
      <Typography13 text={t(`Category${category}`)} color={isSeleted ? "--blue8" : "--gray9"} weight={700} type={"span"} />
      {resultNumber && resultNumber !== 0 && <Typography13 text={` ${resultNumber.toString()}`} color={isSeleted ? "--blue8" : "--gray9"} weight={700} type={"span"} />}
      {isSeleted && isHovered && <StyledIcon icon={`x24`} color={"--blue6"} width={18} height={18} />}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isSeleted: boolean; isHovered: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ isHovered, isSeleted }) => (isHovered && isSeleted ? "6px 8px 6px 16px" : "6px 16px")};
  border-radius: 24px;
  cursor: pointer;

  border: 1px solid ${({ isSeleted }) => (isSeleted ? "var(--blue4)" : "var(--gray4)")};
  background-color: ${({ isSeleted }) => (isSeleted ? "var(--blue1)" : "var(--white)")};

  &:hover {
    background-color: ${({ isSeleted }) => (isSeleted ? "var(--blue1)" : "var(--gray3)")};
  }

  &:active {
    background-color: var(--blue1);
    border: 1px solid var(--blue4);

    & > span {
      color: var(--blue8);
    }
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: 2px;
`;

export default SearchCategoryButton;
