// auth 2.0
export const SortingOption = {
  date: 0,
  name: 1,
  update: 2,
  Date: "Date",
  Name: "Name",
  Update: "Update",
};

export const sortByDate = (documents: any) => {
  return documents.sort((a: any, b: any) => {
    //   @ts-ignore
    return new Date(b.created_time) - new Date(a.created_time);
  });
};

export const sortByName = (documents: any) => {
  return documents.sort((a: any, b: any) => {
    return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : a.title.toLowerCase() > b.title.toLowerCase() ? 1 : 0;
  });
};

export const sortByUpdated = (documents: any) => {
  return documents.sort((a: any, b: any) => {
    //@ts-ignore
    return new Date(b.updated_time) - new Date(a.updated_time);
  });
};
