/* eslint-disable react-hooks/exhaustive-deps */
import { default as React } from "react";
// import { addCodeBlock } from "../../utils";
import { AutoNotesLoaderBlockData } from "./types";
import { API, BlockAPI } from "@editorjs/editorjs";
import AutoNoteBlockView from "./AutoNoteBlockView";

const AutoSlidNotesLoader: React.FC<{
  data: AutoNotesLoaderBlockData;
  editorAPI: API;
  currentBlock: BlockAPI | undefined;
}> = ({ data: generateAutoNotesData, editorAPI, currentBlock }) => {
  return (
    <div>
      <AutoNoteBlockView {...generateAutoNotesData} editorAPI={editorAPI} currentBlock={currentBlock} />
    </div>
  );
};

export default AutoSlidNotesLoader;
