// auth 2.0

export interface ClipboardItem {
  readonly types: string[];
  readonly presentationStyle: "unspecified" | "inline" | "attachment";
  getType(): Promise<Blob>;
}

export interface ClipboardItemData {
  [mimeType: string]: Blob | string | Promise<Blob | string>;
}

declare var ClipboardItem: {
  prototype: ClipboardItem;
  new (itemData: ClipboardItemData): ClipboardItem;
};

export type ClipboardMimeType = "text/plain" | "image/png" | "text/html";

export interface DataToCopy {
  type: ClipboardMimeType;
  data: Blob | string;
}

export class CustomClipboard {
  clipBoardData: ClipboardItem[];
  navigator: any;

  constructor(private dataToCopy: DataToCopy[]) {
    this.clipBoardData = [];
    this.navigator = window.navigator;
  }

  private createClipboardObject(dataToCopy: { type: string; data: Blob | string }): ClipboardItemData {
    if (dataToCopy.type === "text/plain") {
      const text = dataToCopy.data as string;
      return {
        "text/plain": new Blob([text], {
          type: `${dataToCopy.type}; charset=UTF-8`,
        }),
      };
    } else if (dataToCopy.type === "text/html") {
      const contentDiv = document.createElement("div");
      contentDiv.id = "contentDiv";
      contentDiv.innerHTML = dataToCopy.data as string;
      document.body.appendChild(contentDiv);
      const html = contentDiv.innerHTML;
      document.body.removeChild(contentDiv);
      return {
        "text/html": new Blob([html], {
          type: `${dataToCopy.type}; charset=UTF-8`,
        }),
      };
    } else if (typeof dataToCopy.data === "string") {
      // fall back for other types
      return {
        [dataToCopy.type]: new Blob([dataToCopy.data], {
          type: `${dataToCopy.type}; charset=UTF-8`,
        }),
      };
    } else {
      //it is a blob
      return { [dataToCopy.type]: dataToCopy.data };
    }
  }

  private processClipboardData(): ClipboardItemData[] {
    return this.dataToCopy.map((dataToCopy) => {
      return this.createClipboardObject({ type: dataToCopy.type, data: dataToCopy.data });
    });
  }

  async copy(): Promise<void> {
    //TODO , return a promise if you are done....
    return new Promise(async (resolve, reject) => {
      const processedData = Object.assign({}, ...this.processClipboardData());

      this.clipBoardData.push(new ClipboardItem(processedData));

      // if no clip board, use documnent.execCommand
      if (!this.navigator.clipboard) {
        const contentDiv = document.createElement("div");
        contentDiv.id = "contentDiv";
        contentDiv.innerHTML = this.dataToCopy[0].data as string;
        document.body.appendChild(contentDiv);
        window.getSelection()?.selectAllChildren(document.getElementById("contentDiv")!);
        const isSuccess = document.execCommand("copy");
        document.body.removeChild(contentDiv);
        if (isSuccess) {
          return resolve();
        } else {
          return reject();
        }
      } else {
        return await this.navigator.clipboard.write(this.clipBoardData).then(
          () => {
            /* success */
            resolve();
          },
          (err: any) => {
            /* failure */
            reject();
          }
        );
      }
    });
  }
}
