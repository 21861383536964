import React from "react";
import { useTranslation } from "react-i18next";
import { setMarkupTool, setToolSettingMenuType } from "redux/actions/editorActions";
import { MarkupType, ToolItemProps } from "types/editor";
import { ColorPickerIcon } from "components/icons/ImageMarkupIcons/ColorPickerIcon";
import { EraserIcon } from "components/icons/ImageMarkupIcons/EraserIcon";
import { HighlighterIcon } from "components/icons/ImageMarkupIcons/HighlighterIcon";
import { PenIcon } from "components/icons/ImageMarkupIcons/PenIcon";
import { SelectElementIcon } from "components/icons/ImageMarkupIcons/SelectElementIcon";
import { TextBoxIcon } from "components/icons/ImageMarkupIcons/TextBoxIcon";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { useMediaQuery } from "react-responsive";

export const useImageMarkupTools = () => {
  const dispatch = useAppDispatch();
  const { markupTool, markupColor, activeMarkupObject, markupHighlighterMode, markupHighlighterSize, markupPenMode } = useAppSelector((state) => state.editor);
  const location = useLocation();
  const isVideoNote = location.pathname.split("/")[1] === "vdocs";
  const isMobileUI = useMediaQuery({ query: "(max-width:799px)" });

  const PEN_COLORS = [
    "rgba(236, 76, 58, 1)",
    "rgba(223, 139, 136, 1)",
    "rgba(155, 70, 179, 1)",
    "rgba(113, 80, 184, 1)",
    "rgba(86, 98, 183, 1)",
    "rgba(68, 125, 247, 1)",
    "rgba(93, 175, 240, 1)",
    "rgba(102, 192, 213, 1)",
    "rgba(119, 181, 108, 1)",
    "rgba(161, 199, 109, 1)",
    "rgba(213, 224, 106, 1)",
    "rgba(251, 233, 95, 1)",
    "rgba(242, 167, 77, 1)",
    "rgba(131, 103, 92, 1)",
    "rgba(169, 168, 168, 1)",
    "rgba(255, 255, 255, 1)",
    "rgba(8, 10, 12, 1)",
  ];

  const MARKER_COLORS = [
    "rgba(253, 129, 255, 1)",
    "rgba(255, 201, 135, 1)",
    "rgba(255, 246, 128, 1)",
    "rgba(196, 255, 120, 1)",
    "rgba(159, 252, 246, 1)",
    "rgba(196, 159, 255, 1)",
    "rgba(255, 255, 255, 1)",
    "rgba(148, 148, 148, 1)",
  ];

  const { t } = useTranslation("EditorComponent");

  const getCurrentColor = () => {
    if (activeMarkupObject) {
      if (markupTool === MarkupType.Eraser) return "var(--gray15)"; // text color is always black
      switch (activeMarkupObject.type) {
        case "path":
        case "line":
          if (activeMarkupObject.stroke.includes(".3")) {
            return activeMarkupObject.stroke.replace(".3", "1");
          } else {
            return activeMarkupObject.stroke;
          }
        case "i-text":
          return activeMarkupObject.fill;
        default:
          return PEN_COLORS[0];
      }
    } else {
      switch (markupTool) {
        case MarkupType.Select:
        case MarkupType.Eraser:
          return "var(--gray15)";
        case MarkupType.Pen:
        case MarkupType.Highlighter:
          return markupColor[markupTool];
        case MarkupType.Text:
          return markupColor.text;
        default:
          return PEN_COLORS[0];
      }
    }
  };

  const imageMarkUpTools = [
    {
      name: MarkupType.Select,
      tooltip: t("Select"),
      callback: () => {
        trackEvent({
          eventType: eventTypes.click.SELECT_IN_IMAGE_MARKUP_VIEW,
          eventProperties: {
            location: isVideoNote ? "Video note page" : "My notes",
          },
        });
        dispatch(setMarkupTool(MarkupType.Select));
      },
    },
    {
      name: MarkupType.Text,
      tooltip: t("Text"),
      callback: () => {
        trackEvent({
          eventType: eventTypes.click.TEXT_IN_IMAGE_MARKUP_VIEW,
          eventProperties: {
            location: isVideoNote ? "Video note page" : "My notes",
          },
        });
        dispatch(setMarkupTool(MarkupType.Text));
      },
    },
    {
      name: MarkupType.Pen,
      callback: () => {
        trackEvent({
          eventType: eventTypes.click.PEN_IN_IMAGE_MARKUP_VIEW,
          eventProperties: {
            option: `${markupPenMode}, ${markupHighlighterSize}`,
            location: isVideoNote ? "Video note page" : "My notes",
          },
        });
        dispatch(setMarkupTool(MarkupType.Pen));
        dispatch(setToolSettingMenuType(MarkupType.Pen));
      },
    },
    {
      name: MarkupType.Highlighter,
      callback: () => {
        trackEvent({
          eventType: eventTypes.click.HIGHLIGHT_IN_IMAGE_MARKUP_VIEW,
          eventProperties: {
            option: `${markupHighlighterMode}, ${markupHighlighterSize}`,
            location: isVideoNote ? "Video note page" : "My notes",
          },
        });
        dispatch(setMarkupTool(MarkupType.Highlighter));
        dispatch(setToolSettingMenuType(MarkupType.Highlighter));
      },
    },
    {
      name: MarkupType.ColorPicker,
      callback: () => {
        trackEvent({
          eventType: eventTypes.click.COLOR_IN_IMAGE_MARKUP_VIEW,
          eventProperties: {
            location: isVideoNote ? "Video note page" : "My notes",
          },
        });
        dispatch(setToolSettingMenuType(MarkupType.ColorPicker));
      },
    },
    {
      name: MarkupType.Eraser,
      tooltip: t("Eraser"),
      callback: () => {
        trackEvent({
          eventType: eventTypes.click.ERASER_IN_IMAGE_MARKUP_VIEW,
          eventProperties: {
            location: isVideoNote ? "Video note page" : "My notes",
          },
        });
        dispatch(setMarkupTool(MarkupType.Eraser));
      },
    },
  ] as ToolItemProps[];

  const renderToolIcon = (toolName: MarkupType) => {
    const ICON_SIZE = isMobileUI ? 44 : 48;
    switch (toolName) {
      case MarkupType.Pen:
        return <PenIcon color={markupTool === MarkupType.Pen ? `var(--blue6)` : `var(--gray9)`} width={ICON_SIZE} height={ICON_SIZE} />;
      case MarkupType.Highlighter:
        return <HighlighterIcon color={markupTool === MarkupType.Highlighter ? `var(--blue6)` : `var(--gray9)`} width={ICON_SIZE} height={ICON_SIZE} />;
      case MarkupType.ColorPicker:
        return <ColorPickerIcon color={getCurrentColor()} width={ICON_SIZE} height={ICON_SIZE} />;
      case MarkupType.Eraser:
        return <EraserIcon color={markupTool === MarkupType.Eraser ? `var(--blue6)` : `var(--gray9)`} width={ICON_SIZE} height={ICON_SIZE} />;
      case MarkupType.Select:
        return <SelectElementIcon color={markupTool === MarkupType.Select ? `var(--blue6)` : `var(--gray9)`} width={ICON_SIZE} height={ICON_SIZE} />;
      case MarkupType.Text:
        return <TextBoxIcon color={markupTool === MarkupType.Text ? `var(--blue6)` : `var(--gray9)`} width={ICON_SIZE} height={ICON_SIZE} />;
      default:
        return null;
    }
  };

  return { imageMarkUpTools, renderToolIcon, PEN_COLORS, MARKER_COLORS };
};
