//auth 2.0
import "./index.css";
/**
 * Drag/Drop feature for Editor.js.
 *
 * @typedef {Object} DragDrop
 * @description Feature's initialization class.
 * @property {Object} api — Editor.js API
 * @property {HTMLElement} holder — DOM element where the editor is initialized.
 * @property {Number} startBlock - Dragged block position.
 * @property {Number} endBlock - Position where the dragged block is gonna be placed.
 */
export default class TouchDeviceDragDrop {
  /**
   * @param editor: object
   *   editor — Editor.js instance object
   */
  constructor(editor, isReadOnly) {
    this.editor = editor;
    this.api = this.editor.blocks;
    this.holder = document.getElementById(this.editor.configuration.holder);
    this.toolbar = this.editor.toolbar;
    this.editorContainer = this.holder.closest("#editor-container") || this.holder.closest("#editor-component");
    this.isReadOnly = isReadOnly;

    this.borderStyle = "1px dashed var(--blue7)";

    this.startBlock = null;
    this.endBlock = null;
    this.lastBlock = null;

    this.dragEventCounter = 0;

    this.initPositionY = null;
    this.lastDropTarget = null;

    this.setDragStartListener();

    this.flag = null;
    this.scrollSpeed = null;
  }

  setPreventDefault(event) {
    event.preventDefault();
  }

  /**
   * Sets the drag start events listener.
   * 1. set init position of the target
   * 2. set startBlock and lastBlock
   * 3. set ondrag and drop eventListener
   */
  setDragStartListener() {
    if (this.isReadOnly) return;
    const settingsButton = this.holder.querySelector(".ce-toolbar__settings-btn");

    settingsButton.setAttribute("draggable", "true");
    settingsButton.addEventListener("dragstart", () => {
      this.startBlock = this.api.getCurrentBlockIndex();
    });

    this.holder.addEventListener("dragstart", (event) => {
      this.startBlock = this.api.getCurrentBlockIndex();
      this.firstBlock = this.api.getBlockByIndex(0);
      this.lastBlock = this.api.getBlockByIndex(this.api.getBlocksCount() - 1);
      const { target } = event;

      if (this.holder.contains(target)) {
        // set initial mouse position.
        this.initPositionY = event.clientY;
      }

      this.handleDrag = this.handleDrag.bind(this);
      this.handleDrop = this.handleDrop.bind(this);

      this.addDragDropEvent.call(this);
    });
  }

  addDragDropEvent() {
    this.holder.addEventListener("dragenter", (e) => e.preventDefault());
    this.holder.addEventListener("dragover", (e) => e.preventDefault());
    this.holder.addEventListener("drag", this.handleDrag);
    this.holder.addEventListener("drop", this.handleDrop);
  }

  removeDragEvent() {
    this.holder.removeEventListener("drag", this.handleDrag);
  }

  handleDrag(event) {
    this.toolbar.close(); // this closes the toolbar when we start the drag
    if (this.isTheOnlyBlock()) return;
    const allBlocks = this.holder.querySelectorAll(".ce-block");
    const blockFocused = this.holder.querySelector(".ce-block--drop-target");
    this.setElementCursor(blockFocused);
    this.setBorderBlocks(allBlocks, blockFocused);

    if (event.clientY > this.lastBlock.holder.getBoundingClientRect().y) {
      this.lastBlock.holder.classList.add("ce-block--drop-target");
    }

    let distanceFromOrigin;

    distanceFromOrigin = Math.abs(event.y - this.initPositionY);
    if (event.y > this.initPositionY) {
      this.scrollSpeed = distanceFromOrigin / 200;
    } else if (event.y < this.initPositionY) {
      this.scrollSpeed = -distanceFromOrigin / 200;
    }

    // if (
    //   window.innerHeight * 0.2 >= event.y ||
    //   window.innerHeight * 0.8 <= event.y
    // )
    //   this.editorContainer.scrollTop += this.scrollSpeed;

    // Delete selection on drag
    const selectedBlocks = this.holder.querySelectorAll(".ce-block--selected");
    if (selectedBlocks) {
      Array.from(this.holder.querySelectorAll(".ce-block--selected")).forEach((selectedElement) => {
        selectedElement.className = selectedElement.className.replace("ce-block--selected", "");
      });
    }
  }

  /**
   * Sets the drop events listener.
   */
  handleDrop(event) {
    event.stopImmediatePropagation();
    const { target } = event;
    if (this.holder.contains(target)) {
      const dropTarget = this.getDropTarget(target);
      if (dropTarget) {
        const blockContent = dropTarget.querySelector(".ce-block__content");
        blockContent.style.removeProperty("border-top");
        blockContent.style.removeProperty("border-bottom");
        this.endBlock = this.getTargetPosition(dropTarget);
      } else {
        const lastBlockContent = this.lastBlock.holder.querySelector(".ce-block__content");
        lastBlockContent.style.removeProperty("border-top");
        lastBlockContent.style.removeProperty("border-bottom");
        this.endBlock = this.getTargetPosition(this.lastBlock.holder);
      }

      this.moveBlocks();
    }

    this.removeDragEvent.call(this);

    var evt = document.createEvent("MouseEvents");
    evt.initEvent("mouseup", true, true);
    document.dispatchEvent(evt);
    this.editor.toolbar.open();
  }

  /**
   * Sets the cursor at the begining of the drag element
   */
  setElementCursor(element) {
    if (!element) return;
    const range = document.createRange();
    const selection = window.getSelection();

    range.setStart(element.childNodes[0], 0);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
    element.focus();
  }

  /**
   * Sets dinamically the borders in the blocks when a block is dragged
   * @param {object} allBlocks Contains all the blocks in the holder
   * @param {HTMLElement} blockFocused Is the element where the dragged element will be dropped.
   */

  setBorderBlocks(allBlocks, blockFocused) {
    Object.values(allBlocks).forEach((block) => {
      const blockContent = block.querySelector(".ce-block__content");
      if (block !== blockFocused) {
        blockContent.style.removeProperty("border-top");
        blockContent.style.removeProperty("border-bottom");
      } else {
        const index = Object.keys(allBlocks).find((key) => allBlocks[key] === blockFocused);
        if (index > this.startBlock) blockContent.style.borderBottom = this.borderStyle;
        else blockContent.style.borderTop = this.borderStyle;
      }
    });
  }

  /**
   * Notify core that read-only mode is suppoorted
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Returns the closest block DOM element to the drop event target.
   *
   * @param {HTMLElement} target  DOM element which received the drop event.
   * @returns {HTMLElement}
   */
  getDropTarget(target) {
    return target.classList.contains("ce-block") ? target : target.closest(".ce-block");
  }

  /**
   * Returns the target position in the child subtree.
   *
   * @param {HTMLElement} target  DOM element which received the drop event.
   * @returns {Number}
   */
  getTargetPosition(target) {
    return Array.from(target.parentNode.children).indexOf(target);
  }

  isTheOnlyBlock() {
    return this.api.getBlocksCount() === 1;
  }

  /**
   * Moves the dragged element to the drop position.
   *
   * @see {@link https://editorjs.io/blocks#move}
   */
  moveBlocks() {
    if (!this.isTheOnlyBlock() && this.startBlock !== null) {
      this.api.move(this.endBlock, this.startBlock);
    }
  }
}
