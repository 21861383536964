import React from "react";
import styled from "styled-components";
import { ModalTypes, CustomModalProps, Typography16, Icon, Button, Typography28 } from "@slid/slid-ips";
import { Trans, useTranslation } from "react-i18next";
import { useAppSelector } from "hooks";

interface showStudyChallengeModalProps {
  showModal: (props: CustomModalProps) => void;
  closeModal: (props: CustomModalProps) => void;
  onDimClick: () => void;
}

const showStudyChallengeInformationModal = async (props: showStudyChallengeModalProps) => {
  const { showModal, closeModal, onDimClick } = props;
  // TODO: update the tracking code accordingly:
  // trackEvent({ eventType: eventTypes.show.WEBSITE_SELECT_MODAL_IN_DEMO_VIDEO_NOTE_PAGE });
  const modalProps: CustomModalProps = {
    type: ModalTypes.customModal,
    closableDim: true,
    // @ts-ignore
    onDimClick: onDimClick,
    customComponent: <StudyChallengeModalContainer closeModal={closeModal} />,
  };
  showModal(modalProps);
};

interface StudyChallengeModalContainerProps {
  closeModal: (props: CustomModalProps) => void;
}

const StudyChallengeModalContainer = (props: StudyChallengeModalContainerProps) => {
  const { closeModal } = props;
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const { t } = useTranslation("StudyChallenge");

  return (
    <Container>
      <Image src={`/src/assets/study_challenge/failed_modal_${lang === "ko" ? "ko" : "en"}.webp`} />
      <Typography28
        type="span"
        weight={700}
        // @ts-ignore
        text={
          <Trans
            components={{
              colored: (
                <Typography28 type="span" weight={700} text={t("InformationModal.TitleColoredText", { keySeparator: "." })} color={`--blue8`} textAlign={"center"} style={{ display: "inline" }} />
              ),
            }}
          >
            {t("InformationModal.Title", { keySeparator: "." })}
          </Trans>
        }
        color="--gray17"
        marginTop="24px"
        textAlign="center"
      />
      <DescriptionArea>
        {t("InformationModal.Description", { keySeparator: ".", joinArrays: "||" })
          .split("||")
          .map((descriptionText, index) => (
            <DescriptionRow key={index}>
              <Icon icon="check24" color="--blue8" />
              <Typography16 weight={500} text={descriptionText} color="--gray15" />
            </DescriptionRow>
          ))}
      </DescriptionArea>
      <ButtonRow>
        <Button appearance="line" callback={closeModal} size="large" text={t("InformationModal.SecondaryButtonText", { keySeparator: "." })} />
        <Button
          appearance="primary"
          callback={() => {
            if (lang === "ko") {
              window.open("https://slid.oopy.io/14days-challenge");
            } else {
              window.open("https://slid.oopy.io/14days-challenge-en");
            }
          }}
          size="large"
          text={t("InformationModal.PrimaryButtonText", { keySeparator: "." })}
        />
      </ButtonRow>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 39px;
  padding: 48px 36px;
  background-color: var(--gray1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 400px;
`;

const DescriptionArea = styled.div`
  padding-top: 12px;
`;

const DescriptionRow = styled.div`
  display: flex;
  margin-top: 4px;
  gap: 8px;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 7px;
  margin-top: 32px;
  justify-content: center;
`;

export default showStudyChallengeInformationModal;
