import React from "react";
import styled from "styled-components";
import { Tooltip } from "@slid/slid-ips";
import { RedoIcon } from "components/icons/ImageMarkupIcons/RedoIcon";
import { ResetIcon } from "components/icons/ImageMarkupIcons/ResetIcon";
import { UndoIcon } from "components/icons/ImageMarkupIcons/UndoIcon";
import { isMacOs } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { isTouchDevice } from "utils/utils";

interface Props {
  handleUndo: () => void;
  handleRedo: () => void;
  handleReset: () => void;
  canRedo: boolean;
  canUndo: boolean;
  canReset: boolean;
  focusCanvas: () => void;
}

const ImageMarkupController = ({ handleUndo, handleRedo, handleReset, canRedo, canUndo, canReset, focusCanvas }: Props) => {
  const { t } = useTranslation("EditorComponent");

  return (
    <ControllerContainer onClick={focusCanvas}>
      <Tooltip title={t("Undo")} description={isMacOs ? "(Cmd + Z)" : "(Ctrl + Z)"} placement="top" zIndex={999999} disabled={isTouchDevice()}>
        <ControlItem onClick={handleUndo} disabled={!canUndo}>
          <UndoIcon width={48} height={48} color={`var(--gray5)`} />
        </ControlItem>
      </Tooltip>
      <Tooltip title={t("Redo")} description={isMacOs ? "(Cmd + Shift + Z)" : "(Ctrl + Shift + Z)"} placement="top" zIndex={999999} disabled={isTouchDevice()}>
        <ControlItem onClick={handleRedo} disabled={!canRedo}>
          <RedoIcon width={48} height={48} color={`var(--gray5)`} />
        </ControlItem>
      </Tooltip>
      <Tooltip title={t("Reset")} placement="top" zIndex={999999} disabled={isTouchDevice()}>
        <ControlItem onClick={handleReset} disabled={!canReset}>
          <ResetIcon width={48} height={48} color={`var(--gray5)`} />
        </ControlItem>
      </Tooltip>
    </ControllerContainer>
  );
};

const ControllerContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px 8px 20px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background-color: var(--gray17);
`;

const ControlItem = styled.button`
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;

  &:focus {
    outline: 0;
    border: none;
  }
  padding: 0;
  /* e of reset button  */

  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray13);
    transition: background-color 0.2s ease-in-out;
  }

  &:active {
    background-color: var(--gray15);
    transition: background-color 0.2s ease-in-out;
  }

  &:disabled {
    cursor: not-allowed;
    svg path {
      stroke: var(--gray14);
    }

    &:hover {
      background-color: inherit;
    }

    &:active {
      background-color: inherit;
    }
  }
`;

export default ImageMarkupController;
