import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";

const linkPreview_endpoint = `link-preview/`;

export const ServerInterface_getLinkPreview = async ({ url }) => {
  //TODO: add authentication on the backend
  let urlMetaData = null;
  try {
    const urlMetaDataGetResponse = await slidAxios.get(`${linkPreview_endpoint}?url=${url}`);
    urlMetaData = urlMetaDataGetResponse.data;
  } catch (error) {
    if (!urlMetaData) urlMetaData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      urlMetaData.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          urlMetaData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // is handled within the link preview component
          break;
        case 403:
          urlMetaData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          urlMetaData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      urlMetaData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      urlMetaData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return urlMetaData;
  }
};
