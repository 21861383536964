import { Icon, Typography17 } from "@slid/slid-ips";
import { useAppDispatch } from "hooks";
import useUpdateSLTViewVisibility from "hooks/whisperSLT/useUpdateSLTViewVisibility";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SmartLiveTextViewHeader = () => {
  const { t } = useTranslation(["SmartLiveText"]);
  const { onToggleOffSLTMoreButton } = useUpdateSLTViewVisibility();

  return (
    <TranscriptHeader>
      <HeaderTitle>
        <Typography17 text={t("SmartLiveText", { ns: "SmartLiveText" })} color={"--gray2"} weight={700} />
      </HeaderTitle>
      <HeaderSetting>
        <CloseButton onClick={onToggleOffSLTMoreButton}>
          <Icon icon={`x24`} color={`--gray2`} />
        </CloseButton>
      </HeaderSetting>
    </TranscriptHeader>
  );
};

const TranscriptHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px 16px 19px;
  background-color: #191c20;
  min-width: 100%;
  position: relative;
  min-height: 72px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: baseline;
  gap: 6px;
  p {
    user-select: none;
  }
`;

const HeaderSetting = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
`;

const CloseButton = styled.div`
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background-color: var(--gray15);
  }

  :active {
    background-color: var(--gray17);
  }
`;

export default SmartLiveTextViewHeader;
