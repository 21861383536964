import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SmartLiveTextBox from "./SmartLiveTextBox";
import { Typography13 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import useWhisperSLTStore from "store/useWhisperSLTStore";

const SmartLiveTextBoxMemo = React.memo(SmartLiveTextBox);
const SmartLiveTextViewBody = memo(() => {
  const { transcriptChunk, resetTranscriptChunk, transcriptData, isManualAddingMode, isSTTToggledOn } = useWhisperSLTStore();
  const transcriptListRef = useRef<HTMLDivElement>(null);
  const endOfTranscriptRef = useRef<HTMLDivElement>(null);
  const [shouldAutoScroll, setShouldAutoScroll] = useState<boolean>(true);
  const [isTextAddedOnce, setIsTextAddedOnce] = useState<{ [index: number]: boolean }>({});

  const { t } = useTranslation("SmartLiveText");

  const handleAddToNoteButtonInteraction = (id: number, isOnceClicked: boolean) => {
    setIsTextAddedOnce((prev) => ({ ...prev, [id]: isOnceClicked }));
  };

  useEffect(() => {
    if (transcriptData.length > 0) {
      resetTranscriptChunk();
    }
  }, [transcriptData]);

  //observer for auto scroll
  useEffect(() => {
    if (!endOfTranscriptRef.current) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShouldAutoScroll(true);
        } else {
          setShouldAutoScroll(false);
        }
      },
      { threshold: 0.1 }
    );
    observer.observe(endOfTranscriptRef.current);
    return () => {
      observer.disconnect();
    };
  }, [endOfTranscriptRef.current]);

  //handle scroll to bottom if auto scroll is true
  useEffect(() => {
    if (!transcriptListRef.current) return;
    if (!endOfTranscriptRef.current) return;
    if (shouldAutoScroll) {
      //scroll to bottom with smooth scroll
      endOfTranscriptRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [shouldAutoScroll, transcriptListRef.current, transcriptData, isManualAddingMode, isSTTToggledOn]);

  return (
    <TranscriptBody ref={transcriptListRef}>
      {/* {!isManualAddingMode && <Typography13 text={t("AutoAddingModeGuideText")} color={`--gray9`} marginTop={`40px`} textAlign="center" />} */}
      {isSTTToggledOn &&
        transcriptData.length > 0 &&
        transcriptData.map((data: { text: string }, index: number) => {
          return (
            <SmartLiveTextBoxMemo
              {...data}
              index={index}
              key={`slt-${index}`}
              isTextAddedOnce={isTextAddedOnce[index]}
              onAddToNoteButtonInteraction={(newState) => handleAddToNoteButtonInteraction(index, newState)}
              isManualAddingMode={isManualAddingMode}
            />
          );
        })}

      {isSTTToggledOn && (
        <SmartLiveTextBoxMemo
          {...{ text: transcriptChunk.map((block) => block.text).join(" "), isFinal: true, isTyping: true, wordsCount: 0, isWhisperTranscribed: false }}
          index={0}
          isLastData={true}
        />
      )}

      <EndOfTranscript ref={endOfTranscriptRef} />
    </TranscriptBody>
  );
});

const TranscriptBody = styled.div`
  height: 100%;
  flex: 1;
  padding: 12px 7px 12px 12px;
  background-color: var(--gray19);
  min-width: calc(100% - 5px);
  overflow-y: scroll;
  margin-right: 5px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray11);
    border-radius: 8px;
  }
`;

const EndOfTranscript = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  min-width: 100%;
  position: relative;
  height: 72px;
`;

export default SmartLiveTextViewBody;
