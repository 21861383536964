import { PlanLengthType, PlanMembershipType, PlanTypeProps, PricingProps } from "components/pricing/types";
import { setIsCashbackUser, setIsEligibleForCashback, setMembership } from "redux/actions/pricingActions";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { useUpdateEffect } from "../utils/customHook/customHook";

export const useGetCurrentPricingPlan = () => {
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const { planType, planLength, membership, isCashbackUser, isCashbackOptionChecked, isEligibleForCashback } = useAppSelector((state) => state.pricing);
  // monthly price is displayed on the plan option section only for users
  const [monthlyPrice, setMonthlyPrice] = useState("");
  //checkout price is number for stripe/payple payment
  const [checkoutPrice, setCheckoutPrice] = useState(0);

  const pricingOptions: PricingProps = {
    daypass: {
      short: {
        standard: {
          checkout: lang === "ko" ? 3900 : 3.99,
          beforeSaleDisplay: "",
          afterSaleDisplay: lang === "ko" ? "3,900원" : "$3.99",
        },
        premium: {
          checkout: 0,
          beforeSaleDisplay: "",
          afterSaleDisplay: "",
        },
      },
      long: {
        standard: {
          beforeSaleCheckout: lang === "ko" ? "3,900원" : "$3.99",
          checkout: lang === "ko" ? 9900 : 9.99,
          beforeSaleDisplay: lang === "ko" ? "19,500원" : "$19.95",
          afterSaleDisplay: lang === "ko" ? "9,900원" : "$9.99",
        },
        premium: {
          checkout: 0,
          beforeSaleDisplay: "",
          afterSaleDisplay: "",
        },
      },
    },
    subscription: {
      short: {
        standard: {
          checkout: lang === "ko" ? 19900 : 19.99,
          beforeSaleDisplay: "",
          afterSaleDisplay: lang === "ko" ? "19,900원" : "$19.99",
        },
        premium: {
          checkout: lang === "ko" ? 39900 : 39.99,
          beforeSaleDisplay: "",
          afterSaleDisplay: lang === "ko" ? "39,900원" : "$39.99",
        },
      },
      long: {
        standard: {
          beforeSaleCheckout: lang === "ko" ? "19,900원" : "$19.99",
          checkout: lang === "ko" ? 199900 : 199.99,
          beforeSaleDisplay: lang === "ko" ? "19,900원" : "$19.99",
          afterSaleDisplay: lang === "ko" ? "16,600원" : "$16.66",
        },
        premium: {
          beforeSaleCheckout: lang === "ko" ? "39,900원" : "$39.99",
          checkout: lang === "ko" ? 359900 : 359.99,
          beforeSaleDisplay: lang === "ko" ? "39,900원" : "$39.99",
          afterSaleDisplay: lang === "ko" ? "29,900원" : "$29.99",
          cashback: lang === "ko" ? "21,600원" : "$21.69",
        },
      },
    },
  };

  useEffect(() => {
    const pricing = pricingOptions[planType as PlanTypeProps][planLength as PlanLengthType][membership as PlanMembershipType];
    // this effect is for updating monthly price and checkout price
    if (isCashbackUser && pricing.cashback) {
      setMonthlyPrice(pricing.cashback);
    } else {
      setMonthlyPrice(pricing.afterSaleDisplay);
    }
    setCheckoutPrice(pricing.checkout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCashbackUser, planType, membership, planLength, lang]);

  const getCheckoutPrice = ({ planType, planLength, membership }: { planType: PlanTypeProps; planLength: PlanLengthType; membership: PlanMembershipType }) => {
    return pricingOptions[planType][planLength][membership].checkout;
  };

  useUpdateEffect(() => {
    if (planType === "daypass") {
      dispatch(setMembership("standard"));
    } else {
      dispatch(setMembership("premium"));
    }
  }, [planType]);

  useEffect(() => {
    /**
     * cashback event is only available for 12 months premium subscription.
     */
    if (planType === "subscription" && planLength === "long" && membership === "premium") {
      dispatch(setIsEligibleForCashback(true));
    } else {
      dispatch(setIsEligibleForCashback(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planType, planLength, membership]);

  useEffect(() => {
    /**
     * if cashback event is available, set isCashbackUser to true so that user will apply for the cashback event automatically.
     * However, if user unchecked cashback option checkbox, it will be not updated automatically as true even though cashback event is avaliable.
     * initial state of isCashbackOptionChecked is true.
     */
    if (isEligibleForCashback && isCashbackOptionChecked) {
      dispatch(setIsCashbackUser(true));
    } else {
      dispatch(setIsCashbackUser(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEligibleForCashback, isCashbackOptionChecked]);

  return { monthlyPrice, checkoutPrice, pricingOptions, getCheckoutPrice };
};
