import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const SelectElementIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 48} height={height ? height : 48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.539 31.9973C21.4733 32.142 21.3657 32.2637 21.23 32.3466C21.0944 32.4294 20.937 32.4697 20.7783 32.4621C20.6195 32.4545 20.4667 32.3994 20.3396 32.304C20.2125 32.2086 20.1169 32.0772 20.0653 31.9268L14.8779 16.0376C14.8355 15.8996 14.8314 15.7527 14.8662 15.6125C14.9009 15.4724 14.9733 15.3444 15.0753 15.2424C15.1774 15.1403 15.3053 15.0679 15.4454 15.0332C15.5855 14.9984 15.7324 15.0024 15.8703 15.0449L31.7589 20.2305C31.9093 20.2821 32.0406 20.3777 32.136 20.5048C32.2314 20.632 32.2865 20.7848 32.2941 20.9436C32.3017 21.1024 32.2615 21.2598 32.1786 21.3955C32.0958 21.5312 31.9741 21.6388 31.8294 21.7046L27.3864 23.6372C27.2677 23.689 27.1635 23.769 27.0828 23.8702C27.0021 23.9714 26.9474 24.0909 26.9234 24.2181C26.8995 24.3454 26.907 24.4765 26.9453 24.6002C26.9836 24.7239 27.0515 24.8363 27.1432 24.9277L32.0726 29.8581C32.2213 30.0071 32.3049 30.209 32.3049 30.4195C32.3049 30.63 32.2213 30.8319 32.0726 30.9808L30.8152 32.2395C30.6663 32.3883 30.4644 32.4719 30.2539 32.4719C30.0435 32.4719 29.8416 32.3883 29.6927 32.2395L24.7614 27.31C24.67 27.2183 24.5575 27.1504 24.4338 27.1122C24.3101 27.0739 24.1789 27.0665 24.0517 27.0906C23.9245 27.1147 23.8051 27.1695 23.704 27.2503C23.6028 27.3311 23.5229 27.4355 23.4713 27.5542L21.539 31.9973Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
