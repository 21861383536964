import { Typography15, Typography13, Icon } from "@slid/slid-ips";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const NO_EDIT_ON_BREAK_LOCAL_STORAGE = "show-cannot-edit-on-break-mode-popup";

const CannotEditOnBreakModePopUp = () => {
  const { t } = useTranslation();

  const [isPopupClosed, setIsPopupClosed] = useState<boolean>(false);

  if (window.localStorage.getItem(NO_EDIT_ON_BREAK_LOCAL_STORAGE) !== "true" || isPopupClosed) {
    return null;
  }

  return (
    <OnboardingPopupWrapper>
      <Icon
        icon={`x24`}
        onClick={() => {
          window.localStorage.clear();
          setIsPopupClosed(true);
        }}
        color={`--blue6`}
        style={{ position: "absolute", top: "4px", right: "4px", cursor: "pointer" }}
      />
      <Icon icon={`check24`} color={`--gray17`} />
      <PopupTextWrapper>
        <Typography15 text={t("CannotEditOnBreakModePopupTitle", { ns: "ViewNote" })} color="--gray17" weight={700} />
        <Typography13 text={t("CannotEditOnBreakModePopupContent", { ns: "ViewNote" })} color="--gray17" marginTop="2px" />
      </PopupTextWrapper>
    </OnboardingPopupWrapper>
  );
};

const OnboardingPopupWrapper = styled.div`
  position: absolute;
  top: 28px;
  margin-top: 12px;
  background-color: var(--blue1);
  border: 2px solid var(--blue6);
  border-radius: 8px;
  padding: 16px 44px 18px 16px;
  min-width: 27rem;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const PopupTextWrapper = styled.div`
  margin-left: 12px;
`;

export default CannotEditOnBreakModePopUp;
