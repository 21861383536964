import { useAppSelector } from "hooks";
import { useEffect } from "react";
import useWhisperSLTStore from "store/useWhisperSLTStore";

const useUpdateSLTViewVisibility = () => {
  const { isManualAddingMode, showSmartLiveTextView, isSTTMoreButtonClicked, setIsSTTMoreButtonClicked, setIsSTTToggledOn, setShowSmartLiveTextView } = useWhisperSLTStore();

  const { isExtensionMediaPermitted } = useAppSelector((state) => state.vdocs);
  const { applicationType } = useAppSelector((state) => state.slidGlobal);

  useEffect(() => {
    if (isExtensionMediaPermitted && !isManualAddingMode && showSmartLiveTextView && !isSTTMoreButtonClicked) {
      setShowSmartLiveTextView(false);
    }
  }, [isManualAddingMode, showSmartLiveTextView, isSTTMoreButtonClicked, isExtensionMediaPermitted]);

  const onToggleOffSLTMoreButton = () => {
    if (!isExtensionMediaPermitted && applicationType === "extension") {
      setIsSTTToggledOn(false);
    }
    setIsSTTMoreButtonClicked(false);
    setShowSmartLiveTextView(false);
  };

  return { onToggleOffSLTMoreButton };
};

export default useUpdateSLTViewVisibility;
