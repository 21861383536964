// auth 2.0
export const checkIfVersionIsAvailable = ({ requiredVersion, currentVersion }) => {
  if (!requiredVersion || !currentVersion) return;

  if (parseInt(requiredVersion.split(".")[0]) < parseInt(currentVersion.split(".")[0])) {
    return true;
  } else if (parseInt(requiredVersion.split(".")[0]) === parseInt(currentVersion.split(".")[0])) {
    if (parseInt(requiredVersion.split(".")[1]) < parseInt(currentVersion.split(".")[1])) {
      return true;
    } else if (parseInt(requiredVersion.split(".")[1]) === parseInt(currentVersion.split(".")[1])) {
      if (parseInt(requiredVersion.split(".")[2]) <= parseInt(currentVersion.split(".")[2])) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};
