// auth 2.0
import React from "react";
import styled from "styled-components";
import LoadingScreenContent from "./LoadingScreenContent";
import { useAppSelector } from "hooks";

const EditorNoteLoadingScreen = () => {
  const { isEditorNoteLoading } = useAppSelector((state) => state.vdocs);
  return (
    <EditorNoteLoadingWrapper show={isEditorNoteLoading}>
      <LoadingScreenContent />
    </EditorNoteLoadingWrapper>
  );
};

export default EditorNoteLoadingScreen;

const EditorNoteLoadingWrapper = styled.div<{ show: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: ${(props) => (props.show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
`;
