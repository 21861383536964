export const SuccessEventType = {
  //FREE TRIAL
  START_FREE_TRIAL: "Start free trial",

  //24H DAY-PASS
  _24H_PASS_ACTIVATION: "Success 24H Pass activation",

  //ELSE
  SUBSCRIPTION_CANCELLATION: "Subscription-cancellation completed", // should keep legacy
  CLIP_RECORDING_START: "Success CLIP RECORDING START",
  MEMBERSHIP_PAYMENT: "Buy membership",

  // Options in video note
  DELETE_CAPTURED_IMAGE_FROM_ALBUM: "Success DELETE CAPTURED IMAGE FROM ALBUM",

  AUTO_NOTES_PERMISSION: "Success AUTO-NOTES PERMISSION in video note page",
  SMART_LIVETEXT_PERMISSION: "Success SMART LIVETEXT PERMISSION in video note page",
  VIDEO_SNIPPET_PERMISSION: "Success VIDEO SNIPPET PERMISSION in video note page",

  AUTO_NOTES_START: "Success AUTO-NOTES START in Video note page",
};
