import React, { memo } from "react";
import { Typography15, Typography24, Typography20 } from "@slid/slid-ips";
import styled from "styled-components";
import { useAppSelector } from "hooks";

interface ExtensionOnboardingModalContainerProps {
  image: string;
  iconSource: string;
  title: string;
  description: string;
  buttonText: string;
  onClick: () => void;
}

const ExtensionOnboardingModalContainer = memo((props: ExtensionOnboardingModalContainerProps) => {
  const { image, iconSource, title, description, buttonText, onClick } = props;
  const { lang } = useAppSelector((state) => state.slidGlobal);
  return (
    <Container onClick={onClick}>
      <Image src={image} />
      <PaddingContainer>
        <Icon src={iconSource} />
        {lang === "ko" ? (
          <>
            <Typography24 weight={700} text={title} color="--gray17" />
            <Typography15 marginTop={"16px"} weight={700} text={description} color="--gray9" />
          </>
        ) : (
          <>
            <Typography20 weight={700} text={title} color="--gray17" />
            <Typography15 marginTop={"16px"} weight={700} text={description} color="--gray9" />
          </>
        )}
      </PaddingContainer>
      <BottomButton>
        <Typography20 weight={700} text={buttonText} color={"--blue8"} />
      </BottomButton>
    </Container>
  );
});

const Container = styled.div`
  cursor: pointer;
  border: 1px solid #fcefee;
  width: 342px;
  height: 464px;
  background-color: #f8f9fa;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.25s ease-in;

  &:hover {
    background-color: #fefeff;
    box-shadow: 0px 4px 100px -30px rgba(0, 0, 0, 0.3);
  }
`;

const Image = styled.img`
  display: block;
  width: 342px;
  height: 174px;
  background-color: #e7e6ee;
`;

const PaddingContainer = styled.div`
  margin-left: 34px; // not using padding-left because of the 1px bug
  display: flex;
  flex: 1 0;
  flex-direction: column;
`;

const Icon = styled.img`
  display: block;
  width: 22px;
  height: 22px;
  margin-top: 30px;
  margin-bottom: 19px;
`;

const BottomButton = styled.div`
  margin-left: 34px; // not using padding-left because of the 1px bug
  margin-bottom: 41px;
`;

export default ExtensionOnboardingModalContainer;
