import AutoSlidNotesLoader from "./AutoNotesLoader";
import { BlockAPI } from "@editorjs/editorjs";
import { BlockToolConstructorOptions } from "@editorjs/editorjs";
import { AutoNotesLoaderBlockData } from "./types";
import { API, BlockTool } from "@editorjs/editorjs";
import * as React from "react";
import ReactDOM from "react-dom";

class AutoSlidNotesLoaderBlock implements BlockTool {
  private api: API;
  private data: AutoNotesLoaderBlockData;
  private wrapper: HTMLDivElement | undefined;
  private block: BlockAPI | undefined;
  private readOnly: boolean;

  constructor({ api, data, config, readOnly, block }: BlockToolConstructorOptions<AutoNotesLoaderBlockData>) {
    this.api = api;
    this.data = data;
    this.api = api;
    this.readOnly = readOnly;
    this.block = block;
    this.wrapper = document.createElement("div");
  }

  render(): HTMLDivElement {
    const wrapper = this.wrapper!;
    // Create a component to render
    ReactDOM.render(<AutoSlidNotesLoader data={this.data} editorAPI={this.api} currentBlock={this.block} />, wrapper);

    return wrapper;
  }

  save(blockContent: HTMLElement): AutoNotesLoaderBlockData {
    //this block does not need to be saved. but here we just put the original data back such that if it is rendered it can re-run with the origial data, do it's work and delete itself later.
    return this.data;
  }

  validate(savedData: AutoNotesLoaderBlockData): boolean {
    //should check if the data is valid
    return true;
  }

  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }
}

export default AutoSlidNotesLoaderBlock;
