//auth 2.0
/**
 * Build styles
 */
import "./index.css";

/**
 * @typedef {object} HeaderData
 * @description Tool's input and output data format
 * @property {string} text — Header's content
 * @property {number} level - Header's level from 1 to 6
 */

/**
 * @typedef {object} HeaderConfig
 * @description Tool's config from Editor
 * @property {string} placeholder — Block's placeholder
 * @property {number[]} levels — Heading levels
 * @property {number} defaultLevel — default level
 */

/**
 * Header block for the Editor.js.
 *
 * @author CodeX (team@ifmo.su)
 * @copyright CodeX 2018
 * @license MIT
 * @version 2.0.0
 */

export default class Header {
  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {{data: HeaderData, config: HeaderConfig, api: object}}
   *   data — previously saved data
   *   config - user config for Tool
   *   api - Editor.js API
   *   readOnly - read only mode flag
   */
  constructor({ data, config, api, readOnly, block }) {
    this.api = api;
    this.readOnly = readOnly;
    this.block = block;

    /**
     * Styles
     *
     * @type {object}
     */
    this._CSS = {
      block: this.api.styles.block,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
      wrapper: "ce-header",
    };

    /**
     * Tool's settings passed from Editor
     *
     * @type {HeaderConfig}
     * @private
     */
    this.config = config;

    /**
     * Block's data
     *
     * @type {HeaderData}
     * @private
     */
    this._data = this.normalizeData(data);

    /**
     * List of settings buttons
     *
     * @type {HTMLElement[]}
     */
    this.settingsButtons = [];

    /**
     * Main Block wrapper
     *
     * @type {HTMLElement}
     * @private
     */
    this._element = this.getTag();

    /**
     * Bind onKeydown
     */
    if (!this.readOnly) {
      this.onKeyDown = this.onKeyDown.bind(this);
      this._element.addEventListener("keydown", this.onKeyDown);
    }
  }

  onKeyDown(e) {
    // This code is to manually handle the issue that after Undo, when innerText is blank, the header block is deleted.
    if (this._element.innerText === "") {
      // Prevent line break continuously making header block
      if (e.code === "Enter") {
        e.stopPropagation();
        const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
        this.api.blocks.delete(currentBlockIndex);
        this.api.blocks.insert("paragraph", { text: "" }, {}, currentBlockIndex, true);
        this.api.caret.setToBlock(currentBlockIndex, "start");
        return;
      }

      return;
    } else {
      // Prevent line break continuously make header compoennt issue
      if (e.code === "Enter") {
        return;
      }
      // if the header content exist but with space characters only, type Backspace delete header block, so add stopPropagation for blocking event bubbling
      if (e.code === "Backspace") {
        e.stopPropagation();
      }
    }
  }

  /**
   * Normalize input data
   *
   * @param {HeaderData} data - saved data to process
   *
   * @returns {HeaderData}
   * @private
   */
  normalizeData(data) {
    const newData = {};

    if (typeof data !== "object") {
      data = {};
    }

    newData.text = data.text || "";
    newData.level = parseInt(data.level) || this.defaultLevel.number;

    return newData;
  }

  /**
   * Return Tool's view
   *
   * @returns {HTMLHeadingElement}
   * @public
   */
  render() {
    return this._element;
  }

  /**
   * Create Block's settings block
   *
   * @returns {HTMLElement}
   */
  renderSettings() {
    const holder = document.createElement("DIV");

    // do not add settings button, when only one level is configured
    if (this.levels.length <= 1) {
      return holder;
    }

    /** Add type selectors */
    this.levels.forEach((level) => {
      const selectTypeButton = document.createElement("div");
      const iconContainer = document.createElement("div");
      const buttonTitle = document.createElement("span");
      iconContainer.classList.add("ce-settings__button-icon-container");
      buttonTitle.classList.add("ce-settings__button-text");

      selectTypeButton.classList.add("ce-settings__button");
      iconContainer.innerHTML = level.svg;
      buttonTitle.innerHTML = this.api.i18n.t(`Heading ${level.number - 1}`);

      selectTypeButton.appendChild(iconContainer);
      selectTypeButton.appendChild(buttonTitle);

      /**
       * Highlight current level button
       */
      if (this.currentLevel.number === level.number) {
        selectTypeButton.classList.add(this._CSS.settingsButtonActive);
      }

      /**
       * Save level to its button
       */
      selectTypeButton.dataset.level = level.number;

      /**
       * Set up click handler
       */
      selectTypeButton.addEventListener("click", () => {
        this.setLevel(level.number);
      });

      /**
       * Append settings button to holder
       */
      holder.appendChild(selectTypeButton);

      /**
       * Save settings buttons
       */
      this.settingsButtons.push(selectTypeButton);
    });

    return holder;
  }

  /**
   * Callback for Block's settings buttons
   *
   * @param {number} level - level to set
   */
  setLevel(level) {
    this.data = {
      level: level,
      text: this.data.text,
    };

    /**
     * Highlight button by selected level
     */
    this.settingsButtons.forEach((button) => {
      button.classList.toggle(this._CSS.settingsButtonActive, parseInt(button.dataset.level) === level);
    });
  }

  /**
   * Method that specified how to merge two Text blocks.
   * Called by Editor.js by backspace at the beginning of the Block
   *
   * @param {HeaderData} data - saved data to merger with current block
   * @public
   */
  merge(data) {
    const newData = {
      text: this.data.text + data.text,
      level: this.data.level,
    };

    this.data = newData;
  }

  /**
   * Validate Text block data:
   * - check for emptiness
   *
   * @param {HeaderData} blockData — data received after saving
   * @returns {boolean} false if saved data is not correct, otherwise true
   * @public
   */
  validate(blockData) {
    return blockData.text.trim() !== "";
  }

  /**
   * Extract Tool's data from the view
   *
   * @param {HTMLHeadingElement} toolsContent - Text tools rendered view
   * @returns {HeaderData} - saved data
   * @public
   */
  save(toolsContent) {
    return {
      text: toolsContent.innerHTML,
      level: this.currentLevel.number,
    };
  }

  moved(event) {
    if (!document.getElementById("editor-container")) return;
    const currentScrollTop = document.getElementById("editor-container").scrollTop;
    const changedHeight = this.api.blocks.getBlockByIndex(this.api.blocks.getCurrentBlockIndex()).holder.offsetHeight;
    if (event.detail.fromIndex < event.detail.toIndex) {
      document.getElementById("editor-container").scrollTop = currentScrollTop + changedHeight;
    } else {
      document.getElementById("editor-container").scrollTop = currentScrollTop - changedHeight;
    }
  }

  /**
   * Sanitizer Rules
   */
  static get sanitize() {
    return {
      level: false,
      text: {},
    };
  }

  /**
   * Returns true to notify core that read-only is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Get current Tools`s data
   *
   * @returns {HeaderData} Current data
   * @private
   */
  get data() {
    this._data.text = this._element.innerHTML;
    this._data.level = this.currentLevel.number;

    return this._data;
  }

  /**
   * Store data in plugin:
   * - at the this._data property
   * - at the HTML
   *
   * @param {HeaderData} data — data to set
   * @private
   */
  set data(data) {
    this._data = this.normalizeData(data);

    /**
     * If level is set and block in DOM
     * then replace it to a new block
     */
    if (data.level !== undefined && this._element.parentNode) {
      /**
       * Create a new tag
       *
       * @type {HTMLHeadingElement}
       */
      const newHeader = this.getTag();

      /**
       * Save Block's content
       */
      newHeader.innerHTML = this._element.innerHTML;

      /**
       * Replace blocks
       */
      this._element.parentNode.replaceChild(newHeader, this._element);

      /**
       * Save new block to private variable
       *
       * @type {HTMLHeadingElement}
       * @private
       */
      this._element = newHeader;
    }

    /**
     * If data.text was passed then update block's content
     */
    if (data.text !== undefined) {
      this._element.innerHTML = this._data.text || "";
    }
  }

  /**
   * Get tag for target level
   * By default returns second-leveled header
   *
   * @returns {HTMLElement}
   */
  getTag() {
    /**
     * Create element for current Block's level
     */
    const tag = document.createElement(this.currentLevel.tag);

    /**
     * Add onKeyDown event
     */
    /**
     * Add text to block
     */
    tag.innerHTML = this._data.text || "";

    /**
     * Add styles class
     */
    tag.classList.add(this._CSS.wrapper);

    /**
     * Make tag editable
     */
    tag.contentEditable = this.readOnly ? "false" : "true";
    tag.spellcheck = false;

    /**
     * Add Placeholder
     */
    tag.dataset.placeholder = this.api.i18n.t(this.config.placeholder || "");
    tag.setAttribute("slid-cy", `editor-header${this.currentLevel.number}`);

    return tag;
  }

  /**
   * Get current level
   *
   * @returns {level}
   */
  get currentLevel() {
    let level = this.levels.find((levelItem) => levelItem.number === this._data.level);

    if (!level) {
      level = this.defaultLevel;
    }

    return level;
  }

  /**
   * Return default level
   *
   * @returns {level}
   */
  get defaultLevel() {
    /**
     * User can specify own default level value
     */
    if (this.config.defaultLevel) {
      const userSpecified = this.levels.find((levelItem) => {
        return levelItem.number === this.config.defaultLevel;
      });

      if (userSpecified) {
        return userSpecified;
      } else {
        console.warn("(ง'̀-'́)ง Heading Tool: the default level specified was not found in available levels");
      }
    }

    /**
     * With no additional options, there will be H2 by default
     *
     * @type {level}
     */
    return this.levels[1];
  }

  /**
   * @typedef {object} level
   * @property {number} number - level number
   * @property {string} tag - tag corresponds with level number
   * @property {string} svg - icon
   */

  /**
   * Available header levels
   *
   * @returns {level[]}
   */
  get levels() {
    const availableLevels = [
      {
        number: 2,
        tag: "H2",
        svg: '<svg width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 0.5C1 0.223858 0.776142 0 0.5 0C0.223858 0 0 0.223858 0 0.5V6V11.5C0 11.7761 0.223858 12 0.5 12C0.776142 12 1 11.7761 1 11.5V6.5H8V11.5C8 11.7761 8.22386 12 8.5 12C8.77614 12 9 11.7761 9 11.5V6V0.5C9 0.223858 8.77614 0 8.5 0C8.22386 0 8 0.223858 8 0.5V5.5H1V0.5ZM14.0001 4.5C14.0001 4.3156 13.8986 4.14617 13.736 4.05916C13.5734 3.97215 13.3761 3.98169 13.2227 4.08398L11.7227 5.08398C11.4929 5.23715 11.4309 5.54759 11.584 5.77735C11.7372 6.00712 12.0476 6.0692 12.2774 5.91603L13.0001 5.43426V11.5C13.0001 11.7761 13.2239 12 13.5001 12C13.7762 12 14.0001 11.7761 14.0001 11.5V4.5Z" /></svg>',
      },
      {
        number: 3,
        tag: "H3",
        svg: '<svg width="15" height="12" viewBox="0 0 15 12" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 0.5C1 0.223858 0.776142 0 0.5 0C0.223858 0 0 0.223858 0 0.5V6V11.5C0 11.7761 0.223858 12 0.5 12C0.776142 12 1 11.7761 1 11.5V6.5H8V11.5C8 11.7761 8.22386 12 8.5 12C8.77614 12 9 11.7761 9 11.5V6V0.5C9 0.223858 8.77614 0 8.5 0C8.22386 0 8 0.223858 8 0.5V5.5H1V0.5ZM12.5334 6.11564C12.7372 6.00803 12.9718 5.97417 13.1977 6.01976C13.4235 6.06535 13.6268 6.18761 13.773 6.36593C13.9192 6.54426 13.9994 6.76771 14 6.99847L14 6.9995C14.0009 7.19384 13.9438 7.38392 13.8361 7.54544L11.0998 11.2003C10.9863 11.3519 10.9682 11.5545 11.0529 11.7238C11.1376 11.8931 11.3107 12 11.5 12H14.5C14.7761 12 15 11.7761 15 11.5C15 11.2239 14.7761 11 14.5 11H12.499L14.644 8.13492C14.6484 8.12897 14.6528 8.12292 14.6569 8.11678C14.8821 7.78634 15.0017 7.39536 15 6.99551C14.9987 6.53462 14.8385 6.08826 14.5463 5.73187C14.2539 5.37535 13.8475 5.13075 13.3956 5.03953C12.9437 4.94831 12.4741 5.01608 12.0665 5.23136C11.6588 5.44663 11.338 5.79616 11.1583 6.22077C11.0507 6.47507 11.1696 6.76848 11.4239 6.8761C11.6782 6.98373 11.9716 6.86482 12.0792 6.61051C12.1691 6.39805 12.3296 6.22326 12.5334 6.11564Z" /></svg>',
      },
      {
        number: 4,
        tag: "H4",
        svg: '<svg width="15" height="12" viewBox="0 0 15 12" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 0.5C1 0.223858 0.776142 0 0.5 0C0.223858 0 0 0.223858 0 0.5V6V11.5C0 11.7761 0.223858 12 0.5 12C0.776142 12 1 11.7761 1 11.5V6.5H8V11.5C8 11.7761 8.22386 12 8.5 12C8.77614 12 9 11.7761 9 11.5V6V0.5C9 0.223858 8.77614 0 8.5 0C8.22386 0 8 0.223858 8 0.5V5.5H1V0.5ZM11.5 5C11.2239 5 11 5.22386 11 5.5C11 5.77614 11.2239 6 11.5 6H13.5397L12.3404 7.71327C12.2335 7.866 12.2204 8.06553 12.3065 8.23089C12.3926 8.39625 12.5635 8.49999 12.75 8.5C12.9555 8.50002 13.1579 8.55072 13.3392 8.64763C13.5205 8.74454 13.675 8.88466 13.7892 9.05558C13.9034 9.2265 13.9737 9.42294 13.9939 9.62751C14.014 9.83208 13.9834 10.0385 13.9047 10.2284C13.8261 10.4183 13.7018 10.5859 13.5429 10.7163C13.384 10.8467 13.1954 10.9359 12.9938 10.976C12.7922 11.0161 12.5838 11.0059 12.3871 10.9462C12.1904 10.8865 12.0114 10.7793 11.866 10.6339C11.6708 10.4387 11.3542 10.4387 11.1589 10.634C10.9637 10.8292 10.9637 11.1458 11.159 11.3411C11.4206 11.6027 11.7427 11.7957 12.0968 11.9031C12.4509 12.0105 12.826 12.0289 13.1889 11.9568C13.5518 11.8846 13.8913 11.724 14.1773 11.4893C14.4633 11.2545 14.687 10.9529 14.8286 10.6111C14.9702 10.2692 15.0253 9.89774 14.989 9.52952C14.9528 9.1613 14.8263 8.8077 14.6207 8.50004C14.4152 8.19239 14.1369 7.94017 13.8106 7.76574C13.7404 7.7282 13.6684 7.69451 13.595 7.66475L14.9096 5.78673C15.0165 5.634 15.0296 5.43446 14.9435 5.26909C14.8574 5.10373 14.6864 5 14.5 5H11.5Z" /></svg>',
      },
    ];

    return this.config.levels ? availableLevels.filter((l) => this.config.levels.includes(l.number)) : availableLevels;
  }
}
