import React from "react";
import styled from "styled-components";
import { ModalTypes, CustomModalProps, Button, Typography17, Typography34 } from "@slid/slid-ips";
import { Trans, useTranslation } from "react-i18next";
import { markNotificationsAsViewed } from "redux/actions/slidGlobalActions";
import store from "redux/store";
import { trackEvent } from "../eventTracking";
import { eventTypes } from "types/eventTracking";

const showStudyChallengeExpiredNotificationModal = async (props: any) => {
  const { showModal, closeModal, notification } = props;
  const userStudyChallengeData = await store.getState().slidGlobal.userStudyChallengeData;
  const isPlainNote = window.location.pathname.includes("docs") && !!document.getElementById("editor-component");
  const isVideoNote = window.location.pathname.includes("/vdocs/");

  trackEvent({
    eventType: eventTypes.show.FREE_TRIAL_ENDED_NOTIFICATION,
    eventProperties: {
      freeTrialLength: 14,
      type: "retry 14-day study challenge",
      version: "14-day study challenge",
      totalLearningTime: userStudyChallengeData?.progressMarkers.reduce((prev: any, next: any) => prev + next.totalSeconds, 0),
      from: isVideoNote ? "Video note page" : isPlainNote ? "Plain note page" : "My notes",
    },
  });

  const closeWithMarkNotificationAsViewed = async () => {
    await store.dispatch(
      markNotificationsAsViewed({
        notificationsData: {
          notifications: [notification],
        },
      })
    );
    closeModal();
  };

  const modalProps: CustomModalProps = {
    type: ModalTypes.customModal,
    closableDim: false,
    customComponent: <StudyChallengeModalContainer closeModal={closeWithMarkNotificationAsViewed} />,
  };

  showModal(modalProps);
};

const StudyChallengeModalContainer = (props: any) => {
  const { closeModal } = props;
  const { t } = useTranslation("StudyChallenge");

  return (
    <Container>
      <Image src={"/src/assets/study_challenge/expired_modal.webp"} />
      <Typography34
        weight={700}
        //@ts-ignore
        text={
          <Trans components={{ colored: <Typography34 weight={700} text={t("ExpiredNotificationModal.TitleColoredText", { keySeparator: "." })} color={`--blue8`} style={{ display: "inline" }} /> }}>
            {t("ExpiredNotificationModal.Title", { keySeparator: "." })}
          </Trans>
        }
        color={`--gray17`}
        textAlign={"center"}
        marginTop="24px"
      ></Typography34>
      <Typography17 weight={400} color="--gray15" marginTop="24px" text={t("ExpiredNotificationModal.Description", { keySeparator: ".", savedTime: "1" })} style={{ width: 400 }} />
      <ButtonRow>
        <Button appearance="secondary" callback={closeModal} size="medium" text={t("FailedModal.SecondaryButtonText", { keySeparator: "." })} />
        <Button
          appearance="primary"
          callback={() => {
            window.open("/pricing");
          }}
          size="medium"
          text={t("ExpiredNotificationModal.PrimaryButtonText", { keySeparator: "." })}
        />
      </ButtonRow>
    </Container>
  );
};

const Container = styled.div`
  padding: 48px 36px;
  background-color: var(--gray1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 360px;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 48px;
  justify-content: center;
`;

export default showStudyChallengeExpiredNotificationModal;
