import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

interface AnimateHeightProps {
  children: any;
  showChildren: boolean;
}

const AnimateHeight = ({ children, showChildren }: AnimateHeightProps) => {
  const childElementVariants = {
    initial: {
      opacity: 0,
      y: -10,

      transition: {
        duration: 0.1,
      },
    },

    animate: {
      opacity: 1,
      y: 0,

      transition: {
        duration: 0.1,
        ease: "easeInOut",
      },
    },
  };

  return (
    <ParentWrapper showChildren={showChildren}>
      <AnimatePresence>
        {showChildren && (
          <Wrapper variants={childElementVariants} initial="initial" animate="animate" exit="initial">
            {children}
          </Wrapper>
        )}
      </AnimatePresence>
    </ParentWrapper>
  );
};

const ParentWrapper = styled.div<{ showChildren: boolean }>`
  position: relative;
  top: ${({ showChildren }) => (showChildren ? "unset" : "-100px")};
  transition: top 0.3s ease-in-out;
  z-index: ${({ showChildren }) => (showChildren ? "1" : "-1")};
`;

const Wrapper = styled(motion.div)``;

export default AnimateHeight;
