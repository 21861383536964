import React from "react";
import styled from "styled-components";
import { Icon, Sticker, Tooltip } from "@slid/slid-ips";

import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";

import { useSLTButton } from "hooks/whisperSLTRealTime/useSLTButton";
import SmartLiveTextVideoNoteButtonIcon from "./SmartLiveTextVideoNoteButtonIcon";
import useWhisperSLTStore from "store/useWhisperSLTStore";

const SmartLiveTextVideoNoteButton = () => {
  const { t } = useTranslation(["VideoCaptureButton"]);
  const { isSTTToggledOn } = useWhisperSLTStore();
  const { onMouseEnterSLTButton, onMouseLeaveSLTButton, onClickSLTButton, onClickSLTMoreButton, SLTButtonStatus, isSTTHovered, onMouseEnterSLTMoreButton, onMouseLeaveSLTMoreButton } = useSLTButton();

  const isGrayText = (!isSTTHovered && SLTButtonStatus === "recording") || (!isSTTHovered && SLTButtonStatus === "waiting");

  return (
    <ButtonContainer onMouseEnter={onMouseEnterSLTButton} onMouseLeave={onMouseLeaveSLTButton} onClick={onClickSLTButton}>
      <SmartLiveTextVideoNoteButtonIcon isSTTHovered={isSTTHovered} SLTButtonStatus={SLTButtonStatus} />
      <ButtonText isGrayText={isGrayText}>
        {t(
          isSTTHovered && SLTButtonStatus !== "default"
            ? "StopSmartLiveText"
            : SLTButtonStatus === "default"
            ? "SmartLiveText"
            : SLTButtonStatus === "recording"
            ? "SmartLiveTextRecording"
            : SLTButtonStatus === "waiting"
            ? "Waiting"
            : "SmartLiveText"
        )}
      </ButtonText>
      <SettingButton onClick={onClickSLTMoreButton} onMouseEnter={onMouseEnterSLTMoreButton} onMouseLeave={onMouseLeaveSLTMoreButton}>
        <Tooltip title={t(!isSTTToggledOn ? "ShowSLTViewWhileWhisperSTTActive" : "ShowSLTView")} placement={"top"}>
          <div>
            <Icon icon={`chevronDown20`} color={`--gray17`} />
          </div>
        </Tooltip>
      </SettingButton>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button`
  // S of reset button
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  padding: 0;
  position: relative;

  &:focus {
    outline: 0;
    border: none;
  }
  // E of reset button

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 84px;
  height: 52px;
  border-radius: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray2);
  }

  &:active {
    background-color: var(--gray3);
  }
`;

const ButtonText = styled.span<{ isGrayText: boolean }>`
  font-size: 1rem;
  line-height: 1.6rem;
  text-align: center;
  color: ${({ isGrayText }) => (isGrayText ? "var(--gray9)" : "var(--gray17)")};
`;

const SettingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2px;
  top: 6px;
  width: 22px;
  height: 24px;
  border-radius: 8px;

  &:hover {
    background-color: var(--gray5);
  }
`;

const StyledSticker = styled(Sticker)`
  && {
    position: absolute;
    top: -13px;
    z-index: 1;
    padding: 0 4px;
    height: unset;
    margin-left: 6px;

    p {
      font-size: 0.9rem;
      line-height: 1.6rem;
    }
  }
`;

export default SmartLiveTextVideoNoteButton;
