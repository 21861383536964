import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;
interface SliddyIconProps extends SvgIconProps {
  color: "transparent" | "white";
}

export const SliddyIcon = ({ width, height, color }: SliddyIconProps) => {
  if (color === "transparent") {
    return (
      <SvgWrapper width={width} height={height} viewBox={`0 0 20 20`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_308_432)">
          <path
            d="M13.7554 16.4932C12.18 17.405 10.3268 17.7127 8.54124 17.359C6.75569 17.0053 5.15971 16.0143 4.05086 14.5708C2.942 13.1273 2.39595 11.3298 2.51452 9.51341C2.63309 7.69704 3.40819 5.98577 4.69529 4.69866C5.9824 3.41156 7.69367 2.63646 9.51005 2.51789C11.3264 2.39932 13.1239 2.94537 14.5674 4.05422C16.011 5.16308 17.0019 6.75906 17.3556 8.54461C17.7093 10.3302 17.4016 12.1834 16.4898 13.7588L17.4672 16.6768C17.5039 16.7869 17.5092 16.9051 17.4826 17.0181C17.4559 17.131 17.3983 17.2344 17.3162 17.3165C17.2341 17.3985 17.1308 17.4561 17.0178 17.4828C16.9048 17.5095 16.7867 17.5041 16.6765 17.4674L13.7554 16.4932Z"
            stroke="#7A9CCC"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.125 8.4375C8.125 8.95527 7.70527 9.375 7.1875 9.375C6.66973 9.375 6.25 8.95527 6.25 8.4375C6.25 7.91973 6.66973 7.5 7.1875 7.5C7.70527 7.5 8.125 7.91973 8.125 8.4375Z"
            fill="#7A9CCC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.75 8.4375C13.75 8.95527 13.3303 9.375 12.8125 9.375C12.2947 9.375 11.875 8.95527 11.875 8.4375C11.875 7.91973 12.2947 7.5 12.8125 7.5C13.3303 7.5 13.75 7.91973 13.75 8.4375Z"
            fill="#7A9CCC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.62474 11.4423C6.86378 11.304 7.16964 11.3857 7.3079 11.6247C7.88072 12.6151 8.81615 13.2501 10.0001 13.2501C11.184 13.2501 12.1194 12.6151 12.6923 11.6247C12.8305 11.3857 13.1364 11.304 13.3754 11.4423C13.6145 11.5805 13.6962 11.8864 13.5579 12.1254C12.8338 13.3773 11.5927 14.2501 10.0001 14.2501C8.40745 14.2501 7.16632 13.3773 6.44227 12.1254C6.30401 11.8864 6.38571 11.5805 6.62474 11.4423Z"
            fill="#7A9CCC"
          />
        </g>
        <defs>
          <clipPath id="clip0_308_432">
            <rect width="20" height="20" fill="white" transform="matrix(-1 0 0 1 20 0)" />
          </clipPath>
        </defs>
      </SvgWrapper>
    );
  } else {
    return (
      <SvgWrapper width={width} height={height} viewBox={`0 0 40 40`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="19.5" fill="#64ABF1" stroke="#4593FC" />
        <g clipPath="url(#clip0_308_608)">
          <path
            d="M25.2577 29.0902C23.0521 30.3667 20.4576 30.7975 17.9578 30.3023C15.458 29.8071 13.2236 28.4198 11.6712 26.3988C10.1188 24.3779 9.35438 21.8614 9.52038 19.3185C9.68638 16.7756 10.7715 14.3798 12.5735 12.5778C14.3754 10.7759 16.7712 9.69075 19.3141 9.52475C21.857 9.35876 24.3736 10.1232 26.3945 11.6756C28.4154 13.228 29.8028 15.4624 30.2979 17.9622C30.7931 20.4619 30.3623 23.0565 29.0858 25.2621L30.4541 29.3472C30.5055 29.5014 30.513 29.6668 30.4756 29.825C30.4383 29.9832 30.3577 30.1278 30.2427 30.2427C30.1278 30.3577 29.9832 30.4383 29.825 30.4756C29.6668 30.513 29.5014 30.5055 29.3472 30.4541L25.2577 29.0902Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.375 17.8125C17.375 18.5374 16.7874 19.125 16.0625 19.125C15.3376 19.125 14.75 18.5374 14.75 17.8125C14.75 17.0876 15.3376 16.5 16.0625 16.5C16.7874 16.5 17.375 17.0876 17.375 17.8125Z"
            fill="#64ABF1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.25 17.8125C25.25 18.5374 24.6624 19.125 23.9375 19.125C23.2126 19.125 22.625 18.5374 22.625 17.8125C22.625 17.0876 23.2126 16.5 23.9375 16.5C24.6624 16.5 25.25 17.0876 25.25 17.8125Z"
            fill="#64ABF1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2746 22.019C15.6092 21.8254 16.0374 21.9398 16.231 22.2744C17.0329 23.6609 18.3425 24.5499 20 24.5499C21.6575 24.5499 22.9672 23.6609 23.7691 22.2744C23.9627 21.9398 24.3909 21.8254 24.7255 22.019C25.0602 22.2125 25.1745 22.6407 24.981 22.9754C23.9673 24.728 22.2297 25.9499 20 25.9499C17.7704 25.9499 16.0328 24.728 15.0191 22.9754C14.8255 22.6407 14.9399 22.2125 15.2746 22.019Z"
            fill="#64ABF1"
          />
        </g>
        <defs>
          <clipPath id="clip0_308_608">
            <rect width="28" height="28" fill="white" transform="matrix(-1 0 0 1 34 6)" />
          </clipPath>
        </defs>
      </SvgWrapper>
    );
  }
};
