// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const SvgWrapper = styled(Icon)``;

export const SurveySelectdOptionIcon = () => {
  return (
    <SvgWrapper width="20" height="22" viewBox="0 0 20 22" fill="none">
      <circle cx="10" cy="12" r="9" stroke="#64ABF1" strokeWidth="2" />
      <circle cx="10" cy="12" r="5" fill="#64ABF1" />
    </SvgWrapper>
  );
};
