import base from "./base";

export interface StudyChallengeRequest {
  eventName: string;
  startDate: string;
  endDate: string;
  authorName: string;
  memo?: string;
  participantEmailList?: string[];
  recognitionTime: string;
  recognitionDayType: "same_day" | "previous_day";
  recognitionFrequency: number;
}

export interface StudyChallenge {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  teacher: string;
  members: string[];
  groupAverageRate: string;
  recognitionTime: string;
  recognitionDayType: "same_day" | "previous_day";
  recognitionFrequency: number;
  memo?: string;
}

interface StudyChallengeResponse {
  studyChallenge: StudyChallenge;
  message: string;
  notFoundUsers: string[];
}

export const getAllStudyChallenges = async () => {
  return base.get(`study-challenges/`);
};

export const createStudyChallenge = async (data: StudyChallengeRequest) => {
  return base.post<StudyChallengeResponse>(`study-challenges/`, data);
};

export const getStudyChallengeResult = async (studyChallengeId: number) => {
  return base.get(`study-challenges/${studyChallengeId}/`, {
    responseType: "blob",
  });
};

export const updateStudyChallenge = async (studyChallengeId: number, data: StudyChallengeRequest) => {
  return base.put<StudyChallengeResponse>(`study-challenges/${studyChallengeId}/`, data);
};

export const deleteStudyChallenge = async (studyChallengeId: number) => {
  return base.delete(`study-challenges/${studyChallengeId}/`);
};
