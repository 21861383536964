import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import { setIsSTTMoreButtonClicked, setIsSTTToggledOn, setShowSmartLiveTextView } from "redux/actions/sttActions";

const useUpdateSLTViewVisibility = () => {
  const { isManualAddingMode, showSmartLiveTextView, isSTTMoreButtonClicked, smartLiveTextLanguage } = useAppSelector((state) => state.sttReducer);
  const { isExtensionMediaPermitted } = useAppSelector((state) => state.vdocs);
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (smartLiveTextLanguage && isExtensionMediaPermitted && !isManualAddingMode && showSmartLiveTextView && !isSTTMoreButtonClicked) {
      dispatch(setShowSmartLiveTextView(false));
    }
  }, [isManualAddingMode, showSmartLiveTextView, isSTTMoreButtonClicked, smartLiveTextLanguage, isExtensionMediaPermitted]);

  const onToggleOffSLTMoreButton = () => {
    if (!smartLiveTextLanguage || (!isExtensionMediaPermitted && applicationType === "extension")) {
      dispatch(setIsSTTToggledOn(false));
    }
    dispatch(setIsSTTMoreButtonClicked(false));
    dispatch(setShowSmartLiveTextView(false));
  };

  return { onToggleOffSLTMoreButton };
};

export default useUpdateSLTViewVisibility;
