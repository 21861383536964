import React, { useEffect, useRef, memo, useState } from "react";
import { useChat } from "ai/react";
import styled, { css } from "styled-components";
import { Typography17, Typography15, Typography13, Typography11, Icon, Tooltip } from "@slid/slid-ips";
import ExampleQuestionBox from "./ExampleQuestionBox";
import { SliddyIcon } from "../icons/SliddyIcon";
import { useAppDispatch, useAppSelector } from "hooks";
import { saveMessageToFirebase, setAppendToSliddyMessages, setShowSliddyChat, setCurrentFirebaseChatId, clearAiSliddyMessages, setAlignAISessionId } from "redux/modules/aiSliddySlice";
import { useTranslation } from "react-i18next";
import { trackEvent } from "utils/eventTracking";
import SliddyInputSection from "./SliddyInputSection";
import { eventTypes } from "types/eventTracking";
import { useMediaQuery } from "react-responsive";
import { getEventLocationMode, getUserLocationInfo } from "utils/utils";
import { exportToSlidGPT } from "utils/exportNote";
import { getUserIdToken } from "utils/auth/cognitoAuthToolkit";
import env from "config/env";
import * as Sentry from "@sentry/browser";
import { nanoid } from "utils/utils";
import store from "redux/store";

// role enum
export enum SLIDDY_CHAT_ROLE {
  "user" = "user",
  "assistant" = "assistant",
  "system" = "system",
}

interface Message {
  role: SLIDDY_CHAT_ROLE;
  content: string;
}

interface SlidGPTError {
  type: "API_ERROR" | "INPUT_SIZE_ERROR";
  errorMessage: string;
}

interface UserCountryInfo {
  country: string;
  country_3: string;
  ip: string;
  name: string;
}

const SLIDDY_API_URL = env.end_point_url.ai_sliddy_api;
// NOTE: for local testing
const SLIDDY_DEV_URL = "http://localhost:3002/api/sliddy/v5";

const SlidGPT = memo(() => {
  const { t } = useTranslation("SlidGPT");
  const { applicationType, lang, userData } = useAppSelector((state) => state.slidGlobal);
  const { sliddyMessages, alignAISessionId } = useAppSelector((state) => state.aiSliddy);
  const dispatch = useAppDispatch();
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [slidNoteContent, setSlidNoteContent] = useState(null);
  const [userAuthToken, setUserAuthToken] = useState("");
  const [isThinking, setIsThinking] = useState(false);
  const [userCountryInfo, setUserCountryInfo] = useState<null | UserCountryInfo>(null);
  const AI_ROLE = SLIDDY_CHAT_ROLE.assistant;
  const USER_ROLE = SLIDDY_CHAT_ROLE.user;
  const {
    messages,
    setMessages,
    error: chatError,
    isLoading,
    append,
    stop: stopGeneration, // aborts the request but returns all the produced tokens.
    reload, // will regenerate the last message
  } = useChat({
    api: SLIDDY_API_URL,
    headers: {
      Authorization: userAuthToken,
    },
    initialMessages: sliddyMessages,
    body: {
      sessionId: store.getState().aiSliddy.alignAISessionId,
      userId: userData.user_key,
      userEmail: userData.email,
      userCountryInfo: userCountryInfo,
      lang: lang,
      slidNote: slidNoteContent,
    },
    onResponse: (response) => {
      setIsThinking(false);
    },
    onFinish: (message) => {
      dispatch(setAppendToSliddyMessages(message));
      dispatch(saveMessageToFirebase(message));
    },
    onError(err) {
      setShowErrorBanner(true);
      if (env.currentEnv === "development") {
        console.log("Sliddy chat error - ", err);
      } else {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("AI Sliddy error");
          scope.setExtra("error", err);
        });
      }
    },
  });
  const { currentDocument } = useAppSelector((state) => state.vdocs);
  const [shouldPlacerLower, setShouldPlaceLower] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const parentRef = React.useRef<HTMLUListElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isEndVisible, setIsEndVisible] = useState<boolean>(true); // Assuming it's visible at the start

  function updateSlidNoteContentState() {
    const NOTE_CONTENT_PLACEHOLDER = lang === "ko" ? "# 내용 없는  슬리드 노트\n" : "# Empty Slid note markdown content.\n";

    return exportToSlidGPT()
      .then((result) => {
        let content: string = "";
        if (result) {
          content = result;
        } else {
          content = NOTE_CONTENT_PLACEHOLDER;
        }
        // @ts-ignore
        return setSlidNoteContent({ content: content });
      })
      .catch((err) => {
        console.log("Error obtaining slid note for sending to slid gpt ", err);
        return err;
      });
  }

  function handleSendUserChatMessage(message: string) {
    setIsThinking(true);
    getUserIdToken()
      .then((token) => {
        setUserAuthToken(token);
      })
      .then(() => {
        if (!alignAISessionId) {
          const newSessionId = nanoid();
          dispatch(setAlignAISessionId(newSessionId));
        }
      })
      .then(() => updateSlidNoteContentState())
      .then(() => {
        append({
          role: "user",
          content: message,
        });
        dispatch(setAppendToSliddyMessages({ role: USER_ROLE, content: message }));
        dispatch(saveMessageToFirebase({ role: USER_ROLE, content: message }));
      })
      .catch((err) => {
        if (env.currentEnv === "development") {
          console.log("Error sending message to AI sliddy - ", err);
        } else {
          Sentry.withScope((scope) => {
            scope.setLevel("error");
            Sentry.captureMessage("Error before sending message to AI sliddy");
            scope.setExtra("error", err);
          });
        }
      });
  }

  useEffect(() => {
    getUserLocationInfo()
      .then((countryInfo) => {
        setUserCountryInfo(countryInfo);
      })
      .catch((err) => {
        if (env.currentEnv === "development") console.log("Request to fetch user country info has been blocked by ad blocker- ", err);
      });
  }, []);

  useEffect(() => {
    const isNoteMappedToVideos = () => {
      return currentDocument && currentDocument["mapped_videos"] && currentDocument["mapped_videos"].length > 0;
    };
    setShouldPlaceLower((isMobile || applicationType === "web") && !isNoteMappedToVideos());
  }, [isMobile, currentDocument]);

  useEffect(() => {
    const endOfMessagesRef = messagesEndRef.current;
    const observer = new IntersectionObserver((entries) => {
      setIsEndVisible(entries[0].isIntersecting);
    });

    if (endOfMessagesRef) {
      observer.observe(endOfMessagesRef);
    }

    return () => {
      if (endOfMessagesRef) {
        observer.unobserve(endOfMessagesRef);
      }
    };
  }, []);

  const scrollMessagesList = () => {
    if (parentRef.current) {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }
  };

  const handleNewChatButtonClick = () => {
    trackEvent({
      eventType: "Click NEW CHAT in AI Sliddy modal",
      eventProperties: {
        from: getEventLocationMode(),
      },
    });
    handleClearChat();
  };

  const handleHideButtonClick = () => {
    trackEvent({
      eventType: "Click HIDE CHAT in AI Sliddy modal",
      eventProperties: {
        from: getEventLocationMode(),
      },
    });
    dispatch(setShowSliddyChat(false));
  };

  const handleClearChat = () => {
    setShowErrorBanner(false);
    stopGeneration();
    setMessages([]);
    setIsThinking(false);
    dispatch(setCurrentFirebaseChatId(null));
    dispatch(clearAiSliddyMessages());
    dispatch(setAlignAISessionId(null));
  };

  const renderMessageText = (messageContent, messageRole, isBeingGenerated, messageIndex, messageCount) => {
    const isLastMessage = messageIndex === messageCount - 1;
    const shouldAppendLoadingDots = messageRole === AI_ROLE && isBeingGenerated && isLastMessage;
    const messageText = `${messageContent.trim()}${shouldAppendLoadingDots ? "..." : ""}`;
    return <Typography13 text={messageText} color={`--gray17`} weight={400} />;
  };

  const renderMessages = () => {
    return (
      <>
        {messages.length === 0 && (
          <Intro>
            <IntroTextWrapper>
              <Typography13 text={t("IntroTitle")} weight={400} color={`--gray7`} />
              <Typography17 text={t("IntroText")} color={`--gray7`} textAlign={`center`} marginTop={`2px`} weight={700} />
            </IntroTextWrapper>
            <IntroExamplesWrapper>
              {[1, 2, 3].map((index) => (
                <ExampleQuestionBox type={t(`Example${index}Type`)} question={t(`Example${index}Question`)} key={index} sendChatMessage={handleSendUserChatMessage}></ExampleQuestionBox>
              ))}
            </IntroExamplesWrapper>
          </Intro>
        )}
        {messages.map((messageData, index: number) => (
          <MessageWrapper key={index} isAiMessage={messageData.role === AI_ROLE}>
            {messageData.role === AI_ROLE && <SliddyIcon width={24} height={24} color={`white`} />}
            <MessageItem key={index} isAiMessage={messageData.role === AI_ROLE}>
              {renderMessageText(messageData.content, messageData.role, isLoading, index, messages.length)}
            </MessageItem>
          </MessageWrapper>
        ))}
        {isThinking && (
          <MessageWrapper isAiMessage={true}>
            <SliddyIcon width={24} height={24} color={`white`} />

            <MessageItem isAiMessage={true}>
              <Typography13 text={t("Thinking")} color={`--gray9`} weight={400} />
            </MessageItem>
          </MessageWrapper>
        )}
      </>
    );
  };

  useEffect(() => {
    if (isThinking) scrollMessagesList();
  }, [isThinking, isEndVisible, messages]);

  useEffect(() => {
    if (!isEndVisible) return;
    scrollMessagesList();
  }, [messages, isEndVisible]);

  useEffect(() => {
    trackEvent({
      eventType: eventTypes.show.AI_SLIDDY,
      eventProperties: {
        from: getEventLocationMode(),
      },
    });

    return () => {
      stopGeneration();
    };
  }, []);

  return (
    <GPTBackground>
      {/* @ts-ignore */}
      <GPTWrapper isAtExtremeBottom={shouldPlacerLower}>
        <GPTHeader>
          <GPTHeaderTextWrapper>
            <Icon icon={`sparkles24`} color={`--gray15`} />
            <Typography15 text={t("AISliddy")} color={`--gray15`} weight={700} />
          </GPTHeaderTextWrapper>
          <GPTHeaderRightWrapper>
            <Tooltip placement={`top`} title={t("NewChatTooltip")}>
              <NewChatButton onClick={() => handleNewChatButtonClick()}>
                <Typography11 text={t("NewChat")} color={`--gray9`} weight={700} />
              </NewChatButton>
            </Tooltip>
            <Tooltip placement={`top`} title={t("FoldTooltip")}>
              <HideButton id="close-icon" onClick={() => handleHideButtonClick()}>
                <Icon icon={`chevronDown24`} color={`--gray9`} />
              </HideButton>
            </Tooltip>
          </GPTHeaderRightWrapper>
        </GPTHeader>
        <MessagesSection ref={parentRef}>
          {renderMessages()}
          <MessagesScrollAnchor ref={messagesEndRef} />
        </MessagesSection>
        <SliddyInputSection isLoading={isLoading} chatError={showErrorBanner} onSendChatMessage={handleSendUserChatMessage} onStopGeneration={stopGeneration} />
      </GPTWrapper>
    </GPTBackground>
  );
});

export default SlidGPT;

const MessagesScrollAnchor = styled.div`
  width: 100%;
`;

const MessageWrapper = styled.div<{ isAiMessage?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ isAiMessage }) => (isAiMessage ? `flex-start` : `flex-end`)};
  gap: 8px;
  width: 100%;
`;

const MessageItem = styled.li<{ isAiMessage?: boolean }>`
  display: flex;
  padding: 8px 12px;
  width: fit-content;
  max-width: 300px;
  border-radius: 8px;
  background-color: var(--blue2);

  ${({ isAiMessage }) =>
    isAiMessage &&
    css`
      background-color: var(--gray3);
    `}

  p {
    line-break: anywhere;
  }
`;

const GPTBackground = styled.div`
  z-index: 998;
`;

const GPTWrapper = styled.div`
  position: absolute;
  bottom: ${(props: any) => (props.isAtExtremeBottom ? "42px" : "100px")};
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 340px;
  height: fit-content;
  max-height: 488px;
  box-shadow: var(--boxShadow3);
  background-color: var(--gray1);
  border-radius: 12px;
  z-index: 999;
`;

const HideButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: var(--gray3);
  }

  &:active {
    background-color: var(--gray2);
  }
`;

const GPTHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px 12px;
  width: 100%;
  border-radius: 12px 12px 0 0;
  background-color: #fff;
  z-index: 999;
  cursor: default;
`;

const GPTHeaderTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const GPTHeaderRightWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NewChatButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: var(--gray3);
  }

  &:active {
    background-color: var(--gray2);
  }
`;

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const IntroTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IntroExamplesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-top: 30px;
`;

const MessagesSection = styled.ul`
  /* S of reset ul */
  list-style: none;
  padding: 0;
  margin: 0;
  /* E of reset ul */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 20px 0 20px;
  overflow-y: auto;
  height: 384px;
  width: 100%;
  gap: 8px;
`;
