import slidAxios from "./base";
import { handleAuthErrors, isAuthError } from "./errorUtils";

export const ServerInterface_startPDFDownloading = async ({ data }) => {
  const payload = data;
  let startPDFDownloadResponse;
  try {
    startPDFDownloadResponse = await slidAxios.post(`start-pdf-download/`, payload);
  } catch (error) {
    console.error(error);
    if (!startPDFDownloadResponse) startPDFDownloadResponse = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          startPDFDownloadResponse.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          startPDFDownloadResponse.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // "document_key is missing" or "Document is a folder."
          startPDFDownloadResponse.error_message = "PDF_DOWNLOAD_INVALID_DOCUMENT_KEY";
          break;
        case 404:
          switch (error.response.data.message) {
            case "DOCUMENT_OR_FOLDER_NOT_FOUND":
              startPDFDownloadResponse.error_message = "PDF_DOWNLOAD_DOCUMENT_OR_FOLDER_NOT_FOUND";
              break;
            case "UNAUTHORIZED_USER":
              startPDFDownloadResponse.error_message = "PDF_DOWNLOAD_UNAUTHORIZED_USER";
              break;
            case "Document is deleted.":
              startPDFDownloadResponse.error_message = "PDF_DOWNLOAD_DOCUMENT_IS_DELETED";
              break;
            default:
              startPDFDownloadResponse.error_message = "PDF_DOWNLOAD_ERROR";
              break;
          }
          break;
        default:
          startPDFDownloadResponse.error_message = "UNKNOWN_ERROR";
          //TODO: log to sentry as it might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      startPDFDownloadResponse.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      startPDFDownloadResponse.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return startPDFDownloadResponse;
  }
};

export const ServerInterface_checkPDFDownloadStatus = async ({ pdf_download_key }) => {
  let checkPdfDownloadStatusResponse;
  try {
    checkPdfDownloadStatusResponse = await slidAxios.get(`pdf-download/${pdf_download_key}/`);
  } catch (error) {
    console.error(error);
    if (!checkPdfDownloadStatusResponse) checkPdfDownloadStatusResponse = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          checkPdfDownloadStatusResponse.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          checkPdfDownloadStatusResponse.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          // "No PDF Download Found"
          checkPdfDownloadStatusResponse.error_message = "PDF_DOWNLOAD_NOT_FOUND";
          break;
        default:
          checkPdfDownloadStatusResponse.error_message = "UNKNOWN_ERROR";
          //TODO: log to sentry as it might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      checkPdfDownloadStatusResponse.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      checkPdfDownloadStatusResponse.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return checkPdfDownloadStatusResponse;
  }
};
