import React from "react";
import {
  SET_LEGACY_MARKUP_COLOR,
  SET_MARKUP_COLOR,
  SET_MARKUP_PEN_SIZE,
  SET_MARKUP_HIGHLIGHTER_SIZE,
  SET_MARKUP_TOOL,
  SET_TOOL_SETTING_MENU_TYPE,
  SET_MARKUP_HIGHLIGHTER_MODE,
  SET_MARKUP_PEN_MODE,
  SET_ACTIVE_MARKUP_OBJECT,
  SET_SHOW_HIGHLIGHTER_COLOR_GRID,
} from "redux/actions/actionTypes";
import { DrawingModeType, MarkupSizeType, MarkupType } from "types/editor";

const fileUploadInputRef = React.createRef<HTMLInputElement>();

const initialState = {
  legacyMarkupColor: "rgba(0,95,252,0.3)", //legacy
  markupTool: MarkupType.Pen,
  markupColor: {
    pen: "rgba(236, 76, 58, 1)",
    highlighter: "rgba(253, 129, 255, 1)",
    text: "rgba(68, 125, 247, 1)",
  },
  toolSettingMenuType: MarkupType.Pen,
  markupPenSize: MarkupSizeType.Thin,
  markupHighlighterSize: MarkupSizeType.Medium,
  markupPenMode: DrawingModeType.Free,
  markupHighlighterMode: DrawingModeType.Free,
  activeMarkupObject: null,
  showHighlighterColorGrid: false,
  fileUploadInputRef: fileUploadInputRef,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LEGACY_MARKUP_COLOR:
      return {
        ...state,
        legacyMarkupColor: action.payload,
      };
    case SET_MARKUP_TOOL:
      return {
        ...state,
        markupTool: action.payload,
      };
    case SET_MARKUP_COLOR:
      return {
        ...state,
        markupColor: action.payload,
      };
    case SET_TOOL_SETTING_MENU_TYPE:
      return {
        ...state,
        toolSettingMenuType: action.payload,
      };
    case SET_MARKUP_PEN_SIZE:
      return {
        ...state,
        markupPenSize: action.payload,
      };
    case SET_MARKUP_HIGHLIGHTER_SIZE:
      return {
        ...state,
        markupHighlighterSize: action.payload,
      };
    case SET_MARKUP_PEN_MODE:
      return {
        ...state,
        markupPenMode: action.payload,
      };
    case SET_MARKUP_HIGHLIGHTER_MODE:
      return {
        ...state,
        markupHighlighterMode: action.payload,
      };
    case SET_ACTIVE_MARKUP_OBJECT:
      return {
        ...state,
        activeMarkupObject: action.payload,
      };
    case SET_SHOW_HIGHLIGHTER_COLOR_GRID:
      return {
        ...state,
        showHighlighterColorGrid: action.payload,
      };
    default:
      return state;
  }
};
