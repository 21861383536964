// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const SpinnerIcon = () => {
  return (
    <SvgWrapper width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1399_18198)">
        <path
          d="M8 0.5C3.58145 0.5 0 4.08145 0 8.5C0 12.9186 3.58145 16.5 8 16.5C12.4186 16.5 16 12.9186 16 8.5C16 4.08145 12.4186 0.5 8 0.5ZM8 13.7493C5.10087 13.7493 2.75072 11.3991 2.75072 8.5C2.75072 5.60087 5.10087 3.25072 8 3.25072C10.8991 3.25072 13.2493 5.60087 13.2493 8.5C13.2493 11.3991 10.8991 13.7493 8 13.7493Z"
          fill="#D0EBFF"
        />
        <path
          d="M8 13.7493C5.10087 13.7493 2.75072 11.3992 2.75072 8.50007C2.75072 7.74065 2.13507 7.125 1.37565 7.125C0.616232 7.125 0 7.74065 0 8.50007C0 12.9186 3.58145 16.5001 8 16.5001C8.75942 16.5001 9.37507 15.8844 9.37507 15.125C9.37507 14.3656 8.75942 13.7499 8 13.7499V13.7493Z"
          fill="#4593FC"
        />
      </g>
      <defs>
        <clipPath id="clip0_1399_18198">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
