import React, { useEffect } from "react";
import styled from "styled-components";
import { Typography13 } from "@slid/slid-ips";
import LoadMoreContainer from "components/loaders/LoadingComponent";
import { useTranslation } from "react-i18next";

interface Props {
  canvasWrapperContainer: React.RefObject<HTMLDivElement>;
  setCanvasSize: (size: { canvasContainerWidth: number; canvasContainerHeight: number }) => void;
  isBackgroundImageLoaded: boolean;
  markupModalHeight: number;
}

const ImageMarkupContent = ({ canvasWrapperContainer, setCanvasSize, isBackgroundImageLoaded, markupModalHeight }: Props) => {
  const { t } = useTranslation("EditorComponent");

  useEffect(() => {
    const canvasContainer = canvasWrapperContainer.current;
    const handleCanvasResize = () => {
      setCanvasSize({
        canvasContainerWidth: canvasContainer?.getBoundingClientRect().width as number,
        canvasContainerHeight: canvasContainer?.getBoundingClientRect().height as number,
      });
    };

    handleCanvasResize();
    window.addEventListener("resize", handleCanvasResize);

    return () => {
      window.removeEventListener("resize", handleCanvasResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MarkupContentWrapper markupModalHeight={markupModalHeight}>
      <CanvasContainer ref={canvasWrapperContainer}>
        {!isBackgroundImageLoaded && (
          <ImageLoaderContainer>
            <LoadMoreContainer />
            <Typography13 text={t("MarkupImageLoading")} textAlign="center" color={`--white`} />
          </ImageLoaderContainer>
        )}
        <CustomCanvas id={`markup-canvas`} />
      </CanvasContainer>
    </MarkupContentWrapper>
  );
};

const MarkupContentWrapper = styled.div<{ markupModalHeight: number }>`
  padding: 28px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 100%;
  min-height: 652px;

  @media screen and (max-height: 850px) {
    height: fit-content;
    min-height: unset;
    max-height: ${({ markupModalHeight }) => markupModalHeight}px;
  }
`;

const CanvasContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ImageLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--gray18);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const CustomCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

export default ImageMarkupContent;
