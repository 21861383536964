// auth 2.0
import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import Cookie from "js-cookie";
import env from "config/env";
import { getApplicationType } from "utils/utils";
import store from "redux/store";

const SLID_WEB_SITE_HOST = env.end_point_url.slid_web_site_host;

export const signUserOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

export const getCurrentUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      Sentry.captureMessage(`Error getting current user: ", ${error}`);
    });
    return null;
  }
};

export const getCurrentUserAttributes = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const attributes = await Auth.userAttributes(user);
    return attributes;
  } catch (error) {
    console.log("error getting current user attributes: ", error);
  }
};

export const getCurrentUserAttribute = async (attribute) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const attributes = await Auth.userAttributes(user);
    const attributeValue = attributes.find((attr) => attr.Name === attribute);
    return attributeValue.Value;
  } catch (error) {
    console.log("error getting current user attribute: ", error);
  }
};

const _isSlidPocket = () => {
  const { pathname } = window.location;
  const { applicationType } = store.getState().slidGlobal;
  const isSlidPocket = applicationType === "slidpocket" && ["/slid-pocket-editor", "/pricing", "/user-information-form", "/slid-mobile-editor"].includes(pathname);
  return isSlidPocket;
};

export const isUserAuthenticated = async () => {
  const isSlidPocket = _isSlidPocket();
  if (isSlidPocket) return true;

  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (e) {
    return false;
  }
};

export const getUserIdToken = async () => {
  // For Slid Pocket, fetch token from url queryString :
  const isSlidPocket = _isSlidPocket();

  if (isSlidPocket) {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("token")) {
      return searchParams.get("token");
    }
  }

  try {
    const user = await Auth.currentAuthenticatedUser();
    if (!user) {
      return null;
    }
    return user.signInUserSession.idToken.jwtToken;
  } catch (e) {
    return null;
  }
};

export const getUserAccessToken = async () => {
  // For Slid Pocket, fetch token from url queryString:
  const isSlidPocket = _isSlidPocket();
  if (isSlidPocket) {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("token")) {
      return searchParams.get("token");
    }
  }

  try {
    const user = await Auth.currentAuthenticatedUser();
    if (!user) {
      return null;
    }
    return user.signInUserSession.accessToken.jwtToken;
  } catch (e) {
    return null;
  }
};

// custom storage object for desktop authentication
export class CustomCognitoStorage {
  constructor() {
    this.storage = { localStorage: window.localStorage, cookieStorage: Cookie };
  }

  setItem(key, value) {
    this.storage.cookieStorage.set(key, value, {
      expires: 365,
      domain: `${env.currentEnv === "production" ? "." + SLID_WEB_SITE_HOST : SLID_WEB_SITE_HOST}`,
      path: "/",
      secure: true,
      sameSite: "none",
    });
    const applicationType = getApplicationType();
    //NOTE: When the application type is web or extension, we don't want to store tokens in local storage for security reasons.
    if (!["web", "extension"].includes(applicationType)) {
      this.storage.localStorage.setItem(key, value);
    }
  }

  getItem(key) {
    const cookieItem = this.storage.cookieStorage.get(key);

    const applicationType = getApplicationType();
    //NOTE: This ensures web-app and extension auth are in sync with each other.
    // The web-app is configured to rely solely on cookies for authentication, aligning it with the extension's protocol.
    // This approach prevents the web-app from proceeding with login using local storage tokens if corresponding tokens are absent in the cookies.
    // When cookie tokens are missing, users are prompted to re-authenticate on the web-app, maintaining consistent session management across both platforms.
    if (applicationType === "web" || applicationType === "extension") {
      return cookieItem;
    }

    const localStorageItem = this.storage.localStorage.getItem(key);

    return cookieItem ?? localStorageItem;
  }

  removeItem(key) {
    this.storage.localStorage.removeItem(key);
    this.storage.cookieStorage.remove(key, { path: "/", domain: `${env.currentEnv === "production" ? "." + SLID_WEB_SITE_HOST : SLID_WEB_SITE_HOST}` });
  }

  //TODO: Let's figure out why clear is needed. If it is removed from code, it occurred error now(2022/12/12)
  clear() {}
}
