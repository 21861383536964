import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const EraserIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 48} height={height ? height : 48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.6138 16.9395L29.9001 25.1727" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M21.6139 33.4057L32.4004 22.6883C32.5928 22.4972 32.7455 22.2702 32.8496 22.0205C32.9537 21.7707 33.0073 21.503 33.0073 21.2327C33.0073 20.9624 32.9537 20.6947 32.8496 20.4449C32.7455 20.1952 32.5928 19.9682 32.4004 19.7771L27.0441 14.4551C26.8517 14.2639 26.6233 14.1122 26.372 14.0088C26.1206 13.9053 25.8512 13.8521 25.5791 13.8521C25.307 13.8521 25.0376 13.9053 24.7862 14.0088C24.5349 14.1122 24.3065 14.2639 24.1141 14.4551L11.8633 26.6275C11.6709 26.8186 11.5182 27.0455 11.4141 27.2953C11.3099 27.545 11.2563 27.8127 11.2563 28.0831C11.2563 28.3534 11.3099 28.6211 11.4141 28.8709C11.5182 29.1206 11.6709 29.3476 11.8633 29.5387L15.7552 33.4057H35.0791"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
