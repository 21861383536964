//auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const VideoDefaultScreenIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 9C19.5523 9 20 8.55229 20 8C20 7.44772 19.5523 7 19 7L13 7L13 1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1L11 8C11 8.55228 11.4477 9 12 9L19 9ZM1 13L7 13L7 19C7 19.5523 7.44772 20 8 20C8.55229 20 9 19.5523 9 19V12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11L1 11C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13Z"
        fill="#F1F3F5"
      />
    </SvgWrapper>
  );
};
