import base from "./base";
import { DocumentSearchResponse, FolderSearchResponse, RecentDocumentResponse } from "types/search";

interface SearchParams {
  keyword: string;
}

export const documentSearch = async (props: SearchParams): Promise<DocumentSearchResponse> => {
  const { keyword } = props;
  return base.get(`document-search/`, {
    params: { keyword },
  });
};

export const folderSearch = async (props: SearchParams): Promise<FolderSearchResponse> => {
  const { keyword } = props;
  return base.get(`folder-search/`, {
    params: { keyword },
  });
};

export const recentDocuments = async (): Promise<RecentDocumentResponse> => {
  return base.get(`recent-documents/`);
};
