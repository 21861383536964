import { Typography17, Button, Icon, Typography34, Typography20, useModal } from "@slid/slid-ips";
import React, { useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking";

const SavedTimeInfoModal = memo(() => {
  const { t } = useTranslation("Modal");
  const { closeModal } = useModal();

  useEffect(() => {
    const isUserInVideoNote = window.location.pathname.includes("/vdocs/");

    trackEvent({
      eventType: eventTypes.show.SLID_VALUE_PROPOSITION_MODAL,
      eventProperties: {
        from: isUserInVideoNote ? "TIME SAVE BANNER in Video note page" : "TIME SAVE CARD in My notes",
      },
    });
  }, []);

  return (
    <ModalContainer>
      <Typography34 weight={700} color="--gray17" text={t("SavedTimeInfoModalTitle")} textAlign="center" />
      <Typography17 weight={400} color="--gray7" text={t("SavedTimeInfoModalSubTitle")} marginTop="24px" />
      <TextBoxContainer>
        <SavedTimeInfoTextContainer>
          <Typography20 weight={400} color="--gray15" text="📚" />
          <Typography17 weight={400} color="--gray15" text={t("SavedTimeInfoModalFirstInfo")} />
        </SavedTimeInfoTextContainer>
        <SavedTimeInfoTextContainer>
          <Typography20 weight={400} color="--gray15" text="🤩" />
          <Typography17 weight={400} color="--gray15" text={t("SavedTimeInfoModalSecondInfo")} />
        </SavedTimeInfoTextContainer>
        <SavedTimeInfoTextContainer>
          <Icon icon="check24" color="--blue8" />
          <Typography17 weight={400} color="--gray15" text={t("SavedTimeInfoModalThirdInfo")} />
        </SavedTimeInfoTextContainer>
      </TextBoxContainer>

      <Button
        appearance="primary"
        size="maxWidthLarge"
        text={t("SavedTimeInfoModalPrimaryButton")}
        marginTop="32px"
        callback={() => {
          trackEvent({
            eventType: eventTypes.click.GO_TO_PRICING_PAGE_IN_SLID_VALUE_PROPOSITION_MODAL,
          });
          closeModal();
          window.open("/pricing");
        }}
      />
    </ModalContainer>
  );
});

export default SavedTimeInfoModal;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 72px 80px;

  background-color: var(--gray1);
  box-shadow: var(--boxShadow3);
  border-radius: 8px;
`;

const TextBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  gap: 16px;
`;

const SavedTimeInfoTextContainer = styled.div`
  display: flex;
  gap: 8px;
`;
