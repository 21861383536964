import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import {
  setIsManualAddingMode,
  setIsSTTSessionStarted,
  setIsSTTSocketConnected,
  setLastSTTDataAsTranscribed,
  setLeftSTTDataAsTranscribedWhenDeactivated,
  setCurrentSTTData,
  setSTTError,
  setSmartLiveTextLanguage,
} from "redux/actions/sttActions";
import { useUpdateEffect } from "utils/customHook/customHook";
import { trackEvent } from "utils/eventTracking";
import { sendMessageToPrimary } from "utils/utils";

export const useInitializeSLTDesktop = () => {
  const dispatch = useAppDispatch();
  const { isSTTActive, smartLiveTextLanguage, isSTTToggledOn, showSmartLiveTextView } = useAppSelector((state) => state.sttReducer);
  const { iframePort } = useAppSelector((state) => state.vdocs);
  const userDefaultLanguage = window.localStorage.getItem("slid-user-stt-default-lang");

  useEffect(() => {
    dispatch(setIsSTTSessionStarted());
  }, [smartLiveTextLanguage, isSTTToggledOn, isSTTActive]);

  useEffect(() => {
    setSLTDefaultOptions();
  }, []);

  useEffect(() => {
    if (!isSTTActive) dispatch(setLeftSTTDataAsTranscribedWhenDeactivated());
  }, [isSTTActive]);

  useUpdateEffect(() => {
    sendMessageToPrimary({
      message: {
        type: showSmartLiveTextView ? "IFRAME_TO_PRIMARY_showSLTView" : "IFRAME_TO_PRIMARY_hideSLTView",
      },
      port: iframePort,
    });
  }, [showSmartLiveTextView]);

  useEffect(() => {
    const onMessage = (event) => {
      switch (event.data.type) {
        case "PRIMARY_TO_IFRAME_setSTTSocketConnectionStatus":
          dispatch(setIsSTTSocketConnected(event.data.payload.isSTTSocketConnected));
          break;
        case "PRIMARY_TO_IFRAME_receiveAudioTranscriptResult":
          dispatch(setCurrentSTTData(event.data.payload));
          break;
        case "PRIMARY_TO_IFRAME_sendFinalTextToWhisperResult":
          dispatch(
            setCurrentSTTData({
              text: event.data.payload.result,
              isFinal: true,
              isWhisperTranscribed: true,
            })
          );
          break;
        case "PRIMARY_TO_IFRAME_sendWhisperFailed":
          dispatch(setLastSTTDataAsTranscribed());
          break;
        case "PRIMARY_TO_IFRAME_receiveAudioTranscriptError":
          dispatch(setSTTError(event.data.payload.error));
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  const setSLTLanguageFromUserDefaultLanguage = () => {
    trackEvent({
      eventType: "Check LIVETEXT DEFAULT LANGUAGE in video note page",
      eventProperties: { is_default_language_set: !!userDefaultLanguage, default_language: userDefaultLanguage },
    });
    if (userDefaultLanguage) {
      dispatch(setSmartLiveTextLanguage(userDefaultLanguage));
    }
  };

  const setSLTAddingModeFromUserDefaultAddingMode = () => {
    const isManualAddingMode = window.localStorage.getItem("slid-stt-manual-adding-mode");
    if (isManualAddingMode === "true") {
      dispatch(setIsManualAddingMode(true));
    } else {
      dispatch(setIsManualAddingMode(false));
    }
  };

  const setSLTDefaultOptions = () => {
    setSLTLanguageFromUserDefaultLanguage();
    setSLTAddingModeFromUserDefaultAddingMode();
  };

  return {};
};
