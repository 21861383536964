/* eslint-disable react-hooks/exhaustive-deps */
// auth 2.0
import React, { useState, useEffect, memo } from "react";
import styles from "./DeletedDocuments.module.scss";
import { useHistory } from "react-router-dom";
import { useUpdateEffect } from "../../utils/customHook/customHook.js";
import Sweetalert from "sweetalert2";
import { useAppDispatch, useAppSelector } from "hooks";
import { fetchDocuments, fetchDeletedDocuments, restoreDocument, removeDocumentPermanently, setShowDeletedDocumentsPopup, setIsListOpen, setIsEditorNoteLoading } from "redux/actions/vdocsActions";
import { sendAmplitudeData } from "../../utils/amplitude";
import { Typography17, Typography15, Icon, Link, useToast, Tooltip } from "@slid/slid-ips";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { SlidFeatures } from "utils/privilegeManager";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";

const DeletedDocuments = memo(() => {
  const dispatch = useAppDispatch();
  const lang = useAppSelector((state) => state.slidGlobal.lang);
  const { t } = useTranslation();
  const deletedDocuments = useAppSelector((state) => state.vdocs.deletedDocuments);
  const editorWrapperClassName = useAppSelector((state) => state.vdocs.editorWrapperClassName);
  const [isDeletedDocumentFetched, setIsDeletedDocumentFetched] = useState(false);
  const { showToast } = useToast();
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const history = useHistory();
  const { showInsufficientPrivilegeModal, confirmPrivilege } = useConfirmPrivilege();
  const isVideoNote = window.location.pathname.includes("vdocs");

  useEffect(() => {
    setIsDeletedDocumentFetched(false);
    getDeletedDocuments();
  }, []);

  useUpdateEffect(() => {
    setIsDeletedDocumentFetched(true);
  }, [deletedDocuments]);

  const getDocuments = async () => {
    await dispatch(fetchDocuments());
  };

  const getDeletedDocuments = async () => {
    await dispatch(fetchDeletedDocuments());
  };

  const restoreSeletedDocument = async (documentItem) => {
    setIsDeletedDocumentFetched(false);
    await dispatch(restoreDocument({ documentKey: documentItem.document_key }));
    getDeletedDocuments();

    // Show updated documents list
    await getDocuments();

    let documentTitle = documentItem.title;
    if (!documentItem.title) {
      if (documentItem.is_folder) {
        documentTitle = lang === "ko" ? "제목없는 폴더" : "Untitled Folder";
      } else {
        documentTitle = lang === "ko" ? "제목없는 노트" : "Untitled";
      }
    }

    showToast(
      <NoteRestoredMessageContainer>
        <Typography17
          weight={400}
          color="--gray1"
          text={documentItem.is_folder ? t("RestoredFolderMessage", { ns: "MyNotes", folder: documentTitle }) : t("RestoredNoteMessage", { ns: "MyNotes", note: documentTitle })}
        />
        {!documentItem.is_folder && (
          <Link
            linkText={t("RestoredNoteLink", { ns: "MyNotes" })}
            href="#"
            onClick={() => {
              dispatch(setIsEditorNoteLoading(true));
              dispatch(setShowDeletedDocumentsPopup(false));
              if (isMobile) dispatch(setIsListOpen(false));
              history.push(isVideoNote ? `/vdocs/${documentItem.document_key}` : `/docs/${documentItem.document_key}`);
            }}
          />
        )}
      </NoteRestoredMessageContainer>
    );

    // if user in the my-docs push to root
    if (history.location["pathname"] === "/docs") {
      history.push("/docs");
    }
    sendAmplitudeData(`SLID_2_RESTORE_DOCUMENT`);
  };

  const removeSeletedDocument = async (documentKey) => {
    setIsDeletedDocumentFetched(false);
    await dispatch(
      removeDocumentPermanently({
        documentKey: documentKey,
      })
    );
    getDeletedDocuments();
    sendAmplitudeData(`SLID_2_PERMANENTLEY_DELETE_DOCUMENT`);
  };

  const documentItemTag = (documentItem) => {
    return (
      <li className={styles[`document-list-item`]} key={documentItem["document_key"]}>
        <div className={styles[`document-list-item-title`]}>
          <div className={styles[`document-list-item-icon`]}>
            {documentItem["is_folder"] ? <Icon icon={`filledFolder24`} color={`--gray9`} /> : <Icon icon={`recentNote20`} color={`--gray9`} width="24" height="24" />}
          </div>
          <Typography15
            weight={400}
            text={documentItem.title ? documentItem.title : lang === "ko" ? "제목없는 노트" : "Untitled"}
            color="--gray15"
            style={{ width: "80%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
          />
        </div>
        <div className={styles[`document-list-item-button`]}>
          <Tooltip placement={`top`} title={lang === "ko" ? `복구하기` : `Restore`}>
            <div
              className={styles[`document-list-item-button-icon`]}
              onClick={() => {
                if (!confirmPrivilege(SlidFeatures.noteRestoration)) return showInsufficientPrivilegeModal();
                restoreSeletedDocument(documentItem);
              }}
            >
              <Icon icon={`restore20`} color={`--gray7`} />
            </div>
          </Tooltip>

          <Tooltip placement={`top`} title={lang === "ko" ? `영구 삭제하기` : `Delete permanently`}>
            <div
              className={styles[`document-list-item-button-icon`]}
              onClick={() => {
                if (!confirmPrivilege(SlidFeatures.notePermanentDeletion)) return showInsufficientPrivilegeModal();
                let documentTitle = documentItem.title;
                if (!documentItem.title) {
                  if (documentItem.is_folder) {
                    documentTitle = lang === "ko" ? "제목없는 폴더" : "Untitled Folder";
                  } else {
                    documentTitle = lang === "ko" ? "제목없는 노트" : "Untitled";
                  }
                }
                Sweetalert.fire({
                  target: isMobile ? "body" : `.${editorWrapperClassName}`,
                  heightAuto: false,
                  customClass: {
                    container: `position-absolute ${styles[`delete-note-permanently-noti-modal`]}`,
                    header: "p-1",
                    content: "p-1",
                  },
                  title: documentItem.is_folder ? t("DeleteFolderPermanentlyTitle", { ns: "MyNotes" }) : t("DeleteNotePermanentlyTitle", { ns: "MyNotes" }),
                  html: documentItem.is_folder
                    ? t("DeleteFolderPermanentlyContent", { ns: "MyNotes", folder: documentTitle })
                    : t("DeleteNotePermanentlyContent", { ns: "MyNotes", note: documentTitle }),
                  showConfirmButton: false,
                  showCancelButton: true,
                  showDenyButton: true,
                  reverseButtons: true,
                  cancelButtonText: t("DeleteNotePermanentlyCancelButton", { ns: "MyNotes" }),
                  denyButtonColor: "var(--blue8)",
                  denyButtonText: t("DeleteNotePermanentlyDeleteButton", { ns: "MyNotes" }),
                }).then((result) => {
                  if (result.isDenied) {
                    removeSeletedDocument(documentItem["document_key"]);
                  }
                });
              }}
            >
              <Icon icon={`delete20`} color={`--gray7`} />
            </div>
          </Tooltip>
        </div>
      </li>
    );
  };

  const getRecentDocumentsTags = (documents) => {
    const allDocuments = documents;
    let recentDocuments = [];
    if (allDocuments.length > 0) {
      recentDocuments = [...allDocuments];
      // sort by udpated_time
      recentDocuments.sort((a, b) => {
        return new Date(b["updated_time"]) - new Date(a["updated_time"]);
      });
      recentDocuments = recentDocuments.slice(0, 10).map((document) => {
        return documentItemTag(document);
      });
    } else {
      recentDocuments.push(
        <li className={`${styles[`document-list-item`]} ${styles[`no-hover`]}`} key={`empty-item`}>
          {lang === "ko" ? "최근 삭제된 노트가 없습니다." : "No deleted note found"}
        </li>
      );
    }
    return recentDocuments;
  };

  return (
    <div className={styles[`deleted-documents-popup`]}>
      <div className={styles[`document-list-container`]}>
        <div>
          {isDeletedDocumentFetched ? (
            <div className={styles[`document-list`]}>
              <div className={styles[`document-list-title`]}>{lang === "ko" ? "최근 삭제된 노트" : "Recent deleted notes"}</div>
              <ul>{getRecentDocumentsTags(deletedDocuments)}</ul>
            </div>
          ) : (
            <div className={styles["deleted-document-loader-container"]}>
              <div className="spinner-border mt-3" role="status">
                <span className="sr-only">{lang === "ko" ? "삭제된 노트를 가져오는 중..." : "Getting deleted notes..."}</span>
              </div>
              <span className={`loading-text mt-3`}>
                <b>{lang === "ko" ? "삭제된 노트를 가져오는 중..." : "Getting deleted notes..."}</b>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default DeletedDocuments;

const NoteRestoredMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
