import { setOverlayMaskMode } from "redux/actions/demoActions";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "hooks";

export const OverlayMask = () => {
  const { overlayMaskMode } = useAppSelector((state) => state.demo);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setOverlayMaskMode(OVERLAY_MASK_MODE.INITIAL));
  }, []);

  return <Container isVisible={overlayMaskMode !== OVERLAY_MASK_MODE.HIDE} />;
};

const Container = styled.div<{ isVisible: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #212529;
  opacity: ${({ isVisible }) => (isVisible ? 0.8 : 0)};
  top: 0;
  left: 0;
  z-index: 3;
  pointer-events: none;
  transition: opacity 0.3s ease-in;
`;

export enum OVERLAY_MASK_MODE {
  HIDE = "HIDE",
  INITIAL = "INITIAL",
  ONE_CLICK = "ONE_CLICK",
  SLT = "SLT",
  GRAB_TEXT = "GRAB_TEXT",
}

export const setOverlayZIndex = (shouldBeVisible: boolean) => {
  return shouldBeVisible ? { zIndex: 4 } : {};
};
