// auth 2.0
import { Typography15 } from "@slid/slid-ips";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const DesktopCaptureGuideTooltip = () => {
  const { t } = useTranslation("VideoNote");
  const transitionValues = {
    duration: 0.5,
    yoyo: Infinity,
    ease: "easeOut",
  };

  return (
    <TooltipContainer
      transition={{ y: transitionValues }}
      animate={{
        y: ["0px", "-8px"],
      }}
      whileHover={{ y: ["0px", "0px"] }}
    >
      <TooltipText text={t("DesktopGuideTooltipText")} color={`--white`} />
      <Tip></Tip>
    </TooltipContainer>
  );
};

const TooltipContainer = styled(motion.div)`
  position: absolute;
  bottom: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 283px;
  height: 84px;
  background-color: var(--gray15);
  padding: 16px 24px;
  border-radius: 8px;
`;

const TooltipText = styled(Typography15)`
  && {
    text-align: center;
  }
`;

const Tip = styled.div`
  position: absolute;
  bottom: -8px;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border-radius: 0 0 4px 0;
  background-color: var(--gray15);
`;

export default DesktopCaptureGuideTooltip;
