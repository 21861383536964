import { Button, Typography17 } from "@slid/slid-ips";
import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface ImageMarkupButtonsProps {
  handleCancel: () => void;
  onSaveImageMarkup: (target: HTMLButtonElement) => void;
  focusCanvas: () => void;
}
interface ImageMarkupHeaderProps extends ImageMarkupButtonsProps {
  width: number;
}

export const ImageMarkupButtons = ({ handleCancel, onSaveImageMarkup, focusCanvas }: ImageMarkupButtonsProps) => {
  const { t } = useTranslation("EditorComponent");

  return (
    <ButtonWrapper onClick={focusCanvas}>
      <CustomButton
        appearance={"secondary"}
        size={"small"}
        text={t("Cancel")}
        callback={() => {
          handleCancel();
        }}
      />
      <SaveButton
        onClick={(event: MouseEvent) => {
          onSaveImageMarkup(event.target as HTMLButtonElement);
        }}
        appearance={"primary"}
        size={"small"}
        text={t("Save")}
        callback={() => {}}
      />
    </ButtonWrapper>
  );
};

export const ImageMarkupHeader = ({ handleCancel, onSaveImageMarkup, width, focusCanvas }: ImageMarkupHeaderProps) => {
  const { t } = useTranslation("EditorComponent");

  return (
    <Wrapper onClick={focusCanvas}>
      <div>{width > 330 && <Typography17 text={t("ImageMarkup")} color={`--white`} weight={700} />}</div>
      <ImageMarkupButtons handleCancel={handleCancel} onSaveImageMarkup={onSaveImageMarkup} focusCanvas={focusCanvas} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 10px 24px 10px 32px;
  justify-content: space-between;
  align-items: center;
  background: var(--gray17);
  border-bottom: 1px solid var(--gray15);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const CustomButton = styled(Button)`
  && {
    background-color: var(--gray15);
    width: 80px;

    p {
      color: var(--gray5);
    }

    &:hover {
      background-color: var(--gray11);
      transition: background-color 0.2s ease-in-out;
    }

    &:active {
      background-color: var(--gray13);
    }
  }
`;

const SaveButton = styled(Button)`
  && {
    width: 80px;
    &:disabled {
      cursor: not-allowed;
      background-color: var(--blue7);

      &:hover {
        background-color: var(--blue7);
      }

      &:active {
        background-color: var(--blue7);
      }
    }
  }
`;
