export enum MarkupType {
  Select = "select",
  Text = "text",
  Pen = "pen",
  Highlighter = "highlighter",
  Eraser = "eraser",
  ColorPicker = "colorPicker",
}

export enum DrawingType {
  Free = "free",
  Line = "line",
}

export enum MarkupSizeType {
  Thin = "thin",
  Medium = "medium",
  Thick = "thick",
}

export enum DrawingModeType {
  Free = "free",
  Line = "line",
}

export interface ToolItemProps {
  name: MarkupType;
  icon: JSX.Element;
  tooltip: string;
  callback: () => void;
}
