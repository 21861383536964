// auth 2.0
import { sendStartRecordClipRequestToParentWindow, sendStopRecordClipRequestToParentWindow } from "../extensionInterface/sendToExtension";

export const recordClip = ({ applicationType }) => {
  switch (applicationType) {
    case "extension": {
      sendStartRecordClipRequestToParentWindow();
      return;
    }
    case "web": {
      // TODO
      return;
    }
    default: {
      return;
    }
  }
};

export const stopRecordClip = ({ applicationType }) => {
  switch (applicationType) {
    case "extension": {
      sendStopRecordClipRequestToParentWindow();
      return;
    }
    case "web": {
      // TODO
      return;
    }
    default: {
      return;
    }
  }
};
