import React from "react";
import styled from "styled-components";
import { Typography13 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { useRecentDocuments } from "hooks/queries";
import SearchNoteItem from "../resultItems/SearchNoteItem";

const SearchBoxRecentNoteSection = () => {
  const { t } = useTranslation("Search");
  const { data, isLoading } = useRecentDocuments();

  return (
    <RecentNoteWrapper>
      <SectionTitleContainer>
        <Typography13 text={t("RecentlyModifiedNotes")} color={`--gray13`} />
      </SectionTitleContainer>
      <RecentNoteListWrapper>
        {isLoading && (
          <LoadingErrorWrapper>
            <Typography13 text={"loading..."} color={`--gray13`} />
          </LoadingErrorWrapper>
        )}
        {data?.recent_documents &&
          data.recent_documents.map((item: any, index: number) => {
            return <SearchNoteItem key={item.document_key} document={item} />;
          })}
      </RecentNoteListWrapper>
    </RecentNoteWrapper>
  );
};

const RecentNoteWrapper = styled.div`
  border-top: 1px solid var(--gray3);
  padding-bottom: 8px;
`;

const SectionTitleContainer = styled.div`
  padding: 12px 24px 8px;
`;

const RecentNoteListWrapper = styled.div``;

const LoadingErrorWrapper = styled.div`
  padding: 8px 24px 24px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export default SearchBoxRecentNoteSection;
