import EditorSTTView from "./EditorSTTView";
import EditorJS, { BlockAPI } from "@editorjs/editorjs";
import { BlockToolConstructorOptions } from "@editorjs/editorjs";
import * as React from "react";
import { API, BlockTool, ToolSettings } from "@editorjs/editorjs";

import { Provider } from "react-redux";
import store from "redux/store";
import { ActiveNoteBlock } from "types/smartLiveText";
import { createRoot } from "react-dom/client";

class EditorSTTBlock implements BlockTool {
  api: API;
  data: ActiveNoteBlock;
  readOnly: boolean;
  block: BlockAPI | undefined;
  wrapper: HTMLDivElement;
  config: ToolSettings;

  constructor({ api, data, config, readOnly, block }: BlockToolConstructorOptions<ActiveNoteBlock>) {
    this.api = api;
    this.data = data;
    this.config = config;
    this.readOnly = readOnly;
    this.block = block;
    this.wrapper = document.createElement("div");
  }

  render(): HTMLDivElement {
    const wrapper = this.wrapper!;

    // Create a root.
    const root = createRoot(wrapper); // createRoot(container!) if you use TypeScript and your container may be null.

    // Initial render: Render the component tree into the root.
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <EditorSTTView data={this.data} editorAPI={this.api} currentBlock={this.block} />
        </Provider>
      </React.StrictMode>
    );

    return wrapper;
  }

  save(blockContent: HTMLElement): ActiveNoteBlock {
    const textParagraph: HTMLParagraphElement | null = blockContent.querySelector("[data-type='slt-text']");
    if (!textParagraph) {
      console.error("Failed to locate the text paragraph.");
      return { ...this.data };
    }

    return {
      ...this.data,
      text: textParagraph.innerText,
    };
  }

  validate(savedData: ActiveNoteBlock): boolean {
    if (!savedData.id) return false;
    if (typeof savedData.text !== "string") return false;
    return true;
  }

  renderSettings() {
    return [];
  }

  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }
}

export default EditorSTTBlock;
