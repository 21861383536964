import { Typography15, Typography12, useModal, Typography11 } from "@slid/slid-ips";
import { showStudyChallengeCompleteModal, showStudyChallengeInformationModal } from "utils/studyChallenge";
import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import styled from "styled-components";
import { browserInteractionTime } from "components/VideoDocument/VideoDocument";
import { UserStudyChallengeDataType } from "types/studyChallenge";
import { convertSecondsToTimerString } from "utils/utils-ts";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import { patchStudyChallengeData } from "redux/actions/slidGlobalActions";

const StudyChallengeSavedTimeBanner = () => {
  const { t } = useTranslation("StudyChallenge");
  let { userStudyChallengeData }: { userStudyChallengeData: UserStudyChallengeDataType | null } = useAppSelector((state) => state.slidGlobal);
  const { showModal, closeModal } = useModal();
  const dispatch = useAppDispatch();

  const [isTimerInitialized, setIsTimerInitialized] = useState<boolean>(false);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [studySeconds, setStudySeconds] = useState<number>(0);
  const [challengePassed, setChallengePassed] = useState<boolean>(false);

  useEffect(() => {
    // for one time, fetch current seconds:
    if (userStudyChallengeData === null || userStudyChallengeData.currentProgressMarker === null) return;
    setStudySeconds(userStudyChallengeData.currentProgressMarker.totalSeconds);
    setIsTimerInitialized(true);
  }, []);

  useEffect(() => {
    if (studySeconds >= 60 * 5 && !challengePassed) {
      setChallengePassed(true);
    }
  }, [studySeconds]);

  /**
   * Pause the background timer when the modal is opened.
   */
  useEffect(() => {
    if (!isTimerInitialized) return;

    if (isModalOpened) {
      browserInteractionTime.stopTimer();
    } else {
      browserInteractionTime.startTimer();
    }
  }, [isModalOpened, isTimerInitialized]);

  useEffect(() => {
    if (!isTimerInitialized || isModalOpened) return;
    if (browserInteractionTime.isRunning()) {
      const interval = setInterval(() => {
        setStudySeconds((prev) => prev + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isTimerInitialized, browserInteractionTime.isRunning(), isModalOpened]);

  // if the user has not started the challenge yet, do not render anything:
  if (userStudyChallengeData === null || userStudyChallengeData.currentProgressMarker === null) return null;

  // render BEGIN banner if the user has not started the challenge yet:
  if (studySeconds <= 10) {
    return (
      <Container>
        <InnerContainer>
          <BannerBeginImage src={"/src/assets/study_challenge/start_banner.webp"} />
          <TitleArea>
            <Typography15
              type="span"
              weight={700}
              //@ts-ignore
              text={
                <Trans
                  components={{
                    coloredText: <Typography15 weight={700} text={t("VideoNoteBanner.start.coloredTitle", { keySeparator: "." })} color={`--blue10`} style={{ display: "inline" }} />,
                  }}
                >
                  {t("VideoNoteBanner.start.title", { keySeparator: "." })}
                </Trans>
              }
              color="--gray15"
            />
          </TitleArea>
          <ButtonContainer
            style={{ backgroundColor: "var(--blue2)" }}
            onClick={() => {
              trackEvent({
                eventType: eventTypes.click.STUDY_CHALLENGE_BANNER_DETAIL_IN_VIDEO_NOTE_PAGE,
              });

              showStudyChallengeInformationModal({
                showModal: (props: any) => {
                  setIsModalOpened(true);
                  showModal(props);
                },
                closeModal: () => {
                  setIsModalOpened(false);
                  closeModal();
                },
                onDimClick: () => {
                  setIsModalOpened(false);
                  closeModal();
                },
              });
            }}
          >
            <Typography12 type="span" weight={700} text={t("VideoNoteBanner.start.buttonText", { keySeparator: "." })} color="--blue8" />
          </ButtonContainer>
        </InnerContainer>
      </Container>
    );
  }

  // otherwise, render ONGOING banner
  else {
    return (
      <Container>
        <InnerContainer>
          {challengePassed ? <BannnerDoneImage src={"/src/assets/study_challenge/done_banner.webp"} /> : <BannerOngoingImage src={"/src/assets/study_challenge/ongoing_banner.webp"} />}

          <TitleArea>
            <Typography15
              type="span"
              weight={700}
              text={t("VideoNoteBanner.ongoing.title", { keySeparator: ".", currentDay: userStudyChallengeData.currentDay, totalDays: userStudyChallengeData.totalDays })}
              color={`--blue10`}
              style={{ display: "inline" }}
            />
            <Typography15 weight={700} type="span" text={t("•", { keySeparator: "." })} color={`--gray15`} style={{ marginLeft: 6, display: "inline" }} />
            <Typography15 weight={700} type="span" text={convertSecondsToTimerString(studySeconds)} color={`--gray15`} style={{ marginLeft: 6, display: "inline" }} />
          </TitleArea>
          {challengePassed ? (
            <>
              <DescriptionText>
                <Typography11 type="span" weight={500} text={t("VideoNoteBanner.ongoing.doneDescription", { keySeparator: "." })} color="--gray9" />
              </DescriptionText>
              <ButtonContainer
                style={{ backgroundColor: "var(--blue9)", border: "1px solid var(--blue9)" }}
                onClick={async () => {
                  if (userStudyChallengeData?.currentProgressMarker?.totalSeconds) {
                    setStudySeconds(Math.max(userStudyChallengeData?.currentProgressMarker?.totalSeconds, studySeconds));
                  }

                  if (userStudyChallengeData?.currentProgressMarker?.totalSeconds && userStudyChallengeData?.currentProgressMarker?.totalSeconds < studySeconds) {
                    const currentTotalSeconds = userStudyChallengeData?.currentProgressMarker?.totalSeconds;
                    await dispatch(
                      patchStudyChallengeData({
                        data: {
                          event_name: "14_DAYS_STUDY_CHALLENGE",
                          date: userStudyChallengeData?.currentDate,
                          study_time: studySeconds - currentTotalSeconds,
                        },
                      })
                    );
                  }

                  if (userStudyChallengeData !== null && userStudyChallengeData.currentProgressMarker !== null) {
                    trackEvent({
                      eventType: eventTypes.click.STUDY_CHALLENGE_BANNER_DETAIL_IN_VIDEO_NOTE_PAGE,
                      eventProperties: {
                        learningTime: userStudyChallengeData.currentProgressMarker.totalSeconds * 1000,
                      },
                    });
                  }

                  showStudyChallengeCompleteModal({
                    showModal: (props: any) => {
                      setIsModalOpened(true);
                      showModal(props);
                    },
                    onDimClick: () => {
                      setIsModalOpened(false);
                      closeModal();
                    },
                    closeModal: () => {
                      setIsModalOpened(false);
                      closeModal();
                    },
                  });
                }}
              >
                <Typography12 type="span" weight={700} text={t("VideoNoteBanner.ongoing.buttonText", { keySeparator: "." })} color="--white" />
              </ButtonContainer>
            </>
          ) : (
            <>
              <DescriptionText>
                <Typography11 type="span" weight={500} text={t("VideoNoteBanner.ongoing.inProgressDescription", { keySeparator: "." })} color="--gray9" />
              </DescriptionText>
              <ButtonContainer style={{ border: "1px solid var(--gray4)", cursor: "initial" }} onClick={() => {}}>
                <Typography12 type="span" weight={700} text={t("VideoNoteBanner.ongoing.buttonText", { keySeparator: "." })} color="--gray5" />
              </ButtonContainer>
            </>
          )}
        </InnerContainer>
      </Container>
    );
  }
};

export default StudyChallengeSavedTimeBanner;

const Container = styled.div`
  width: 100%;
  padding: 8px 10px 0;
  user-select: none;
`;

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: var(--blue1);
  border: 1px solid var(--gray4);
  border-radius: 8px;
`;

const BannerBeginImage = styled.img`
  margin-left: 14px;
  width: 81px;
  aspect-ratio: ${170 / 81};
`;
const BannerOngoingImage = styled.img`
  margin-left: 18px;
  height: 36px;
  margin-top: 8px;
  aspect-ratio: ${170 / 81};
`;
const BannnerDoneImage = styled.img`
  margin-left: 22px;
  width: 64px;
  aspect-ratio: ${198 / 132};
`;

const TitleArea = styled.div`
  display: flex;
  flex: 1 0;
  align-self: center;
  padding-left: 10px;
`;

const ButtonContainer = styled.div`
  padding: 6px 17px;
  border-radius: 40px;
  margin-left: 8px;
  margin-top: 7px;
  margin-bottom: 6px;
  margin-right: 14px;
  cursor: pointer;
  display: flex;

  align-items: center;
`;

const DescriptionText = styled.div`
  color: var(--gray9);
  display: flex;
  align-items: center;
  margin-right: 8px;
`;
