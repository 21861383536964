// auth 2.0
import { setEditorLastActiveBlockPosition } from "redux/actions/vdocsActions";
import store from "redux/store";
import "./index.css";
import i18n from "config/i18n/i18n";
const t = i18n.t.bind(i18n);

class VideoLoader {
  constructor({ data, config, api, readOnly = false }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = data;
    this.config = config;

    this.countdownNumber = 1;
  }

  render() {
    const loader = document.createElement("div");
    loader.contentEditable = false;

    const loaderPlaceHolder = document.createElement("div");
    loaderPlaceHolder.setAttribute("blocktype", "loader");
    loaderPlaceHolder.className = "loader-placeholder";

    const loaderHeader = document.createElement("div");
    loaderHeader.className = "loader-header";
    const loaderRedDotIcon = document.createElement("img");
    loaderRedDotIcon.src = "/src/design/assets/slid_recording_loader_red_icon.png";
    const loaderHeaderText = document.createElement("span");
    loaderHeaderText.innerText = " " + t("Recording", { ns: "ClipRecording" });
    loaderHeader.append(loaderRedDotIcon, loaderHeaderText);

    const loaderBody = document.createElement("div");
    loaderBody.className = "loader-body";
    const loaderCountdownText = document.createElement("span");
    this.countdownNumber = 0;
    loaderCountdownText.innerText = `${this.countdownNumber} ${t("Sec", { ns: "ClipRecording" })}`;
    const loaderAdditionalText = document.createElement("span");
    loaderAdditionalText.className = "loader-body-additional-text";
    loaderAdditionalText.innerText = " " + t("Max60sec", { ns: "ClipRecording" });
    loaderBody.append(loaderCountdownText, loaderAdditionalText);

    loaderPlaceHolder.append(loaderHeader, loaderBody);
    loader.append(loaderPlaceHolder);

    const countdownId = setInterval(() => {
      this.countdownNumber = this.countdownNumber + 1;
      loaderCountdownText.innerText = `${this.countdownNumber} ${t("Sec", { ns: "ClipRecording" })}`;
      if (this.countdownNumber === this.data.clipRecordingMaxCountdownNumber - 5) loaderCountdownText.className = "countdown-red";
      if (this.countdownNumber === this.data.clipRecordingMaxCountdownNumber) clearInterval(countdownId);
    }, 1000);

    loader.addEventListener("click", () => {
      store.dispatch(setEditorLastActiveBlockPosition(this.api.blocks.getCurrentBlockIndex()));
    });

    loader.setAttribute("slid-cy", "editor-video-loader");

    return loader;
  }

  save() {
    return this.data;
  }

  static get isReadOnlySupported() {
    return true;
  }
}

export default VideoLoader;
