import { SUPPORT_LANGUAGES } from "../../codeMirror";
interface CodeBlock {
  type: string;
  lang: string;
  meta: null | any;
  value: string;
  position: {
    start: {
      line: number;
      column: number;
      offset: number;
    };
    end: {
      line: number;
      column: number;
      offset: number;
    };
  };
}
export function parseMarkdownToCode(blocks: CodeBlock) {
  return handleCodeNode(blocks);
}

export function handleCodeNode(node: CodeBlock) {
  // const upperCaseLang = blocks.lang?.toUpperCase() || "CSS";
  // const supportedLanguages = Object.keys(SUPPORT_LANGUAGES);
  // const upperCaseSupportLangMap = supportedLanguages.reduce((acc, lang) => {
  //   acc[lang.toUpperCase()] = SUPPORT_LANGUAGES[lang];
  //   return acc;
  // }, {});
  // const language = upperCaseSupportLangMap[upperCaseLang] || "Python";
  // console.log("found lanuage - ", language);
  const codeData = {
    data: {
      code: node.value,
      language: null, // node.lang, //TODO: handle check for suppored langs in the code mirror
      theme: "Dark",
    },
    type: "codeMirrorTool",
  };

  return codeData;
}
