export function parseDelimiterToMarkdown() {
  const delimiter = "---";

  return delimiter.concat("\n");
}

export function parseMarkdownToDelimiter(node) {
  let delimiterData = {};

  delimiterData = {
    data: {
      divider: false,
    },
    type: "divider",
  };

  return delimiterData;
}
