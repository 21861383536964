import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppSelector } from "hooks";
import { useDocumentSearch, useFolderSearch } from "hooks/queries";
import { SearchCategoryType } from "types/search";
import SearchCategoryButton from "./SearchCategoryButton";

const SearchCategory = ({ location }: { location: "My notes" | "search modal" }) => {
  const { searchCategories, searchKeywords } = useAppSelector((state) => state.search);
  const { data: noteData } = useDocumentSearch({ keyword: searchKeywords });
  const { data: folderData } = useFolderSearch({ keyword: searchKeywords });

  const [categoryResultNumber, setCategoryResultNumber] = useState<{
    [SearchCategoryType.Notes]: number;
    [SearchCategoryType.Folders]: number;
  }>({
    [SearchCategoryType.Notes]: 0,
    [SearchCategoryType.Folders]: 0,
  });

  useEffect(() => {
    return () => {
      setCategoryResultNumber({
        [SearchCategoryType.Notes]: 0,
        [SearchCategoryType.Folders]: 0,
      });
    };
  }, []);

  useEffect(() => {
    setCategoryResultNumber({
      [SearchCategoryType.Notes]: noteData?.number_of_results ?? 0,
      [SearchCategoryType.Folders]: folderData?.number_of_results ?? 0,
    });
  }, [noteData, folderData]);

  return (
    <CategoryWrapper>
      {searchCategories.categories.map((category: SearchCategoryType) => {
        if (categoryResultNumber[category] !== 0) return <SearchCategoryButton key={category} category={category} resultNumber={categoryResultNumber[category]} location={location} />;
      })}
    </CategoryWrapper>
  );
};

const CategoryWrapper = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export default SearchCategory;
