import { convertSLTBlocksToParagraph } from "components/SmartLiveText/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { setIsGettingExtensionMediaPermission, setShowExtensionMediaPermissionModal } from "redux/actions/vdocsActions";
import { eventTypes } from "types/eventTracking";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { trackEvent } from "utils/eventTracking";
import { SlidFeatures } from "utils/privilegeManager";
import useSLTMediaEffects from "./useSLTMediaEffects";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import env from "config/env";

export const useSLTButton = () => {
  const {
    transcriptChunk,
    isSTTToggledOn,
    isManualAddingMode,
    setIsSTTMoreButtonClicked,
    isSTTActive,
    setShowSmartLiveTextView,
    setIsGettingSTTMediaPermission,
    setIsSTTToggledOn,
    setShowPermissionGuideInTextView,
    resetTranscriptChunk,
    isRealTimeTranscriptSupported,
  } = useWhisperSLTStore();
  const { isExtensionMediaPermitted, isRecordActive } = useAppSelector((state) => state.vdocs);
  const editorInstance = useAppSelector((state) => state.vdocs.editorInstance);
  const { isAutoNotesToggledOn } = useAppSelector((state) => state.autoNotes);
  const dispatch = useAppDispatch();
  const [SLTButtonStatus, setSLTButtonStatus] = useState<"waiting" | "recording" | "default">("default");
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { manageClipRecordingEffects } = useSLTMediaEffects();
  const [isSTTHovered, setIsSTTHovered] = useState<boolean>(false);
  const [isMouseOnSTTButton, setIsMouseOnSTTButton] = useState<boolean>(false);

  useEffect(() => {
    const updateSLTButtonUI = () => {
      if (!isSTTToggledOn) {
        setSLTButtonStatus("default");
      } else if (isSTTActive) {
        setSLTButtonStatus("recording");
      } else if (isSTTToggledOn && !isSTTActive) {
        setSLTButtonStatus("waiting");
      }
    };
    updateSLTButtonUI();
  }, [isSTTToggledOn, isSTTActive]);

  useEffect(() => {
    if (isExtensionMediaPermitted || !isSTTToggledOn) return;
    manageExtensionPermissionStatus();
  }, [isSTTToggledOn, isExtensionMediaPermitted]);

  const wakeUpRealTimeSLTServer = () => {
    fetch(`${env.end_point_url.slid_stt_server_realtime_whisper}/wake-up`, {
      method: "GET",
    })
      .then(() => {
        // The slid-realtime-whisper server is wake-up
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onMouseEnterSLTButton = () => {
    if (isRealTimeTranscriptSupported) {
      wakeUpRealTimeSLTServer();
    }

    setIsSTTHovered(true);
    setIsMouseOnSTTButton(true);
  };

  const onMouseLeaveSLTButton = () => {
    setIsMouseOnSTTButton(false);
    setIsSTTHovered(false);
  };

  const onMouseEnterSLTMoreButton = () => {
    setIsSTTHovered(true);
  };

  const onMouseLeaveSLTMoreButton = () => {
    if (isMouseOnSTTButton) setIsSTTHovered(true);
  };

  const manageExtensionPermissionStatus = () => {
    trackEvent({
      eventType: "Check SMART LIVETEXT PERMISSION in video note page",
      eventProperties: {
        is_permission_needed: !isExtensionMediaPermitted,
      },
    });

    dispatch(setShowExtensionMediaPermissionModal(true));
    setIsGettingSTTMediaPermission(true);
    dispatch(setIsGettingExtensionMediaPermission(true));
    setShowPermissionGuideInTextView(true);
  };

  const manageSmartLiveTextViewVisibility = () => {
    if (!isExtensionMediaPermitted || isManualAddingMode) setShowSmartLiveTextView(true);
  };

  const onToggleOnSLTButton = async () => {
    if (isRecordActive) {
      manageClipRecordingEffects("SNIPPET_TO_SLT");
      return;
    } else if (isAutoNotesToggledOn) {
      manageClipRecordingEffects("AUTO_NOTES_TO_SLT");
      return;
    }
    await convertSLTBlocksToParagraph();
    manageSmartLiveTextViewVisibility();
    setIsSTTToggledOn(true);
  };

  const insertCurrentSLTBlockToEditor = () => {
    const { blocks } = editorInstance;
    blocks.insert("paragraph", { text: transcriptChunk.map((chunk) => chunk.text).join(" ") }, {}, blocks.getBlocksCount() + 1, true);
  };

  const onToggleOffSLTButton = () => {
    setIsSTTToggledOn(false);
    if (!isManualAddingMode) {
      setShowSmartLiveTextView(false);
      insertCurrentSLTBlockToEditor();
    }
    resetTranscriptChunk();
  };

  const onClickSLTMoreButton = (e) => {
    trackEvent({
      eventType: eventTypes.click.AUTO_TYPING_SETTING_IN_VIDEO_NOTE_PAGE,
      eventProperties: {
        location: isSTTActive ? "menu/set language" : "check all transcript",
      },
    });

    e.stopPropagation();
    setShowSmartLiveTextView(true);
    // To keep showing SLT view when clicking this button even for auto mode users
    // isSTTMoreButtonClicked is reset as false when clicking the close button in SLT view
    setIsSTTMoreButtonClicked(true);
  };

  const onClickSLTButton = async () => {
    trackEvent({
      eventType: isSTTToggledOn ? eventTypes.click.STOP_TYPING_IN_VIDEO_NOTE_PAGE : eventTypes.click.AUTO_TYPING_IN_VIDEO_NOTE_PAGE,
      eventProperties: {
        location: "video note main button",
      },
    });

    if (!isSTTToggledOn) {
      if (!confirmPrivilege(SlidFeatures.smartLiveText)) return showInsufficientPrivilegeModal();
      onToggleOnSLTButton();
    } else {
      onToggleOffSLTButton();
    }
  };

  return {
    onClickSLTButton,
    SLTButtonStatus,
    onClickSLTMoreButton,
    onMouseEnterSLTButton,
    onMouseLeaveSLTButton,
    onMouseEnterSLTMoreButton,
    onMouseLeaveSLTMoreButton,
    isSTTHovered,
    isMouseOnSTTButton,
    onToggleOffSLTButton,
  };
};
