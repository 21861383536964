import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const AutoNotesIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clip-path="url(#clip0_1815_26569)">
        <path
          d="M7.76282 4.47293C8.07744 3.17547 9.92272 3.17547 10.2373 4.47293L11.103 8.04299C11.2154 8.50622 11.577 8.8679 12.0403 8.98023L15.6103 9.84591C16.9078 10.1605 16.9078 12.0058 15.6103 12.3204L12.0403 13.1861C11.577 13.2984 11.2154 13.6601 11.103 14.1234L10.2373 17.6934C9.92272 18.9909 8.07744 18.9909 7.76282 17.6934L6.89714 14.1234C6.78481 13.6601 6.42313 13.2984 5.9599 13.1861L2.38985 12.3204C1.09238 12.0058 1.09238 10.1605 2.38984 9.84591L5.9599 8.98023C6.42313 8.8679 6.78481 8.50622 6.89714 8.04299L7.76282 4.47293Z"
          fill={color}
        />
        <path
          d="M18.1554 16.932C18.349 16.1336 19.4845 16.1336 19.6781 16.932L20.2109 19.129C20.28 19.414 20.5026 19.6366 20.7876 19.7057L22.9846 20.2384C23.783 20.4321 23.783 21.5676 22.9846 21.7612L20.7876 22.294C20.5026 22.3631 20.28 22.5857 20.2109 22.8707L19.6781 25.0677C19.4845 25.8661 18.349 25.8661 18.1554 25.0677L17.6226 22.8707C17.5535 22.5857 17.3309 22.3631 17.0459 22.294L14.8489 21.7612C14.0505 21.5676 14.0505 20.4321 14.8489 20.2384L17.0459 19.7057C17.3309 19.6366 17.5535 19.414 17.6226 19.129L18.1554 16.932Z"
          fill={color}
        />
        <path
          d="M21.9171 4.33032C22.0441 3.80634 22.7894 3.80634 22.9164 4.33032L23.266 5.77207C23.3114 5.95914 23.4574 6.10521 23.6445 6.15057L25.0863 6.50017C25.6102 6.62723 25.6102 7.37244 25.0863 7.4995L23.6445 7.8491C23.4574 7.89447 23.3114 8.04053 23.266 8.2276L22.9164 9.66936C22.7894 10.1933 22.0441 10.1933 21.9171 9.66936L21.5675 8.2276C21.5221 8.04053 21.3761 7.89447 21.189 7.8491L19.7472 7.4995C19.2233 7.37244 19.2233 6.62723 19.7472 6.50017L21.189 6.15057C21.3761 6.10521 21.5221 5.95914 21.5675 5.77207L21.9171 4.33032Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4167 4.58611L22.1314 5.76284C22.0174 6.2332 21.6501 6.60044 21.1797 6.7145L20.003 6.99984L21.1797 7.28518C21.6501 7.39923 22.0174 7.76648 22.1314 8.23684L22.4167 9.41356L22.7021 8.23684C22.8161 7.76648 23.1834 7.39923 23.6537 7.28518L24.8305 6.99984L23.6537 6.7145C23.1834 6.60044 22.8161 6.2332 22.7021 5.76284L22.4167 4.58611ZM22.9878 3.94896C22.8426 3.35013 21.9909 3.35013 21.8457 3.94896L21.4462 5.59667C21.3943 5.81047 21.2274 5.9774 21.0136 6.02925L19.3659 6.42879C18.767 6.574 18.767 7.42567 19.3659 7.57088L21.0136 7.97043C21.2274 8.02227 21.3943 8.1892 21.4462 8.403L21.8457 10.0507C21.9909 10.6495 22.8426 10.6495 22.9878 10.0507L23.3873 8.403C23.4392 8.1892 23.6061 8.02227 23.8199 7.97043L25.4676 7.57088C26.0665 7.42567 26.0665 6.574 25.4676 6.42879L23.8199 6.02925C23.6061 5.9774 23.4392 5.81047 23.3873 5.59667L22.9878 3.94896Z"
          fill={color}
        />
        <path
          d="M8.41675 26.8332C7.77242 26.8332 7.25008 26.3108 7.25008 25.6665C7.25008 25.0222 7.77242 24.4998 8.41675 24.4998C9.06108 24.4998 9.58341 25.0222 9.58341 25.6665C9.58341 26.3108 9.06108 26.8332 8.41675 26.8332Z"
          fill={color}
        />
        <path
          d="M2.58341 22.1665C1.93908 22.1665 1.41675 21.6442 1.41675 20.9998C1.41675 20.3555 1.93908 19.8332 2.58341 19.8332C3.22775 19.8332 3.75008 20.3555 3.75008 20.9998C3.75008 21.6442 3.22775 22.1665 2.58341 22.1665Z"
          fill={color}
        />
        <path
          d="M15.4167 3.49984C14.7724 3.49984 14.2501 2.9775 14.2501 2.33317C14.2501 1.68884 14.7724 1.1665 15.4167 1.1665C16.0611 1.1665 16.5834 1.68884 16.5834 2.33317C16.5834 2.9775 16.0611 3.49984 15.4167 3.49984Z"
          fill={color}
        />
        <path
          d="M25.9167 16.3332C25.2724 16.3332 24.7501 15.8108 24.7501 15.1665C24.7501 14.5222 25.2724 13.9998 25.9167 13.9998C26.5611 13.9998 27.0834 14.5222 27.0834 15.1665C27.0834 15.8108 26.5611 16.3332 25.9167 16.3332Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1815_26569">
          <rect width="28" height="28" fill="white" transform="translate(0.25)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export const AutoNotesSideBarIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        d="M5.90295 3.5146C6.15015 2.49516 7.60002 2.49516 7.84721 3.5146L8.52739 6.31964C8.61565 6.6836 8.89983 6.96778 9.2638 7.05604L12.0688 7.73622C13.0883 7.98342 13.0883 9.43329 12.0688 9.68048L9.2638 10.3607C8.89983 10.4489 8.61565 10.7331 8.52739 11.0971L7.84721 13.9021C7.60002 14.9215 6.15015 14.9215 5.90295 13.9021L5.22277 11.0971C5.13451 10.7331 4.85033 10.4489 4.48636 10.3607L1.68132 9.68048C0.66189 9.43329 0.661889 7.98342 1.68132 7.73622L4.48636 7.05604C4.85033 6.96778 5.13451 6.6836 5.22277 6.31964L5.90295 3.5146Z"
        fill="white"
      />
      <path
        d="M14.0685 13.3039C14.2206 12.6765 15.1129 12.6765 15.265 13.3039L15.6836 15.03C15.7379 15.254 15.9127 15.4289 16.1367 15.4832L17.8629 15.9018C18.4903 16.0539 18.4902 16.9461 17.8629 17.0983L16.1367 17.5168C15.9127 17.5711 15.7379 17.746 15.6836 17.97L15.265 19.6962C15.1129 20.3235 14.2206 20.3235 14.0685 19.6962L13.6499 17.97C13.5956 17.746 13.4207 17.5711 13.1968 17.5168L11.4706 17.0983C10.8432 16.9461 10.8432 16.0539 11.4706 15.9018L13.1968 15.4832C13.4207 15.4289 13.5956 15.254 13.6499 15.03L14.0685 13.3039Z"
        fill="white"
      />
      <path
        d="M17.0242 3.40254C17.124 2.99085 17.7095 2.99085 17.8093 3.40254L18.084 4.53535C18.1197 4.68233 18.2344 4.7971 18.3814 4.83274L19.5142 5.10743C19.9259 5.20726 19.9259 5.79278 19.5142 5.89261L18.3814 6.1673C18.2344 6.20294 18.1197 6.31771 18.084 6.46469L17.8093 7.5975C17.7095 8.00919 17.124 8.00919 17.0242 7.5975L16.7495 6.46469C16.7138 6.31771 16.5991 6.20294 16.4521 6.1673L15.3193 5.89261C14.9076 5.79278 14.9076 5.20726 15.3193 5.10743L16.4521 4.83274C16.5991 4.7971 16.7138 4.68233 16.7495 4.53535L17.0242 3.40254Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4167 3.60352L17.1926 4.52809C17.1029 4.89766 16.8144 5.18621 16.4448 5.27583L15.5202 5.50002L16.4448 5.72422C16.8144 5.81383 17.1029 6.10238 17.1926 6.47195L17.4167 7.39652L17.6409 6.47195C17.7306 6.10238 18.0191 5.81383 18.3887 5.72422L19.3132 5.50002L18.3887 5.27583C18.0191 5.18621 17.7306 4.89766 17.6409 4.52809L17.4167 3.60352ZM17.8654 3.1029C17.7513 2.63239 17.0822 2.63239 16.9681 3.1029L16.6541 4.39754C16.6134 4.56552 16.4822 4.69668 16.3143 4.73741L15.0196 5.05134C14.5491 5.16544 14.5491 5.83461 15.0196 5.9487L16.3143 6.26263C16.4822 6.30336 16.6134 6.43452 16.6541 6.6025L16.9681 7.89714C17.0822 8.36765 17.7513 8.36765 17.8654 7.89714L18.1794 6.6025C18.2201 6.43452 18.3512 6.30336 18.5192 6.26263L19.8139 5.9487C20.2844 5.83461 20.2844 5.16544 19.8139 5.05134L18.5192 4.73741C18.3512 4.69668 18.2201 4.56552 18.1794 4.39754L17.8654 3.1029Z"
        fill="white"
      />
      <path
        d="M6.41675 21.0834C5.91049 21.0834 5.50008 20.6729 5.50008 20.1667C5.50008 19.6604 5.91049 19.25 6.41675 19.25C6.92301 19.25 7.33341 19.6604 7.33341 20.1667C7.33341 20.6729 6.92301 21.0834 6.41675 21.0834Z"
        fill="white"
      />
      <path
        d="M1.83341 17.4167C1.32715 17.4167 0.916748 17.0063 0.916748 16.5C0.916748 15.9938 1.32715 15.5834 1.83341 15.5834C2.33968 15.5834 2.75008 15.9938 2.75008 16.5C2.75008 17.0063 2.33968 17.4167 1.83341 17.4167Z"
        fill="white"
      />
      <path
        d="M11.9167 2.75002C11.4105 2.75002 11.0001 2.33961 11.0001 1.83335C11.0001 1.32709 11.4105 0.916687 11.9167 0.916687C12.423 0.916687 12.8334 1.32709 12.8334 1.83335C12.8334 2.33961 12.423 2.75002 11.9167 2.75002Z"
        fill="white"
      />
      <path
        d="M20.1667 12.8334C19.6605 12.8334 19.2501 12.4229 19.2501 11.9167C19.2501 11.4104 19.6605 11 20.1667 11C20.673 11 21.0834 11.4104 21.0834 11.9167C21.0834 12.4229 20.673 12.8334 20.1667 12.8334Z"
        fill="white"
      />
    </SvgWrapper>
  );
};

export const AutoNotesEditorIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        d="M6.17027 2.7469C6.42659 1.68984 7.92998 1.68983 8.1863 2.7469L8.8916 5.65549C8.98311 6.0329 9.27778 6.32756 9.65518 6.41908L12.5638 7.12437C13.6208 7.38069 13.6208 8.88408 12.5638 9.14041L9.65518 9.84569C9.27778 9.93721 8.98311 10.2319 8.8916 10.6093L8.1863 13.5179C7.92998 14.5749 6.42659 14.5749 6.17027 13.5179L5.46498 10.6093C5.37346 10.2319 5.07879 9.93721 4.70139 9.8457L1.7928 9.14041C0.735734 8.88408 0.735733 7.38069 1.7928 7.12437L4.70139 6.41908C5.07879 6.32756 5.37346 6.0329 5.46498 5.65549L6.17027 2.7469Z"
        fill="#64ABF1"
      />
      <path
        d="M14.5777 10.8837C14.7354 10.2332 15.6606 10.2332 15.8183 10.8837L16.2523 12.6736C16.3086 12.9059 16.49 13.0872 16.7222 13.1435L18.5121 13.5776C19.1626 13.7353 19.1626 14.6605 18.5121 14.8182L16.7222 15.2522C16.49 15.3085 16.3086 15.4899 16.2523 15.7221L15.8183 17.512C15.6606 18.1625 14.7354 18.1625 14.5777 17.512L14.1436 15.7221C14.0873 15.4899 13.906 15.3085 13.6737 15.2522L11.8838 14.8182C11.2333 14.6605 11.2333 13.7353 11.8838 13.5776L13.6737 13.1435C13.906 13.0872 14.0873 12.9059 14.1436 12.6736L14.5777 10.8837Z"
        fill="#64ABF1"
      />
    </SvgWrapper>
  );
};

export const AutoNotesErrorIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper style={{ width, height }} viewBox={`0 0 ${width} ${height}`}>
      <path
        d="M11.6666 2.5V5.83333C11.6666 6.05435 11.7544 6.26631 11.9107 6.42259C12.067 6.57887 12.2789 6.66667 12.5 6.66667H15.8333"
        stroke="#FC9495"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1666 17.5H5.83329C5.39127 17.5 4.96734 17.3244 4.65478 17.0118C4.34222 16.6993 4.16663 16.2754 4.16663 15.8333V4.16667C4.16663 3.72464 4.34222 3.30072 4.65478 2.98816C4.96734 2.67559 5.39127 2.5 5.83329 2.5H11.6666L15.8333 6.66667V15.8333C15.8333 16.2754 15.6577 16.6993 15.3451 17.0118C15.0326 17.3244 14.6087 17.5 14.1666 17.5Z"
        stroke="#FC9495"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10 14.1665H10.0083" stroke="#FC9495" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10 8L10 12" stroke="#FC9495" stroke-linecap="round" stroke-linejoin="round" />
    </SvgWrapper>
  );
};

export const AutoNotesStopIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${23} ${23}`} style={{ width, height }} fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4858 22.5C17.6477 22.5 22.6429 17.5751 22.6429 11.5C22.6429 5.42487 17.6477 0.5 11.4858 0.5C5.32384 0.5 0.328613 5.42487 0.328613 11.5C0.328613 17.5751 5.32384 22.5 11.4858 22.5ZM15.5429 7.5H7.42861V15.5H15.5429V7.5Z"
        fill="#F93D3F"
      />
    </SvgWrapper>
  );
};

export const AutoNotesGeneratingIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 22C17.5751 22 22.5 17.0751 22.5 11C22.5 4.92487 17.5751 0 11.5 0C5.42487 0 0.5 4.92487 0.5 11C0.5 17.0751 5.42487 22 11.5 22ZM11.5 18.8084C15.7526 18.8084 19.2 15.361 19.2 11.1084C19.2 6.85582 15.7526 3.40842 11.5 3.40842C7.24746 3.40842 3.80005 6.85582 3.80005 11.1084C3.80005 15.361 7.24746 18.8084 11.5 18.8084Z"
        fill="#99CDFF"
      />
      <circle cx="11.5" cy="11" r="5" fill="#4593FC" />
    </SvgWrapper>
  );
};
// 73, 23
export const KoreanFlagIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clip-path="url(#clip0_1815_18851)">
        <mask id="mask0_1815_18851" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <path d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1815_18851)">
          <path d="M0 0.5H16V16.5H0V0.5Z" fill="#EEEEEE" />
          <path
            d="M10.9375 10.9688L11.6875 10.2188L12.1875 10.7188L11.4375 11.4375L10.9375 10.9688ZM9.71875 12.1875L10.4688 11.4375L10.9375 11.9375L10.2188 12.6875L9.71875 12.1875ZM12.4375 12.4375L13.1562 11.6875L13.6562 12.1875L12.9062 12.9375L12.4375 12.4375ZM11.1875 13.6562L11.9375 12.9375L12.4375 13.4062L11.6875 14.1562L11.1875 13.6562ZM11.6875 11.6875L12.4375 10.9688L12.9062 11.4375L12.1875 12.1875L11.6875 11.6875ZM10.4688 12.9375L11.1875 12.1875L11.6875 12.6875L10.9375 13.4062L10.4688 12.9375ZM12.4375 6.03125L10.4688 4.0625L10.9375 3.59375L12.9375 5.5625L12.4375 6.03125ZM10.4688 5.5625L9.71875 4.8125L10.2188 4.3125L10.9375 5.0625L10.4688 5.5625ZM11.6875 6.78125L10.9375 6.03125L11.4375 5.5625L12.1875 6.28125L11.6875 6.78125ZM11.9375 4.0625L11.1875 3.34375L11.6875 2.84375L12.4375 3.59375L11.9375 4.0625ZM13.1562 5.3125L12.4375 4.5625L12.9062 4.0625L13.6562 4.8125L13.1562 5.3125ZM2.84375 11.6875L4.8125 13.6562L4.3125 14.1562L2.34375 12.1875L2.84375 11.6875ZM4.8125 12.1875L5.53125 12.9375L5.0625 13.4062L4.3125 12.6875L4.8125 12.1875ZM3.5625 10.9688L4.3125 11.6875L3.8125 12.1875L3.09375 11.4375L3.5625 10.9688ZM4.3125 10.2188L6.28125 12.1875L5.78125 12.6875L3.8125 10.7188L4.3125 10.2188ZM4.8125 3.34375L2.84375 5.3125L2.34375 4.8125L4.3125 2.84375L4.8125 3.34375ZM5.53125 4.0625L3.5625 6.03125L3.09375 5.53125L5.0625 3.5625L5.53125 4.0625ZM6.28125 4.8125L4.3125 6.78125L3.8125 6.28125L5.78125 4.3125L6.28125 4.8125Z"
            fill="#333333"
          />
          <path
            d="M9.96875 10.4688L6.03125 6.5313C6.28979 6.27276 6.59672 6.06768 6.93452 5.92776C7.27232 5.78784 7.63437 5.71582 8 5.71582C8.36563 5.71582 8.72768 5.78784 9.06548 5.92776C9.40328 6.06768 9.71021 6.27276 9.96875 6.5313C10.2273 6.78984 10.4324 7.09677 10.5723 7.43457C10.7122 7.77237 10.7842 8.13442 10.7842 8.50005C10.7842 8.86568 10.7122 9.22774 10.5723 9.56553C10.4324 9.90333 10.2273 10.2103 9.96875 10.4688Z"
            fill="#D80027"
          />
          <path
            d="M9.9688 10.4688C9.71026 10.7273 9.40333 10.9324 9.06553 11.0723C8.72774 11.2122 8.36568 11.2842 8.00005 11.2842C7.63442 11.2842 7.27237 11.2122 6.93457 11.0723C6.59677 10.9324 6.28984 10.7273 6.0313 10.4688C5.77276 10.2102 5.56768 9.90328 5.42776 9.56548C5.28784 9.22768 5.21582 8.86563 5.21582 8.5C5.21582 8.13437 5.28784 7.77232 5.42776 7.43452C5.56768 7.09672 5.77276 6.78979 6.0313 6.53125L9.9688 10.4688Z"
            fill="#0052B4"
          />
          <path
            d="M7.01562 8.90625C7.78365 8.90625 8.40625 8.28365 8.40625 7.51562C8.40625 6.7476 7.78365 6.125 7.01562 6.125C6.2476 6.125 5.625 6.7476 5.625 7.51562C5.625 8.28365 6.2476 8.90625 7.01562 8.90625Z"
            fill="#D80027"
          />
          <path
            d="M8.98438 10.875C9.7524 10.875 10.375 10.2524 10.375 9.48438C10.375 8.71635 9.7524 8.09375 8.98438 8.09375C8.21635 8.09375 7.59375 8.71635 7.59375 9.48438C7.59375 10.2524 8.21635 10.875 8.98438 10.875Z"
            fill="#0052B4"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1815_18851">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export const USFlagIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clip-path="url(#clip0_1815_12115)">
        <mask id="mask0_1815_12115" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <path d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1815_12115)">
          <path d="M8 0.5H16V2.5L15 3.5L16 4.5V6.5L15 7.5L16 8.5V10.5L15 11.5L16 12.5V14.5L8 15.5L0 14.5V12.5L1 11.5L0 10.5V8.5L8 0.5Z" fill="#EEEEEE" />
          <path d="M7 2.5H16V4.5H7V2.5ZM7 6.5H16V8.5H8L7 6.5ZM0 10.5H16V12.5H0V10.5ZM0 14.5H16V16.5H0V14.5Z" fill="#D80027" />
          <path d="M0 0.5H8V8.5H0V0.5Z" fill="#0052B4" />
          <path
            d="M5.84375 8.09375L7.625 6.8125H5.4375L7.21875 8.09375L6.53125 6L5.84375 8.09375ZM3.3125 8.09375L5.09375 6.8125H2.90625L4.6875 8.09375L4 6L3.3125 8.09375ZM0.78125 8.09375L2.5625 6.8125H0.375L2.15625 8.09375L1.46875 6L0.78125 8.09375ZM5.84375 5.5625L7.625 4.28125H5.4375L7.21875 5.5625L6.53125 3.46875L5.84375 5.5625ZM3.3125 5.5625L5.09375 4.28125H2.90625L4.6875 5.5625L4 3.46875L3.3125 5.5625ZM0.78125 5.5625L2.5625 4.28125H0.375L2.15625 5.5625L1.46875 3.46875L0.78125 5.5625ZM5.84375 3L7.625 1.71875H5.4375L7.21875 3L6.53125 0.90625L5.84375 3ZM3.3125 3L5.09375 1.71875H2.90625L4.6875 3L4 0.90625L3.3125 3ZM0.78125 3L2.5625 1.71875H0.375L2.15625 3L1.46875 0.90625L0.78125 3Z"
            fill="#EEEEEE"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1815_12115">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export const ChineseFlagIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clip-path="url(#clip0_1815_18895)">
        <mask id="mask0_1815_18895" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <path d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1815_18895)">
          <path d="M0 0.5H16V16.5H0V0.5Z" fill="#D80027" />
          <path
            d="M4.37813 5.36895L5.06875 7.49394H7.30312L5.49687 8.80957L6.1875 10.9346L4.37813 9.62207L2.56875 10.9346L3.2625 8.80957L1.45312 7.49394H3.6875L4.37813 5.36895ZM9.48438 12.8908L8.95625 12.2408L8.175 12.5439L8.62813 11.8408L8.1 11.1877L8.90938 11.4033L9.36563 10.7002L9.40938 11.5377L10.2219 11.7533L9.4375 12.0533L9.48438 12.8908ZM10.5344 10.9846L10.7844 10.1846L10.1 9.70019L10.9375 9.6877L11.1844 8.88769L11.4563 9.68144L12.2937 9.67207L11.6219 10.1721L11.8906 10.9658L11.2063 10.4814L10.5344 10.9846ZM11.95 6.37207L11.5813 7.12519L12.1812 7.70957L11.3531 7.59082L10.9844 8.34082L10.8406 7.51582L10.0094 7.39707L10.7531 7.00644L10.6094 6.17832L11.2094 6.76269L11.95 6.37207ZM9.50625 4.09082L9.44375 4.9252L10.2219 5.24082L9.40625 5.44082L9.34688 6.27832L8.90625 5.56582L8.09062 5.76582L8.63125 5.1252L8.1875 4.41582L8.96562 4.73145L9.50625 4.09082Z"
            fill="#FFDA44"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1815_18895">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
export const IndianFlagIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clip-path="url(#clip0_1815_18906)">
        <mask id="mask0_1815_18906" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <path d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1815_18906)">
          <path d="M0 5.5L8 4.5L16 5.5V11.5L8 12.5L0 11.5V5.5Z" fill="#EEEEEE" />
          <path d="M0 0.5H16V5.5H0V0.5Z" fill="#FF9811" />
          <path d="M0 11.5H16V16.5H0V11.5Z" fill="#6DA544" />
          <path d="M8 10.75C9.24264 10.75 10.25 9.74264 10.25 8.5C10.25 7.25736 9.24264 6.25 8 6.25C6.75736 6.25 5.75 7.25736 5.75 8.5C5.75 9.74264 6.75736 10.75 8 10.75Z" fill="#0052B4" />
          <path d="M8 10C8.82843 10 9.5 9.32843 9.5 8.5C9.5 7.67157 8.82843 7 8 7C7.17157 7 6.5 7.67157 6.5 8.5C6.5 9.32843 7.17157 10 8 10Z" fill="#EEEEEE" />
          <path d="M8 9.25C8.41421 9.25 8.75 8.91421 8.75 8.5C8.75 8.08579 8.41421 7.75 8 7.75C7.58579 7.75 7.25 8.08579 7.25 8.5C7.25 8.91421 7.58579 9.25 8 9.25Z" fill="#0052B4" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1815_18906">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export const SpanishFlagIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clip-path="url(#clip0_1815_18920)">
        <mask id="mask0_1815_18920" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <path d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1815_18920)">
          <path d="M0 4.5L8 3.5L16 4.5V12.5L8 13.5L0 12.5V4.5Z" fill="#FFDA44" />
          <path d="M0 0.5H16V4.5H0V0.5ZM0 12.5H16V16.5H0V12.5Z" fill="#D80027" />
          <path d="M4.5 10H4V7.5H4.5V10ZM8.5 10H9V7.5H8.5V10Z" fill="#EEEEEE" />
          <path d="M6.5 10.75C7.32843 10.75 8 10.3023 8 9.75C8 9.19772 7.32843 8.75 6.5 8.75C5.67157 8.75 5 9.19772 5 9.75C5 10.3023 5.67157 10.75 6.5 10.75Z" fill="#EEEEEE" />
          <path d="M4.5 6.75C4.5 6.61193 4.38807 6.5 4.25 6.5C4.11193 6.5 4 6.61193 4 6.75V7C4 7.13807 4.11193 7.25 4.25 7.25C4.38807 7.25 4.5 7.13807 4.5 7V6.75Z" fill="#D80027" />
          <path d="M9 6.75C9 6.61193 8.88807 6.5 8.75 6.5C8.61193 6.5 8.5 6.61193 8.5 6.75V7C8.5 7.13807 8.61193 7.25 8.75 7.25C8.88807 7.25 9 7.13807 9 7V6.75Z" fill="#D80027" />
          <path
            d="M6.5 9V9.75C6.5 9.94891 6.57902 10.1397 6.71967 10.2803C6.86032 10.421 7.05109 10.5 7.25 10.5C7.44891 10.5 7.63968 10.421 7.78033 10.2803C7.92098 10.1397 8 9.94891 8 9.75V9H7.25H6.5Z"
            fill="#D80027"
          />
          <path d="M4.5 7H4C3.86193 7 3.75 7.11193 3.75 7.25C3.75 7.38807 3.86193 7.5 4 7.5H4.5C4.63807 7.5 4.75 7.38807 4.75 7.25C4.75 7.11193 4.63807 7 4.5 7Z" fill="#FF9811" />
          <path d="M9 7H8.5C8.36193 7 8.25 7.11193 8.25 7.25C8.25 7.38807 8.36193 7.5 8.5 7.5H9C9.13807 7.5 9.25 7.38807 9.25 7.25C9.25 7.11193 9.13807 7 9 7Z" fill="#FF9811" />
          <path d="M4.5 10H4C3.86193 10 3.75 10.1119 3.75 10.25C3.75 10.3881 3.86193 10.5 4 10.5H4.5C4.63807 10.5 4.75 10.3881 4.75 10.25C4.75 10.1119 4.63807 10 4.5 10Z" fill="#FF9811" />
          <path d="M9 10H8.5C8.36193 10 8.25 10.1119 8.25 10.25C8.25 10.3881 8.36193 10.5 8.5 10.5H9C9.13807 10.5 9.25 10.3881 9.25 10.25C9.25 10.1119 9.13807 10 9 10Z" fill="#FF9811" />
          <path
            d="M5 9V9.75C5 10 5.125 10.1875 5.28125 10.3438L5.4375 10.1562L5.59375 10.4688C5.6964 10.4939 5.8036 10.4939 5.90625 10.4688L6.0625 10.1562L6.21875 10.3438C6.40625 10.1875 6.5 10 6.5 9.75V9H6.21875L6.0625 9.25L5.90625 9H5.59375L5.4375 9.25L5.28125 9H5Z"
            fill="#FF9811"
          />
          <path
            d="M3.8125 8.25C3.77935 8.25 3.74755 8.26317 3.72411 8.28661C3.70067 8.31005 3.6875 8.34185 3.6875 8.375C3.6875 8.40815 3.70067 8.43995 3.72411 8.46339C3.74755 8.48683 3.77935 8.5 3.8125 8.5H9.1875C9.22065 8.5 9.25245 8.48683 9.27589 8.46339C9.29933 8.43995 9.3125 8.40815 9.3125 8.375C9.3125 8.34185 9.29933 8.31005 9.27589 8.28661C9.25245 8.26317 9.22065 8.25 9.1875 8.25H3.8125ZM3.8125 9C3.77935 9 3.74755 9.01317 3.72411 9.03661C3.70067 9.06005 3.6875 9.09185 3.6875 9.125C3.6875 9.15815 3.70067 9.18995 3.72411 9.21339C3.74755 9.23683 3.77935 9.25 3.8125 9.25H4.6875C4.72065 9.25 4.75245 9.23683 4.77589 9.21339C4.79933 9.18995 4.8125 9.15815 4.8125 9.125C4.8125 9.09185 4.79933 9.06005 4.77589 9.03661C4.75245 9.01317 4.72065 9 4.6875 9H3.8125ZM8.3125 9C8.27935 9 8.24755 9.01317 8.22411 9.03661C8.20067 9.06005 8.1875 9.09185 8.1875 9.125C8.1875 9.15815 8.20067 9.18995 8.22411 9.21339C8.24755 9.23683 8.27935 9.25 8.3125 9.25H9.1875C9.22065 9.25 9.25245 9.23683 9.27589 9.21339C9.29933 9.18995 9.3125 9.15815 9.3125 9.125C9.3125 9.09185 9.29933 9.06005 9.27589 9.03661C9.25245 9.01317 9.22065 9 9.1875 9H8.3125Z"
            fill="#D80027"
          />
          <path
            d="M6.125 5.75C5.90625 5.75 5.71875 5.90625 5.65625 6.09375L5.5 6.0625C5.21875 6.0625 5 6.28125 5 6.5625C5 6.84375 5.21875 7.0625 5.5 7.0625C5.71875 7.0625 5.90625 6.9375 5.96875 6.71875C6.05989 6.75163 6.15862 6.75732 6.25293 6.73512C6.34725 6.71293 6.43308 6.66382 6.5 6.59375C6.56692 6.66382 6.65275 6.71293 6.74707 6.73512C6.84138 6.75732 6.94011 6.75163 7.03125 6.71875C7.06157 6.8134 7.11942 6.89687 7.19739 6.95849C7.27537 7.02012 7.36995 7.05711 7.46905 7.06473C7.56814 7.07236 7.66726 7.05028 7.75375 7.00131C7.84024 6.95234 7.91018 6.87871 7.95463 6.78981C7.99907 6.70092 8.01602 6.60079 8.0033 6.50222C7.99058 6.40364 7.94877 6.3111 7.88321 6.2364C7.81765 6.1617 7.73132 6.10823 7.63523 6.08282C7.53914 6.05741 7.43766 6.06122 7.34375 6.09375C7.31361 6.01273 7.26298 5.94091 7.19681 5.8853C7.13063 5.82968 7.05117 5.79218 6.96617 5.77644C6.88117 5.7607 6.79355 5.76726 6.71184 5.79549C6.63014 5.82372 6.55715 5.87265 6.5 5.9375C6.40625 5.8125 6.28125 5.75 6.125 5.75ZM6.125 6C6.28125 6 6.375 6.125 6.375 6.25C6.375 6.40625 6.28125 6.5 6.125 6.5C6 6.5 5.875 6.40625 5.875 6.25C5.875 6.125 6 6 6.125 6ZM6.875 6C7.03125 6 7.125 6.125 7.125 6.25C7.125 6.40625 7.03125 6.5 6.875 6.5C6.75 6.5 6.625 6.40625 6.625 6.25C6.625 6.125 6.75 6 6.875 6ZM5.5 6.3125L5.625 6.34375L5.75 6.59375C5.75 6.71875 5.625 6.8125 5.5 6.8125C5.375 6.8125 5.25 6.71875 5.25 6.5625C5.25 6.4375 5.375 6.3125 5.5 6.3125ZM7.5 6.3125C7.65625 6.3125 7.75 6.4375 7.75 6.5625C7.75 6.71875 7.65625 6.8125 7.5 6.8125C7.375 6.8125 7.25 6.71875 7.25 6.59375L7.375 6.34375L7.5 6.3125Z"
            fill="#EEEEEE"
          />
          <path d="M6.25 5.5H6.75V6.5H6.25V5.5Z" fill="#FF9811" />
          <path d="M6.5 7.5H8V9H6.5V7.5Z" fill="#EEEEEE" />
          <path d="M7.75 7L7.5 7.25H5.5L5.25 7C5.25 6.59375 5.8125 6.25 6.5 6.25C7.1875 6.25 7.75 6.59375 7.75 7ZM5 7.5H6.5V9H5V7.5Z" fill="#D80027" />
          <path
            d="M7.5625 8.0625C7.5625 7.88991 7.42259 7.75 7.25 7.75C7.07741 7.75 6.9375 7.88991 6.9375 8.0625V8.4375C6.9375 8.61009 7.07741 8.75 7.25 8.75C7.42259 8.75 7.5625 8.61009 7.5625 8.4375V8.0625Z"
            fill="#D80027"
          />
          <path d="M5.25 7.75V8H5.5V8.5H5.25V8.75H6.25V8.5H6V8H6.25V7.75H5.25ZM5.5 7.25H7.5V7.5H5.5V7.25Z" fill="#FF9811" />
          <path d="M5.8125 7C5.91605 7 6 6.91605 6 6.8125C6 6.70895 5.91605 6.625 5.8125 6.625C5.70895 6.625 5.625 6.70895 5.625 6.8125C5.625 6.91605 5.70895 7 5.8125 7Z" fill="#FFDA44" />
          <path d="M6.5 7C6.60355 7 6.6875 6.91605 6.6875 6.8125C6.6875 6.70895 6.60355 6.625 6.5 6.625C6.39645 6.625 6.3125 6.70895 6.3125 6.8125C6.3125 6.91605 6.39645 7 6.5 7Z" fill="#FFDA44" />
          <path d="M7.1875 7C7.29105 7 7.375 6.91605 7.375 6.8125C7.375 6.70895 7.29105 6.625 7.1875 6.625C7.08395 6.625 7 6.70895 7 6.8125C7 6.91605 7.08395 7 7.1875 7Z" fill="#FFDA44" />
          <path d="M5.28125 9V10.3438C5.3746 10.4086 5.48146 10.4513 5.59375 10.4688V9H5.28125ZM5.90625 9V10.4688C6.01854 10.4513 6.1254 10.4086 6.21875 10.3438V9H5.90625Z" fill="#D80027" />
          <path d="M6.5 9.5C6.77614 9.5 7 9.27614 7 9C7 8.72386 6.77614 8.5 6.5 8.5C6.22386 8.5 6 8.72386 6 9C6 9.27614 6.22386 9.5 6.5 9.5Z" fill="#338AF3" />
          <path d="M9 10.5H8.5C8.36193 10.5 8.25 10.6119 8.25 10.75C8.25 10.8881 8.36193 11 8.5 11H9C9.13807 11 9.25 10.8881 9.25 10.75C9.25 10.6119 9.13807 10.5 9 10.5Z" fill="#338AF3" />
          <path d="M4.5 10.5H4C3.86193 10.5 3.75 10.6119 3.75 10.75C3.75 10.8881 3.86193 11 4 11H4.5C4.63807 11 4.75 10.8881 4.75 10.75C4.75 10.6119 4.63807 10.5 4.5 10.5Z" fill="#338AF3" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1815_18920">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export const JapaneseFlagIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clip-path="url(#clip0_1815_18960)">
        <mask id="mask0_1815_18960" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <path d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1815_18960)">
          <path d="M0 0.5H16V16.5H0V0.5Z" fill="#EEEEEE" />
          <path
            d="M8.0001 11.9782C9.92101 11.9782 11.4782 10.421 11.4782 8.5001C11.4782 6.57918 9.92101 5.02197 8.0001 5.02197C6.07918 5.02197 4.52197 6.57918 4.52197 8.5001C4.52197 10.421 6.07918 11.9782 8.0001 11.9782Z"
            fill="#D80027"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1815_18960">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export const FrenchFlagIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clip-path="url(#clip0_1815_18970)">
        <mask id="mask0_1815_18970" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <path d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1815_18970)">
          <path d="M5.21885 0.5H10.7813L11.5907 8.38438L10.7813 16.5H5.21885L4.2876 8.58125L5.21885 0.5Z" fill="#EEEEEE" />
          <path d="M0 0.5H5.21875V16.5H0V0.5Z" fill="#0052B4" />
          <path d="M10.7812 0.5H16V16.5H10.7812V0.5Z" fill="#D80027" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1815_18970">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export const BrazilianFlagIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clip-path="url(#clip0_1815_18981)">
        <mask id="mask0_1815_18981" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <path d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1815_18981)">
          <path d="M0 0.5H16V16.5H0V0.5Z" fill="#6DA544" />
          <path d="M8 3.63135L14.6094 8.5001L8 13.3688L1.39062 8.5001L8 3.63135Z" fill="#FFDA44" />
          <path
            d="M5.44373 7.40643C5.28824 7.76405 5.21151 8.15092 5.21873 8.54081L10.2812 10.0971C10.5043 9.77847 10.6576 9.41643 10.7312 9.03456C9.46248 6.99393 6.9906 6.52518 5.44685 7.40956L5.44373 7.40643Z"
            fill="#EEEEEE"
          />
          <path
            d="M7.99063 5.71876C7.53358 5.71991 7.08386 5.83369 6.68125 6.05001C6.12712 6.34898 5.69084 6.82713 5.44375 7.40626C6.39058 7.20521 7.37309 7.24954 8.29796 7.53505C9.22284 7.82055 10.0594 8.33774 10.7281 9.03751C10.8524 8.40486 10.7529 7.74871 10.4469 7.18126C10.2082 6.738 9.85361 6.36785 9.42103 6.11028C8.98845 5.85272 8.49408 5.7174 7.99063 5.71876ZM6.625 8.32814C6.14813 8.32654 5.67385 8.39821 5.21875 8.54063C5.22401 8.98683 5.33655 9.42521 5.54688 9.81876C5.71995 10.1406 5.95474 10.4252 6.23785 10.6562C6.52096 10.8873 6.84683 11.0603 7.19683 11.1654C7.54683 11.2704 7.9141 11.3055 8.27766 11.2685C8.64121 11.2316 8.99392 11.1233 9.31563 10.95C9.69757 10.7443 10.0261 10.4522 10.275 10.0969C9.83638 9.54925 9.28115 9.10631 8.64973 8.80034C8.01832 8.49436 7.32662 8.33305 6.625 8.32814Z"
            fill="#0052B4"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1815_18981">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export const GermanFlagIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <g clip-path="url(#clip0_1815_18993)">
        <mask id="mask0_1815_18993" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="17">
          <path d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1815_18993)">
          <path d="M0 11.2812L8.02188 10.4844L16 11.2812V16.5H0V11.2812Z" fill="#FFDA44" />
          <path d="M0 5.71875L7.96875 5L16 5.71875V11.2812H0V5.71875Z" fill="#D80027" />
          <path d="M0 0.5H16V5.71875H0V0.5Z" fill="#333333" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1815_18993">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};

export const DutchFlagIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        d="M15.0684 12.25C15.6631 11.1315 16 9.85509 16 8.5C16 7.14491 15.6631 5.86851 15.0684 4.75H0.931552C0.336917 5.86851 0 7.14491 0 8.5C0 9.85509 0.336917 11.1315 0.931552 12.25H15.0684Z"
        fill="#EEEEEE"
      />
      <path d="M15.515 11.25C14.3936 14.3136 11.4522 16.5 8.00016 16.5C4.54813 16.5 1.60676 14.3136 0.485352 11.25H15.515Z" fill="#0052B4" />
      <path d="M15.515 5.75H0.485352C1.60676 2.68643 4.54813 0.5 8.00016 0.5C11.4522 0.5 14.3936 2.68643 15.515 5.75Z" fill="#A2001D" />
    </SvgWrapper>
  );
};
