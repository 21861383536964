//auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const BeforeVideoIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }} fill="none">
      <path d="M11 12V0L3 6L11 12Z" fill={color ? `${color}` : `#F1F3F5`} />
      <path d="M2 0H0V12H2V0Z" fill={color ? `${color}` : `#F1F3F5`} />
    </SvgWrapper>
  );
};
