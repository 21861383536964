import { createDocument } from "redux/actions/vdocsActions";
import store from "redux/store";
import useEditorStore from "store/useEditorStore";
export const isPreviousBlockEmpty = (previousBlockIndex: number) => {
  const { blocks } = store.getState().vdocs.editorInstance;
  const { getBlockByIndex } = blocks;
  const previousBlock = getBlockByIndex(previousBlockIndex);
  return typeof previousBlock !== "undefined" && previousBlock.name !== "image" && previousBlock.isEmpty;
};

export const getCurrentDocumentKey = async () => {
  const currentEditorDocumentTitle = useEditorStore.getState().editorDocumentTitle;
  const currentDocument = store.getState().vdocs.currentDocument;
  if (currentDocument && currentDocument["document_key"] !== "new") {
    return currentDocument["document_key"];
  }
  const createdDocument = await store.dispatch(createDocument({ origin: "vdocs", title: currentEditorDocumentTitle }));
  if (createdDocument.error_message) return;
  return createdDocument["document_key"];
};
