/* eslint-disable react-hooks/exhaustive-deps */
// auth 2.0
import React, { useState, useEffect, memo } from "react";
import styles from "./ListTags.module.scss";
import DocumentItem from "./DocumentItem";
import FolderItem from "./FolderItem";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { moveDocument, fetchDocument, fetchDocuments, createDocument } from "redux/actions/vdocsActions";
import { fetchCurrentFolder } from "components/myNotes/folder/folder.utils";
import { isTouchDevice } from "utils/utils";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";
import { useSortDocument } from "hooks/useSortDocument";

// 1. states
// documentData, -> documents
// documentKey, -> currentDocument['document_key']
// currentDocument, -> currentDocument
// 2. actions
// onDropDocument,
// onUpdateDocument,
// moveDocument
// fetchDocuments

const ListTags = memo(() => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const lang = useAppSelector((state) => state.slidGlobal.lang);
  const { currentFolder, currentDocument, documents: documentData } = useAppSelector((state) => state.vdocs);
  const [isHover, setIsHover] = useState(false);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { recursiveSortDocuments } = useSortDocument();
  const isVideoNote = location.pathname.split("/")[1] === "vdocs";

  useEffect(() => {
    if (currentDocument) {
      dispatch(fetchDocuments());
    }
  }, [currentDocument]);

  const onDropDocument = async (event, item) => {
    event.preventDefault();
    event.stopPropagation();

    if (!confirmPrivilege(SlidFeatures.noteMove)) return showInsufficientPrivilegeModal();

    let documentKey = event.dataTransfer.getData("documentKey");
    if (documentKey === "new") {
      const documentData = await dispatch(createDocument({}));
      if (documentData.error_message) return;
      documentKey = documentData["document_key"];
      history.push(`/vdocs/${documentKey}`);
    }

    await dispatch(
      moveDocument({
        parentKey: !item ? "root" : item.document_key,
        documentKeys: [documentKey],
        location: isVideoNote ? "Video note page" : "Plain note page",
        from: "single selection",
      })
    );

    if (currentFolder) {
      fetchCurrentFolder({ documentKey: currentFolder["document_key"] });
    } else {
      fetchCurrentFolder({ documentKey: "root" });
    }

    if ((currentDocument && currentDocument["document_key"] === documentKey) || documentKey === "new") {
      dispatch(
        fetchDocument({
          documentKey: documentKey,
        })
      );
    }
    dispatch(fetchDocuments());
  };

  const getListTags = () => {
    const folderListTags = [];
    const documentListTags = [];

    if (currentDocument === null || currentDocument.document_key === "new") {
      documentListTags.push(
        <DocumentItem
          key={"new"}
          title={lang === "ko" ? "제목 없음 (현재 노트)" : "Untitled (Current)"}
          documentKey={"new"}
          currentDocumentKey={"new"}
          onUpdateDocument={() => {
            dispatch(fetchDocuments());
          }}
        />
      );
    }

    const sortedChildDocuments = recursiveSortDocuments(documentData);

    sortedChildDocuments?.forEach((item) => {
      if (item.is_folder) {
        const sortedChildDocuments = recursiveSortDocuments(item.documents);

        folderListTags.push(
          <FolderItem
            key={item["document_key"]}
            item={item}
            title={item["title"]}
            documentKey={item["document_key"]}
            childDocuments={sortedChildDocuments}
            currentDocument={currentDocument}
            currentDocumentKey={currentDocument ? currentDocument["document_key"] : null}
            onUpdateDocument={() => {
              dispatch(fetchDocuments());
            }}
            onDropDocument={!isTouchDevice() && onDropDocument}
          />
        );
      } else {
        documentListTags.push(
          <DocumentItem
            key={item["document_key"]}
            title={item["title"]}
            documentKey={item["document_key"]}
            currentDocumentKey={currentDocument ? currentDocument["document_key"] : null}
            onUpdateDocument={() => {
              dispatch(fetchDocuments());
            }}
          />
        );
      }
    });
    return folderListTags.concat(documentListTags);
  };

  return (
    <div
      className={`${styles[`list-tags-container`]} ${isHover ? styles[`hover`] : ""}`}
      onDragOver={(event, item) => {
        if (isTouchDevice()) return;
        setIsHover(true);
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.getData("documentKey") === item?.document_key) return;
      }}
      onDragEnd={() => setIsHover(false)}
      onDragLeave={() => setIsHover(false)}
      onDrop={(e, item) => {
        if (isTouchDevice()) return;
        onDropDocument(e, item);
        setIsHover(false);
      }}
    >
      {getListTags()}
    </div>
  );
});

export default ListTags;
