import { IPSColor, Typography11, Typography13, Typography14, Typography16, Typography20, Typography34, Typography9, Tooltip } from "@slid/slid-ips";
import React, { useEffect } from "react";
import styled from "styled-components";

type ContainerSize = "large" | "medium" | "small" | "xsmall" | "xxsmall";

interface StatusBoxProps {
  size: ContainerSize;
  headerText?: string;
  headerColor?: IPSColor;
  onClickHeader?: () => void;
  contentTitle: string;
  contentDesc: string;
  tooltipTitle: string;
  tooltipDesc?: string;
}

const StatusBox = ({ size, headerText, headerColor, onClickHeader, contentTitle, contentDesc, tooltipTitle, tooltipDesc }: StatusBoxProps) => {
  const boxContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    let parent = boxContainerRef.current?.parentElement;
    if (parent) {
      parent.style.width = "100%";
    }
  }, [size]);

  switch (size) {
    case "large":
      return (
        <Tooltip placement="bottom" title={tooltipTitle} description={tooltipDesc}>
          <StatusBoxContainer ref={boxContainerRef} size={size}>
            <div className="header">
              {onClickHeader
                ? headerText && (
                    <TextButton
                      onClick={() => {
                        onClickHeader();
                      }}
                    >
                      <Typography14 weight={500} color={headerColor!} text={headerText} type={"span"} />
                    </TextButton>
                  )
                : headerText && <Typography14 weight={500} color={headerColor!} text={headerText} type={"span"} />}
            </div>
            <div className="content">
              <Typography34 weight={700} color="--gray17" text={contentTitle} type={"span"} />
              <Typography14 weight={500} color="--gray17" text={contentDesc} type={"span"} />
            </div>
          </StatusBoxContainer>
        </Tooltip>
      );
    case "medium":
      return (
        <Tooltip placement="bottom" title={tooltipTitle} description={tooltipDesc}>
          <StatusBoxContainer ref={boxContainerRef} size={size}>
            <div className="header">
              {onClickHeader
                ? headerText && (
                    <TextButton
                      onClick={() => {
                        onClickHeader();
                      }}
                    >
                      <Typography11 weight={500} color={headerColor!} text={headerText} type={"span"} />
                    </TextButton>
                  )
                : headerText && <Typography11 weight={500} color={headerColor!} text={headerText} type={"span"} />}
            </div>
            <div className="content">
              <Typography20 weight={700} color="--gray17" text={contentTitle} type={"span"} />
              <Typography11 weight={500} color="--gray17" text={contentDesc} type={"span"} />
            </div>
          </StatusBoxContainer>
        </Tooltip>
      );
    case "small":
      return (
        <Tooltip placement="bottom" title={tooltipTitle} description={tooltipDesc}>
          <StatusBoxContainer ref={boxContainerRef} size={size}>
            <div className="header">
              {onClickHeader
                ? headerText && (
                    <TextButton
                      onClick={() => {
                        onClickHeader();
                      }}
                    >
                      <Typography9 weight={500} color={headerColor!} text={headerText} type={"span"} />
                    </TextButton>
                  )
                : headerText && <Typography9 weight={500} color={headerColor!} text={headerText} type={"span"} />}
            </div>
            <div className="content">
              <Typography16 weight={700} color="--gray17" text={contentTitle} type={"span"} />
              <Typography9 weight={500} color="--gray17" text={contentDesc} type={"span"} />
            </div>
          </StatusBoxContainer>
        </Tooltip>
      );
    case "xsmall":
      return (
        <Tooltip placement="bottom" title={tooltipTitle} description={tooltipDesc}>
          <StatusBoxContainer ref={boxContainerRef} size={size}>
            <div className="header">
              {onClickHeader
                ? headerText && (
                    <TextButton
                      onClick={() => {
                        onClickHeader();
                      }}
                    >
                      <Typography11 weight={500} color={headerColor!} text={headerText} type={"span"} />
                    </TextButton>
                  )
                : headerText && <Typography11 weight={500} color={headerColor!} text={headerText} type={"span"} />}
            </div>
            <div className="content">
              <Typography13 weight={700} color="--gray17" text={contentTitle} type={"span"} />
              <Typography11 weight={500} color="--gray17" text={contentDesc} type={"span"} />
            </div>
          </StatusBoxContainer>
        </Tooltip>
      );
    case "xxsmall":
      return <></>;
  }
};

export default StatusBox;

const StatusBoxContainer = styled.div<{ size: ContainerSize }>`
  position: relative;
  width: 100%;

  padding: ${(props) => (props.size === "xsmall" ? "12px 16px" : "24px")};

  border-radius: 8px;
  border: 1px solid var(--gray2);
  background: #fafafb;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 16px;

  ${(props) => {
    switch (props.size) {
      case "large":
      case "medium":
      case "small":
        return "min-height: 154px;";
      case "xsmall":
        return "min-height: 82px;";

      default:
        return "min-height: 154px;";
    }
  }};

  .header {
    height: 20px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

const TextButton = styled.span`
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  width: 100%;
  height: 100%;
`;
