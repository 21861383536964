import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const ResetIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 48} height={height ? height : 48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27 20L27 16L31 16" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.3455 18.2276C17.226 19.3555 16.4634 20.7927 16.1542 22.3576C15.845 23.9225 16.003 25.5447 16.6083 27.019C17.2137 28.4933 18.239 29.7535 19.5548 30.6401C20.8705 31.5268 22.4175 32 24 32C25.5825 32 27.1295 31.5268 28.4452 30.6401C29.761 29.7535 30.7863 28.4933 31.3917 27.019C31.997 25.5447 32.155 23.9225 31.8458 22.3576C31.5366 20.7927 30.774 19.3555 29.6545 18.2276L27 16"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
