import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";

const notifications_endpoint = `notification/`;

export const ServerInterface_getNotifications = async () => {
  let notificationsData = {};
  try {
    const notificationsResponse = await slidAxios.get(notifications_endpoint);
    notificationsData = notificationsResponse.data;
  } catch (error) {
    if (!notificationsData) notificationsData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        case 405:
          notificationsData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          notificationsData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          notificationsData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is uknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      notificationsData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      notificationsData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return notificationsData;
  }
};

export const ServerInterface_markNotificationsAsViewed = async ({ notificationsData }) => {
  const payload = notificationsData;
  let result = null;
  try {
    const notificationsPutResponse = await slidAxios.put(notifications_endpoint, payload);
    result = notificationsPutResponse.data;
  } catch (error) {
    if (!result) result = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        case 405:
          result.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          result.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 400:
          result.error_message = "NOTIFICATION_REQUIRED_FIELD_MISSING";
          break;
        default:
          result.error_message = "UNKNOWN_ERROR";
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      result.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      result.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return result;
  }
};
