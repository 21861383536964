export const STEP1_DATA_KO = [
  {
    id: 1,
    src: "/src/assets/video_note_demo/demo1_ko.png",
    text: "루소\n모든 인간은 살인자에게 희생되어서는 안된다. 그러므로 살인자가 된다는\n것은 자신이 죽임을 당해도 좋다고 동의한 것이다. 사회계약은 시민의 생명\n을 처분하는 것이 아니라 시민의 생명을 보전하는 것이다. 사회계약을 할때\n시민은 국가에 대한 생명박탈의 권리를 양도하였으므로 국가는 타인을 살\n해한 시민을 사형에 처할 권리가 있다.\n1. 사회계약론\n2. 사형수: 계약파기자, 공공의적, 시민 자격의 상실\n3. 응보주의: 계약위반에 대한 보복",
  },
  {
    id: 2,
    src: "/src/assets/video_note_demo/demo2_ko.png",
    text: "범죄와 형벌\n이 책에서 그는 종교로부터 법을 해방시켜야 한다고 강력히 주장하였다. 즉,\n사회계약에 포함되지 않은 형벌은 부당한 것이며, 형벌은 마땅히 입법자에\n의하여 법률로 엄밀히 규정되어야 한다고 역설하였다.\n이로 인해 중세의 주관주의적 형법사상(밀실재판, 고문에 의한 자백, 죄형전\n단주의, 가혹한 형벌 등)에 대해 근대적 객관주의의 형법사상이 확립되게\n되어, 형법 근대화에 매우 큰 공헌을 하였다.\n베카리아는 자유주의적이고 인도주의적인 형법이론의 중심은 국가 형벌권\n의 기초는 사회계약이라고 한 것이다. 즉, 각자는 자신의 자유를 확보하기\n위하여 그 자유의 일부를 내놓고 사회를 만들었으며, 그렇게 내놓은 자유의\n총계가 형벌권의 기초이고, 이러한 기초를 일탈하는 형벌권의 행사는 모두\n가 남용이며 부정이라고 하며 죄형 법정주의, 사형폐지, 고문의 금지, 법적\n평등을 주장하였다.",
  },
  {
    id: 3,
    src: "/src/assets/video_note_demo/demo3_ko.png",
    text: "<확인 0, X>\n① 루소는 사형수라도 시민자격을 가지고 있다고 본다.\n② 루소는 사형을 사회계약의 위반에 대한 응보로 본다.\n③ 베카리아는 사회계약에 의거하여 범죄자에게 형벌의 가해\n④ 형벌은 공리성의 원리에 근거하여 집행되어야 한다.",
  },
];

export const STEP1_DATA_EN = [
  {
    id: 1,
    src: "/src/assets/video_note_demo/demo1_en.jpg",
    text: 'Denotation and Connotation\n• Denotation: Dictionary meaning\n• Connotation: Commonly understood meaning or\nassociation\n\n1. The children have been happy and gay this morning.\n2. The company needs a manly sales force.\n3. "Merry Christmas!" Santa ejaculated.',
  },
  {
    id: 2,
    src: "/src/assets/video_note_demo/demo2_en.jpg",
    text: "Expanding Your Vocabulary\n• Write down words you don't know\n• Learn how and where they are commonly used\n(context) AND how to pronounce them\n• Allow the words to ferment in your mind\n• Use them yourself",
  },
  {
    id: 3,
    src: "/src/assets/video_note_demo/demo3_en.jpg",
    text: '• Avoid buzz words, legalese, and "market speak"\n\n1. My seamless brainflow will solutionize your\noperationalization.\n2. We\'re drinking the Google juice here and really\nsynergizing big time, but we need absolute reciprocal\noptions and a balanced transitional interface.',
  },
];

export const STEP3_DATA_KO = [
  {
    id: 1,
    text: "사형은 사람의 생명을 빼앗는 거기 때문에",
  },
  {
    id: 2,
    text: "인도주의적 성격에서 벗어난다 라는거죠.",
  },
  {
    id: 3,
    text: "그래서 자유주의적이고 인도주의적 형법이 이루어져야 한다.",
  },
];

export const STEP3_DATA_EN = [
  {
    id: 1,
    text: "Lastly, we have the sound of the words.",
  },
  {
    id: 2,
    text: "Now, always think like a poet, even though you're not writing in verse,",
  },
  {
    id: 3,
    text: "you want to pay attention to the way things sound.",
  },
];
