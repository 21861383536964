import { LanguageMenuItem } from "types/smartLiveText";
import { trackEvent } from "utils/eventTracking";
import { useAppDispatch, useAppSelector } from "hooks";
import { setSmartLiveTextLanguage, setStartSTT, setStopSTT } from "redux/actions/sttActions";
import { SUPPORTED_LANGUAGES } from "components/SmartLiveText/utils";
import * as Sentry from "@sentry/browser";

const useUpdateSLTLanguage = () => {
  const dispatch = useAppDispatch();
  const { smartLiveTextLanguage, isLanguageSelectedFromHeader } = useAppSelector((state) => state.sttReducer);
  const { isSTTActive } = useAppSelector((state) => state.sttReducer);

  const userDefaultLanguage = window.localStorage.getItem("slid-user-stt-default-lang");

  const languageList: LanguageMenuItem[] = SUPPORTED_LANGUAGES.map((item: LanguageMenuItem) => {
    item["callback"] = () => {
      trackEvent({
        eventType: "SELECT LIVETEXT LANGUAGE in video note page",
        eventProperties: { selected_language: item.text, origin: !isLanguageSelectedFromHeader ? "first-selection" : "header" },
      });
      handleSelectLanguage(item.text);
    };
    return item;
  });

  const handleSwitchSLTLanguage = async (lang: string) => {
    try {
      dispatch(setStopSTT());
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch(setStartSTT(lang));
    } catch (err) {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("SLID_WEB_STT_UPDATE_LANGUAGE_ERROR");
      });
    }
  };

  const handleSelectLanguage = (language: string) => {
    const hasUserSetSTTLang = window.localStorage.getItem("slid-user-has-set-stt-default-lang");

    trackEvent({
      eventType: "Check LIVETEXT DEFAULT LANGUAGE in video note page",
      eventProperties: { is_default_language_set: !!userDefaultLanguage, default_language: userDefaultLanguage },
    });
    const isDefaultLanguageOn = !hasUserSetSTTLang || (hasUserSetSTTLang && userDefaultLanguage);
    if (isDefaultLanguageOn && language) {
      window.localStorage.setItem("slid-user-stt-default-lang", language);
    } else {
      window.localStorage.removeItem("slid-user-stt-default-lang");
    }
    dispatch(setSmartLiveTextLanguage(language));
    if (language !== smartLiveTextLanguage && isSTTActive) handleSwitchSLTLanguage(language);
  };

  return { languageList };
};
export default useUpdateSLTLanguage;
