import {  signUserOut } from "utils/auth/cognitoAuthToolkit";

const AUTH_ERROR_MESSAGES = [
  [500, "ERROR DOWNLOADING KEYS"],
  [401, "COGNITO ID TOKEN TOKEN IS NULL OR INVALID"],
  [422, "PUBLIC KEY NOT FOUND IN JWKS.JSON"],
  [422, "SIGNATURE VERIFICATION FAILED"],
  [404, "USER DOES NOT EXIST"],
];

export const isAuthError = (error) => {
  const status = error.response.status;
  const errorMessage = error.response.data.message;
  for (let i = 0; i < AUTH_ERROR_MESSAGES.length; i++) {
    const error = AUTH_ERROR_MESSAGES[i];
    if (error[0] === status && error[1] === errorMessage) {
      return true;
    }
  }
  return false;
};

export const handleAuthErrors = async (error) => {
  await signUserOut();
  window.location.assign("/sign-in");
};
