import React, { useEffect, useRef, useState } from "react";
import { ModalType, useModalStore } from "store/useModalStore";
import styled from "styled-components";
import StatusBox from "./StatusBox";
import { useStudyChallengeStatus } from "hooks/queries";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import { useTranslation } from "react-i18next";

type ContainerSize = "large" | "medium" | "small" | "xsmall" | "xxsmall";

const CurrentStudyChallenge = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<ContainerSize>("large");
  const { StudyChallengeStatus } = useStudyChallengeStatus();

  const { t } = useTranslation("StudyChallenge");

  useEffect(() => {
    const updateSize = () => {
      if (ref.current) {
        if (ref.current.offsetWidth >= 811) {
          setSize("large");
        } else if (ref.current.offsetWidth >= 692) {
          setSize("medium");
        } else if (ref.current.offsetWidth >= 573) {
          setSize("small");
        } else if (ref.current.offsetWidth >= 384) {
          setSize("xsmall");
        } else {
          setSize("xxsmall");
        }
      }
    };

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const { showModal } = useModalStore();

  return (
    <CurrentStudyChallengeWrapper>
      <CurrentStudyChallengeStatusContainer ref={ref} size={size}>
        <StatusBox
          size={size}
          contentTitle={StudyChallengeStatus ? StudyChallengeStatus?.completion_rate + "%" : ""}
          contentDesc={t("GroupChallenge.ChallengeStatus.MyAchievementRate", { keySeparator: "." })}
          tooltipTitle={t("GroupChallenge.ChallengeStatus.TooltipTitle1", { keySeparator: "." })}
        />
        <StatusBox
          size={size}
          headerText={
            StudyChallengeStatus?.submitted_today
              ? t("GroupChallenge.ChallengeStatus.SubmittedTodayNote", { keySeparator: "." })
              : t("GroupChallenge.ChallengeStatus.SubmitTodayNote", { keySeparator: "." })
          }
          headerColor={StudyChallengeStatus?.submitted_today ? "--gray7" : "--red4"}
          contentTitle={
            StudyChallengeStatus
              ? t("GroupChallenge.ChallengeStatus.MyNoteSubmissionDays", { keySeparator: ".", currentDay: StudyChallengeStatus?.user_submission_days, totalDays: StudyChallengeStatus?.total_periods })
              : ""
          }
          contentDesc={t("GroupChallenge.ChallengeStatus.MyNoteSubmission", { keySeparator: "." })}
          tooltipTitle={t("GroupChallenge.ChallengeStatus.TooltipTitle2", { keySeparator: "." })}
          tooltipDesc={t("GroupChallenge.ChallengeStatus.TooltipDesc2", { keySeparator: ".", recognitionTime: StudyChallengeStatus?.recognition_time })}
        />
        <StatusBox
          size={size}
          contentTitle={StudyChallengeStatus ? StudyChallengeStatus?.group_avg_completion_rate + "%" : ""}
          contentDesc={t("GroupChallenge.ChallengeStatus.GroupAverageAchievementRate", { keySeparator: "." })}
          tooltipTitle={t("GroupChallenge.ChallengeStatus.TooltipTitle3", { keySeparator: "." })}
        />
        <StatusBox
          size={size}
          headerText={t("GroupChallenge.ChallengeStatus.ViewDetails", { keySeparator: "." })}
          headerColor="--blue7"
          contentTitle={
            StudyChallengeStatus
              ? t("GroupChallenge.ChallengeStatus.TodayNoteSubmissionPeople", {
                  keySeparator: ".",
                  todaySubmit: StudyChallengeStatus?.members_submitted_today,
                  totalMembers: StudyChallengeStatus?.total_members,
                })
              : ""
          }
          contentDesc={t("GroupChallenge.ChallengeStatus.TodayNoteSubmission", { keySeparator: "." })}
          onClickHeader={() => {
            trackEvent({
              eventType: eventTypes.click.SHOW_MORE_CHALLENGE_STATUS_IN_GROUP_CHALLENGE_PAGE,
            });

            showModal({ type: ModalType.STUDY_CHALLENGE, target: "body" });
          }}
          tooltipTitle={t("GroupChallenge.ChallengeStatus.TooltipTitle4", { keySeparator: "." })}
        />
      </CurrentStudyChallengeStatusContainer>
    </CurrentStudyChallengeWrapper>
  );
};

export default CurrentStudyChallenge;

const CurrentStudyChallengeWrapper = styled.div`
  max-width: 1040px;
  width: 100%;

  padding: 0px 10px;
`;

const CurrentStudyChallengeStatusContainer = styled.div<{ size: ContainerSize }>`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  ${(props) => {
    if (props.size === "xsmall") {
      return `
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
      `;
    }
  }}
`;

const TextButton = styled.span`
  cursor: pointer;
`;

const StatusBoxContainer = styled.div`
  width: 100%;

  padding: 24px;

  border-radius: 8px;
  border: 1px solid var(--gray2);
  background: #fafafb;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 16px;
`;
