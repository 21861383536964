//auth 2.0
/**
 * Build styles
 */
import { extractContentAfterCaret, fragmentToHtml, make } from "./utils";

import "./index.css";

/**
 * Require polyfills
 */
import "./polyfills.js";
import { cloneDeep, convertToArrow, getCaretPos, getTextDataToMerge, setCursorAtPosition } from "../../../../utils/utils";

/**
 * @typedef {object} ChecklistData
 * @property {ChecklistItem} item - checklist elements
 */

/**
 * @typedef {object} ChecklistItem
 * @property {string} text - item label
 * @property {boolean} checked - is the item checked
 */

/**
 * Checklist Tool for the Editor.js 2.0
 */
export default class BlockChecklist {
  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behavior
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: '<svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.8418 1.36493C5.04334 1.17617 5.0537 0.859753 4.86493 0.658206C4.67617 0.456659 4.35975 0.446301 4.15821 0.635069L1.83126 2.81448L0.842322 1.88556C0.641048 1.6965 0.32462 1.70641 0.135562 1.90768C-0.053497 2.10896 -0.0435939 2.42538 0.157681 2.61444L1.48845 3.86444C1.68067 4.045 1.98008 4.04521 2.17257 3.86493L4.8418 1.36493ZM6.5 2C6.22386 2 6 2.22386 6 2.5C6 2.77614 6.22386 3 6.5 3H14.5C14.7761 3 15 2.77614 15 2.5C15 2.22386 14.7761 2 14.5 2H6.5ZM6.5 7.5C6.22386 7.5 6 7.72386 6 8C6 8.27614 6.22386 8.5 6.5 8.5H14.5C14.7761 8.5 15 8.27614 15 8C15 7.72386 14.7761 7.5 14.5 7.5H6.5ZM6 13.5C6 13.2239 6.22386 13 6.5 13H14.5C14.7761 13 15 13.2239 15 13.5C15 13.7761 14.7761 14 14.5 14H6.5C6.22386 14 6 13.7761 6 13.5ZM4.86493 5.65821C5.0537 5.85975 5.04334 6.17617 4.8418 6.36493L2.17257 8.86493C1.98008 9.04521 1.68067 9.045 1.48845 8.86444L0.157681 7.61444C-0.0435939 7.42538 -0.053497 7.10896 0.135562 6.90768C0.32462 6.70641 0.641048 6.6965 0.842322 6.88556L1.83126 7.81448L4.15821 5.63507C4.35975 5.4463 4.67617 5.45666 4.86493 5.65821ZM4.8418 11.3649C5.04334 11.1762 5.0537 10.8598 4.86493 10.6582C4.67617 10.4567 4.35975 10.4463 4.15821 10.6351L1.83126 12.8145L0.842322 11.8856C0.641048 11.6965 0.32462 11.7064 0.135562 11.9077C-0.053497 12.109 -0.0435939 12.4254 0.157681 12.6144L1.48845 13.8644C1.68067 14.045 1.98008 14.0452 2.17257 13.8649L4.8418 11.3649Z" fill="currentColor" /></svg>',
      title: "Checklist",
    };
  }

  /**
   * Allow Checkbox Tool to be converted to/from other block
   *
   * @returns {{export: Function, import: Function}}
   */
  static get conversionConfig() {
    return {
      /**
       * To create exported string from the checkbox, concatenate items by dot-symbol.
       *
       * @param {ChecklistData} data - checklist data to create a string from that
       * @returns {string}
       */
      export: (data) => {
        return data.items.map(({ text }) => text).join(". ");
      },
      /**
       * To create a checklist from other block's string, just put it at the first item
       *
       * @param {string} string - string to create list tool data from that
       * @returns {ChecklistData}
       */
      import: (string) => {
        return {
          items: [
            {
              text: string,
              checked: false,
            },
          ],
          indentLevel: 0, // 0 ~ 8
        };
      },
    };
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} options - block constructor options
   * @param {ChecklistData} options.data - previously saved data
   * @param {object} options.config - user config for Tool
   * @param {object} options.api - Editor.js API
   * @param {boolean} options.readOnly - read only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    /**
     * HTML nodes
     *
     * @private
     */
    this._elements = {
      wrapper: null,
      textField: null,
    };
    this.readOnly = readOnly;
    this.api = api;
    this.config = config;
    /**
     * Fill or create tool's data structure
     */
    this.data =
      data && data.items
        ? cloneDeep(data)
        : {
            items: [{ text: "", checked: false }],
            indentLevel: 0, // 0 ~ 8
          };
    this.MAX_INDENT_LEVEL = 8;
    this.INDENT_PADDING = 30;
  }

  /**
   * Returns checklist tag with item
   *
   * @returns {Element}
   */
  render() {
    this._elements.wrapper = make("div", [this.CSS.baseBlock, this.CSS.wrapper]);

    const firstItem = this.createChecklistItem(this.data.items[0]);

    this._elements.wrapper.appendChild(firstItem);

    const setCypressAttribute = () => {
      this._elements.wrapper.setAttribute("slid-cy", "editor-checklist");
      this._elements.textField.setAttribute("slid-cy", "editor-checklist-input");
    };

    setCypressAttribute();
    this._elements.wrapper.style.marginLeft = `${this.INDENT_PADDING * this.data.indentLevel}px`;

    /**
     * If read-only mode is on, do not bind events
     */
    if (this.readOnly) {
      return this._elements.wrapper;
    }

    /**
     * Add event-listeners
     */
    this.onKeyDown = this.onKeyDown.bind(this);
    this._elements.wrapper.addEventListener(
      "keydown",
      (e) => {
        this.onKeyDown(e);
      },
      false
    );

    this._elements.wrapper.addEventListener("click", (event) => {
      this.toggleCheckbox(event);
    });

    return this._elements.wrapper;
  }

  merge(data) {
    const caretPos = getCaretPos(this._elements.textField);
    const newData = {
      items: [{ text: this.data.items[0].text + getTextDataToMerge(data), checked: this.data.items[0].checked }],
      indentLevel: this.data.indentLevel,
    };

    this.data = newData;
    this._elements.textField.innerHTML = this.data.items[0].text;

    setTimeout(() => {
      setCursorAtPosition(this._elements.textField, caretPos);
    });
  }

  onKeyDown(event) {
    convertToArrow(event);

    const [ENTER, BACKSPACE, TAB] = [13, 8, 9]; // key codes
    switch (event.keyCode) {
      case ENTER:
        if (event.shiftKey) return;
        this.enterPressed(event);
        break;
      case BACKSPACE:
        this.backspace(event);
        break;
      case TAB:
        if (event.shiftKey) {
          this.shiftTab(event);
        } else {
          this.addTab(event);
        }
        break;
      default:
        return;
    }
  }

  /**
   * Add indentation to current item
   *
   * @param {KeyboardEvent} event - keydown
   */
  addTab(event) {
    /**
     * Prevent editor.js behavior
     */
    event.stopPropagation();

    /**
     * Prevent browser tab behavior
     */
    event.preventDefault();
    /**
     * Prevent this.data.indentLevel becomes 8 when sometimes this.data.indentLevel is undefined
     */
    if (this.data.indentLevel === undefined) this.data.indentLevel = 0;

    this.data.indentLevel = this.data.indentLevel < this.MAX_INDENT_LEVEL ? this.data.indentLevel + 1 : this.MAX_INDENT_LEVEL;

    this._elements.wrapper.style.marginLeft = `${this.INDENT_PADDING * this.data.indentLevel}px`;
  }

  shiftTab(event) {
    /**
     * Prevent editor.js behavior
     */
    event.stopPropagation();

    /**
     * Prevent browser tab behavior
     */
    event.preventDefault();

    this.data.indentLevel = this.data.indentLevel > 0 ? this.data.indentLevel - 1 : 1;

    this._elements.wrapper.style.marginLeft = `${this.INDENT_PADDING * this.data.indentLevel}px`;
  }
  /**
   * Return Checklist data
   *
   * @returns {ChecklistData}
   */
  save() {
    return this.data;
  }

  /**
   * Toggle checklist item state
   *
   * @param {MouseEvent} event - click
   * @returns {void}
   */
  toggleCheckbox(event) {
    const checkListItem = event.target.closest(`.${this.CSS.item}`);
    if (checkListItem) {
      const checkbox = checkListItem.querySelector(`.${this.CSS.checkbox}`);

      if (checkbox.contains(event.target)) {
        checkListItem.classList.toggle(this.CSS.itemChecked);
        this.data.items[0].checked = !this.data.items[0].checked;
      }
    }
  }

  /**
   * Create Checklist item
   *
   * @param {ChecklistItem} item - data.items
   * @returns {Element} checkListItem - new element of checklist
   */
  createChecklistItem(item = {}) {
    const checkListItem = make("div", this.CSS.item);
    const checkbox = make("span", this.CSS.checkbox);
    checkbox.innerHTML = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12L10.5 16L18 8" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
    const textField = make("div", this.CSS.textField, {
      innerHTML: item.text ? item.text : "",
      contentEditable: !this.readOnly,
    });

    textField.addEventListener("input", () => {
      this.data.items[0].text = textField.innerHTML;
    });
    const mutationObserver = new MutationObserver((mutationsList) => {
      this.data.items[0].text = mutationsList[0].target.innerHTML; // For check changing by inline tools
    });
    mutationObserver.observe(textField, { characterData: false, childList: true, attributes: false });

    this._elements.textField = textField;
    if (item.checked) {
      checkListItem.classList.add(this.CSS.itemChecked);
    }

    checkListItem.appendChild(checkbox);
    checkListItem.appendChild(textField);

    return checkListItem;
  }

  /**
   * Append new elements to the list by pressing Enter
   *
   * @param {KeyboardEvent} event - keyboard event
   */
  enterPressed(event) {
    /**
     * Prevent editor.js behavior
     */
    event.stopPropagation();

    /**
     * Prevent browser behavior
     */
    event.preventDefault();
    const value = this._elements.textField.innerHTML;
    /**
     * Prevent checklist item generation if it's the last item and it's empty
     * and get out of checklist
     */
    if (value === "") {
      /** Insert New Paragraph Block and set caret */
      const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
      this.api.blocks.delete();

      // If the block was the first block, which is index 0, it automatically creates new empty block.
      // So only create new block when the block index is not 0
      if (currentBlockIndex !== 0) {
        this.api.blocks.insert();
      }
      this.api.caret.setToBlock(currentBlockIndex);
      return;
    }

    /**
     * Cut content after caret
     */
    const fragmentAfterCaret = extractContentAfterCaret();
    const htmlAfterCaret = fragmentToHtml(fragmentAfterCaret);

    /** Insert New list and set caret */
    const blockIndex = this.api.blocks.getCurrentBlockIndex();
    this.api.blocks.insert(
      "blockChecklist",
      {
        items: [{ text: htmlAfterCaret, checked: false }],
        indentLevel: this.data.indentLevel,
      },
      undefined,
      blockIndex + 1,
      true
    );
    this.api.caret.setToBlock(blockIndex + 1);

    /** Remove ending text from current data */
    this.data.items[0].text = this._elements.textField.innerHTML;
  }

  /**
   * Handle backspace
   *
   * @param {KeyboardEvent} event - keyboard event
   */
  backspace(event) {
    event.stopPropagation(); // * It prevents editorjs automatically pointing previous block, not deleting space.
    const value = this._elements.textField.innerHTML;
    const caretPos = getCaretPos(this._elements.textField);
    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
    if (caretPos === 0 || value === "") {
      this.api.blocks.delete(currentBlockIndex);
      this.api.blocks.insert("paragraph", { text: value }, {}, currentBlockIndex, true);
      this.api.caret.setToBlock(currentBlockIndex);
    }
  }

  /**
   * Styles
   *
   * @private
   * @returns {object<string>}
   */
  get CSS() {
    return {
      baseBlock: this.api.styles.block,
      wrapper: "cdx-checklist",
      item: "cdx-checklist__item",
      itemChecked: "cdx-checklist__item--checked",
      checkbox: "cdx-checklist__item-checkbox",
      textField: "cdx-checklist__item-text",
    };
  }

  /**
   * Find and return item's content editable element
   *
   * @private
   * @param {Element} el - item wrapper
   * @returns {Element}
   */
  getItemInput(el) {
    return el.querySelector(`.${this.CSS.textField}`);
  }

  static convertToPlainHTML(data) {
    return `<ul><li> [${data.items[0].checked ? "x" : "&emsp;"}]&emsp;${data.items[0].text}</li></ul>`;
  }
}
