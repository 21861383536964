import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const UndoIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 48} height={height ? height : 48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 26.0103L16 21.0103L21 16.0103" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M20.25 32.0103H28.0417C29.3567 32.0103 30.6179 31.4308 31.5477 30.3993C32.4776 29.3679 33 27.9689 33 26.5103C33 25.0516 32.4776 23.6526 31.5477 22.6212C30.6179 21.5897 29.3567 21.0103 28.0417 21.0103H16"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
