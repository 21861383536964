import { Typography13, Typography24 } from "@slid/slid-ips";
import { useAppSelector } from "hooks";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import SmartLiveTextLanguageSelectSession from "./SmartLiveTextLanguageSelectSession";

const SelectLanguageView = () => {
  const { t } = useTranslation(["VideoNote"]);
  const { isSTTActive, sttData, showPermissionGuideInTextView, smartLiveTextLanguage } = useAppSelector((state) => state.sttReducer);

  if (isSTTActive || sttData.length >= 1 || showPermissionGuideInTextView) return null;

  return (
    <CustomDim smartLiveTextLanguage={smartLiveTextLanguage}>
      <SelectLanguageSessionContainer>
        <SelectLanguageSessionTextWrapper>
          <Typography24
            type={`span`}
            // @ts-ignore
            text={
              <Trans
                components={{
                  SelectLanguageSessionTitleBlueText: (
                    <Typography24 text={t("SelectLanguageSessionTitleBlueText", { ns: "VideoNote" })} color={`--blue7`} style={{ display: "inline" }} type={`span`} />
                  ),
                }}
              >
                {t("SelectLanguageSessionTitle", { ns: "VideoNote" })}
              </Trans>
            }
            color={`--white`}
          />
          <Typography13 text={t("SelectLanguageSessionDesc", { ns: "VideoNote" })} color={`--gray9`} marginTop={`12px`} />
        </SelectLanguageSessionTextWrapper>
        <SmartLiveTextLanguageSelectSession />
      </SelectLanguageSessionContainer>
    </CustomDim>
  );
};

const CustomDim = styled.div<{ smartLiveTextLanguage: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  left: 0;
  top: ${({ smartLiveTextLanguage }) => (smartLiveTextLanguage ? "128px" : "72px")};
  width: 100%;
  height: ${({ smartLiveTextLanguage }) => (smartLiveTextLanguage ? "calc(100% - 128px)" : "calc(100% - 72px)")};
  position: absolute;
  background: rgba(33, 37, 41, 0.4);
  backdrop-filter: blur(6px);
`;

const SelectLanguageSessionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
`;

const SelectLanguageSessionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
`;

export default SelectLanguageView;
