// auth 2.0
import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";

const videos_endpoint = `videos/`;

export const ServerInterface_fetchVideos = async () => {
  let userVideosData = {};
  try {
    const userVideosDataResponse = await slidAxios.get(videos_endpoint);
    userVideosData = userVideosDataResponse.data;
  } catch (error) {
    if (!userVideosData) userVideosData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      userVideosData.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          userVideosData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          userVideosData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          userVideosData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      userVideosData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      userVideosData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return userVideosData;
  }
};

/*
  This function registers a video in the DB and map it to the document
  [POST] /api/<version>/videos/
  returns {"message": register_status, "video_key": video_key}
*/
export const ServerInterface_registerVideoToDocument = async ({ data }) => {
  const payload = data;
  let videoRegisterData = null;
  try {
    const videoPostResponse = await slidAxios.post(`${videos_endpoint}`, payload);
    videoRegisterData = videoPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!videoRegisterData) videoRegisterData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          videoRegisterData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          videoRegisterData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 400:
          switch (error.response.data.message) {
            case "'document_key' not Provided":
              videoRegisterData.error_message = "VIDEO_REGISTER_DOCUMENT_KEY_NOT_PROVIDED";
              break;
            case "Invalid Data (for Videoboard)":
              videoRegisterData.error_message = "VIDEO_REGISTER_INVALID_DATA_FOR_VIDEOBOARD";
              break;
            case "Invalid Data (document doesn't exists)":
              videoRegisterData.error_message = "VIDEO_REGISTER_INVALID_DATA_DOCUMENT_DOESNT_EXISTS";
              break;
            case "Invalid Data":
              videoRegisterData.error_message = "VIDEO_REGISTER_INVALID_DATA";
              break;
            default:
              videoRegisterData.error_message = "VIDEO_REGISTER_ERROR_REGISTERING_VIDEO";
              break;
          }
          break;
        default:
          videoRegisterData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      videoRegisterData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      videoRegisterData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return videoRegisterData;
  }
};
