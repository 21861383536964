// auth 2.0
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import env from "config/env";
import { fetchUser, isSlidUser } from "redux/actions/slidGlobalActions";
import { sendActivatePreviousTab } from "utils/extensionInterface/sendToExtension";
import { intervalPolling } from "utils/request";
import { setIsWaitingForSignIn } from "redux/actions/demoActions";
import { useCognitoIdToken } from "utils/auth/cognitoAuthHooks";
import { isUserAuthenticated } from "../auth/cognitoAuthToolkit";

export const usePolling = () => {
  const { cognitoIdToken } = useCognitoIdToken();
  const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;
  const isAuthRequiredPage = document.location.href === `${SLID_WEB_APP_URL}/auth-required`;
  const { userData } = useAppSelector((state) => state.slidGlobal);
  const { isWaitingForSignIn } = useAppSelector((state) => state.demo);
  const dispatch = useAppDispatch();
  const startPolling = useCallback(() => {
    const sendCognitoIdToken = async () => {
      const isAuthenticated = await isUserAuthenticated();
      if (isAuthenticated) {
        //NOTE: being authenticated is cognito does not mean the user is  registered in slid backend. We must check.
        const isRegisteredOnBackend = await dispatch(isSlidUser());
        //@ts-ignore
        if (isRegisteredOnBackend) {
          clearPolling();
          await dispatch(fetchUser());
          sendActivatePreviousTab();
          document.location.href = `${SLID_WEB_APP_URL}/vdocs/new`;
        }
      }
    };

    const clearPolling = () => {
      dispatch(setIsWaitingForSignIn(false));
    };

    intervalPolling(sendCognitoIdToken, 1000, 120, clearPolling);
  }, [userData, cognitoIdToken, dispatch, isAuthRequiredPage]);

  return { isWaitingForSignIn, startPolling };
};
