import {
  SET_IS_STT_ACTIVE,
  ADD_STT_DATA,
  SET_CURRENT_STT_DATA,
  SET_STT_ERROR,
  SET_SHOW_SMART_LIVE_TEXT_VIEW,
  SET_SMART_LIVE_TEXT_LANGUAGE,
  SET_IS_STT_TOGGLED_ON,
  SET_IS_STT_SOCKET_CONNECTED,
  SET_IS_GETTING_STT_MEDIA_PERMISSION,
  SET_IS_STT_SESSION_STARTED,
  SET_IS_MANUAL_ADDING_MODE,
  SET_IS_STT_MORE_BUTTON_CLICKED,
  RESET_STT_DATA,
  SET_IS_STT_V2_SUPPORTED,
  SET_PROCESSED_STT_DATA,
  SET_SHOW_PERMISSION_GUIDE_IN_TEXT_VIEW,
  SET_IS_LANGUAGE_SELECTED_FROM_HEADER,
  SET_IS_SLT_STARTED_BY_USER,
  SET_STT_ACTIVE_NOTE_BLOCK,
} from "redux/actions/actionTypes";

interface State {
  sttData: any[];
  sttError: string | null;
  currentSTTData: any;
  isSTTActive: boolean;
  isGettingSTTMediaPermission: boolean;
  showPermissionGuideInTextView: boolean;
  showSmartLiveTextView: boolean;
  smartLiveTextLanguage: string | null;
  isSTTToggledOn: boolean;
  isSTTSocketConnected: boolean;
  isSTTSessionStarted: boolean;
  isManualAddingMode: boolean;
  isSTTMoreButtonClicked: boolean;
  isSTTDataReset: boolean;
  isSTTv2Supported: boolean;
  processedSTTData: any[];
  isLanguageSelectedFromHeader: boolean;
  isSTTStartedByUser: boolean;
  activeSttNoteBlock: any;
}

const initialState: State = {
  sttData: [], //[{text: "hello one", timeStamp: "02:00", isFinal: true}, {text: "text two", timeStamp: "02:00", isFinal: false}]
  sttError: null, // string data
  currentSTTData: null,
  isSTTActive: false,
  isGettingSTTMediaPermission: false,
  showPermissionGuideInTextView: false,
  showSmartLiveTextView: false,
  smartLiveTextLanguage: null,
  isSTTToggledOn: false,
  isSTTSocketConnected: false,
  isSTTSessionStarted: false, // for tracking usage of STT feature
  isManualAddingMode: false,
  isSTTMoreButtonClicked: false,
  isSTTDataReset: false,
  isSTTv2Supported: false,
  processedSTTData: [],
  isLanguageSelectedFromHeader: false,
  isSTTStartedByUser: false,
  activeSttNoteBlock: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PROCESSED_STT_DATA:
      return {
        ...state,
        processedSTTData: action.payload,
      };
    case RESET_STT_DATA:
      return {
        ...state,
        sttData: [],
        isSTTDataReset: action.payload,
        sttError: null,
      };
    case ADD_STT_DATA:
      return {
        ...state,
        sttData: [...state.sttData, ...action.payload],
        sttError: null,
      };
    case SET_CURRENT_STT_DATA:
      return {
        ...state,
        currentSTTData: action.payload,
      };
    case SET_IS_STT_ACTIVE:
      return {
        ...state,
        isSTTActive: action.payload,
      };
    case SET_STT_ERROR:
      return {
        ...state,
        sttError: action.payload, // string data
      };
    case SET_SHOW_SMART_LIVE_TEXT_VIEW:
      return {
        ...state,
        showSmartLiveTextView: action.payload,
      };
    case SET_SMART_LIVE_TEXT_LANGUAGE:
      return {
        ...state,
        smartLiveTextLanguage: action.payload,
      };
    case SET_IS_STT_TOGGLED_ON:
      return {
        ...state,
        isSTTToggledOn: action.payload,
      };

    case SET_IS_STT_SOCKET_CONNECTED:
      return {
        ...state,
        isSTTSocketConnected: action.payload,
      };
    case SET_IS_GETTING_STT_MEDIA_PERMISSION:
      return {
        ...state,
        isGettingSTTMediaPermission: action.payload,
      };
    case SET_IS_STT_SESSION_STARTED:
      return {
        ...state,
        isSTTSessionStarted: action.payload,
      };
    case SET_IS_MANUAL_ADDING_MODE:
      return {
        ...state,
        isManualAddingMode: action.payload,
      };
    case SET_IS_STT_MORE_BUTTON_CLICKED:
      return {
        ...state,
        isSTTMoreButtonClicked: action.payload,
      };
    case SET_IS_STT_V2_SUPPORTED:
      return {
        ...state,
        isSTTv2Supported: action.payload,
      };
    case SET_SHOW_PERMISSION_GUIDE_IN_TEXT_VIEW:
      return {
        ...state,
        showPermissionGuideInTextView: action.payload,
      };
    case SET_IS_LANGUAGE_SELECTED_FROM_HEADER:
      return {
        ...state,
        isLanguageSelectedFromHeader: action.payload,
      };
    case SET_IS_SLT_STARTED_BY_USER:
      return {
        ...state,
        isSTTStartedByUser: action.payload,
      };
    case SET_STT_ACTIVE_NOTE_BLOCK:
      return {
        ...state,
        activeSttNoteBlock: { ...action.payload },
      };
    default:
      return state;
  }
};
