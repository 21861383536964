import React from "react";
import ModalPortal from "./ModalPortal";
import BaseModalContainer from "./BaseModalContainer";
import styled from "styled-components";
import { Button, Typography13, Typography15, Typography20 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { useModalStore } from "store/useModalStore";

const ServiceNotificationModal = () => {
  const { t } = useTranslation("Modal");
  const { hideModal } = useModalStore();

  return (
    <ModalPortal target={"body"}>
      <BaseModalContainer callback={() => {}} closable={false}>
        <ModalContainer>
          <Typography15 text={t("ServiceNotificationModal.title", { keySeparator: "." })} color={"--gray18"} weight={700} textAlign="center" />
          <Typography13 text={t("ServiceNotificationModal.description", { keySeparator: "." })} color={"--gray18"} marginTop="8px" textAlign="center" />
          <ButtonWrapper>
            <Button
              text={t("ServiceNotificationModal.primaryButton", { keySeparator: "." })}
              callback={() => {
                hideModal();
              }}
              appearance="primary"
              size="small"
            />
          </ButtonWrapper>
        </ModalContainer>
      </BaseModalContainer>
    </ModalPortal>
  );
};

const ModalContainer = styled.div`
  z-index: 9999999;
  background-color: white;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 28px 20px 16px;
  box-shadow: var(--boxShadow1);
  width: 80%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 28px;
  gap: 8px;
  width: 100%;

  && * {
    flex: 1;
  }
`;

export default ServiceNotificationModal;
