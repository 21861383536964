// auth 2.0

import { Storage } from "aws-amplify";
import { Amplify } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import env from "config/env";

const USER_POOL_WEB_CLIENT_ID = env.common_api_key.amplify.user_pool_web_client_id[env.currentEnv];
const AWS_CAPTURE_S3 = env.end_point_url.aws_capture_s3;

export const uploadBase64Image = async ({ path, base64 }) => {
  Amplify.configure({
    Auth: {
      identityPoolId: env.common_api_key.amplify.identity_pool_id,
      region: env.common_api_key.amplify.region,
      userPoolId: env.common_api_key.amplify.user_pool_id,
      userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    },
  });
  Storage.configure({
    bucket: "slid-capture",
    level: "public",
    region: env.common_api_key.amplify.region,
    identityPoolId: env.common_api_key.amplify.identity_pool_id,
  });

  const file = await base64ToFile(base64, `${uuidv4()}.png`);
  const config = {
    ContentEncoding: "base64",
    contentType: "image/png",
    ACL: "public-read",
  };
  try {
    const uploadResult = await Storage.put(`${path}/${file.name}`, file, config);
    return AWS_CAPTURE_S3 + uploadResult.key;
  } catch (e) {
    console.log(`Cannot upload file to S3: ${e}`);
    return;
  }
};

export const uploadFileImage = async ({ path, file }) => {
  Amplify.configure({
    Auth: {
      identityPoolId: env.common_api_key.amplify.identity_pool_id,
      region: env.common_api_key.amplify.region,
      userPoolId: env.common_api_key.amplify.user_pool_id,
      userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    },
  });
  Storage.configure({
    bucket: "slid-capture",
    level: "public",
    region: env.common_api_key.amplify.region,
    identityPoolId: env.common_api_key.amplify.identity_pool_id,
  });

  const config = {
    contentType: "image/png",
    ACL: "public-read",
  };

  try {
    const uploadResult = await Storage.put(`${path}/${uuidv4()}.png`, file, config);
    return AWS_CAPTURE_S3 + uploadResult.key;
  } catch (e) {
    console.log(`Cannot upload file to S3: ${e}`);
    return;
  }
};

export const uploadFile = async ({ path, file }) => {
  Amplify.configure({
    Auth: {
      identityPoolId: env.common_api_key.amplify.identity_pool_id,
      region: env.common_api_key.amplify.region,
      userPoolId: env.common_api_key.amplify.user_pool_id,
      userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    },
  });
  Storage.configure({
    bucket: "slid-capture",
    level: "public",
    region: env.common_api_key.amplify.region,
    identityPoolId: env.common_api_key.amplify.identity_pool_id,
  });

  const config = {
    contentType: file.type,
    ACL: "public-read",
  };

  try {
    const uploadResult = await Storage.put(`${path}/${uuidv4()}.${file.name.split(".")[file.name.split(".").length - 1]}`, file, config);
    return AWS_CAPTURE_S3 + uploadResult.key;
  } catch (e) {
    console.log(`Cannot upload file to S3: ${e}`);
    return;
  }
};

const base64ToFile = (url, filename, mimeType) => {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};
