// auth 2.0
import Observer from "./observer";
import store from "redux/store";

/**
 * Undo/Redo feature for Editor.js.
 *
 * @typedef {Object} Undo
 * @description Feature's initialization class.
 * @property {Object} editor — Editor.js instance object.
 * @property {Number} maxLength - Max amount of changes recorded by the history stack.
 * @property {Function} onUpdate - Callback called when the user performs an undo or redo action.
 * @property {Boolean} shouldSaveHistory - Defines if the plugin should save the change in the stack
 * @property {Object} initialItem - Initial data object.
 */
export default class Undo {
  /**
   * @param options — Plugin custom options.
   */
  constructor({ editor, config = {}, onUpdate, maxLength }) {
    const defaultOptions = {
      maxLength: 30,
      onUpdate() {},
      config: {
        debounceTimer: 200,
        shortcuts: {
          undo: "CMD+Z",
          redo: "CMD+Y",
        },
      },
    };

    const { blocks, caret } = editor;
    const { configuration } = editor;
    const { holder } = configuration;
    const defaultShortcuts = defaultOptions.config.shortcuts;
    const { shortcuts = defaultShortcuts } = config;
    const defaultDebounceTimer = defaultOptions.config.debounceTimer;
    const { debounceTimer = defaultDebounceTimer } = config;

    this.holder = typeof holder === "string" ? document.getElementById(holder) : holder;
    this.editor = editor;
    this.blocks = blocks;
    this.caret = caret;
    this.shouldSaveHistory = true;
    this.readOnly = configuration.readOnly;
    this.maxLength = maxLength || defaultOptions.maxLength;
    this.onUpdate = onUpdate || defaultOptions.onUpdate;
    this.config = { debounceTimer, shortcuts };
    const observer = new Observer(() => this.registerChange(), this.holder, this.config.debounceTimer);
    observer.setMutationObserver();
    this.setEventListeners();
    this.initialItem = null;
    this.lastScrollPosition = 0;
    this.lastActivatedBlockIndex = 0;
    this.clear();
  }

  /**
   * Notify core that read-only mode is suppoorted
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Truncates the history stack when it excedes the limit of changes.
   *
   * @param {Object} stack  Changes history stack.
   * @param {Number} stack  Limit of changes recorded by the history stack.
   */
  truncate(stack, limit) {
    while (stack.length > limit) {
      stack.shift();
    }
  }

  /**
   * Initializes the stack when the user provides initial data.
   *
   * @param {Object} initialItem  Initial data provided by the user.
   */
  initialize(initialItem) {
    const initialData = "blocks" in initialItem ? initialItem.blocks : initialItem;
    const initialIndex = initialData.length - 1;
    const firstElement = { index: initialIndex, state: initialData };
    this.stack[0] = firstElement;
    this.initialItem = firstElement;
  }

  /**
   * Clears the history stack.
   */
  clear() {
    this.stack = this.initialItem ? [this.initialItem] : [{ index: 0, state: [{ type: "paragraph", data: { text: "" } }] }];
    this.position = 0;
    this.onUpdate();
    this.lastActivatedBlockIndex = 0;
    this.lastScrollPosition = 0;
  }

  /**
   * returns true if readOnly was toggled to true
   * @returns {Node} Indirectly shows if readOnly was set to true or false
   */
  setReadOnly() {
    const toolbox = document.querySelector(".ce-toolbox");
    this.readOnly = !toolbox || store.getState().vdocs.isReadOnly;
  }

  /**
   * Registers the data returned by API's save method into the history stack.
   */
  registerChange() {
    this.setReadOnly();
    if (!this.readOnly) {
      if (this.editor && this.editor.save && this.shouldSaveHistory) {
        this.editor.save().then((savedData) => {
          if (this.editorDidUpdate(savedData?.blocks)) this.save(savedData.blocks);
        });
      }
      this.shouldSaveHistory = true;
    }
  }

  /**
   * Checks if the saved data has to be added to the history stack.
   *
   * @param {Object} newData  New data to be saved in the history stack.
   * @returns {Boolean}
   */
  editorDidUpdate(newData) {
    const { state } = this.stack[this.position];
    if (!newData || !newData.length) return false;
    if (newData.length !== state.length) return true;

    return JSON.stringify(state) !== JSON.stringify(newData);
  }

  memorizeLastBlockIndexAndScrollPosition() {
    const index = this.editor.blocks.getCurrentBlockIndex();
    this.lastActivatedBlockIndex = index;
    this.lastScrollPosition = this.editor.blocks.getBlockByIndex(index)?.holder.offsetTop;
  }

  /**
   * Adds the saved data in the history stack and updates current position.
   */
  save(state) {
    if (this.position >= this.maxLength) {
      this.truncate(this.stack, this.maxLength);
    }

    this.position = Math.min(this.position, this.stack.length - 1);

    this.stack = this.stack.slice(0, this.position + 1);

    const index = this.editor.blocks.getCurrentBlockIndex();

    // save the last activated block index and its scroll position when the position is 1 or 0 to prevent scrolling to the top when undoing the first change
    if (this.position === 1 || (this.position === 0 && this.stack.length === 1)) this.memorizeLastBlockIndexAndScrollPosition();

    /**
     * Do not save state if
     * 1) there is no block type, or
     * 2) there is no src of image
     * to prevent rendering of empty image when undoing
     */
    if (
      state[index - 1]?.type === "videoLoader" ||
      state[index - 1]?.type === "loader" ||
      state[index - 1]?.type === undefined ||
      (state[index - 1]?.type === "image" && state[index - 1]?.data.src === undefined)
    ) {
      return;
    }

    /**
     * Do not save state of video loader and loader to prevent rendering of empty video and loader when undoing
     */
    if (state[index]?.type === "videoLoader" || state[index]?.type === "loader") {
      return;
    }
    this.stack.push({ index, state });
    this.position += 1;
    this.onUpdate();
  }

  adjustScroll(currentBlockIndex) {
    const currentBlock = this.editor.blocks.getBlockByIndex(currentBlockIndex);

    const editorInVdocs = document.querySelector("#editor-container");
    const editorInDocs = document.querySelector("#editor-component");
    const editorWrapper = editorInVdocs ? editorInVdocs : editorInDocs;

    const currentBlockClientYPosition = currentBlock.holder.offsetTop;
    const EDITOR_PADDING_BOTTOM = 300;

    if (this.position === 0) {
      // To prevent scrolling to the top when undoing the first change
      editorWrapper && (editorWrapper.scrollTop = this.lastScrollPosition - EDITOR_PADDING_BOTTOM);
      return;
    } else {
      editorWrapper && (editorWrapper.scrollTop = currentBlockClientYPosition - EDITOR_PADDING_BOTTOM);
    }
  }

  /**
   * Decreases the current position and renders the data in the editor.
   */
  undo() {
    if (this.canUndo()) {
      this.shouldSaveHistory = false;
      const { index, state } = this.stack[(this.position -= 1)];
      this.onUpdate();

      this.editor.blocks.render({ blocks: state }).then(() => {
        // To prevent the caret moves to the last block when undoing the first change
        if (this.position !== 0) this.editor.caret.setToBlock(index, "end");

        this.adjustScroll(index);
      });
    }
  }

  /**
   * Increases the current position and renders the data in the editor.
   */
  redo() {
    if (this.canRedo()) {
      this.shouldSaveHistory = false;
      const { state, index } = this.stack[(this.position += 1)];
      let selection = document.getSelection();

      this.onUpdate();

      this.editor.blocks.render({ blocks: state }).then(() => {
        this.adjustScroll(index);
        this.editor.caret.setToBlock(index, "end");
      });
    }
  }

  /**
   * Check if image popup is visible on the editor before undo/redo
   * @returns {Boolean} true if image popup is visible
   */
  isImagePopupVisible() {
    const imagePopupLayer = document.getElementById("image-markup");
    if (imagePopupLayer) return true;
    return false;
  }

  /**
   * Checks if the history stack can perform an undo action.
   *
   * @returns {Boolean}
   */
  canUndo() {
    return !this.isImagePopupVisible() && !this.readOnly && this.position > 0;
  }

  /**
   * Checks if the history stack can perform a redo action.
   *
   * @returns {Boolean}
   */
  canRedo() {
    return !this.isImagePopupVisible() && !this.readOnly && this.position < this.count();
  }

  /**
   * Returns the number of changes recorded in the history stack.
   *
   * @returns {Number}
   */
  count() {
    return this.stack.length - 1; // -1 because of initial item
  }

  /**
   * Parses the keys passed in the shortcut property to accept CMD,ALT and SHIFT
   *
   * @param {Array} keys are the keys passed in shortcuts in config
   * @returns {Array}
   */

  parseKeys(keys) {
    const specialKeys = {
      CMD: /(Mac)/i.test(navigator.platform) ? "metaKey" : "ctrlKey",
      ALT: "altKey",
      SHIFT: "shiftKey",
    };
    const parsedKeys = keys.slice(0, -1).map((key) => specialKeys[key]);
    const letterKey = parsedKeys.includes("shiftKey") && keys.length === 2 ? keys[keys.length - 1].toUpperCase() : keys[keys.length - 1].toLowerCase();

    parsedKeys.push(letterKey);
    return parsedKeys;
  }

  /**
   * Sets events listeners to allow keyboard actions support
   */

  setEventListeners() {
    const { holder } = this;
    const { shortcuts } = this.config;
    const { undo, redo } = shortcuts;
    const keysUndo = undo.replace(/ /g, "").split("+");
    const keysRedo = redo.replace(/ /g, "").split("+");

    const keysUndoParsed = this.parseKeys(keysUndo);
    const keysRedoParsed = this.parseKeys(keysRedo);

    const getRawKey = (code) => code.replace("Key", "").toLowerCase();

    const twoKeysPressed = (e, keys) => keys.length === 2 && e[keys[0]] && (e.key === keys[1] || getRawKey(e.code) === keys[1]);
    const threeKeysPressed = (e, keys) => keys.length === 3 && e[keys[0]] && e[keys[1]] && (e.key === keys[2] || getRawKey(e.code) === keys[2]);

    const pressedKeys = (e, keys, compKeys) => {
      if (twoKeysPressed(e, keys) && !threeKeysPressed(e, compKeys)) {
        return true;
      }
      if (threeKeysPressed(e, keys)) {
        return true;
      }
      return false;
    };

    const handleUndo = (e) => {
      if (pressedKeys(e, keysUndoParsed, keysRedoParsed)) {
        e.preventDefault();
        this.undo();
      }
    };

    const handleRedo = (e) => {
      if (pressedKeys(e, keysRedoParsed, keysUndoParsed)) {
        e.preventDefault();
        this.redo();
      }
    };

    const handleDestroy = () => {
      document.removeEventListener("keydown", handleUndo);
      document.removeEventListener("keydown", handleRedo);
    };

    document.addEventListener("keydown", handleUndo);
    document.addEventListener("keydown", handleRedo);
    document.addEventListener("destroy", handleDestroy);
  }
}
