import { useState, useEffect } from "react";
import { Typography13, Typography24 } from "@slid/slid-ips";
import { useAppDispatch, useAppSelector } from "hooks";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import { SLT_SUPPORTED_LANGUAGES, ISLTSupportedLanguage } from "../supportedLangs";
import { setAutoNotesVideoLang, setAutoNotesResultLang } from "redux/modules/autoNotesSlice";
import LanguageSelectMenu from "./LanguageSelectMenu";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

const SelectLanguageView = () => {
  const [isVideoLangListOpen, setIsVideoLangListOpen] = useState<boolean>(false);
  const [selectedSLTLang, setSelectedSLTLang] = useState<ISLTSupportedLanguage>(SLT_SUPPORTED_LANGUAGES[0]);
  const [isResultLangListOpen, setIsResultLangListOpen] = useState<boolean>(false);
  const [selectedResultLang, setSelectedResultLang] = useState<ISLTSupportedLanguage>(SLT_SUPPORTED_LANGUAGES[0]);
  const { t } = useTranslation("AutoNotes");
  const { smartLiveTextLanguage } = useAppSelector((state) => state.sttReducer);

  const dispatch = useAppDispatch();
  const { lang: userLang } = useAppSelector((state) => state.slidGlobal);
  const { isWhisperAutoNotesSupported } = useAppSelector((state) => state.autoNotes);

  useEffect(() => {
    const cachedSTTLang = window.localStorage.getItem("slid-user-stt-default-lang"); //returns like "English"

    if (cachedSTTLang) {
      dispatch(setAutoNotesVideoLang(cachedSTTLang));
      setSelectedSLTLang(SLT_SUPPORTED_LANGUAGES.find((lang) => lang.text === cachedSTTLang)!);
    } else if (userLang) {
      const fullLanguage = userLang === "ko" ? "한국어" : "English";
      dispatch(setAutoNotesVideoLang(fullLanguage));
      setSelectedSLTLang(SLT_SUPPORTED_LANGUAGES.find((lang) => lang.text === fullLanguage)!);
    }
  }, [smartLiveTextLanguage, dispatch, userLang]);

  useEffect(() => {
    const cachedResultLang = window.localStorage.getItem("auto-notes-result-lang");
    if (cachedResultLang) {
      dispatch(setAutoNotesResultLang(cachedResultLang));
      setSelectedResultLang(SLT_SUPPORTED_LANGUAGES.find((lang) => lang.text === cachedResultLang)!);
    } else {
      const fullLanguage = userLang === "ko" ? "한국어" : "English";
      dispatch(setAutoNotesResultLang(fullLanguage));
      setSelectedResultLang(SLT_SUPPORTED_LANGUAGES.find((lang) => lang.text === fullLanguage)!);
    }
  }, [dispatch, userLang]);

  const handleChangeSLTLanguage = (language: ISLTSupportedLanguage) => {
    trackEvent({
      eventType: eventTypes.select.SELECT_AUTO_NOTES_LANGUAGE_IN_VIDEO_NOTE_PAGE,
      eventProperties: {
        type: "video language",
      },
    });
    window.localStorage.setItem("slid-user-stt-default-lang", language.text);
    setSelectedSLTLang(language);
    dispatch(setAutoNotesVideoLang(language.text));
  };

  const handleChangeResultLanguage = (language: ISLTSupportedLanguage) => {
    trackEvent({
      eventType: eventTypes.select.SELECT_AUTO_NOTES_LANGUAGE_IN_VIDEO_NOTE_PAGE,
      eventProperties: {
        type: "result language",
      },
    });

    window.localStorage.setItem("auto-notes-result-lang", language.text);
    setSelectedResultLang(language);
    dispatch(setAutoNotesResultLang(language.text));

    setIsResultLangListOpen(false);
  };

  const handleToggleLangList = (list: "video" | "result") => {
    if (list === "video") {
      if (isVideoLangListOpen) {
        setIsVideoLangListOpen(false);
      } else {
        setIsResultLangListOpen(false);
        setIsVideoLangListOpen(true);
        trackEvent({
          eventType: eventTypes.click.SELECT_AUTO_NOTES_LANGUAGE_IN_VIDEO_NOTE_PAGE,
          eventProperties: {
            type: "video language",
          },
        });
      }
    } else {
      if (isResultLangListOpen) {
        setIsResultLangListOpen(false);
      } else {
        setIsVideoLangListOpen(false);
        setIsResultLangListOpen(true);
        trackEvent({
          eventType: eventTypes.click.SELECT_AUTO_NOTES_LANGUAGE_IN_VIDEO_NOTE_PAGE,
          eventProperties: {
            type: "result language",
          },
        });
      }
    }
  };

  return (
    <CustomDim smartLiveTextLanguage={smartLiveTextLanguage}>
      <SelectLanguageSessionContainer>
        <SelectLanguageSessionTextWrapper>
          <Typography24
            type={`span`}
            // @ts-ignore
            text={
              <Trans
                components={{
                  SelectLanguageSessionTitleBlueText: (
                    <Typography24 text={t(isWhisperAutoNotesSupported ? "AutoNoteResult" : "AutoNoteVideo&Result")} color={`--white`} style={{ display: "inline" }} type={`span`} weight={700} />
                  ),
                }}
              >
                {t("SelectLanguageSessionTitle", { ns: "AutoNotes" })}
              </Trans>
            }
            color={`--white`}
          />
          <Typography13
            text={t(isWhisperAutoNotesSupported ? "WhisperAutoNotesChangeItLater" : "AutoNotesChangeItLater", { ns: "AutoNotes" })}
            color={`--gray9`}
            marginTop={`12px`}
            style={{
              textAlign: "left",
              width: "100%",
            }}
            weight={400}
          />
        </SelectLanguageSessionTextWrapper>
      </SelectLanguageSessionContainer>
      <LanguageSelectOuterContainer>
        {!isWhisperAutoNotesSupported && (
          <LanguageSelectMenu
            selectedLanguage={selectedSLTLang}
            onSelectLanguage={handleChangeSLTLanguage}
            supportedLanguages={SLT_SUPPORTED_LANGUAGES}
            isLangListOpen={isVideoLangListOpen}
            onToggleLangList={() => handleToggleLangList("video")}
            listTitle={t("AutoNotesVideoLang", { ns: "AutoNotes" })}
          />
        )}
        <LanguageSelectMenu
          selectedLanguage={selectedResultLang}
          onSelectLanguage={handleChangeResultLanguage}
          supportedLanguages={SLT_SUPPORTED_LANGUAGES}
          isLangListOpen={isResultLangListOpen}
          onToggleLangList={() => handleToggleLangList("result")}
          listTitle={t("AutoNotesResultLang", { ns: "AutoNotes" })}
        />
      </LanguageSelectOuterContainer>
    </CustomDim>
  );
};

const CustomDim = styled.div<{ smartLiveTextLanguage: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 45px;
  padding-left: 36px;
  padding-right: 36px;
  width: 100%;
  background: rgba(33, 37, 41, 0.4);
  backdrop-filter: blur(6px);
  background: var(--SGray-Colors-SGray19, #080a0c);
  backdrop-filter: blur(6px);
  flex: 1;
`;

const SelectLanguageSessionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 28px;
`;

const SelectLanguageSessionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LanguageSelectOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 18px;
  flex: 1;
`;

export default SelectLanguageView;
