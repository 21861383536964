import React from "react";
import { Icon, Typography18, Typography20 } from "@slid/slid-ips";
import { useAppDispatch } from "hooks";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import AutoNotesStartMainButton from "./AutoNotesSideBarButton";
import { setIsAutoNotesSettingsOpen } from "redux/modules/autoNotesSlice";

const AutoNotesSettingsViewHeader = () => {
  const { t } = useTranslation("AutoNotes");
  const dispatch = useAppDispatch();

  const handleCloseAutoNotesSettings = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setIsAutoNotesSettingsOpen(false));
  };

  return (
    <TranscriptHeader>
      <HeaderTitleContainer>
        <HeaderTitle>
          <Typography18 text={t("AutoNotes")} color={"--gray2"} weight={700} />
        </HeaderTitle>
        <CloseButton onClick={handleCloseAutoNotesSettings}>
          <Icon icon={`x24`} color={`--gray2`} />
        </CloseButton>
      </HeaderTitleContainer>
      <StartAutoNotesButtonHolder>
        <AutoNotesStartMainButton />
      </StartAutoNotesButtonHolder>
    </TranscriptHeader>
  );
};

const TranscriptHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 12px 16px 19px;
  background-color: #191c20;
  min-width: 100%;
  position: relative;
  gap: 16px;
`;
const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const HeaderTitle = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 4px;
  gap: 6px;
  p {
    user-select: none;
  }
`;

const StartAutoNotesButtonHolder = styled.div`
  width: 100%;
`;

const CloseButton = styled.div`
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background-color: var(--gray15);
  }

  :active {
    background-color: var(--gray17);
  }
`;

export default AutoNotesSettingsViewHeader;
