// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const VideoSettingIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }} fill="none">
      <path
        d="M19.3358 10.1816C19.5209 10.4599 19.683 10.7543 19.8202 11.0616L21.8115 12.224C22.0602 13.3933 22.0628 14.6055 21.8192 15.776L19.8202 16.9384C19.683 17.2457 19.5209 17.5401 19.3358 17.8184L19.3743 20.2159C18.5304 21.0234 17.5315 21.6317 16.445 22L14.4844 20.7649C14.1619 20.7891 13.8381 20.7891 13.5156 20.7649L11.5627 21.9919C10.4727 21.6304 9.4706 21.0244 8.62572 20.2159L8.66416 17.8264C8.48061 17.5443 8.31864 17.2474 8.17978 16.9384L6.18845 15.776C5.9398 14.6067 5.93718 13.3945 6.18076 12.224L8.17978 11.0616C8.31701 10.7543 8.47906 10.4599 8.66416 10.1816L8.62572 7.78406C9.46965 6.9766 10.4685 6.36828 11.555 6L13.5156 7.23512C13.8381 7.21089 14.1619 7.21089 14.4844 7.23512L16.4373 6.00807C17.5273 6.36964 18.5294 6.97562 19.3743 7.78406L19.3358 10.1816Z"
        stroke="#F1F3F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 14C17 15.6569 15.6569 17 14 17C12.3431 17 11 15.6569 11 14C11 12.3431 12.3431 11 14 11C15.6569 11 17 12.3431 17 14Z"
        stroke="#F1F3F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
