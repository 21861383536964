import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { appendMessageToDbChatData, makeNewChatInDb } from "utils/firebase/firebase";
import { Message } from "ai/react";
import { AppDispatch, RootState } from "redux/store";
import * as Sentry from "@sentry/browser";
import env from "config/env";

const name = "aiSliddy";

interface State {
  sliddyMessages: Message[];
  currentFirebaseChatId: string | null;
  currentNoteEmbeddings: null;
  showSliddyChat: boolean;
  alignAISessionId: string | null;
}

const initialState: State = {
  sliddyMessages: [],
  currentFirebaseChatId: null,
  currentNoteEmbeddings: null,
  showSliddyChat: false,
  alignAISessionId: null,
};

export const saveMessageToFirebase = createAsyncThunk("messages/saveToFirebase", async (message: Partial<Message>, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const dispatch = thunkAPI.dispatch as AppDispatch;

  const { currentFirebaseChatId } = state.aiSliddy;
  const { userData, lang } = state.slidGlobal;

  if (!currentFirebaseChatId) {
    if (env.currentEnv === "development") console.log("Creating new chat in firebase");
    const chatId = await makeNewChatInDb({
      userKey: userData.user_key,
      userLanguage: lang,
      email: userData.email,
      country: userData.country,
      message: message,
    });
    dispatch(aiSliddySlice.actions.setCurrentFirebaseChatId(chatId));
    return chatId;
  } else {
    if (env.currentEnv === "development") console.log("Saved to old chat in firebase - ", currentFirebaseChatId);
    const response = await appendMessageToDbChatData({ chatDataId: currentFirebaseChatId, message: message });
    return response;
  }
});

export const aiSliddySlice = createSlice({
  name,
  initialState,
  reducers: {
    setAppendToSliddyMessages: (state, action: PayloadAction<Partial<Message>>) => {
      // @ts-ignore
      state.sliddyMessages.push(action.payload);
    },
    clearAiSliddyMessages: (state) => {
      state.sliddyMessages = [];
    },
    setCurrentFirebaseChatId: (state, action: PayloadAction<string | null>) => {
      state.currentFirebaseChatId = action.payload;
    },
    setShowSliddyChat: (state, action: PayloadAction<boolean>) => {
      state.showSliddyChat = action.payload;
    },
    setAlignAISessionId: (state, action: PayloadAction<string | null>) => {
      state.alignAISessionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveMessageToFirebase.fulfilled, (state, action) => {
      if (env.currentEnv === "development") console.log("AI Sliddy message successfully saved to firebase");
    });
    builder.addCase(saveMessageToFirebase.rejected, (state, action) => {
      if (env.currentEnv === "development") console.log("Failed to save AI sliddy message to firebase");
      else {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          Sentry.captureMessage("Failed to save AI sliddy message to firebase");
          scope.setExtra("error", action);
        });
      }
    });
  },
});

const { actions, reducer } = aiSliddySlice;
export const { setAppendToSliddyMessages, clearAiSliddyMessages, setCurrentFirebaseChatId, setShowSliddyChat, setAlignAISessionId } = actions;
export default reducer;
