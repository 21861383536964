// auth 2.0
import amplitude from "amplitude-js";
import env from "config/env";
import { getApplicationType, isSlidPocketMobileApp } from "utils/utils";
import { isAndroid } from "react-device-detect";

import packageJson from "../../package.json";

export const initAmplitude = (deviceId = null) => {
  const isSlidPocket = isSlidPocketMobileApp();
  const platform = isAndroid ? "Android" : "iOS";
  amplitude.getInstance().init(env.api_key.amplitude, null, {
    deviceId,
    platform: isSlidPocket ? platform : "Web",
  });
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const setAmplitudeUserPropertyOnce = (key, value) => {
  const identify = new amplitude.Identify().setOnce(key, value);
  amplitude.getInstance().identify(identify);
};

export const sendAmplitudeData = (eventType, eventProperties, callback) => {
  const applicationType = getApplicationType();

  amplitude.getInstance().logEvent(eventType, { ...eventProperties, version: packageJson.version, platform: applicationType, domain: "slid web app" }, callback);

  if (window.hj) window.hj("event", eventType);
};
