//auth 2.0
import React from "react";
import styled from "styled-components";
import { Typography13, Typography11, Icon } from "@slid/slid-ips";
import { SliddyIcon } from "../icons/SliddyIcon";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { setIsHelpMenuOpen } from "redux/actions/vdocsActions";
import { setShowSliddyChat } from "redux/modules/aiSliddySlice";
import { isMacOs } from "react-device-detect";
import { getEventLocationMode, sendMessageToPrimary } from "../../utils/utils";
import Sweetalert from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import env from "config/env";

const DESKTOP_GUIDE_EN = "https://bit.ly/desktop_guide_en";
const DESKTOP_GUIDE_KO = "https://bit.ly/desktop_guide";
const CHANNELTALK_URL = "https://slid.channel.io/lounge";

interface HelpMenuProps {
  isSliddyAvailable: boolean;
}

const HelpMenu = ({ isSliddyAvailable }: HelpMenuProps) => {
  const dispatch = useAppDispatch();
  const { lang, applicationType } = useAppSelector((state) => state.slidGlobal);
  const { currentDocument, currentVideo, videoIndex, iframePort } = useAppSelector((state) => state.vdocs);
  const { t } = useTranslation(["VideoNote", "SweetAlertModals", "ViewNote"]);
  const ReactSweetalert = withReactContent(Sweetalert);

  const handleClickAISliddy = () => {
    dispatch(setShowSliddyChat(true));
    trackEvent({
      eventType: eventTypes.click.AI_SLIDDY,
      eventProperties: {
        from: getEventLocationMode(),
      },
    });
    dispatch(setIsHelpMenuOpen(false));
  };

  const handleClickHelpCenter = () => {
    trackEvent({
      eventType: "Click 1:1 HELP CENTER in video note page",
    });
    dispatch(setIsHelpMenuOpen(false));
    window.open(CHANNELTALK_URL);
  };

  const handleClickHowToSlid = () => {
    dispatch(setIsHelpMenuOpen(false));
    if (applicationType === "desktop" && iframePort) {
      trackEvent({
        eventType: "Click how-to-Slid",
      });

      sendMessageToPrimary({
        message: {
          type: "IFRAME_TO_PRIMARY_SHOW_HOW_TO_SLID_DESKTOP",
          payload: null,
        },
        port: iframePort,
      });
      window.open(lang === "ko" ? DESKTOP_GUIDE_KO : DESKTOP_GUIDE_EN);
    } else {
      if (lang === "ko") {
        trackEvent({
          eventType: "click FAQ in video note",
        });
      } else {
        // When EN FAQ is updated, remove it.
        trackEvent({
          eventType: "Click how-to-Slid",
        });
      }

      window.open(lang === "ko" ? env.links.slid_faq_ko : env.links.slid_faq_en);
    }
  };

  const alertVideoNoteShortcut = () => {
    ReactSweetalert.fire({
      target: "#editor-wrapper",
      heightAuto: false,
      width: "85%",
      customClass: {
        container: "position-absolute",
      },
      title: t("Shortcuts", { ns: "VideoNote" }),
      html: (
        <div>
          <div>
            <p className={`text-left font-weight-bold text-primary`}>{t("EditorShortcutTitle", { ns: "SweetAlertModals" })}</p>
            <div className="bg-light container-fluid p-3 text-left">
              <div className={`row`}>
                <>
                  <div className={`col-5`}>{t("OneClickCapture", { ns: "SweetAlertModals" })}</div>
                  <div className={`col-7`}>
                    <span className={`shortcut-badge badge badge-secondary px-2 `}>{isMacOs ? "Cmd" : "Alt"}</span>
                    {` + `}
                    <span className={`shortcut-badge badge badge-secondary px-2`}>{`/`}</span>
                  </div>
                </>
              </div>
            </div>
          </div>
          {/* show video shortcut */}
          {/* when extension, use currentVideo state from redux */}
          {/* when web, use mapped_videos */}
          {(applicationType === "extension" && currentVideo["videoType"] !== "iframe") ||
          (applicationType === "web" &&
            currentDocument &&
            currentDocument["mapped_videos"] &&
            currentDocument["mapped_videos"][videoIndex] &&
            currentDocument["mapped_videos"][videoIndex]["video_type"] !== "iframe") ? (
            <div className={`mt-3`}>
              <p className={`text-left font-weight-bold text-primary`}>{t("VideoShortcut", { ns: "SweetAlertModals" })}</p>
              <div className="bg-light container-fluid p-3 text-left">
                <div className={`row`}>
                  <div className={`col-5`}>{t("PlayPause", { ns: "SweetAlertModals" })}</div>
                  <div className={`col-7`}>
                    <span className={`shortcut-badge badge badge-secondary px-2 `}>{isMacOs ? "Cmd" : "Alt"}</span> + <span className={`shortcut-badge badge badge-secondary px-2`}>{"K"}</span>
                  </div>
                </div>
                <hr />
                <div className={`row`}>
                  <div className={`col-5`}>{t("Skip", { ns: "SweetAlertModals" })}</div>
                  <div className={`col-7`}>
                    <span className={`shortcut-badge badge badge-secondary px-2`}>{isMacOs ? "Cmd" : "Alt"}</span> + <span className={`shortcut-badge badge badge-secondary px-2`}>{"J"}</span> /{" "}
                    <span className={`shortcut-badge badge badge-secondary px-2`}>{"L"}</span>
                  </div>
                </div>
                <hr />
                <div className={`row`}>
                  <div className={`col-5`}>{t("Speed", { ns: "SweetAlertModals" })}</div>
                  <div className={`col-7`}>
                    <span className={`shortcut-badge badge badge-secondary px-2`}>{isMacOs ? "Cmd" : "Alt"}</span> + <span className={`shortcut-badge badge badge-secondary px-2`}>{";"}</span> /{" "}
                    <span className={`shortcut-badge badge badge-secondary px-2`}>{"'"}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ),
      showConfirmButton: false,
    });
  };

  const handleClickKeyboardShortcut = () => {
    trackEvent({
      eventType: "Click KEYBOARD SHORTCUTS in video note page",
    });
    dispatch(setIsHelpMenuOpen(false));
    alertVideoNoteShortcut();
  };

  return (
    <Background
      onMouseLeave={() => {
        dispatch(setIsHelpMenuOpen(false));
      }}
    >
      <HelpMenuWrapper>
        {isSliddyAvailable && (
          <HelpMenuItem onClick={handleClickAISliddy} isPurple={true}>
            <SliddyIcon width={20} height={20} color={`transparent`} />
            <MenuTextWrapper>
              <Typography13 text={t("AISliddy", { ns: "VideoNote" })} color={`--blue10`} />
              <Typography11 text={t("AISliddyDescription", { ns: "VideoNote" })} color={`--blue2_cloudy`} />
            </MenuTextWrapper>
          </HelpMenuItem>
        )}
        <HelpMenuItem onClick={handleClickHelpCenter}>
          <Icon icon={`chat20`} color={`--gray9`} />
          <Typography13 text={t("HelpCenter", { ns: "VideoNote" })} color={`--gray15`} />
        </HelpMenuItem>
        <HelpMenuItem onClick={handleClickHowToSlid}>
          <Icon icon={`tutorial20`} color={`--gray9`} />
          <Typography13 text={t("HowToSlid", { ns: "VideoNote" })} color={`--gray15`} />
        </HelpMenuItem>
        <HelpMenuItem onClick={handleClickKeyboardShortcut}>
          <Icon icon={`shortcut20`} color={`--gray9`} />
          <Typography13 text={t("Shortcuts", { ns: "VideoNote" })} color={`--gray15`} />
        </HelpMenuItem>
      </HelpMenuWrapper>
    </Background>
  );
};

const Background = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 32px 32px 32px 0;
  z-index: 999;
`;

const HelpMenuWrapper = styled.ul`
  position: relative;
  bottom: 33px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 8px 0;
  margin-bottom: 0;
  min-width: 220px;
  box-shadow: var(--boxShadow2);
  background-color: #fff;
  border-radius: 8px;
  list-style: none;
  z-index: 999;
`;

const HelpMenuItem = styled.li<{ isPurple?: boolean }>`
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  cursor: pointer;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: ${({ isPurple }) => (isPurple ? "var(--purple1)" : "var(--gray3)")};
  }

  &:active {
    background-color: ${({ isPurple }) => (isPurple ? "#F3F0FD" : "var(--gray2)")};
  }
`;

const MenuTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default HelpMenu;
