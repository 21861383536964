// auth 2.0
import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";
const deletedDocuments_endpoint = `deleted-documents/`;

/*
 This function fetches all the deleted documents for the user.
 GET /deleted-documents/ endpoint is used 
 returns deleted documents data with the followinf fields per document:
        "document_key",
        "parent_key",
        "is_folder",
        "title",
        "content",
        "pure_text",
        "created_time",
        "updated_time",
        "is_public",
        "more_info",
 */
export const ServerInterface_getDeletedDocuments = async () => {
  let deletedDocumentsData = null;
  try {
    const deletedDocumentsGetResponse = await slidAxios.get(deletedDocuments_endpoint);
    deletedDocumentsData = deletedDocumentsGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!deletedDocumentsData) deletedDocumentsData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          deletedDocumentsData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          deletedDocumentsData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          deletedDocumentsData.error_message = "NO_DELETED_DOCUMENTS_OR_FOLDERS_FOUND";
          break;
        default:
          deletedDocumentsData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      deletedDocumentsData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      deletedDocumentsData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return deletedDocumentsData;
  }
};
