// auth 2.0
import React, { useState, useEffect, useRef, memo } from "react";
import styles from "./DocumentSearch.module.scss";
import { useHistory } from "react-router-dom";
import Sweetalert from "sweetalert2";
import { useAppDispatch, useAppSelector } from "hooks";
import { searchDocuments, setShowSearchPopup } from "redux/actions/vdocsActions";
import { Typography15, Icon } from "@slid/slid-ips";
import { isTouchDevice } from "utils/utils";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";

const DocumentSearch = memo(() => {
  const dispatch = useAppDispatch();
  const applicationType = useAppSelector((state) => state.slidGlobal.applicationType);
  const documents = useAppSelector((state) => state.vdocs.documents);
  const editorWrapperClassName = useAppSelector((state) => state.vdocs.editorWrapperClassName);
  const lang = useAppSelector((state) => state.slidGlobal.lang);
  const history = useHistory();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchedDocuments, setSearchedDocuments] = useState(null);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();

  const searchKeywordUpdateTimeoutIdRef = useRef(null);

  // serach documents after set timer to prevent multi requests
  useEffect(() => {
    if (!searchKeyword || searchKeyword === "") {
      setSearchedDocuments(null);
      return;
    }
    searchKeywordUpdateTimeoutIdRef.current = setTimeout(() => {
      fetchSearchDocuments(searchKeyword);
    }, 500);
    return () => {
      if (searchKeywordUpdateTimeoutIdRef.current) clearTimeout(searchKeywordUpdateTimeoutIdRef.current);
    };
  }, [searchKeyword]);

  const onDocumentClick = () => {
    dispatch(setShowSearchPopup(false));
    Sweetalert.fire({
      target: `.${editorWrapperClassName}`,
      heightAuto: false,
      customClass: {
        container: "position-absolute",
      },
      title: "Loading...",
      didOpen: () => {
        Sweetalert.showLoading();
      },
      timer: 500,
    });
    // when in extension, assume that user is trying to add more notes to existing note.
    // so scroll to bottom
    if (applicationType === "extension") {
      setTimeout(() => {
        const editorContainer = document.getElementById("editor-container");
        editorContainer.scrollTop = editorContainer.scrollHeight;
      }, 500);
    }
  };

  const documentItemTag = (documentItem) => {
    return (
      <li
        className={styles[`document-list-item`]}
        key={documentItem["document_key"]}
        onClick={() => {
          history.push(`./${documentItem["document_key"]}`);
          onDocumentClick();
        }}
      >
        <div className={styles[`item-icon`]}>
          <Icon icon={`recentNote20`} color={`--gray9`} width="24" height="24" />
        </div>
        <Typography15
          weight={400}
          text={documentItem.title ? documentItem.title : lang === "ko" ? "제목없는 노트" : "Untitled"}
          color="--gray15"
          style={{ width: "80%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
        />
      </li>
    );
  };
  const getRecentDocumentsTags = (documents) => {
    const allDocuments = getChildDocuments(documents);
    function getChildDocuments(parentDocument) {
      let childDocuments = [];
      parentDocument.forEach((document) => {
        if (document["is_folder"]) {
          const tempDocument = { ...document };
          tempDocument["title"] = parentDocument["title"] ? `${parentDocument["title"]} / ${document["title"]}` : document["title"];
          childDocuments = [...childDocuments, ...getChildDocuments(tempDocument.documents)];
        } else {
          const tempDocument = { ...document };
          tempDocument["title"] = parentDocument["title"] ? `${parentDocument["title"]} / ${document["title"]}` : document["title"];
          childDocuments.push(tempDocument);
        }
      });
      return childDocuments;
    }
    let recentDocuments = [];
    if (allDocuments.length > 0) {
      recentDocuments = [...allDocuments];
      // sort by udpated_time
      recentDocuments.sort((a, b) => {
        return new Date(b["updated_time"]) - new Date(a["updated_time"]);
      });
      recentDocuments = recentDocuments.slice(0, 10).map((document) => {
        return documentItemTag(document);
      });
    } else {
      recentDocuments.push(
        <li className={`${styles[`document-list-item`]} ${styles[`no-hover`]}`} key={`empty-item`}>
          {lang === "ko" ? "최근 노트가 없습니다." : "No note found"}
        </li>
      );
    }
    return recentDocuments;
  };

  const getSearchedListTags = () => {
    if (!searchedDocuments)
      return [
        <li className={`${styles[`document-list-item`]} ${styles[`no-hover`]}`} key={`loading-item`}>
          {lang === "ko" ? "검색 중..." : "Searching..."}
        </li>,
      ];
    const searchedListTags = [];
    if (searchedDocuments.length > 0) {
      const sortedSearchedDocuments = [...searchedDocuments];
      sortedSearchedDocuments.sort((a, b) => {
        return new Date(b.updated_time) - new Date(a.updated_time);
      });
      sortedSearchedDocuments.forEach((document) => {
        searchedListTags.push(documentItemTag(document));
      });
    } else {
      searchedListTags.push(
        <li className={`${styles[`document-list-item`]} ${styles[`no-hover`]}`} key={`empty-item`}>
          {lang === "ko" ? "해당 키워드의 노트를 찾지 못했습니다." : "No note found"}
        </li>
      );
    }
    return searchedListTags;
  };

  const fetchSearchDocuments = async (searchKeyword) => {
    const searchDocumentResult = await dispatch(searchDocuments(searchKeyword));
    setSearchedDocuments(!searchDocumentResult.error_message ? searchDocumentResult.documents : []);
  };

  return (
    <div className={styles[`search-popup`]}>
      <div className={styles[`search-input-container`]}>
        <input
          type={`text`}
          className={`${styles[`search-input`]} form-control`}
          placeholder={lang === "ko" ? "🔍  내 노트를 검색하세요." : "🔍  Search your note"}
          autoFocus={!isTouchDevice()}
          value={searchKeyword}
          onInput={(event) => {
            const searchKeyword = event.target.value;
            setSearchKeyword(searchKeyword);
          }}
          onChange={(event) => {
            if (!confirmPrivilege(SlidFeatures.noteSearching)) return showInsufficientPrivilegeModal();
          }}
        />
      </div>
      <div className={styles[`document-list-container`]}>
        {searchKeyword && searchKeyword.length > 0 ? (
          <div>
            <div className={styles[`document-list-title`]}>{lang === "ko" ? "검색 결과" : "Search results"}</div>
            <div className={styles[`document-list`]}>
              <ul>{getSearchedListTags(searchKeyword)}</ul>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles[`document-list-title`]}>{lang === "ko" ? "최근 노트" : "Recent notes"}</div>
            <div className={styles[`document-list`]}>
              <ul>{getRecentDocumentsTags(documents)}</ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default DocumentSearch;
