//auth 2.0
/**
 * @typedef {object} LinkToolData
 * @description Link Tool's input and output data format
 * @property {string} link — data url
 * @property {metaData} meta — fetched link data
 * @property {boolean} imageViewMode  — whether to display image view mode
 */

/**
 * @typedef {object} metaData
 * @description Fetched link meta data
 * @property {string} image - link's meta image
 * @property {string} title - link's meta title
 * @property {string} description - link's description
 */

/**
 * @typedef {object} UploadResponseFormat
 * @description This format expected from backend on link data fetching
 * @property {number} success  - 1 for successful uploading, 0 for failure
 * @property {metaData} meta - Object with link data.
 *
 * Tool may have any data provided by backend, currently are supported by design:
 * title, description, image, url
 */

// eslint-disable-next-line
import css from "./index.css";
import { LinkToolIcon, ImageViewIcon, TextViewIcon } from "./icons/toolbox";
import store from "redux/store";
import { isTouchDevice } from "utils/utils";
import { getLinkPreviewData } from "redux/actions/vdocsActions";
// import polyfill from "url-polyfill";
export default class LinkTool {
  /**
   * Notify core that read-only mode supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    const lang = store.getState().slidGlobal.lang;
    return {
      icon: LinkToolIcon,
      title: lang === "ko" ? "링크 미리보기" : "Link Preview",
    };
  }

  /**
   * Allow to press Enter inside the LinkTool input
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * @param {LinkToolData} data - previously saved data
   * @param {config} config - user config for Tool
   * @param {object} api - Editor.js API
   * @param {boolean} readOnly - read-only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    /**
     * Tool's initial config
     */
    this.config = {
      endpoint: config.endpoint || "",
      headers: config.headers || {},
      showToast: config.showToast,
    };

    this.nodes = {
      wrapper: null,
      container: null,
      progress: null,
      input: null,
      inputHolder: null,
      linkContent: null,
      linkImage: null,
      linkTitle: null,
      linkDescription: null,
      linkText: null,
      textContainer: null,
      imageContainer: null,
    };

    this._data = {
      link: "",
      meta: {},
      imageViewMode: data && data?.imageViewMode ? true : false,
    };

    this.data = data;
  }

  /**
   * Renders Block content
   *
   * @public
   *
   * @returns {HTMLDivElement}
   */
  render() {
    this.nodes.wrapper = this.make("div", this.CSS.baseClass);
    this.nodes.wrapper.draggable = !this.readOnly && !isTouchDevice();
    this.nodes.container = this.make("div", this.CSS.container);

    this.nodes.inputHolder = this.makeInputHolder();
    this.nodes.linkContent = this.prepareLinkPreview();

    /**
     * If Tool already has data, render link preview, otherwise render input block
     */
    if (Object.keys(this.data.meta).length) {
      this.nodes.container.appendChild(this.nodes.linkContent);
      this.showLinkPreview(this.data.meta);
    } else {
      this.nodes.container.appendChild(this.nodes.inputHolder);
    }

    this.nodes.wrapper.appendChild(this.nodes.container);

    // add dummy input for deleting the block by pressing backspace
    const dummyInput = this.make("input", "dummy-input");
    this.nodes.wrapper.appendChild(dummyInput);

    return this.nodes.wrapper;
  }

  /**
   * Add plugin settings
   *
   * @returns {HTMLElement} - wrapper element
   */

  renderSettings() {
    const wrapper = [];

    const tunes = [
      {
        name: "imageViewMode",
        title: this.api.i18n.t(`${this.data?.imageViewMode ? "Change to text view" : "Change to image view"}`),
        icon: this.data?.imageViewMode ? TextViewIcon : ImageViewIcon,
        setTune: () => {
          this.data.imageViewMode = !this.data?.imageViewMode;
        },
      },
    ];

    tunes.forEach((tune) => {
      if (tune.name === "imageViewMode" && (!this.data.meta?.image?.url || !this.isValidUrl(this.data.meta?.image?.url))) return;
      const tuneButton = this.make("div", "ce-settings__button");

      const iconContainer = this.make("div", "ce-settings__button-icon-container", {
        innerHTML: tune.icon,
      });

      const buttonTitle = this.make("span", "ce-settings__button-text", {
        innerHTML: tune.title,
      });

      tuneButton.appendChild(iconContainer);
      tuneButton.appendChild(buttonTitle);

      tuneButton.addEventListener("click", () => this.toggleTune(tune, tuneButton));

      wrapper.push(tuneButton);
    });

    return wrapper;
  }

  /**
   * Changes the state of the tune
   * Updates its representation in the link block
   *
   * @param {Tune} tune - One of the link block settings
   * @param {HTMLElement} tuneButton - DOM element of the tune
   * @returns {void}
   */
  toggleTune(tune, tuneButton) {
    if (!this.data.meta?.image.url || !this.isValidUrl(this.data.meta?.image.url)) return;
    tune.setTune();

    const icon = tuneButton.children[0];
    const tuneText = tuneButton.children[1];

    icon.innerHTML = this.data?.imageViewMode ? TextViewIcon : ImageViewIcon;
    tuneText.innerHTML = this.api.i18n.t(`${this.data?.imageViewMode ? "Change to text view" : "Change to image view"}`);

    this.toggleImageViewStyles(this.data?.imageViewMode);
  }

  /**
   * Toggles classes on the the link-image when view mode changes
   * @param {boolean} imageViewMode
   */
  toggleImageViewStyles(imageViewMode) {
    if (imageViewMode) {
      this.nodes.linkContent.prepend(this.nodes.imageContainer);
      this.nodes.imageContainer.classList.add(this.CSS.largeImageViewContainer);
      this.nodes.textContainer.classList.add(this.CSS.textContainerImageView);

      //Remove textView CSS
      this.nodes.textContainer.classList.remove(this.CSS.textLinkTextContainer);
      this.nodes.linkTitle.classList.remove(this.CSS.textLinkTitle);
      this.nodes.linkDescription.classList.remove(this.CSS.textLinkDescription);
      this.nodes.linkText.classList.remove(this.CSS.textLinkAnchor);
    } else {
      this.nodes.imageContainer.classList.remove(this.CSS.largeImageViewContainer);
      this.nodes.textContainer.prepend(this.nodes.imageContainer);
      this.nodes.textContainer.classList.remove(this.CSS.textContainerImageView);

      //Add textView CSS
      this.nodes.textContainer.classList.add(this.CSS.textLinkTextContainer);
      this.nodes.linkTitle.classList.add(this.CSS.textLinkTitle);
      this.nodes.linkDescription.classList.add(this.CSS.textLinkDescription);
      this.nodes.linkText.classList.add(this.CSS.textLinkAnchor);
    }
  }

  /**
   * Return Block data
   *
   * @public
   *
   * @returns {LinkToolData}
   */
  save() {
    return this.data;
  }

  /**
   * Validate Block data
   * - check if given link is an empty string or not.
   *
   * @public
   *
   * @returns {boolean} false if saved data is incorrect, otherwise true
   */
  validate() {
    return this.data.link.trim() !== "";
  }

  /**
   * Stores all Tool's data
   *
   * @param {LinkToolData} data
   */
  set data(data) {
    this._data = Object.assign(
      {},
      {
        link: data.link || this._data.link,
        meta: data.meta || this._data.meta,
        imageViewMode: data?.imageViewMode || this._data?.imageViewMode,
      }
    );
  }

  /**
   * Return Tool data
   *
   * @returns {LinkToolData}
   */
  get data() {
    return this._data;
  }

  /**
   * @returns {object} - Link Tool styles
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      input: this.api.styles.input,

      /**
       * Tool's classes
       */
      container: "link-tool",
      inputEl: "link-tool__input",
      inputHolder: "link-tool__input-holder",
      inputError: "link-tool__input-holder--error",
      linkContent: "link-tool__content",
      linkContentRendered: "link-tool__content--rendered",
      linkImageContainer: "link-tool__image-container",
      linkTitle: "link-tool__title",
      linkDescription: "link-tool__description",
      linkText: "link-tool__anchor",
      progress: "link-tool__progress",
      progressLoading: "link-tool__progress--loading",
      progressLoaded: "link-tool__progress--loaded",
      settingsWrapper: "",
      largeImageViewContainer: "link-tool__large-image-container",
      textContainer: "link-tool__text-container",
      textContainerImageView: "image-view",
      textLinkTextContainer: "text-link-tool__text-container",
      textLinkTitle: "text-link-tool__title",
      textLinkDescription: "text-link-tool__description",
      textLinkAnchor: "text-link-tool__anchor",
    };
  }

  /**
   * Prepare input holder
   *
   * @returns {HTMLElement}
   */
  makeInputHolder() {
    const inputHolder = this.make("div", this.CSS.inputHolder);

    this.nodes.progress = this.make("label", this.CSS.progress);
    this.nodes.input = this.make("div", [this.CSS.input, this.CSS.inputEl], {
      contentEditable: !this.readOnly,
    });

    this.nodes.input.dataset.placeholder = this.api.i18n.t("Link");

    if (!this.readOnly) {
      this.nodes.input.addEventListener("paste", (event) => {
        this.startFetching(event);
      });

      this.nodes.input.addEventListener("keydown", (event) => {
        const [ENTER, A, BACKSPACE] = [13, 65, 8];
        const cmdPressed = event.ctrlKey || event.metaKey;

        switch (event.keyCode) {
          case ENTER:
            event.preventDefault();
            event.stopPropagation();

            this.startFetching(event);
            break;
          case A:
            if (cmdPressed) {
              this.selectLinkUrl(event);
            }
            break;
          case BACKSPACE:
            event.stopPropagation();
            break;
          default:
            break;
        }
      });
    }

    inputHolder.appendChild(this.nodes.progress);
    inputHolder.appendChild(this.nodes.input);

    return inputHolder;
  }

  /**
   * Activates link data fetching by url
   * @param {PasteEvent} event
   */
  startFetching(event) {
    let url = this.nodes.input.textContent;

    if (event.type === "paste") {
      url = (event.clipboardData || window.clipboardData).getData("text");
    }

    this.removeErrorStyle();
    this.fetchLinkData(url);
  }

  /**
   * If previous link data fetching failed, remove error styles
   */
  removeErrorStyle() {
    this.nodes.inputHolder.classList.remove(this.CSS.inputError);
    this.nodes.inputHolder.insertBefore(this.nodes.progress, this.nodes.input);
  }

  /**
   * Select LinkTool input content by CMD+A
   *
   * @param {KeyboardEvent} event
   */
  selectLinkUrl(event) {
    event.preventDefault();
    event.stopPropagation();

    const selection = window.getSelection();
    const range = new Range();

    const currentNode = selection.anchorNode.parentNode;
    const currentItem = currentNode.closest(`.${this.CSS.inputHolder}`);
    const inputElement = currentItem.querySelector(`.${this.CSS.inputEl}`);

    range.selectNodeContents(inputElement);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  /**
   * Prepare link preview holder (make required dom nodes)
   *
   * @returns {HTMLElement}
   */
  prepareLinkPreview() {
    const holder = this.make("a", this.CSS.linkContent, {
      target: "_blank",
      rel: "nofollow noindex noreferrer",
      draggable: false,
    });

    const imageContainerStyles = [this.CSS.linkImageContainer];

    if (this.data?.imageViewMode) imageContainerStyles.push(this.CSS.largeImageViewContainer);

    this.nodes.imageContainer = this.make("div", imageContainerStyles);

    this.nodes.linkImage = this.make("img", [], { src: "", alt: "" });

    this.nodes.linkTitle = this.make("div", this.CSS.linkTitle);
    this.nodes.linkDescription = this.make("p", this.CSS.linkDescription);
    this.nodes.linkText = this.make("div", this.CSS.linkText);

    this.nodes.textContainer = this.make("div", this.CSS.textContainer);

    return holder;
  }

  /**
   * Checks if given URL is a valid
   * @param {String} urlString - The URL to check
   * @return {Boolean} - If url is valid
   */

  isValidUrl(urlString) {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  }

  /**
   * Compose link preview from fetched data
   *
   * @param {metaData} meta - link meta data
   */
  showLinkPreview({ image, title, description }) {
    this.nodes.container.appendChild(this.nodes.linkContent);

    if (image && image.url && this.isValidUrl(image.url)) {
      this.nodes.linkImage.src = image.url;
      this.nodes.imageContainer.appendChild(this.nodes.linkImage);

      if (this.data.imageViewMode) {
        this.nodes.linkContent.appendChild(this.nodes.imageContainer);
      } else {
        this.nodes.textContainer.classList.add(this.CSS.textLinkTextContainer);
        this.nodes.textContainer.appendChild(this.nodes.imageContainer);
      }
    }

    if (title) {
      this.nodes.linkTitle.textContent = title;
      if (!this.data.imageViewMode) this.nodes.linkTitle.classList.add(this.CSS.textLinkTitle);
      this.nodes.textContainer.appendChild(this.nodes.linkTitle);
    }

    if (description) {
      this.nodes.linkDescription.textContent = description;
      if (!this.data.imageViewMode) this.nodes.linkDescription.classList.add(this.CSS.textLinkDescription);
      this.nodes.textContainer.appendChild(this.nodes.linkDescription);
    }

    this.nodes.linkContent.classList.add(this.CSS.linkContentRendered);
    this.nodes.linkContent.setAttribute("href", this.data.link);
    if (!this.data.imageViewMode) this.nodes.linkText.classList.add(this.CSS.textLinkAnchor);
    this.nodes.textContainer.appendChild(this.nodes.linkText);
    this.nodes.linkContent.appendChild(this.nodes.textContainer);

    try {
      this.nodes.linkText.textContent = new URL(this.data.link).hostname;
    } catch (e) {
      this.nodes.linkText.textContent = this.data.link;
    }
  }

  /**
   * Show loading progressbar
   */
  showProgress() {
    this.nodes.progress.classList.add(this.CSS.progressLoading);
  }

  /**
   * Hide loading progressbar
   */
  hideProgress() {
    return new Promise((resolve) => {
      this.nodes.progress.classList.remove(this.CSS.progressLoading);
      this.nodes.progress.classList.add(this.CSS.progressLoaded);

      setTimeout(resolve, 500);
    });
  }

  /**
   * If data fetching failed, set input error style
   */
  applyErrorStyle() {
    this.nodes.inputHolder.classList.add(this.CSS.inputError);
    this.nodes.progress.remove();
  }

  /**
   * Sends to backend pasted url and receives link data
   *
   * @param {string} url - link source url
   */
  async fetchLinkData(url) {
    this.showProgress();
    this.data = { link: url };
    try {
      const result = await store.dispatch(getLinkPreviewData(url));
      if (result.error_message) {
        this.fetchingFailed(this.api.i18n.t("Couldn't get this link data, try the other one"));
        return;
      }

      this.onFetch(result);
    } catch (error) {
      this.fetchingFailed(this.api.i18n.t("Couldn't get this link data, try the other one"));
    }
  }

  /**
   * Link data fetching callback
   *
   * @param {UploadResponseFormat} response
   */
  onFetch(response) {
    if (!response.success) {
      this.fetchingFailed(this.api.i18n.t("Couldn't get this link data, try the other one"));
      return;
    }

    const metaData = response.meta;

    const link = response.link || this.data.link;

    this.data = {
      meta: metaData,
      link,
    };

    if (!metaData) {
      this.fetchingFailed(this.api.i18n.t("Wrong response format from the server"));

      return;
    }

    this.hideProgress().then(() => {
      this.nodes.inputHolder.remove();
      this.showLinkPreview(metaData);
    });
  }

  /**
   * Handle link fetching errors
   *
   * @private
   *
   * @param {string} errorMessage
   */
  fetchingFailed(errorMessage) {
    this.applyErrorStyle();
    this.config.showToast(errorMessage);
  }

  /**
   * Helper method for elements creation
   *
   * @param tagName
   * @param classNames
   * @param attributes
   * @returns {HTMLElement}
   */
  make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }

  /**
   * Sanitizer Rules
   */
  static get sanitize() {
    return true;
  }

  static convertToPlainHTML(data) {
    return `<p><a href=${data.link}  target="_blank" rel="nofollow noopener noreferrer">${data.meta?.title ?? "Link"}</a></p>`;
  }
}
