import slidAxios from "./base";
import { handleAuthErrors, isAuthError } from "./errorUtils";
const uploadClip_endpoint = "upload-clip/";
const captureClip_endpoint = "capture-clip/";
const clips_endpoint = "clips/";

/**
 * This function registers a clip in the DB which is metadata for asset stored on s3
 * [POST] /api/<version>/upload-clip/ OR /api/<version>/capture-clip/
 * imageSource is capture-clip(default) or upload-clip
 */
export const ServerInterface_registerClip = async ({ data, clipEndPoint = "upload-clip" }) => {
  //clipEndPoint = "upload-clip" or "capture-clip"
  const payload = data;
  let clipRegisterData = null;
  const endpoint = clipEndPoint === "upload-clip" ? uploadClip_endpoint : captureClip_endpoint;
  try {
    const clipPostResponse = await slidAxios.post(endpoint, payload);
    clipRegisterData = clipPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!clipRegisterData) clipRegisterData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          clipRegisterData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          clipRegisterData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 400:
          // "Invalid Data (document or video for given document_key doesn't exist)"
          // "Invalid Data (for Clipboard)"
          clipRegisterData.error_message = "POST_CLIP_INVALID_DATA";
          break;
        default:
          clipRegisterData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      clipRegisterData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      clipRegisterData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return clipRegisterData;
  }
};

/**
 * imageSource is capture-clip(default) or upload-clip
 */
export const ServerInterface_updateClip = async ({ data, clipEndPoint = "upload-clip" }) => {
  const payload = data;
  let clipUpdateData = null;
  const endpoint = clipEndPoint === "upload-clip" ? uploadClip_endpoint : captureClip_endpoint;
  try {
    const clipPutResponse = await slidAxios.put(endpoint, payload);
    clipUpdateData = clipPutResponse.data;
  } catch (error) {
    console.error(error);
    if (!clipUpdateData) clipUpdateData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      clipUpdateData.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          clipUpdateData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          //  "Clip for given 'clip_key' Doesn't Exist ('clip_key' not None)"
          clipUpdateData.error_message = "CLIP_NOT_FOUND";
          break;
        case 400:
          // "Either 'img_src' or 'img_markup_src' or 'document_key' Not Provided"
          clipUpdateData.error_message = "UPDATE_CLIP_BAD_REQUEST";
          break;
        case 403:
          clipUpdateData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          clipUpdateData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      clipUpdateData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      clipUpdateData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return clipUpdateData;
  }
};

export const ServerInterface_deleteClip = async ({ data }) => {
  let deleteClipData = null;

  try {
    const clipDeleteResponse = await slidAxios.delete(`${clips_endpoint}${data.clipKey}/`);
    deleteClipData = clipDeleteResponse.data;
  } catch (error) {
    console.error(error);
    if (!deleteClipData) deleteClipData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      deleteClipData.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          deleteClipData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          // "Clip Doesn't Exist"
          deleteClipData.error_message = "CLIP_NOT_FOUND";
          break;
        case 400:
          // "Undefined Request"
          deleteClipData.error_message = "UPDATE_CLIP_BAD_REQUEST";
          break;
        case 403:
          deleteClipData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          deleteClipData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      deleteClipData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      deleteClipData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return deleteClipData;
  }
};
