import React from "react";
import { Button, CustomModalProps, Icon, ModalTypes, Typography16, Typography28 } from "@slid/slid-ips";
import styled from "styled-components";
import { useAppSelector } from "hooks";
import { Trans, useTranslation } from "react-i18next";
import ChannelService from "config/channel-talk";

/**
 * This modal is shown when the user has failed the study challenge,
 * as a one-time notification.
 */
export const showStudyChallengeFailedModal = async (props: any) => {
  const { showModal, closeModal } = props;

  const modalProps: CustomModalProps = {
    type: ModalTypes.customModal,
    customComponent: <CommonComponents closeModal={closeModal} />,
  };
  showModal(modalProps);
};

const CommonComponents = ({ closeModal }: any) => {
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const { t } = useTranslation("StudyChallenge");
  const IMAGE_PATH = `/src/assets/study_challenge/failed_modal_${lang === "ko" ? "ko" : "en"}.webp`;

  return (
    <Container>
      <Image src={IMAGE_PATH} />
      <Typography28
        type="span"
        weight={700}
        // @ts-ignore
        text={
          <Trans
            components={{
              colored: <Typography28 type="span" weight={700} text={t("FailedModal.TitleColoredText", { keySeparator: "." })} color={`--blue8`} textAlign={"center"} style={{ display: "inline" }} />,
            }}
          >
            {t("FailedModal.Title", { keySeparator: "." })}
          </Trans>
        }
        color="--gray17"
        marginTop="24px"
        textAlign="center"
      />
      <DescriptionArea>
        {t("FailedModal.Description", { keySeparator: ".", joinArrays: "||" })
          .split("||")
          .map((descriptionText, index) => (
            <DescriptionRow key={index}>
              <Icon icon="check24" color="--blue8" />
              <Typography16 weight={400} text={descriptionText} color="--gray15" />
            </DescriptionRow>
          ))}
      </DescriptionArea>
      <ButtonRow>
        <Button appearance="secondary" callback={closeModal} size="medium" text={t("FailedModal.SecondaryButtonText", { keySeparator: "." })} />
        <Button
          appearance="primary"
          callback={() => {
            closeModal();
            setTimeout(() => {
              if (ChannelService) ChannelService.openChat();
            }, 0);
          }}
          size="medium"
          text={t("FailedModal.PrimaryButtonText", { keySeparator: "." })}
        />
      </ButtonRow>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 39px;
  padding: 48px 36px;
  background-color: var(--gray1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 400px;
`;

const DescriptionArea = styled.div`
  margin-top: 24px;
`;

const DescriptionRow = styled.div`
  display: flex;
  margin-top: 4px;
  gap: 8px;
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 48px;
  justify-content: center;
`;
