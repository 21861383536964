//auth 2.0
import * as Dom from "./utils/dom";
import Caret from "./utils/caret";
import "./index.css";
import { convertToArrow, getCaretPos, getTextDataToMerge, makeElement, setCursorAtPosition } from "../../../../utils/utils";

/**
 * List Tool for the Editor.js 2.0
 */
class UnorderedList {
  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behavior
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: '<svg width="15" height="14" viewBox="0 0 15 14" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1 2.5C1.55228 2.5 2 2.05228 2 1.5C2 0.947715 1.55228 0.5 1 0.5C0.447715 0.5 0 0.947715 0 1.5C0 2.05228 0.447715 2.5 1 2.5ZM4.5 1C4.22386 1 4 1.22386 4 1.5C4 1.77614 4.22386 2 4.5 2H14.5C14.7761 2 15 1.77614 15 1.5C15 1.22386 14.7761 1 14.5 1H4.5ZM4.5 12C4.22386 12 4 12.2239 4 12.5C4 12.7761 4.22386 13 4.5 13H14.5C14.7761 13 15 12.7761 15 12.5C15 12.2239 14.7761 12 14.5 12H4.5ZM4.5 6.5C4.22386 6.5 4 6.72386 4 7C4 7.27614 4.22386 7.5 4.5 7.5H14.5C14.7761 7.5 15 7.27614 15 7C15 6.72386 14.7761 6.5 14.5 6.5H4.5ZM1 8C1.55228 8 2 7.55228 2 7C2 6.44772 1.55228 6 1 6C0.447715 6 0 6.44772 0 7C0 7.55228 0.447715 8 1 8ZM2 12.5C2 13.0523 1.55228 13.5 1 13.5C0.447715 13.5 0 13.0523 0 12.5C0 11.9477 0.447715 11.5 1 11.5C1.55228 11.5 2 11.9477 2 12.5Z" fill="currentColor" /></svg>',
      title: "List",
    };
  }

  /**
   * Allow List Tool to be converted to/from other block
   *
   * @returns {{export: Function, import: Function}}
   */
  static get conversionConfig() {
    return {
      /**
       * To create exported string from list, concatenate items by dot-symbol.
       *
       * @param {ListData} data - list data to create a string from thats
       * @returns {string}
       */
      export: (data) => {
        return data.items.map(({ text }) => text).join(". ");
      },
      /**
       * To create a list from other block's string, just put it at the first item
       *
       * @param {string} string - string to create list tool data from that
       * @returns {ListData}
       */
      import: (string) => {
        return {
          items: [
            {
              text: string,
            },
          ],
        };
      },
    };
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} params - tool constructor options
   * @param {ListData} params.data - previously saved data
   * @param {object} params.config - user config for Tool
   * @param {object} params.api - Editor.js API
   * @param {boolean} params.readOnly - read-only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    /**
     * HTML nodes
     *
     * @private
     */
    this._elements = {
      container: null,
      input: null,
    };

    this.config = config;
    this.api = api;
    this.readOnly = readOnly;
    /**
     * Tool's data
     *
     * @type {ListData}
     */
    this.data =
      data && data.items
        ? data
        : {
            items: [{ text: "" }],
            indentLevel: 0, // 0 ~ 8
          };
    this.MAX_INDENT_LEVEL = 8;
    this.INDENT_MARGIN = 30;
  }

  onKeyDown(event) {
    convertToArrow(event);

    const [ENTER, BACKSPACE, TAB] = [13, 8, 9]; // key codes

    switch (event.keyCode) {
      case ENTER:
        if (event.shiftKey) return;
        this.addNewUnorderedList(event);
        break;
      case BACKSPACE:
        this.backspace(event);
        break;
      case TAB:
        if (event.shiftKey) {
          this.shiftTab(event);
        } else {
          this.addTab(event);
        }
        break;
      default:
        return;
    }
  }

  /**
   * Styles
   *
   * @private
   */
  get CSS() {
    return {
      baseBlock: this.api.styles.block,
      container: "cdx-unordred-list-outer-wrapper",
      innerWrapper: "cdx-unordred-list-inner-wrapper",
      settingsWrapper: "cdx-unordred-list-settings",
      pseudoSelection: "cdx-unordred-list__pseudo-selection",
      pseudoBefore: "cdx-unordred-list__pseudo-before",
      inputWrapper: "cdx-unordred-list___input-wrapper",
      input: "cdx-unordred-list__input",
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
    };
  }

  /**
   * Returns list tag with item
   *
   * @returns {Element}
   * @public
   */
  render() {
    this._elements.container = makeElement("div", [this.CSS.container]);
    this._elements.container.setAttribute("slid-cy", "editor-unordered-list");
    const innerWrapper = makeElement("div", [this.CSS.innerWrapper]);
    const pseudoSelection = makeElement("div", [this.CSS.pseudoSelection]);
    const pseudoBefore = makeElement("div", [this.CSS.pseudoBefore]);
    const inputWrapper = makeElement("div", [this.CSS.inputWrapper]);
    this._elements.input = makeElement("div", [this.CSS.input], {
      contentEditable: !this.readOnly,
      innerHTML: this.data.items[0].text || "",
    });
    this._elements.input.setAttribute("slid-cy", "editor-unordered-list-input");
    this._elements.input.addEventListener("input", (e) => {
      this.data.items[0].text = this._elements.input.innerHTML;
    });
    const mutationObserver = new MutationObserver((mutationsList) => {
      this.data.items[0].text = mutationsList[0].target.innerHTML; // For check changing by inline tools
    });
    mutationObserver.observe(this._elements.input, { characterData: false, childList: true, attributes: false });
    pseudoBefore.setAttribute("data-before", "•");
    pseudoSelection.appendChild(pseudoBefore);

    inputWrapper.appendChild(this._elements.input);

    innerWrapper.appendChild(pseudoSelection);
    innerWrapper.appendChild(inputWrapper);

    if (!this.readOnly) {
      // detect keydown on the last item to escape List
      this.onKeyDown = this.onKeyDown.bind(this);
      this._elements.container.addEventListener("keydown", this.onKeyDown, false);
    }
    this._elements.container.appendChild(innerWrapper);
    this._elements.container.style.marginLeft = `${this.INDENT_MARGIN * this.data.indentLevel}px`;

    return this._elements.container;
  }

  /**
   * @returns {ListData}
   * @public
   */
  save() {
    return this.data;
  }

  merge(data) {
    const caretPos = getCaretPos(this._elements.input);
    const newData = {
      items: [{ text: this.data.items[0].text + getTextDataToMerge(data) }],
      indentLevel: this.data.indentLevel,
    };

    this.data = newData;
    this._elements.input.innerHTML = this.data.items[0].text;

    setTimeout(() => {
      setCursorAtPosition(this._elements.input, caretPos);
    });
  }

  /**
   * Sanitizer rules
   *
   * @returns {object}
   */
  static get sanitize() {
    return {
      items: { br: true },
    };
  }

  addNewUnorderedList(event) {
    /**
     * Prevent editor.js behavior
     */
    event.stopPropagation();

    /**
     * Prevent browser behavior
     */
    event.preventDefault();

    const value = this._elements.input.innerHTML;

    /** Prevent Default li generation if item is empty */
    if (value === "") {
      /** Insert New Paragraph Block and set caret */
      const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
      this.api.blocks.delete();

      // If the block was the first block, which is index 0, it automatically creates new empty block.
      // So only create new block when the block index is not 0
      if (currentBlockIndex !== 0) {
        this.api.blocks.insert();
      }
      this.api.caret.setToBlock(currentBlockIndex);
    } else {
      /**
       * On other Enters, get content from caret till the end of the block
       * And move it to the new item
       */
      const endingFragment = Caret.extractFragmentFromCaretPositionTillTheEnd();
      const endingText = Dom.fragmentToString(endingFragment);
      /** Insert New list and set caret */
      const blockIndex = this.api.blocks.getCurrentBlockIndex();
      this.api.blocks.insert(
        "unorderedList",
        {
          items: [{ text: endingText }],
          indentLevel: this.data.indentLevel,
        },
        undefined,
        blockIndex + 1,
        true
      );
      this.api.caret.setToBlock(blockIndex + 1);

      /** Remove ending text from current data */
      this.data.items[0].text = this._elements.input.innerHTML;
    }
  }

  /**
   * Handle backspace
   *
   * @param {KeyboardEvent} event
   */
  backspace(event) {
    event.stopPropagation(); // * It prevents editorjs automatically pointing previous block, not deleting space.
    const value = this._elements.input.innerHTML;
    const caretPos = getCaretPos(this._elements.input);
    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
    if (caretPos === 0 || value === "") {
      this.api.blocks.delete(currentBlockIndex);
      this.api.blocks.insert("paragraph", { text: value }, {}, currentBlockIndex, true);
      this.api.caret.setToBlock(currentBlockIndex);
    }
  }

  /**
   * Add indentation to current item
   *
   * @param {KeyboardEvent} event - keydown
   */
  addTab(event) {
    /**
     * Prevent editor.js behavior
     */
    event.stopPropagation();

    /**
     * Prevent browser tab behavior
     */
    event.preventDefault();
    this.data.indentLevel = this.data.indentLevel < this.MAX_INDENT_LEVEL ? this.data.indentLevel + 1 : this.MAX_INDENT_LEVEL;

    this._elements.container.style.marginLeft = `${this.INDENT_MARGIN * this.data.indentLevel}px`;
  }

  shiftTab(event) {
    /**
     * Prevent editor.js behavior
     */
    event.stopPropagation();

    /**
     * Prevent browser tab behavior
     */
    event.preventDefault();

    this.data.indentLevel = this.data.indentLevel > 0 ? this.data.indentLevel - 1 : 0;

    this._elements.container.style.marginLeft = `${this.INDENT_MARGIN * this.data.indentLevel}px`;
  }

  static convertToPlainHTML(data) {
    function makeList(listItems) {
      return `<ul>${listItems.map((item) => (Array.isArray(item) ? makeList(item) : `<li>${item}</li>`)).join("")}</ul > `;
    }
    return makeList(data);
  }
  // TODO: add paste handler
}

export default UnorderedList;
