export const getTranscriptLanguageCode = (language) => {
  const languageCodes = {
    English: "en-US",
    한국어: "ko-KR",
    中文: "cmn-Hans-CN",
    हिन्दी: "hi-IN",
    Español: "es-ES",
    Français: "fr-FR",
    日本語: "ja-JP",
    Português: "pt-PT",
    Deutsch: "de-DE",
    Dutch: "nl-NL",
  };
  return languageCodes[language] || "en-US";
};
