// auth 2.0

import { SET_SEARCH_CATEGORIES, SET_SEARCH_KEYWORDS, SET_SEARCH_RESULTS, SET_SEARCH_SORTING_OPTION, SET_SHOW_SEARCH_MODAL } from "redux/actions/actionTypes";
import { SearchCategoryType, SortingOptionType } from "types/search";

const initialState = {
  showSearchModal: false,
  searchKeywords: "",
  searchResults: [],
  searchCategories: { categories: [SearchCategoryType.Folders, SearchCategoryType.Notes], selectedCategories: [] },
  searchSortingOption: SortingOptionType.Date,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_SEARCH_MODAL:
      return {
        ...state,
        showSearchModal: action.payload,
      };
    case SET_SEARCH_KEYWORDS:
      return {
        ...state,
        searchKeywords: action.payload,
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };
    case SET_SEARCH_CATEGORIES:
      return {
        ...state,
        searchCategories: action.payload,
      };
    case SET_SEARCH_SORTING_OPTION:
      return {
        ...state,
        searchSortingOption: action.payload,
      };
    default:
      return state;
  }
};
