// auth 2.0
import React from "react";
import styled from "styled-components";
import { Typography15, IPSColor } from "@slid/slid-ips";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const LoadingScreenContent = ({ loadingText, textColor }: { loadingText?: string; textColor?: IPSColor }) => {
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  const { t } = useTranslation("EditorLoadingScreen");

  return (
    <InnerWrapper>
      <Spinner>
        <SpinnerCircle />
        <SpinnerCircle />
        <SpinnerCircle />
        <SpinnerCircle />
      </Spinner>
      <Typography15 text={loadingText ? loadingText : t("LoadingText")} color={textColor ? textColor : "--gray17"} weight={700} style={{ marginTop: isMobile ? "28px" : "32px" }} />
    </InnerWrapper>
  );
};

export default LoadingScreenContent;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  @media (max-width: 799px) {
    width: 60px;
    height: 60px;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerCircle = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  border: 8px solid #4593fc;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4593fc transparent transparent transparent;
  @media (max-width: 799px) {
    width: 60px;
    height: 60px;
  }
  &:nth-child(1) {
    animation-delay: -0.45s;
  }
  &:nth-child(2) {
    animation-delay: -0.3s;
  }
  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
