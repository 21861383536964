// auth 2.0
const MarkdownRules = [
  {
    syntax: "# ",
    block: {
      type: "header1",
      data: {
        level: 2,
      },
      config: {
        focusSelf: true,
      },
    },
  },
  {
    syntax: "## ",
    block: {
      type: "header2",
      data: {
        level: 3,
      },
      config: {
        focusSelf: true,
      },
    },
  },
  {
    syntax: "### ",
    block: {
      type: "header3",
      data: {
        level: 4,
      },
      config: {
        focusSelf: true,
      },
    },
  },
  {
    syntax: "- ",
    block: {
      type: "unorderedList",
      data: {
        items: [{ text: "" }],
        indentLevel: 0,
      },
      config: {
        focusSelf: true,
      },
    },
  },
  {
    syntax: "1. ",
    block: {
      type: "orderedList",
      data: {
        items: [{ text: "" }],
        indentLevel: 0, // 0 ~ 8
        indentPhase: 0,
        numberingLevels: [
          [1, 1, 1],
          [1, 1, 1],
          [1, 1, 1],
        ],
        numberForMarkdownDownloading: 1,
      },
      config: {
        focusSelf: true,
      },
    },
  },
  {
    syntax: "[]",
    block: {
      type: "blockChecklist",
      data: {
        items: [{ text: "", checked: false }],
        indentLevel: 0,
      },
      config: {
        focusSelf: true,
      },
    },
  },
  {
    syntax: "```",
    block: {
      type: "codeMirrorTool",
      data: { code: "" },
      config: {
        focusSelf: true,
      },
    },
  },
  {
    syntax: "---",
    block: {
      type: "divider",
      config: {
        focusSelf: false,
      },
    },
  },
];

export default MarkdownRules;
