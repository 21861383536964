// auth 2.0

import React, { memo } from "react";
import { useHistory, Route } from "react-router-dom";
import { isUserAuthenticated } from "utils/auth/cognitoAuthToolkit";
import { getApplicationType } from "utils/utils";

/**
 * Prevents access to a route if the user is not authenticated.
 * @param {Object} props - All the props passed to the Route component in the App.js file
 * @returns {JSX.Element} - A Route component if user is authenticated or a Loading component if checking the auth state on first render
 */
const ProtectedRoute = memo(({ ...props }) => {
  const [isCheckingAuth, setIsCheckingAuth] = React.useState(true);
  const history = useHistory();

  React.useEffect(() => {
    const checkAuth = async () => {
      const applicationType = getApplicationType();

      const isAuthenticated = await isUserAuthenticated();
      if (!isAuthenticated) {
        if (applicationType === "extension") return history.push("/demo");
        return history.push("/sign-in");
      } else {
        setIsCheckingAuth(false);
      }
    };
    checkAuth();
    return () => {};
  }, [history]);

  if (isCheckingAuth) {
    return (
      <div className={`main-loading-container d-flex flex-column justify-content-center align-items-center`}>
        <div>
          <img className={`logo`} src={`/src/logo/slid_logo.png`} alt={``} />
          <img className={`logo-text`} src={`/src/logo/slid_logo_text.png`} alt={``} />
        </div>
        <div className="spinner-border mt-3" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <span className={`mt-3`}>Loading... </span>
      </div>
    );
  }
  return <Route {...props} />;
});

export default ProtectedRoute;
