import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const PenIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 48} height={height ? height : 48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.062 32.2014L31.8717 27.3833L30.9098 23.5288" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.9684 35.0922H13.5947C13.3396 35.0922 13.095 34.9907 12.9146 34.81C12.7342 34.6292 12.6328 34.3841 12.6328 34.1286V28.7455C12.6329 28.4903 12.7341 28.2455 12.9142 28.065L27.7424 13.2107C27.9228 13.0301 28.1674 12.9287 28.4224 12.9287C28.6774 12.9287 28.922 13.0301 29.1023 13.2107L34.476 18.5901C34.6562 18.7708 34.7575 19.0159 34.7575 19.2713C34.7575 19.5268 34.6562 19.7718 34.476 19.9525L19.6477 34.8103C19.4675 34.9907 19.2231 35.0921 18.9684 35.0922Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M24.1763 16.7837L30.9098 23.529" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.6943 28.4082L19.3052 35.0307" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgWrapper>
  );
};
