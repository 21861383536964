import env from "config/env";

const SEOUL_CDN = env.CDN.seoul_cdn;
const OHIO_CDN = env.CDN.ohio_cdn;
const MUMBAI_CDN = env.CDN.mumbai_cdn;

/* image link format:
https://slid-users-assets-v1-seoul.s3.ap-northeast-2.amazonaws.com/public/image_upload/17bab75234d84f0ea26b6639fb3c84bc/a4b10f67-2acb-4fd2-9d63-407c781b3fbb.png
*/
export const getCDNSrc = (src: String) => {
  if (!src.includes(".s3.")) return src;
  if (!src.includes(".amazonaws.com")) return src;
  const suffix = src.split(".amazonaws.com").pop();
  if (src.includes("slid-users-assets-v1-seoul.s3")) {
    //   return src;
    return SEOUL_CDN + suffix;
  } else if (src.includes("slid-users-assets-v1-ohio.s3")) {
    //   return src;
    return OHIO_CDN + suffix;
  } else if (src.includes("slid-users-assets-v1-mumbai.s3")) {
    //   return src;
    return MUMBAI_CDN + suffix;
  } else {
    return src;
  }
};
