// auth 2.0

import { EditorBlockType, EditorBlockClasses } from "components/editor/tools/EditorTools";

interface GlobalExportInstance {
  currentContent: EditorContent;
  title: string;
  documentKey: string;
  lang: Language;
  mappedVideos: [];
}

interface BlockData {
  id: string;
  type: string;
  data: any;
}

interface EditorContent {
  time: number;
  blocks: BlockData[];
}

interface NestedList {
  list: any[];
  itemsCount: number;
}

type Language = "en" | "ko";

const EDITOR_LIST_BLOCK_TYPES: string[] = [EditorBlockType.orderedList, EditorBlockType.unorderedList];

/**
 *
 * @param content
 * @param startingIndex
 * @param listType
 * @returns object containing an array of all list elements in editor
 */
const returnNestedList = (content: EditorContent, startingIndex: number, listType: string): NestedList => {
  const nestedList: NestedList = {
    list: [],
    itemsCount: 0,
  };

  let nextBlock = content.blocks[startingIndex];
  const currentIndentLevel = nextBlock.data.indentLevel;

  while (nextBlock.type === listType) {
    nestedList.list.push(nextBlock.data.items[0].text);
    startingIndex++;
    nestedList.itemsCount++;

    //Reach the end of editor content or find a block that is not a list
    if (startingIndex > content.blocks.length || content.blocks[startingIndex]?.type !== listType) {
      return nestedList;
    }

    nextBlock = content.blocks[startingIndex];
    const nextIndentLevel = nextBlock.data.indentLevel;

    if (nextIndentLevel === currentIndentLevel) {
      continue;
    }

    //If you have a nested list, you need to recursively call this function
    if (nextIndentLevel > currentIndentLevel) {
      const innerList = returnNestedList(content, startingIndex, listType);
      nestedList.list.push(innerList.list);
      nestedList.itemsCount += innerList.itemsCount;

      //if at end of editor content, return or if next block is not a list, return
      if (startingIndex + innerList.itemsCount >= content.blocks.length || content.blocks[startingIndex + innerList.itemsCount]?.type !== listType) {
        return nestedList;
      }

      //if next block is a list, continue
      startingIndex += innerList.itemsCount;
      nextBlock = content.blocks[startingIndex];
      if (nextBlock.data.indentLevel === currentIndentLevel) {
        continue;
      } else {
        //if the next block is not at the same indent level, then you have reached the end of the nested list
        return nestedList;
      }
    } else {
      //we just got out of a nested list
      return nestedList;
    }
  }
  return nestedList;
};

export class GlobalExport {
  private data: EditorContent;
  private noteTitle: string;
  private wholeDocumentHTML: string;
  private lang: Language;
  private wholeDocumentMarkdown: string;
  private mappedVideos: { website_origin_url: string }[];

  constructor({ currentContent, title, documentKey, lang, mappedVideos }: GlobalExportInstance) {
    this.data = currentContent;
    this.noteTitle = title;
    this.lang = lang;
    this.wholeDocumentHTML = "";
    this.wholeDocumentMarkdown = "";
    this.mappedVideos = mappedVideos;
  }

  getNoteHTML(): string {
    this.wholeDocumentHTML = `<h1>${this.noteTitle ?? ""}</h1>\n`;

    if (this.mappedVideos.length > 0) {
      let referenceVideoText = `${this.lang === "ko" ? "* 영상 출처: " : "*  Reference Video" + (this.mappedVideos.length > 1 ? "s: " : ": ")}`;

      referenceVideoText += `${this.mappedVideos.map((video) => `<a href=${video.website_origin_url} title=${video.website_origin_url}>  ${video.website_origin_url}</a>`).join(", ")}`;

      this.wholeDocumentHTML += `<i>${referenceVideoText}</i>`;
    }

    for (let i = 0; i < this.data.blocks.length; i++) {
      const block = this.data.blocks[i];
      const blockClass = EditorBlockClasses[block.type as EditorBlockType].class as any;

      if (EDITOR_LIST_BLOCK_TYPES.includes(block.type)) {
        //combine all list block items into one array and render them together
        const { list: listContentArray, itemsCount: blocksToSkip } = returnNestedList(this.data, i, block.type);

        this.wholeDocumentHTML += blockClass?.convertToPlainHTML?.(listContentArray);

        //skip to block immediately after all list items
        i += blocksToSkip - 1;
      } else {
        //for general block types (not list blocks)
        if (typeof blockClass.convertToPlainHTML === "function") this.wholeDocumentHTML += blockClass?.convertToPlainHTML?.(block.data);
      }
    }
    return this.wholeDocumentHTML;
  }
}
