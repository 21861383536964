import { Dispatch } from "@reduxjs/toolkit";
import {
  SET_LEGACY_MARKUP_COLOR,
  SET_MARKUP_TOOL,
  SET_MARKUP_COLOR,
  SET_TOOL_SETTING_MENU_TYPE,
  SET_MARKUP_PEN_SIZE,
  SET_MARKUP_HIGHLIGHTER_SIZE,
  SET_MARKUP_PEN_MODE,
  SET_MARKUP_HIGHLIGHTER_MODE,
  SET_ACTIVE_MARKUP_OBJECT,
  SET_SHOW_HIGHLIGHTER_COLOR_GRID,
} from "./actionTypes";
import { MarkupType, MarkupSizeType, DrawingModeType } from "types/editor";

export const setLegacyMarkupColor = (legacyMarkupColor: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_LEGACY_MARKUP_COLOR,
    payload: legacyMarkupColor,
  });
};

export const setMarkupTool = (markupTool: MarkupType) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MARKUP_TOOL,
    payload: markupTool,
  });
};

export const setMarkupColor = (markupColor: { pen: string; highlighter: string; text: string }) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MARKUP_COLOR,
    payload: markupColor,
  });
};

export const setToolSettingMenuType = (toolSettingMenuType: MarkupType.ColorPicker | MarkupType.Pen | MarkupType.Highlighter) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_TOOL_SETTING_MENU_TYPE,
    payload: toolSettingMenuType,
  });
};

export const setMarkupPenSize = (markupPenSize: MarkupSizeType.Medium | MarkupSizeType.Thick | MarkupSizeType.Thin) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MARKUP_PEN_SIZE,
    payload: markupPenSize,
  });
};

export const setMarkupHighlighterSize = (markupHighlighterSize: MarkupSizeType.Medium | MarkupSizeType.Thick | MarkupSizeType.Thin) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MARKUP_HIGHLIGHTER_SIZE,
    payload: markupHighlighterSize,
  });
};

export const setMarkupPenMode = (markupPenMode: DrawingModeType.Free | DrawingModeType.Line) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MARKUP_PEN_MODE,
    payload: markupPenMode,
  });
};

export const setMarkupHighlighterMode = (markupHighlighterMode: DrawingModeType.Free | DrawingModeType.Line) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MARKUP_HIGHLIGHTER_MODE,
    payload: markupHighlighterMode,
  });
};

export const setActiveMarkupObject = (activeMarkupObject: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ACTIVE_MARKUP_OBJECT,
    payload: activeMarkupObject,
  });
};

export const setShowHighlighterColorGrid = (showHighlighterColorGrid: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SHOW_HIGHLIGHTER_COLOR_GRID,
    payload: showHighlighterColorGrid,
  });
};
