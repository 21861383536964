import { trackEvent } from "utils/eventTracking";
import { ModalTypes, ModalProps } from "@slid/slid-ips";
import i18n from "config/i18n/i18n";
import { ShowModalProps } from "./index";
import { eventTypes } from "types/eventTracking";

interface ImageMarkupResetProps extends ShowModalProps {
  handleResetConfirm: () => void;
  focusCanvas: () => void;
  isMobileUI: boolean;
}

interface ImageMarkupCancelProps extends ShowModalProps {
  handleCancelConfirm: () => void;
  focusCanvas: () => void;
  isMobileUI: boolean;
}

const t = i18n.t.bind(i18n);

export const showExportOnPCAlertModal = async ({ showModal, closeModal, history }: ShowModalProps) => {
  const modalProps: ModalProps = {
    type: ModalTypes.modal,
    device: "mobile",
    title: t("ExportOnPCAlertModalTitle", { ns: "Modal" }),
    text: t("ExportOnPCAlertModalText", { ns: "Modal" }),
    primaryButton: {
      text: t("ExportOnPCAlertModalButtonText", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: "Click OKAY in export modal in video note page" });
        closeModal();
      },
    },
  };
  showModal(modalProps);
};

export const showCopyOnPCModal = async ({ showModal, closeModal, history }: ShowModalProps) => {
  const modalProps: ModalProps = {
    type: ModalTypes.modal,
    device: "mobile",
    title: t("CopyOnPCAlertModalTitle", { ns: "Modal" }),
    text: t("CopyOnPCAlertModalText", { ns: "Modal" }),
    primaryButton: {
      text: t("CopyOnPCAlertModalButtonText", { ns: "Modal" }),
      callBack: () => {
        trackEvent({ eventType: "Click OKAY in Copy note modal in video note page" });
        closeModal();
      },
    },
  };
  showModal(modalProps);
};

export const showImageMarkupCancelModal = async ({ showModal, closeModal, handleCancelConfirm, focusCanvas, isMobileUI }: ImageMarkupCancelProps) => {
  const modalProps: ModalProps = {
    type: ModalTypes.modal,
    device: isMobileUI ? "mobile" : "desktop",
    title: t("ImageMarkupCancelModalTitle", { ns: "Modal" }),
    text: t("ImageMarkupCancelModalText", { ns: "Modal" }),
    secondaryButton: {
      text: t("ImageMarkupCancelModalSecondaryButtonText", { ns: "Modal" }),
      callBack: () => {
        closeModal();
        focusCanvas();
      },
    },
    primaryButton: {
      text: t("ImageMarkupCancelModalButtonText", { ns: "Modal" }),
      callBack: () => {
        handleCancelConfirm();
      },
    },
  };
  showModal(modalProps);
};

export const showImageMarkupResetModal = async ({ showModal, closeModal, handleResetConfirm, focusCanvas, isMobileUI }: ImageMarkupResetProps) => {
  const modalProps: ModalProps = {
    type: ModalTypes.modal,
    device: isMobileUI ? "mobile" : "desktop",
    title: t("ImageMarkupResetModalTitle", { ns: "Modal" }),
    text: t("ImageMarkupResetModalText", { ns: "Modal" }),
    secondaryButton: {
      text: t("ImageMarkupResetModalSecondaryButtonText", { ns: "Modal" }),
      callBack: () => {
        closeModal();
        focusCanvas();
      },
    },
    primaryButton: {
      text: t("ImageMarkupResetModalButtonText", { ns: "Modal" }),
      callBack: () => {
        handleResetConfirm();
      },
    },
  };
  showModal(modalProps);
};
