import { useQuery } from "@tanstack/react-query";
import { recentDocuments } from "query-api/search";

export const useRecentDocuments = () => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ["recentDocuments"],
    queryFn: async () => {
      const { data } = await recentDocuments();

      return data;
    },
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, isError };
};
