// auth 2.0
import {
  SET_ACTIVE_BUTTON_TYPE,
  SET_CURRENT_VIDEO_TIME,
  SET_VISIBLE_IMAGE_BLOCKS,
  SET_VISIBLE_GRAB_TEXT_BLOCKS,
  SET_IS_ONBOARDING_VIDEO_PLAYING,
  SET_IS_WAITING_FOR_SIGN_IN,
  SET_INSERT_SLT_BLOCKS_TO_DEMO_EDITOR,
  SET_VISIBLE_SLT_BLOCKS_ON_SLT_VIEW,
  SET_VISIBLE_SLT_BLOCKS_ON_NOTE,
  SET_OVERLAY_MASK_MODE,
} from "./actionTypes";

export const setIsWaitingForSignIn = (isWaitingForSignIn) => (dispatch) => {
  dispatch({
    type: SET_IS_WAITING_FOR_SIGN_IN,
    payload: isWaitingForSignIn,
  });
};

export const playOnboardingVideo = () => (dispatch) => {
  dispatch({
    type: SET_IS_ONBOARDING_VIDEO_PLAYING,
    payload: true,
  });
};

export const stopOnboardingVideo = () => (dispatch) => {
  dispatch({
    type: SET_IS_ONBOARDING_VIDEO_PLAYING,
    payload: false,
  });
};

export const setCurrentVideoTime = (currentVideoTime) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_VIDEO_TIME,
    payload: currentVideoTime,
  });
};

export const setVisibleImageBlocks = (visibleImageBlocks) => (dispatch) => {
  dispatch({
    type: SET_VISIBLE_IMAGE_BLOCKS,
    payload: visibleImageBlocks,
  });
};

export const setVisibleGrabTextBlocks = (visibleGrabTextBlocks) => (dispatch) => {
  dispatch({
    type: SET_VISIBLE_GRAB_TEXT_BLOCKS,
    payload: visibleGrabTextBlocks,
  });
};

export const setActiveButtonType = (activeButtonType) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_BUTTON_TYPE,
    payload: activeButtonType,
  });
};

export const setVisibleSLTBlocksOnSLTView = (visibleSLTBlocksOnSLTView) => (dispatch) => {
  dispatch({
    type: SET_VISIBLE_SLT_BLOCKS_ON_SLT_VIEW,
    payload: visibleSLTBlocksOnSLTView,
  });
};

export const setVisibleSLTBlocksOnNote = (visibleSLTBlocksOnNote) => (dispatch) => {
  dispatch({
    type: SET_VISIBLE_SLT_BLOCKS_ON_NOTE,
    payload: visibleSLTBlocksOnNote,
  });
};

export const setInsertSLTBlocksToDemoEditor = (insertSLTBlocksToDemoEditor) => (dispatch) => {
  dispatch({
    type: SET_INSERT_SLT_BLOCKS_TO_DEMO_EDITOR,
    payload: insertSLTBlocksToDemoEditor,
  });
};

export const setOverlayMaskMode = (overlayMaskMode) => (dispatch) => {
  dispatch({
    type: SET_OVERLAY_MASK_MODE,
    payload: overlayMaskMode,
  });
};
