// auth 2.0

export const intervalPolling = (func, interval, maxAttempts = -1, clearFunc) => {
  let attempts = 0;
  let intervalId = setInterval(async () => {
    if (maxAttempts === attempts) {
      clearInterval(intervalId);
      if (clearFunc) clearFunc();
      return;
    }
    attempts++;
    await func();
  }, interval);
};
