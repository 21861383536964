import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const ColorPickerIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 48} height={height ? height : 48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="10" fill={color} className={"color-picker-fill"} />
      <circle cx="24" cy="24" r="12.5" stroke={color} className={"color-picker-stroke"} />
    </SvgWrapper>
  );
};
