// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const VideoFullScreenIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0C10.4477 0 10 0.447715 10 1C10 1.55228 10.4477 2 11 2H17V8C17 8.55228 17.4477 9 18 9C18.5523 9 19 8.55228 19 8V1C19 0.447715 18.5523 0 18 0H11ZM8 14H2L2 8C2 7.44772 1.55228 7 1 7C0.447715 7 0 7.44772 0 8V15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H8C8.55229 16 9 15.5523 9 15C9 14.4477 8.55229 14 8 14Z"
        fill="#F1F3F5"
      />
    </SvgWrapper>
  );
};
