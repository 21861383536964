/**
 * This file is temporary file of utils.js, but in TypeScript.
 * The reason for creating this file is to prevent the error of "Cannot find module 'app/utils/utils'",
 * by adding some type hints to some fucntions.
 * Currently, it takes time for me to convert all the functions in utils.js to TypeScript.
 * So, I will gradually convert the functions in utils.js to TypeScript.
 * When all the functions in utils.js are converted to TypeScript, this file will
 * be renamed to utils.ts and the original utils.js will be deleted.
 */
import { ColorTypes } from "@slid/slid-ips";
import { UserMembership } from "types/globals";
import { DayPassDataType, PaymentsHistoryDataType } from "types/pricing";

import env from "config/env";
import { isMacOs } from "react-device-detect";
import Firebase from "./firebase/firebase";
import * as Sentry from "@sentry/browser";

/**
 * Format datetime string into specific format
 * @param {*} dateString YYYY-MM-DDThh:mm:ssZ (e.g. 2023-03-17T09:35:53Z)
 * @param {*} formatType "type1"
 */
export const getPrettyDate = (dateString: string | null, formatType: "type1" | "type2", language?: "ko" | "en"): string => {
  // return empty string if dateString is null:
  if (dateString === null) return "-";

  // set default language to English:
  if (!language) language = "en";

  const dateObject = new Date(dateString);

  // get 2-digit stringified values:
  const stringYear = dateObject.getFullYear();
  const stringMonth = ("0" + (dateObject.getMonth() + 1)).slice(-2);
  const stringDate = ("0" + dateObject.getDate()).slice(-2);
  const stringMinutes = ("0" + dateObject.getMinutes()).slice(-2);

  // format hours, into 12-hour format:
  const _hours = dateObject.getHours();
  const isAfternoon = 13 <= _hours;
  const stringHours = ("0" + (_hours - 12 * (isAfternoon ? 1 : 0))).slice(-2);

  switch (formatType) {
    case "type1":
      // type 1 in Korean: 2022/02/09 오전 9:25
      if (language === "ko") {
        return `${stringYear}/${stringMonth}/${stringDate} ${isAfternoon ? "오후" : "오전"} ${stringHours}:${stringMinutes}`;
      }
      // type1 in English: 2022/02/09 9:25 AM
      if (language === "en") {
        return `${stringYear}/${stringMonth}/${stringDate} ${parseInt(stringHours)}:${stringMinutes} ${isAfternoon ? "PM" : "AM"}`;
      }
      break;
    case "type2":
      // type 2 in Korean & English: 2022.02.09
      return `${stringYear}.${stringMonth}.${stringDate}`;

    default:
      break;
  }

  // For safe-failure, return hyphen if the formatType is not supported:
  return "-";
};

/**
 * Returns the color of the sticker for the given user type
 */
export const getUserTypeSticker = (type: keyof typeof UserMembership): ColorTypes => {
  if (type === UserMembership.basic) {
    return ColorTypes.Green;
  }
  if (type === UserMembership.pro) {
    return ColorTypes.Purple;
  }
  if (type === UserMembership.trial) {
    return ColorTypes.Red;
  }
  if (type === UserMembership.daypass) {
    return ColorTypes.Orange;
  }
  if (type === UserMembership.standard) {
    return ColorTypes.Green;
  }
  if (type === UserMembership.premium) {
    return ColorTypes.Purple;
  }
  if (type === UserMembership.mobile_standard) {
    return ColorTypes.Green;
  }
  if (type === UserMembership.mobile_premium) {
    return ColorTypes.Purple;
  }

  // Since color of Sticker cannot be `undefined`, return some value for fallback:
  return ColorTypes.Blue;
};

/**
 * This function returns the current active subscription plan's duration
 */
// TODO: This function requires too many parameters. It should be refactored by adding a Redux Action that can get all the required data at once.
export const getCurrentPaymentPeriodString = (props: { dayPassData: DayPassDataType; paymentsHistoryData: PaymentsHistoryDataType; gracePeriods: any; lang?: "ko" | "en" }): [string, string] => {
  const { dayPassData, paymentsHistoryData, gracePeriods } = props;

  // set default language to English.
  const lang = props.lang || "en";

  // if dayPass is active, return the dayPass duration:
  if (dayPassData.isUsingDayPass) {
    return [getPrettyDate(dayPassData.startedAt, "type1", lang), getPrettyDate(dayPassData.expiresAt, "type1", lang)];
  }

  // otherwise, check if there is any active payment history:
  // paymentsHistoryData is sorted in date-descending order, so the first item is the most recent payment.
  const currentPaymentItem = paymentsHistoryData?.payments?.filter((item) => {
    // filter out dayPass items:
    try {
      const moreInfoData = JSON.parse(item.more_info);
      // if is_day_pass is undefined, it means that they are legacy plan hence cannot be dayPass.
      const isLegacyPlan = moreInfoData.is_day_pass === undefined;
      return isLegacyPlan ? true : !moreInfoData.is_day_pass;
    } catch (error) {
      // if there is a typo on more_info, return true to include the item.
      return true;
    }
  })[0];
  if (!currentPaymentItem) return ["", ""];

  // if there is an active payment history, return the payment duration with grace period applied:
  const totalGracePeriodDays = gracePeriods.reduce((acc: number, item: any) => acc + item.days, 0);
  const startDateString = currentPaymentItem.start_date;
  let endDate = new Date(currentPaymentItem.end_date);
  endDate.setDate(endDate.getDate() + totalGracePeriodDays);
  const endDateString = endDate.toISOString();
  return [getPrettyDate(startDateString, "type1", lang), getPrettyDate(endDateString, "type1", lang)];
};

/**
 * @Description
 * This function waits for the asyncFunction to return true, with a interval and timeout.
 *
 * @arguments
 * asyncFunction : async function that returns true or false
 * interval : interval in milliseconds
 * timeout : (optional) timeout in milliseconds
 * maxLoop : (optional) maximum number of tries.
 *
 * @returns
 * true if the asyncFunction returns true within the timeout, false otherwise.
 */
export const waitWithLoop = async (props: { asyncFunction: Function; interval: number; timeout?: number; maxLoop?: number }): Promise<boolean> => {
  const { asyncFunction, interval } = props;
  let { maxLoop, timeout } = props;
  const makeDelay = () => new Promise((resolve) => setTimeout(resolve, interval));

  const startTime = new Date();
  let count = 0;

  while (true) {
    const isValid = await asyncFunction();

    // if condition is met, return true:
    if (isValid) {
      return true;
    }

    // wait for some interval:
    await makeDelay();

    // if timed-out, return false:
    if (timeout && +new Date() - +startTime > timeout) {
      return false;
    }

    // if maxLoop is reached, return false:
    if (maxLoop && count > maxLoop) {
      return false;
    }

    count += 1;
  }
};

export const getSlidDesktopDownloadLink = async () => {
  const SLID_DOWNLOAD_CDN_URL = env.slid_download_cdn_url;

  // get latest version from the firebase
  const latestVersions = await Firebase.getStableVersions();
  try {
    if (isMacOs) {
      // Mac URL Pattern: https://download.slid.cc/Slid-darwin-x64-0.0.0.zip
      const version = latestVersions && latestVersions["slid_desktop_mac_version"] ? latestVersions["slid_desktop_mac_version"] : "3.1.2";
      return `${SLID_DOWNLOAD_CDN_URL}/Slid-darwin-x64-${version}.zip`;
    }
    if (!isMacOs) {
      // windows URL Pattern: https://download.slid.cc/Slid+Setup+0.0.0.exe
      const version = latestVersions && latestVersions["slid_desktop_windows_version"] ? latestVersions["slid_desktop_windows_version"] : "3.1.2";
      return `${SLID_DOWNLOAD_CDN_URL}/Slid+Setup+${version}.exe`;
    }
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      Sentry.captureMessage("SLID_INVALID_DOWNLOAD_LINK_FETCHED");
    });
  }

  // fallback URL:
  return `${SLID_DOWNLOAD_CDN_URL}/Slid+Setup+3.1.1.exe`;
};

export const convertSecondsToTimerString = (seconds: number): string => {
  const _hours = Math.floor(seconds / 3600);
  const _minutes = Math.floor((seconds % 3600) / 60);
  const _seconds = seconds % 60;

  const timeStr = `${_hours.toString().padStart(2, "0")}:${_minutes.toString().padStart(2, "0")}:${_seconds.toString().padStart(2, "0")}`;

  return timeStr;
};

/**
 *
 * @param images
 * @returns true if all the images are loaded, false otherwise.
 */
export const waitImagesToLoad = async (images: string[], timeout: number = Infinity): Promise<boolean> => {
  const loadImage = async (image: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(image);
      img.onerror = () => reject();
      img.src = image;
    });
  };
  const imagePromises = images.map((image) => loadImage(image));

  const timeoutPromise = new Promise((_, reject) => {
    if (timeout !== Infinity) {
      const id = setTimeout(() => {
        clearTimeout(id);
        reject();
      }, timeout);
    }
  });

  try {
    await Promise.race([Promise.all(imagePromises), timeoutPromise]);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const isJEIUUser = (email: string) => {
  if (typeof email !== "string") return false;

  if (email.length !== 17) return false;
  if (!email.startsWith("jeiu_")) return false;
  if (!email.endsWith("@slid.cc")) return false;

  return true;
};
