import { useAppSelector } from "hooks";
import { useHistory } from "react-router-dom";
import { getCurrentDocumentKey, isPreviousBlockEmpty } from "utils/editor/util";

export const useSmartLiveText = () => {
  const { editorInstance } = useAppSelector((state) => state.vdocs);
  const history = useHistory();
  const editorLastActiveBlockPosition = useAppSelector((state) => state.vdocs.editorLastActiveBlockPosition);

  const insertTranscriptTextToEditor = async (sttBlock) => {
    const insertTranscriptText = () => {
      const { blocks, caret } = editorInstance;
      const { getCurrentBlockIndex, insert: insertBlock, delete: deleteBlock } = blocks;
      const { setToBlock: setCaretToBlock } = caret;

      const insertIndex = editorInstance.blocks.getCurrentBlockIndex() === -1 ? editorLastActiveBlockPosition + 1 : undefined;

      setTimeout(() => {
        insertBlock(
          "paragraph",
          {
            text: sttBlock.text,
          },
          undefined,
          insertIndex,
          true
        );
        // delete previous block if it's empty
        const previousBlockIndex = getCurrentBlockIndex() - 1;

        if (isPreviousBlockEmpty(previousBlockIndex)) {
          try {
            deleteBlock(previousBlockIndex);
          } catch (e) {
            // when focus is lost,
            // Uncaught (in promise) TypeError: Cannot read property 'lastInput' of undefined
            // will happen.
            // ignore this for now. it's editor js error.
            // https://github.com/codex-team/editor.js/pull/1218
          }
        }
        insertBlock();
        setCaretToBlock(getCurrentBlockIndex());
      });
    };

    let currentDocumentKey = await getCurrentDocumentKey();
    if (!currentDocumentKey) return false;
    history.replace(`./${currentDocumentKey}`);
    insertTranscriptText();
    return true;
  };

  return {
    insertTranscriptTextToEditor,
  };
};
