import React, { memo } from "react";
import styled from "styled-components";
import { Typography13, Icon } from "@slid/slid-ips";
import { useAppDispatch } from "hooks";
import { trackEvent } from "utils/eventTracking";
import { getEventLocationMode } from "utils/utils";

interface ExampleQuestionBoxProps {
  type: string;
  question: string;
  sendChatMessage: (message: string) => void;
}

const ExampleQuestionBox = memo(({ type, question, sendChatMessage }: ExampleQuestionBoxProps) => {
  const [isButtonHovered, setIsButtonHovered] = React.useState(false);

  return (
    <ExampleQuestion
      onClick={() => {
        trackEvent({
          eventType: "Click EXAMPLE CHAT in AI Sliddy modal",
          eventProperties: {
            from: getEventLocationMode(),
          },
        });
        sendChatMessage(question);
      }}
      onMouseEnter={() => setIsButtonHovered(true)}
      onMouseLeave={() => setIsButtonHovered(false)}
    >
      <TextWrapper>
        <Typography13 text={type} color={`--gray15`} weight={700} />
        <Typography13 text={question} color={`--gray15`} marginTop={`6px`} />
      </TextWrapper>
      <ChevronIcon icon={`chevronRight16`} width={24} height={24} color={isButtonHovered ? `--blue6` : `--blue4`} />
    </ExampleQuestion>
  );
});

const ExampleQuestion = styled.div`
  background-color: var(--blue1);
  border: 1px solid var(--blue2);
  padding: 11px 8px 11px 20px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  &:hover {
    background-color: var(--blue4);
    border: 1px solid var(--blue6);
  }

  &:active {
    background-color: var(--blue2);
    border: 1px solid var(--blue4);
  }
`;

const TextWrapper = styled.div``;

const ChevronIcon = styled(Icon)`
  flex-shrink: 0;
`;

export default ExampleQuestionBox;
