import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppSelector } from "hooks";
import SearchBoxSearchInput from "./SearchBoxSearchInput";
import SearchBoxPlaceholder from "./placeholders/SearchBoxPlaceholder";
import SearchBoxResultsView from "./SearchBoxResultsView";
import SearchBoxNoResultPlaceholder from "./placeholders/SearchBoxNoResultPlaceholder";
import { useDocumentSearch, useFolderSearch } from "hooks/queries";
import LoadMoreContainer from "components/loaders/LoadingComponent";

const SearchBox = () => {
  const { searchKeywords } = useAppSelector((state) => state.search);
  const { data: noteData, isLoading: isLoadingNotes } = useDocumentSearch({ keyword: searchKeywords });
  const { data: folderData, isLoading: isLoadingFolders } = useFolderSearch({ keyword: searchKeywords });

  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    // show loading component if all 3 queries are loading
    if (isLoadingNotes && isLoadingFolders) {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [isLoadingNotes, isLoadingFolders]);

  const renderSearchBoxContent = () => {
    /** Render different content based on the following conditions:
     * 1. There is no search keyword: default placeholder
     * 2. There is search keyword: search result list
     */
    if (searchKeywords === "") {
      return <SearchBoxPlaceholder />;
    } else if (showLoading) {
      return <LoadMoreContainer />;
    } else if ((noteData && noteData?.number_of_results > 0) || (folderData && folderData?.number_of_results > 0)) {
      return <SearchBoxResultsView />;
    } else {
      return <SearchBoxNoResultPlaceholder />;
    }
  };

  return (
    <Wrapper>
      <SearchBoxSearchInput />
      <SearchContentWrapper>{renderSearchBoxContent()}</SearchContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 880px;

  background: var(--white);
  overflow: hidden;
  border-radius: 12px;
`;

const SearchContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export default SearchBox;
