import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

interface markupCanvasRectProps extends SvgIconProps {
  size: "thin" | "medium" | "thick";
}
const SvgWrapper = styled(Icon)``;

const thicknessMap = {
  thick: { r: 8 },
  medium: { r: 5 },
  thin: { r: 2 },
};

export const MarkupSizeIcon = ({ width, height, color, size }: markupCanvasRectProps) => {
  const { r } = thicknessMap[size];

  return (
    <SvgWrapper width={width ? width : 40} height={height ? height : 40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle cx="20" cy="20" r={r} fill={color} />
      </g>
    </SvgWrapper>
  );
};
