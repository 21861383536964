/**
 * Build styles
 */
const Picker = require("./picker");
require("./index.css").toString();

/**
 * Text Color Tool for Editor.js
 */
export default class Color {
  /**
   * @param {{api: object}}  - Editor.js API
   */
  constructor({ config, api }) {
    this.api = api;
    this.config = config;
    this.clickedOnLeft = false;
    this.pluginType = this.config.type || "text";
    this.parentTag = this.pluginType === "marker" ? "MARK" : "FONT";
    this.hasCustomPicker = this.config.customPicker || false;
    this.color = "";
    this.picker = null;
    this.icon = null;

    /**
     * Toolbar Button
     *
     * @type {HTMLElement|null}
     */
    this.button = null;

    /**
     * CSS classes
     */
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive,
    };
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @return {boolean}
   */
  static get isInline() {
    return true;
  }

  /**
   * Create button element for Toolbar
   *
   * @return {HTMLElement}
   */
  render() {
    this.button = document.createElement("button");
    this.button.type = "button";
    this.button.classList.add("colorPlugin");
    this.button.classList.add(this.iconClasses.base);
    // this.button.appendChild(this.createLeftButton());
    this.button.appendChild(this.createRightButton(this));

    return this.button;
  }

  /**
   * Create left part button
   *
   * @return {HTMLElement}
   */
  createLeftButton() {
    if (!this.icon) {
      this.icon = document.createElement("div");
      this.icon.id = "color-left-btn";
      this.icon.appendChild(this.createButtonIcon());
      this.icon.addEventListener("click", () => (this.clickedOnLeft = true));
    }

    return this.icon;
  }

  /**
   * Create button icon
   *
   * @return {HTMLElement}
   */
  createButtonIcon() {
    const buttonIcon = document.createElement("div");
    buttonIcon.id = "color-btn-text";
    const defaultIcon = "";
    buttonIcon.innerHTML = this.config.icon || defaultIcon;
    return buttonIcon;
  }

  /**
   * Create right part button
   *
   * @return {HTMLElement}
   */
  createRightButton(sharedScope) {
    if (!this.picker) {
      this.picker = new Picker.ColorPlugin({
        onColorPicked: function (value) {
          sharedScope.color = value;
        },
        hasCustomPicker: this.hasCustomPicker,
        defaultColor: this.config.defaultColor,
        colorCollections: this.config.colorCollections,
        type: this.pluginType,
      });
    }

    return this.picker;
  }

  /**
   * handle selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround(range) {
    // TODO: as document.execCommand is deprecated, remove this and use unwrap & wrap instead
    if (this.pluginType === "marker") {
      document.execCommand("backColor", false, this.color === "rgb(255, 255, 255)" ? "transparent" : this.color);
    } else {
      document.execCommand("foreColor", false, this.color);
    }
  }

  /**
   * Wrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  wrap(range) {
    const selectedText = range.extractContents();
    const newWrapper = document.createElement(this.parentTag);

    newWrapper.appendChild(selectedText);
    range.insertNode(newWrapper);

    if (this.pluginType === "marker") {
      this.wrapMarker(newWrapper);
    } else {
      this.wrapTextColor(newWrapper);
    }

    this.api.selection.expandToTag(newWrapper);
  }

  /**
   * Wrap selected marker fragment
   *
   * @param newWrapper - wrapper for selected fragment
   */
  wrapMarker(newWrapper) {
    newWrapper.color = this.color;
    newWrapper.style.padding = "3px 0";
    const colorWrapper = this.api.selection.findParentTag("FONT");
    if (colorWrapper) newWrapper.style.color = colorWrapper.style.color;
  }

  /**
   * Wrap selected text color fragment
   *
   * @param {Range} newWrapper - wrapper for selected fragment
   */
  wrapTextColor(newWrapper) {
    newWrapper.color = this.color;
  }

  /**
   * Unwrap selected fragment
   *
   * @param {Range} termWrapper - parent of selected fragment
   */
  unwrap(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);

    const sel = window.getSelection();
    const range = sel.getRangeAt(0);

    const unwrappedContent = range.extractContents();

    /**
     * Remove empty term-tag
     */
    if (this.clickedOnLeft) {
      this.removeWrapper(termWrapper);
    } else {
      this.updateWrapper(termWrapper);
    }

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    sel.removeAllRanges();
    sel.addRange(range);
  }

  /**
   * update color without create a new tag
   *
   * @param {Range} termWrapper - parent of selected fragment
   */
  updateWrapper(termWrapper) {
    if (this.pluginType === "marker") {
      termWrapper.style.backgroundColor = this.color;
    } else {
      termWrapper.style.color = this.color;
    }
    termWrapper.setAttribute("color", this.color);
  }

  /**
   * remove wrapper
   *
   * @param {Range} termWrapper - parent of selected fragment
   */
  removeWrapper(termWrapper) {
    termWrapper.parentNode.removeChild(termWrapper);
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState() {
    const parentTag = window.getSelection().focusNode.parentNode;
    let textColor = parentTag.tagName === "FONT" ? parentTag.attributes.color.value : "";
    let markColor = parentTag.parentNode.tagName === "SPAN" ? parentTag.parentNode.style.backgroundColor : parentTag.style.backgroundColor;

    document.documentElement.style.setProperty("--colorPickerIconColor", textColor);
    const colorButtonLists = document.getElementsByTagName("xy-color-picker");
    if (colorButtonLists.length !== 2) return;

    const markColorButtonsList = document.getElementsByTagName("xy-color-picker")[0].shadowRoot.getElementById("colors").childNodes;
    const textColorButtonsList = document.getElementsByTagName("xy-color-picker")[1].shadowRoot.getElementById("colors").childNodes;
    textColorButtonsList.forEach((el) => {
      if (!textColor && el.dataset?.color === "#212529") {
        el.id = "text-picked-color-btn";
      } else if (el.dataset?.color === textColor?.toUpperCase()) {
        el.id = "text-picked-color-btn";
      } else if (el.id) {
        el.removeAttribute("id");
      }
    });
    markColorButtonsList.forEach((el) => {
      if (!markColor && el.dataset?.color === "rgb(255, 255, 255)") {
        el.id = "marker-picked-color-btn";
      } else if (el.dataset?.color === markColor) {
        el.id = "marker-picked-color-btn";
      } else if (el.id) {
        el.removeAttribute("id");
      }
    });
  }

  /**
   * handle icon active state for legacy wrappers
   */
  handleLegacyWrapper(legacyWrapper, termTag) {
    return this.pluginType === "marker" ? legacyWrapper : termTag & legacyWrapper;
  }

  /**
   * Sanitizer rule
   * @return {{color: {class: string}}}
   */
  static get sanitize() {
    return {
      font: true,
      span: true,
      mark: true,
      u: true,
      i: true,
      b: true,
      a: true,
      code: true,
    };
  }

  clear() {
    this.picker = null;
    this.icon = null;
  }
}
