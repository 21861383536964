import { Typography13, Icon } from "@slid/slid-ips";
import { trackEvent } from "utils/eventTracking";
import { getEventLocationMode } from "utils/utils";
import React, { useRef, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";

const MIN_TEXTAREA_HEIGHT = 20;

const SliddyInputSection = memo(({ chatError, isLoading, onSendChatMessage, onStopGeneration }: any) => {
  const { t } = useTranslation("SlidGPT");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [userTextInput, setUserTextInput] = useState("");

  React.useLayoutEffect(() => {
    if (!textareaRef.current) return;
    // Reset height
    textareaRef.current.style.height = `${MIN_TEXTAREA_HEIGHT}px`;
    // Set height
    textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, MIN_TEXTAREA_HEIGHT)}px`;
  }, [userTextInput]);

  const handleMessageSubmit = () => {
    if (isLoading) return;
    trackEvent({
      eventType: "Add CHAT MESSAGE in AI Sliddy modal",
      eventProperties: {
        from: getEventLocationMode(),
      },
    });
    if (userTextInput.trim().length > 0) onSendChatMessage(userTextInput);
    setUserTextInput("");
    return;
  };

  const handleInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    if (isLoading) return;
    e.preventDefault();
    setUserTextInput(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const cmdPressed = e.ctrlKey || e.metaKey;

    if (e.key === "Enter" && !e.shiftKey) {
      e?.preventDefault();
      e?.stopPropagation();
      e?.nativeEvent.stopImmediatePropagation();
      handleMessageSubmit();
    }

    if (e.key === "z" && cmdPressed) {
      const textArea = e.target as HTMLTextAreaElement;
      const caretPos = textArea.selectionStart;
      if (caretPos === 0) e.preventDefault();
      // to prevent undo on editor
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
    }
  };

  return (
    <TextareaSection isError={!!chatError}>
      {!chatError ? (
        <>
          <TextareaElement ref={textareaRef} value={userTextInput} placeholder={t("Placeholder")} onInput={handleInputChange} onKeyDown={handleKeyDown} autoFocus />
          <TextareaButtonElement
            type="submit"
            disabled={isLoading || userTextInput.length <= 0}
            isActivated={userTextInput.length > 0}
            onClick={(e) => {
              e.preventDefault();
              handleMessageSubmit();
            }}
          >
            <Icon icon={`arrowUp24`} color={isLoading || userTextInput.length <= 0 ? `--gray5` : `--white`} />
          </TextareaButtonElement>
        </>
      ) : (
        //TODO: for invalid input size type, let them try again
        <ErrorWrapper>
          <Icon icon={`shortcut20`} color={`--red7`} />
          <Typography13 text={t("Error")} color={`--red7`} />
        </ErrorWrapper>
      )}
    </TextareaSection>
  );
});

const TextareaSection = styled.div<{ isError: boolean }>`
  padding: 12px 20px 16px;
  width: 100%;
  background-color: ${({ isError }) => (isError ? `var(--red1)` : `var(--white)`)};
  border-radius: 0 0 12px 12px;
  border-top: 1px solid var(--gray3);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 4px;
`;

const TextareaElement = styled.textarea`
  /* S of reset input */
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline-style: none;
  padding: 0;
  border: none;
  resize: none;
  /* E of reset input */
  width: 92%;
  overflow-y: auto;

  /* IPS - Typography13 */
  font-size: 1.3rem;

  color: var(--gray17);
  /* To show 4 lines maximum */
  min-height: 20px;
  max-height: 80px;

  &::placeholder {
    color: var(--gray7);
  }
`;

const TextareaButtonElement = styled.button<{ isActivated?: boolean }>`
  /* S of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  padding: 0;

  &:focus {
    outline: 0;
    border: none;
  }
  /* E of reset button */

  border-radius: 50%;
  ${({ isActivated }) =>
    isActivated &&
    css`
      background: var(--blue7);

      &:hover {
        background: var(--blue9);
      }
    `}

  &:disabled {
    background: unset;
  }
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  width: 100%;
`;

export default SliddyInputSection;
