import React, { memo } from "react";
import { Typography13, Icon } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SlidFeatures } from "utils/privilegeManager";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";

interface NoteListViewItemOptionMenuProps {
  onMoveToFolder?: (documentKey: string) => void;
  onRemoveDocument?: (e: any) => void;
  onRenameDocument: (e: any) => void;
  optionPositionValue: number;
  documentKey: string;
}
const NoteListViewItemOptionMenu = memo(({ onMoveToFolder, onRemoveDocument, onRenameDocument, optionPositionValue, documentKey }: NoteListViewItemOptionMenuProps) => {
  const { t } = useTranslation("MyNotes");
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();

  return (
    <OptionMenuContainer optionPositionValue={optionPositionValue}>
      <OptionItemList>
        {onMoveToFolder && (
          <OptionItem
            onClick={() => {
              if (!confirmPrivilege(SlidFeatures.noteMove)) return showInsufficientPrivilegeModal();

              onMoveToFolder(documentKey);
            }}
          >
            <Icon icon={`folder20`} width={20} height={20} color="--gray9" />
            <OptionTitle weight={400} color="--gray15" text={t("MoveToFolderOption")} />
          </OptionItem>
        )}

        <OptionItem onClick={(e) => onRenameDocument(e)}>
          <Icon icon={`rename20`} width={20} height={20} color="--gray9" />
          <OptionTitle weight={400} color="--gray15" text={t("RenameOption")} />
        </OptionItem>

        {onRemoveDocument && (
          <OptionItem onClick={(e) => onRemoveDocument(e)}>
            <Icon icon={`delete20`} width={20} height={20} color="--gray9" />
            <OptionTitle weight={400} color="--gray15" text={t("DeleteOption")} />
          </OptionItem>
        )}
      </OptionItemList>
    </OptionMenuContainer>
  );
});

const OptionMenuContainer = styled.div<{ optionPositionValue: number }>`
  position: absolute;
  left: 20px;
  top: ${({ optionPositionValue }) => optionPositionValue + 30}px;
  width: 220px;
  background: #fff;
  padding: 8px 0px;
  border: 1px solid var(--gray3);
  box-sizing: border-box;
  border-radius: 8px;
  filter: drop-shadow(var(--boxShadow2));
  margin-right: 12px;
  z-index: 2;
`;

const OptionItemList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
`;

const OptionItem = styled.li`
  display: flex;
  align-items: center;
  padding: 8px 18px;
  cursor: pointer;
  :hover {
    background: var(--gray3);
  }
  :active {
    background: var(--gray2);
  }
`;

const OptionTitle = styled(Typography13)`
  && {
    margin-left: 4px;
  }
`;

export default NoteListViewItemOptionMenu;
