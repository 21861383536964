// auth 2.0

import {
  SET_LANG,
  SET_IS_AUTHENTICATED,
  SET_USER_DATA,
  SET_APPLICATION_TYPE,
  SET_EXTENSION_VERSION,
  SET_DESKTOP_VERSION,
  SET_ERROR_MESSAGE,
  SET_SCREEN_ORIENTATION,
  SET_DEVICE_TYPE,
  FETCH_NOTIFICATIONS,
  SET_IS_EXTENSION_INSTALLED,
  SET_NOTIFICATION_DATA,
  SET_EXTENSION_ID,
  SET_IS_NEW_USER,
  SET_GRACE_PERIODS,
  SET_IS_ONBOARDING_NEEDED_USER,
  SET_USER_TRIAL_DATA,
  SET_HACKLE_EXPERIMENT_KEYS,
  SET_PENDING_TRACK_EVENT,
  CLEAR_PENDING_TRACK_EVENTS,
  SET_USER_STUDY_CHALLENGE_DATA,
  SET_IS_SIGNING_UP,
  SET_IS_SIGNING_IN,
} from "redux/actions/actionTypes";
import { SlidUser } from "types/globals";
interface State {
  lang: string;
  isAuthenticated: boolean;
  userData: SlidUser | null;
  applicationType: string | null;
  extensionVersion: {
    version: string | null;
  };
  desktopVersion: string;
  errorMessage: string | null;
  screenOrientation: string;
  deviceType: string | null;
  notifications: any;
  isExtensionInstalled: boolean | null;
  notificationData: any;
  extensionId: string | null;
  isNewUser: boolean | null;
  isOnboardingNeededUser: boolean | null;
  gracePeriods: any[];
  userTrialData: any;
  userStudyChallengeData: any;
  hackleExperimentKeys: any;
  pendingTrackEvents: any[];
  isSigningIn: boolean;
  isSigningUp: boolean;
}

const initialState: State = {
  lang: "en",
  isAuthenticated: false, // replaced isCognitoUser
  userData: null,
  applicationType: null, // web, extension, desktop
  extensionVersion: {
    version: null,
  },
  desktopVersion: "2.5.4",
  errorMessage: null,
  screenOrientation: "horizontal",
  deviceType: null, // "PC", "MobileOr"
  // What is our product?
  // 1. Chrome extension -> javaScrip
  // 2. Desktop App -> ??
  // 3. Andriod/iOS -> maybe exposcreen-capture
  notifications: null,
  isExtensionInstalled: null,
  notificationData: null,
  extensionId: null,
  isNewUser: false,
  isOnboardingNeededUser: null,
  gracePeriods: [],
  userTrialData: null,
  userStudyChallengeData: null,
  hackleExperimentKeys: {},
  pendingTrackEvents: [],
  isSigningIn: false,
  isSigningUp: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case SET_APPLICATION_TYPE:
      return {
        ...state,
        applicationType: action.payload,
      };
    case SET_EXTENSION_VERSION:
      return {
        ...state,
        extensionVersion: action.payload,
      };
    case SET_DESKTOP_VERSION:
      return {
        ...state,
        desktopVersion: action.payload,
      };
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SET_SCREEN_ORIENTATION:
      return {
        ...state,
        screenOrientation: action.payload,
      };
    case SET_DEVICE_TYPE:
      return {
        ...state,
        deviceType: action.payload,
      };
    case SET_IS_EXTENSION_INSTALLED:
      return {
        ...state,
        isExtensionInstalled: action.payload,
      };
    case SET_NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: action.payload,
      };
    case SET_EXTENSION_ID:
      return {
        ...state,
        extensionId: action.payload,
      };
    case SET_IS_NEW_USER:
      return {
        ...state,
        isNewUser: action.payload,
      };
    case SET_GRACE_PERIODS:
      return {
        ...state,
        gracePeriods: action.payload,
      };
    case SET_IS_ONBOARDING_NEEDED_USER:
      return {
        ...state,
        isOnboardingNeededUser: action.payload,
      };
    case SET_USER_TRIAL_DATA:
      return {
        ...state,
        userTrialData: action.payload,
      };
    case SET_HACKLE_EXPERIMENT_KEYS:
      return {
        ...state,
        hackleExperimentKeys: action.payload,
      };
    case SET_PENDING_TRACK_EVENT:
      return {
        ...state,
        pendingTrackEvents: [...state.pendingTrackEvents, action.payload],
      };
    case CLEAR_PENDING_TRACK_EVENTS:
      return {
        ...state,
        pendingTrackEvents: [],
      };
    case SET_USER_STUDY_CHALLENGE_DATA:
      return {
        ...state,
        userStudyChallengeData: action.payload,
      };
    case SET_IS_SIGNING_UP:
      return {
        ...state,
        isSigningUp: action.payload,
      };
    case SET_IS_SIGNING_IN:
      return {
        ...state,
        isSigningIn: action.payload,
      };
    default:
      return state;
  }
};
