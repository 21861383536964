import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalTypes, CustomModalProps, Typography16, Icon, Button, Typography28, Typography18, Typography20, Typography12, Typography9, Typography14 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { UserStudyChallengeDataType, UserStudyChallengeMarkerUIItemProps } from "types/studyChallenge";
import { useAppSelector } from "hooks";
import { convertSecondsToTimerString } from "../utils-ts";
import { redirectToMyNotes } from "../utils";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "../eventTracking";

interface showStudyChallengeModalProps {
  showModal: (props: CustomModalProps) => void;
  closeModal: (props: CustomModalProps) => void;
  onDimClick: () => void;
}

const showStudyChallengeCompleteModal = async (props: showStudyChallengeModalProps) => {
  const { showModal, closeModal, onDimClick } = props;

  const modalProps: CustomModalProps = {
    type: ModalTypes.customModal,
    closableDim: true,
    // @ts-ignore
    onDimClick: onDimClick,
    customComponent: <StudyChallengeModalContainer closeModal={closeModal} />,
  };
  showModal(modalProps);
};

interface StudyChallengeModalContainerProps {
  closeModal: (props: CustomModalProps) => void;
}

const StudyChallengeModalContainer = (props: StudyChallengeModalContainerProps) => {
  let { userStudyChallengeData }: { userStudyChallengeData: UserStudyChallengeDataType | null } = useAppSelector((state) => state.slidGlobal);
  const { iframePort } = useAppSelector((state) => state.vdocs);
  const history = useHistory();
  const { closeModal } = props;
  const { t } = useTranslation("StudyChallenge");

  const [todayStudyTime, setTodayStudyTime] = useState<string>("");
  const [totalStudyTime, setTotalStudyTime] = useState<string>("");

  useEffect(() => {
    if (userStudyChallengeData === null || userStudyChallengeData.currentProgressMarker === null) return;
    setTodayStudyTime(convertSecondsToTimerString(userStudyChallengeData.currentProgressMarker.totalSeconds));
    setTotalStudyTime(convertSecondsToTimerString(userStudyChallengeData.progressMarkers.reduce((prev, next) => prev + next.totalSeconds, 0)));
  }, []);

  if (userStudyChallengeData === null || userStudyChallengeData.currentProgressMarker === null) return null;

  const today = new Date();
  today.setTime(0);
  const quotes = t("Quotes", { keySeparator: ".", returnObjects: true });
  const quoteData = quotes[+today % quotes.length];

  return (
    <Container>
      <ImageArea>
        <Image src={"/src/assets/study_challenge/complete_quote.webp"} />
        <FloatingTextArea>
          <LabelContainer>
            <Typography12 color="--blue4" weight={700} text={t("CompleteModal.QuoteLabelText", { keySeparator: "." })} />
          </LabelContainer>
          {/* @ts-ignore */}
          <Typography20 marginTop={"16px"} color="--white" weight={700} text={quoteData.text} />
          {/* @ts-ignore */}
          <Typography12 color="--gray8" marginTop={"16px"} weight={700} text={quoteData.author} />
        </FloatingTextArea>
      </ImageArea>
      <InnerContainer>
        <CheckItemArea>
          <CheckItemRow>
            {userStudyChallengeData.progressMarkers.slice(0, 7).map((item: any, index: number) => {
              if (userStudyChallengeData === null) return null;
              return <CheckItem key={index} day={item.challengeDay} status={item.status} />;
            })}
          </CheckItemRow>
          <CheckItemRow>
            {userStudyChallengeData.progressMarkers.slice(7, 14).map((item: any, index: number) => {
              if (userStudyChallengeData === null) return null;
              return <CheckItem key={index} day={item.challengeDay} status={item.status} />;
            })}
          </CheckItemRow>
        </CheckItemArea>
        <TimeSummaryArea>
          <TimeSummaryRow>
            <Typography18 weight={700} text={t("CompleteModal.DailyTotalTimeLabel", { keySeparator: "." })} color="--gray10" style={{ flex: 1, flexShrink: 0 }} />
            <Typography20 weight={700} text={todayStudyTime} color="--blue11" />
          </TimeSummaryRow>
          <TimeSummaryRow>
            <Typography18 weight={700} text={t("CompleteModal.TotalTimeLabel", { keySeparator: "." })} color="--gray10" style={{ flex: 1, flexShrink: 0 }} />
            <Typography20 weight={700} text={totalStudyTime} color="--blue11" />
          </TimeSummaryRow>
        </TimeSummaryArea>
        <ButtonArea>
          <Button
            callback={(props: any) => {
              trackEvent({
                eventType: eventTypes.click.CONTINUE_IN_STUDY_CHALLENGE_DAILY_MODAL,
              });
              closeModal(props);
            }}
            appearance="line"
            size="large"
            text={t("CompleteModal.SecondaryButtonText", { keySeparator: "." })}
            color="--gray16"
          />
          <Button
            callback={() => {
              trackEvent({
                eventType: eventTypes.click.MY_NOTES_IN_STUDY_CHALLENGE_DAILY_MODAL,
              });
              redirectToMyNotes({ history, iframePort });
            }}
            appearance="primary"
            size="large"
            text={t("CompleteModal.PrimaryButtonText", { keySeparator: "." })}
            color="--gray16"
          />
        </ButtonArea>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 400px;
`;

const ImageArea = styled.div`
  width: 400px;
`;

const FloatingTextArea = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 29px 24px 0px;
`;

const LabelContainer = styled.div`
  background-color: var(--blue15);
  padding: 4px 10px 6px;
  border-radius: 26px;
  display: inline-block;
`;

const Image = styled.img`
  width: 400px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const InnerContainer = styled.div`
  padding: 0px 34px 30px;
  background-color: var(--white);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const TimeSummaryArea = styled.div`
  margin-top: 20px;
`;

const TimeSummaryRow = styled.div`
  display: flex;
  margin-top: 9px;
`;

const ButtonArea = styled.div`
  margin-top: 21px;
  display: flex;
  gap: 12px;
`;

const CheckItemArea = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CheckItemRow = styled.div`
  margin-top: 11px;
  display: flex;
  gap: 22px;
  padding: 0 19px;
`;

const CheckItem = (props: UserStudyChallengeMarkerUIItemProps) => {
  const { day, status } = props;

  let imageSource;
  let backgroundColor;
  let color;
  switch (status) {
    case "PASSED":
      imageSource = "/src/assets/study_challenge/daytracker_passed.webp";
      backgroundColor = "--blue7";
      color = "--blue8";
      break;
    case "FAILED":
      imageSource = "/src/assets/study_challenge/daytracker_failed.webp";
      backgroundColor = "--green1_cloudy";
      color = "--green1_cloudy";
      break;
    default:
      imageSource = undefined;
      backgroundColor = "--gray3";
      color = "--gray10";
      break;
  }

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const IconContainer = styled.div<{ backgroundColor: string }>`
    margin-top: 2px;
    width: 28px;
    height: 28px;
    background-color: ${(props: { backgroundColor: string }) => `var(${props.backgroundColor})`};
    border-radius: 50%;
    position: relative;
  `;

  const Icon = styled.img`
    width: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
  `;

  return (
    <Container>
      <Typography14 weight={700} color={color as any} text={`${day}`} />
      <IconContainer backgroundColor={backgroundColor}>{imageSource && <Icon src={imageSource} />}</IconContainer>
    </Container>
  );
};
export default showStudyChallengeCompleteModal;
