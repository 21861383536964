//auth 2.0
export const svgPlusButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
    <path d="M8.02 2.48a.9.9 0 01.9.9l-.001 3.74h3.701a.9.9 0 010 1.8l-3.701-.001.001 3.701a.9.9 0 01-1.8 0V8.919l-3.74.001a.9.9 0 010-1.8h3.74V3.38a.9.9 0 01.9-.9z" />
  </svg>`;
export const newToLeftIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 8C8.5 5.51472 10.5147 3.5 13 3.5H15.5C15.7761 3.5 16 3.27614 16 3C16 2.72386 15.7761 2.5 15.5 2.5H13C9.96243 2.5 7.5 4.96243 7.5 8V15.7929L4.35355 12.6464C4.15829 12.4512 3.84171 12.4512 3.64645 12.6464C3.45118 12.8417 3.45118 13.1583 3.64645 13.3536L7.64645 17.3536C7.69439 17.4015 7.74964 17.4377 7.80861 17.4621C7.86669 17.4861 7.9303 17.4996 7.997 17.5C7.998 17.5 7.999 17.5 8 17.5C8.001 17.5 8.002 17.5 8.003 17.5C8.12996 17.4992 8.25669 17.4504 8.35355 17.3536L12.3536 13.3536C12.5488 13.1583 12.5488 12.8417 12.3536 12.6464C12.1583 12.4512 11.8417 12.4512 11.6464 12.6464L8.5 15.7929V8Z" fill="#868E96"/>
</svg>
`;

export const newToRightIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 8C11.5 5.51472 9.48528 3.5 7 3.5H4.5C4.22386 3.5 4 3.27614 4 3C4 2.72386 4.22386 2.5 4.5 2.5H7C10.0376 2.5 12.5 4.96243 12.5 8V15.7929L15.6464 12.6464C15.8417 12.4512 16.1583 12.4512 16.3536 12.6464C16.5488 12.8417 16.5488 13.1583 16.3536 13.3536L12.3536 17.3536C12.3056 17.4015 12.2504 17.4377 12.1914 17.4621C12.1333 17.4861 12.0697 17.4996 12.003 17.5C12.002 17.5 12.001 17.5 12 17.5C11.999 17.5 11.998 17.5 11.997 17.5C11.87 17.4992 11.7433 17.4504 11.6464 17.3536L7.64645 13.3536C7.45118 13.1583 7.45118 12.8417 7.64645 12.6464C7.84171 12.4512 8.15829 12.4512 8.35355 12.6464L11.5 15.7929V8Z" fill="#868E96"/>
</svg>
`;

export const newToUpIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 8.5C5.51472 8.5 3.5 10.5147 3.5 13L3.5 15.5C3.5 15.7761 3.27614 16 3 16C2.72386 16 2.5 15.7761 2.5 15.5L2.5 13C2.5 9.96243 4.96243 7.5 8 7.5L15.7929 7.5L12.6464 4.35355C12.4512 4.15829 12.4512 3.84171 12.6464 3.64645C12.8417 3.45118 13.1583 3.45118 13.3535 3.64645L17.3509 7.64385C17.3607 7.65343 17.37 7.6634 17.3789 7.67373C17.4096 7.70931 17.4344 7.74817 17.4535 7.78909C17.4833 7.85317 17.5 7.92464 17.5 8C17.5 8.07513 17.4834 8.14638 17.4537 8.21031C17.4343 8.25237 17.4086 8.29228 17.3768 8.32867C17.3686 8.33811 17.36 8.34724 17.3511 8.35603L13.3535 12.3536C13.1583 12.5488 12.8417 12.5488 12.6464 12.3536C12.4512 12.1583 12.4512 11.8417 12.6464 11.6464L15.7929 8.5L8 8.5Z" fill="#868E96"/>
</svg>
`;

export const newToDownIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 11.5C5.51472 11.5 3.5 9.48528 3.5 7L3.5 4.5C3.5 4.22386 3.27614 4 3 4C2.72386 4 2.5 4.22386 2.5 4.5L2.5 7C2.5 10.0376 4.96243 12.5 8 12.5L15.7929 12.5L12.6464 15.6464C12.4512 15.8417 12.4512 16.1583 12.6464 16.3536C12.8417 16.5488 13.1583 16.5488 13.3535 16.3536L17.3509 12.3561C17.3607 12.3466 17.37 12.3366 17.3789 12.3263C17.4096 12.2907 17.4344 12.2518 17.4535 12.2109C17.4833 12.1468 17.5 12.0754 17.5 12C17.5 11.9249 17.4834 11.8536 17.4537 11.7897C17.4343 11.7476 17.4086 11.7077 17.3768 11.6713C17.3686 11.6619 17.36 11.6528 17.3511 11.644L13.3535 7.64645C13.1583 7.45118 12.8417 7.45118 12.6464 7.64645C12.4512 7.84171 12.4512 8.15829 12.6464 8.35355L15.7929 11.5L8 11.5Z" fill="#868E96"/>
</svg>
`;

export const deleteIcon = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5 1.5C5 1.22386 5.22386 1 5.5 1H10.5C10.7761 1 11 1.22386 11 1.5V3H5V1.5ZM4 3V1.5C4 0.671573 4.67157 0 5.5 0H10.5C11.3284 0 12 0.671573 12 1.5V3H13.5H15C15.2761 3 15.5 3.22386 15.5 3.5C15.5 3.77614 15.2761 4 15 4H14V14.5C14 15.3284 13.3284 16 12.5 16H3.5C2.67157 16 2 15.3284 2 14.5V4H1C0.723858 4 0.5 3.77614 0.5 3.5C0.5 3.22386 0.723858 3 1 3H2.5H4ZM11.5 4H4.5H3V14.5C3 14.7761 3.22386 15 3.5 15H12.5C12.7761 15 13 14.7761 13 14.5V4H11.5ZM6.5 6.5C6.77614 6.5 7 6.72386 7 7V12C7 12.2761 6.77614 12.5 6.5 12.5C6.22386 12.5 6 12.2761 6 12V7C6 6.72386 6.22386 6.5 6.5 6.5ZM10 7C10 6.72386 9.77614 6.5 9.5 6.5C9.22386 6.5 9 6.72386 9 7V12C9 12.2761 9.22386 12.5 9.5 12.5C9.77614 12.5 10 12.2761 10 12V7Z" fill="#868E96"/>
</svg>
`;

export const toolboxIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
  <rect width="18" height="18" fill="#F4F5F7" rx="2"/>
  <circle cx="11.5" cy="6.5" r="1.2"/>
  <circle cx="11.5" cy="11.5" r="1.2"/>
  <circle cx="6.5" cy="6.5" r="1.2"/>
  <circle cx="6.5" cy="11.5" r="1.2"/>
</svg>`;

export const tableIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.11905 4C4.0533 4 4 4.0533 4 4.11905V9.5H9.5V4H4.11905ZM9.5 10.5H4V15.881C4 15.9467 4.0533 16 4.11905 16H9.5V10.5ZM3 10V4.11905C3 3.50101 3.50101 3 4.11905 3H10H15.881C16.499 3 17 3.50101 17 4.11905V10V15.881C17 16.499 16.499 17 15.881 17H10H4.11905C3.50101 17 3 16.499 3 15.881V10ZM16 10.5V15.881C16 15.9467 15.9467 16 15.881 16H10.5V10.5H16ZM16 9.5H10.5V4H15.881C15.9467 4 16 4.0533 16 4.11905V9.5Z" fill="#868E96"/>
</svg>
`;

export const withHeadings = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 4.5C2.5 3.67157 3.17157 3 4 3H16C16.8284 3 17.5 3.67157 17.5 4.5C17.5 5.32843 16.8284 6 16 6H4C3.17157 6 2.5 5.32843 2.5 4.5ZM2.99999 10C2.99999 9.72386 3.22385 9.5 3.49999 9.5H16.5C16.7761 9.5 17 9.72386 17 10C17 10.2761 16.7761 10.5 16.5 10.5H3.49999C3.22385 10.5 2.99999 10.2761 2.99999 10ZM3.49999 15C3.22385 15 2.99999 15.2239 2.99999 15.5C2.99999 15.7761 3.22385 16 3.49999 16H16.5C16.7761 16 17 15.7761 17 15.5C17 15.2239 16.7761 15 16.5 15H3.49999Z" fill="#868E96"/>
</svg>`;

export const withoutHeadings = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3 4.5C3 4.22386 3.22386 4 3.5 4H16.5C16.7761 4 17 4.22386 17 4.5C17 4.77614 16.7761 5 16.5 5H3.5C3.22386 5 3 4.77614 3 4.5ZM3 10C3 9.72386 3.22386 9.5 3.5 9.5H16.5C16.7761 9.5 17 9.72386 17 10C17 10.2761 16.7761 10.5 16.5 10.5H3.5C3.22386 10.5 3 10.2761 3 10ZM3.5 15C3.22386 15 3 15.2239 3 15.5C3 15.7761 3.22386 16 3.5 16H16.5C16.7761 16 17 15.7761 17 15.5C17 15.2239 16.7761 15 16.5 15H3.5Z" fill="#868E96"/>
</svg>`;
