import axios from "axios";
import env from "config/env";
import { getUserIdToken } from "utils/auth/cognitoAuthToolkit";

const API_VERSION = "v8";

const slidAxios = axios.create({
  baseURL: `${env.end_point_url.slid_api}/${API_VERSION}`,
  withCredentials: false,
});

slidAxios.interceptors.request.use(
  async (config) => {
    const cognitoIdToken = await getUserIdToken();

    if (cognitoIdToken) {
      config.headers["Authorization"] = `${cognitoIdToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default slidAxios;
