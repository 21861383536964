import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Icon, Tooltip, Typography15 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { setMarkupColor, setMarkupHighlighterSize, setToolSettingMenuType, setMarkupPenSize, setMarkupPenMode, setMarkupHighlighterMode, setMarkupTool } from "redux/actions/editorActions";
import { MarkupType, MarkupSizeType, ToolItemProps, DrawingModeType } from "types/editor";
import { MarkupPencilIcon } from "components/icons/ImageMarkupIcons/MarkupPencilIcon";
import { MarkupLineIcon } from "components/icons/ImageMarkupIcons/MarkupLineIcon";
import { MarkupSizeIcon } from "components/icons/ImageMarkupIcons/MarkupSizeIcon";
import { MarkupHighLighterSizeIcon } from "components/icons/ImageMarkupIcons/MarkupHighLighterSizeIcon";
import AnimateHeight from "components/interactions/AnimateHeight";
import { useImageMarkupTools } from "hooks/useImageMarkupTools";
import { ImageMarkupButtons } from "./ImageMarkupHeader";
import { useLocation } from "react-router-dom";
import { HighlighterSettingIcon } from "components/icons/ImageMarkupIcons/HighlighterSettingIcon";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking";
import { useAppDispatch, useAppSelector } from "hooks";
import { useMediaQuery } from "react-responsive";
import { isTouchDevice } from "utils/utils";

const drawingToolSizeList: MarkupSizeType[] = [MarkupSizeType.Thin, MarkupSizeType.Medium, MarkupSizeType.Thick];

interface Props {
  width: number;
  handleCancel: () => void;
  onSaveImageMarkup: (target: HTMLButtonElement) => void;
  focusCanvas: () => void;
  handleClickColorItem: (color: string) => void;
}
const ImageMarkupTools = ({ width, handleCancel, onSaveImageMarkup, focusCanvas, handleClickColorItem }: Props) => {
  const { t } = useTranslation("EditorComponent");
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isVideoNote = location.pathname.split("/")[1] === "vdocs";
  const isMobileUI = useMediaQuery({ query: "(max-width:799px)" });

  const { markupTool, markupColor, toolSettingMenuType, markupPenSize, markupHighlighterSize, markupHighlighterMode, markupPenMode, activeMarkupObject, showHighlighterColorGrid } = useAppSelector(
    (state) => state.editor
  );
  const [hoveredTool, setHoveredTool] = useState<MarkupType | null>(null);
  const [showSettingToolBox, setShowSettingToolBox] = useState<boolean>(false);
  const [clickedTool, setClickedTool] = useState<MarkupType.Pen | MarkupType.Highlighter | MarkupType.ColorPicker | null>(null);

  const { imageMarkUpTools: TOOL_ITEMS, renderToolIcon, PEN_COLORS, MARKER_COLORS } = useImageMarkupTools();

  useEffect(() => {
    if (!activeMarkupObject) {
      setShowSettingToolBox(false);
    }
  }, [activeMarkupObject]);

  // set colors when markup tool is changed
  useEffect(() => {
    if (activeMarkupObject) {
      switch (activeMarkupObject.type) {
        case "path":
        case "line":
          if (activeMarkupObject.stroke.includes("0.3)")) {
            dispatch(
              setMarkupColor({
                ...markupColor,
                highlighter: activeMarkupObject.stroke.replace("0.3)", "1)"),
              })
            );
          } else {
            dispatch(
              setMarkupColor({
                ...markupColor,
                pen: activeMarkupObject.stroke,
              })
            );
          }
          break;
        case "i-text":
          dispatch(
            setMarkupColor({
              ...markupColor,
              text: activeMarkupObject.fill,
            })
          );
          break;
        default:
          break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markupTool, activeMarkupObject]);

  const isCurrentColor = (color) => {
    if (activeMarkupObject) {
      switch (activeMarkupObject.type) {
        case "path":
        case "line":
          return activeMarkupObject.stroke === color;
        case "i-text":
          return activeMarkupObject.fill === color;
        default:
          return false;
      }
    } else {
      if (markupTool === MarkupType.Select || markupTool === MarkupType.Eraser) {
        return false;
      } else {
        return markupColor[markupTool] === color;
      }
    }
  };

  const handleMarkupToolMouseEnter = (toolName: MarkupType) => {
    if (toolName === MarkupType.Eraser || toolName === MarkupType.Select || toolName === MarkupType.Text) {
      if (clickedTool) {
        dispatch(setToolSettingMenuType(clickedTool));
        return;
      }
      setShowSettingToolBox(false);
    } else if (toolName === MarkupType.Pen) {
      setShowSettingToolBox(true);
      dispatch(setToolSettingMenuType(MarkupType.Pen));
    } else if (toolName === MarkupType.Highlighter) {
      setShowSettingToolBox(true);
      dispatch(setToolSettingMenuType(MarkupType.Highlighter));
    } else {
      setShowSettingToolBox(true);
      dispatch(setToolSettingMenuType(MarkupType.ColorPicker));
    }
  };

  const handleMarkupToolMouseLeave = () => {
    if (clickedTool) return;
    setShowSettingToolBox(false);
  };

  const handleChangePenSize = (thickness: MarkupSizeType) => {
    dispatch(setMarkupPenSize(thickness));
  };

  const handleChangeHighlighterSize = (thickness: MarkupSizeType) => {
    dispatch(setMarkupHighlighterSize(thickness));
  };

  const renderSettingMenu = () => {
    switch (toolSettingMenuType) {
      case MarkupType.ColorPicker:
        return (
          <ColorPickerGrid>
            {(markupTool === MarkupType.Highlighter && !activeMarkupObject) || showHighlighterColorGrid
              ? MARKER_COLORS.map((color, index) => {
                  return <ColorItem key={index} color={color} onClick={() => handleClickColorItem(color)} isCurrentColor={markupColor.highlighter === color} showBorder={false} />;
                })
              : PEN_COLORS.map((color, index) => {
                  return <ColorItem key={index} color={color} onClick={() => handleClickColorItem(color)} isCurrentColor={isCurrentColor(color)} showBorder={index === PEN_COLORS.length - 1} />;
                })}
          </ColorPickerGrid>
        );
      case MarkupType.Pen:
        return (
          <DrawSettingsWrapper width={width}>
            <SettingItem
              width={width}
              isCurrentItem={markupPenMode === DrawingModeType.Free}
              onClick={() => {
                trackEvent({
                  eventType: eventTypes.click.PEN_IN_IMAGE_MARKUP_VIEW,
                  eventProperties: {
                    option: `${DrawingModeType.Free}, ${markupHighlighterSize}`,
                    location: isVideoNote ? "Video note page" : "My notes",
                  },
                });
                dispatch(setMarkupTool(MarkupType.Pen));
                dispatch(setMarkupPenMode(DrawingModeType.Free));
              }}
            >
              <MarkupPencilIcon color={`var(--gray9)`} width={40} height={40} />
              {width > 400 && <Typography15 text={t("Pencil")} color={`--gray9`} />}
            </SettingItem>
            <SettingItem
              width={width}
              isCurrentItem={markupPenMode === DrawingModeType.Line}
              onClick={() => {
                trackEvent({
                  eventType: eventTypes.click.PEN_IN_IMAGE_MARKUP_VIEW,
                  eventProperties: {
                    option: `${DrawingModeType.Line}, ${markupHighlighterSize}`,
                    location: isVideoNote ? "Video note page" : "My notes",
                  },
                });
                dispatch(setMarkupTool(MarkupType.Pen));
                dispatch(setMarkupPenMode(DrawingModeType.Line));
              }}
            >
              <MarkupLineIcon color={`var(--gray9)`} width={40} height={40} />
              {width > 400 && <Typography15 text={t("Line")} color={`--gray9`} />}
            </SettingItem>
            <Divider />
            <DummyDiv />
            {drawingToolSizeList.map((size, index) => {
              return (
                <SettingItem
                  key={index}
                  width={width}
                  onClick={() => {
                    trackEvent({
                      eventType: eventTypes.click.PEN_IN_IMAGE_MARKUP_VIEW,
                      eventProperties: {
                        option: `${markupPenMode}, ${size}`,
                        location: isVideoNote ? "Video note page" : "My notes",
                      },
                    });
                    dispatch(setMarkupTool(MarkupType.Pen));
                    handleChangePenSize(size);
                  }}
                  isCurrentItem={markupPenSize === size}
                >
                  <MarkupSizeIcon color={markupPenSize === size ? `var(--blue6)` : `var(--gray9)`} size={size} width={40} height={40} />
                  {width > 400 && <Typography15 text={t(size)} color={markupPenSize === size ? `--blue6` : `--gray9`} />}
                </SettingItem>
              );
            })}
          </DrawSettingsWrapper>
        );
      case MarkupType.Highlighter:
        return (
          <DrawSettingsWrapper width={width}>
            <SettingItem
              isCurrentItem={markupHighlighterMode === DrawingModeType.Free}
              width={width}
              onClick={() => {
                trackEvent({
                  eventType: eventTypes.click.HIGHLIGHT_IN_IMAGE_MARKUP_VIEW,
                  eventProperties: {
                    option: `${DrawingModeType.Free}, ${markupHighlighterSize}`,
                    location: isVideoNote ? "Video note page" : "My notes",
                  },
                });
                dispatch(setMarkupTool(MarkupType.Highlighter));
                dispatch(setMarkupHighlighterMode(DrawingModeType.Free));
              }}
            >
              <HighlighterSettingIcon color={`var(--gray9)`} width={40} height={40} />
              {width > 400 && <Typography15 text={t("Highlighter")} color={`--gray9`} />}
            </SettingItem>
            <SettingItem
              width={width}
              isCurrentItem={markupHighlighterMode === DrawingModeType.Line}
              onClick={() => {
                trackEvent({
                  eventType: eventTypes.click.HIGHLIGHT_IN_IMAGE_MARKUP_VIEW,
                  eventProperties: {
                    option: `${DrawingModeType.Line}, ${markupHighlighterSize}`,
                    location: isVideoNote ? "Video note page" : "My notes",
                  },
                });
                dispatch(setMarkupTool(MarkupType.Highlighter));
                dispatch(setMarkupHighlighterMode(DrawingModeType.Line));
              }}
            >
              <MarkupLineIcon color={`var(--gray9)`} width={40} height={40} />
              {width > 400 && <Typography15 text={t("Line")} color={`--gray9`} />}
            </SettingItem>
            <Divider />
            <DummyDiv />
            {drawingToolSizeList.map((size, index) => {
              return (
                <SettingItem
                  key={index}
                  width={width}
                  onClick={() => {
                    trackEvent({
                      eventType: eventTypes.click.HIGHLIGHT_IN_IMAGE_MARKUP_VIEW,
                      eventProperties: {
                        option: `${markupHighlighterMode}, ${size}`,
                        location: isVideoNote ? "Video note page" : "My notes",
                      },
                    });
                    dispatch(setMarkupTool(MarkupType.Highlighter));
                    handleChangeHighlighterSize(size);
                  }}
                  isCurrentItem={markupHighlighterSize === size}
                >
                  <MarkupHighLighterSizeIcon color={markupHighlighterSize === size ? `var(--blue6)` : `var(--gray9)`} size={size} width={40} height={40} />
                  {width > 400 && <Typography15 text={t(size)} color={markupHighlighterSize === size ? `--blue6` : `--gray9`} />}
                </SettingItem>
              );
            })}
          </DrawSettingsWrapper>
        );
      default:
        return null;
    }
  };

  const onClickToolItem = (item: ToolItemProps) => {
    item.callback();
    const shouldShowSettingMenu = [MarkupType.Pen, MarkupType.Highlighter, MarkupType.ColorPicker].includes(item.name);
    if (shouldShowSettingMenu) {
      setShowSettingToolBox(true);
      setClickedTool(item.name as MarkupType.Pen | MarkupType.Highlighter | MarkupType.ColorPicker);
    } else {
      setClickedTool(null);
      setShowSettingToolBox(false);
    }
  };

  const getIsCurrentToolItem = (toolName: MarkupType) => {
    return markupTool === toolName || (toolSettingMenuType === MarkupType.ColorPicker && toolName === MarkupType.ColorPicker && showSettingToolBox);
  };

  const getIsToolItemDisabled = (toolName: MarkupType): boolean => {
    if (toolName !== MarkupType.ColorPicker || (activeMarkupObject && markupTool !== MarkupType.Eraser)) {
      return false;
    }

    return markupTool === MarkupType.Select || markupTool === MarkupType.Eraser;
  };

  return (
    <ToolBoxPositioner onClick={focusCanvas}>
      <ToolboxWrapper width={width} onMouseLeave={handleMarkupToolMouseLeave} isMobileUI={isMobileUI}>
        <ToolsWrapper isMobileUI={isMobileUI}>
          {TOOL_ITEMS.map((item: ToolItemProps, index: number) => {
            return (
              <ToolItemContainer key={item.name} index={index}>
                <Tooltip key={item.name} title={item.tooltip} placement={showSettingToolBox ? "top" : "bottom"} disabled={item.tooltip && !isTouchDevice() ? false : true} zIndex={999999}>
                  <ToolItem
                    isCurrentItem={getIsCurrentToolItem(item.name)}
                    toolName={item.name}
                    disabled={getIsToolItemDisabled(item.name)}
                    onClick={() => onClickToolItem(item)}
                    onMouseEnter={() => {
                      handleMarkupToolMouseEnter(item.name);
                      setHoveredTool(item.name);
                    }}
                  >
                    {renderToolIcon(item.name)}
                  </ToolItem>
                </Tooltip>
                {(index === 0 || index === TOOL_ITEMS.length - 2) && <Divider />}
              </ToolItemContainer>
            );
          })}
        </ToolsWrapper>
        {!isVideoNote && !isMobileUI && <ImageMarkupButtons handleCancel={handleCancel} onSaveImageMarkup={onSaveImageMarkup} focusCanvas={focusCanvas} />}
      </ToolboxWrapper>
      <AnimateHeight showChildren={showSettingToolBox}>
        <SettingWrapper
          onMouseEnter={() => {
            if (hoveredTool) handleMarkupToolMouseEnter(hoveredTool);
          }}
          onMouseLeave={handleMarkupToolMouseLeave}
        >
          <CloseButtonWrapper
            onClick={() => {
              setClickedTool(null);
              setShowSettingToolBox(false);
            }}
          >
            <Icon icon={`x24`} color={`--gray11`} />
          </CloseButtonWrapper>
          {renderSettingMenu()}
        </SettingWrapper>
      </AnimateHeight>
    </ToolBoxPositioner>
  );
};

const ToolBoxPositioner = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--gray15);
`;

const ToolboxWrapper = styled.div<{ width: number; isMobileUI: boolean }>`
  display: flex;
  padding: 8px 20px;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  background-color: var(--gray17);
  width: 100%;

  ${({ width }) =>
    width < 400 &&
    css`
      div:nth-child(4) {
        flex-basis: 35%;
      }
    `}

  ${({ isMobileUI }) =>
    isMobileUI &&
    css`
      padding: 8px 12px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    `}
`;

const ToolsWrapper = styled.div<{ isMobileUI: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  ${({ isMobileUI }) =>
    isMobileUI &&
    css`
      gap: 4px;
      flex-wrap: nowrap;
    `}
`;

const ToolItemContainer = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ToolItem = styled.button<{ isCurrentItem: boolean; toolName: ToolItemProps["name"] }>`
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;
  padding: 0;

  &:focus {
    outline: 0;
    border: none;
  }
  /* e of reset button  */

  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ isCurrentItem }) => (isCurrentItem ? "var(--gray15)" : "transparent")};

  &:hover {
    background-color: var(--gray13);
  }
  &:active {
    background-color: var(--gray15);
  }
  &:disabled {
    cursor: not-allowed;
    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }
  }

  ${({ toolName }) => {
    if (toolName !== "colorPicker") {
      return css`
        &:hover {
          svg {
            path {
              stroke: var(--blue6);
            }
            .color-picker-fill {
              fill: var(--blue6);
            }
            .color-picker-stroke {
              stroke: var(--blue6);
            }
          }
        }

        &:active {
          svg {
            path {
              stroke: var(--blue6);
            }
            .color-picker-fill {
              fill: var(--blue6);
            }
            .color-picker-stroke {
              stroke: var(--blue6);
            }
          }
        }
      `;
    }
  }}
`;

const Divider = styled.div`
  padding: 6px 0;
  min-height: calc(100% - 12px);
  width: 1px;
  background-color: var(--gray15);
`;

const DummyDiv = styled.div``;

const SettingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 92px;
  padding: 8px 20px;
  flex-direction: column;
  background-color: var(--gray17);
  position: absolute;
  border-top: 1px solid var(--gray15);
  border-bottom: 1px solid var(--gray15);
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: var(--gray5);
      }
    }
  }

  &:active {
    svg {
      path {
        stroke: var(--gray8);
      }
    }
  }
`;

const ColorPickerGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 50px);
  gap: 12px;
  padding: 4px 12px;
`;

const ColorItem = styled.div<{ color: string; isCurrentColor: boolean; showBorder: boolean }>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  cursor: pointer;
  position: relative;
  border: ${({ showBorder }) => showBorder && ".5px solid var(--gray14)"};

  ${({ isCurrentColor, color }) =>
    isCurrentColor &&
    css`
      border: none;
      &:before {
        content: "";
        position: absolute;
        transform: translate(-4px, -4px);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid ${color};
      }
    `}

  &:hover {
    border: none;
    outline: 4px solid var(--gray13);
  }
`;

const DrawSettingsWrapper = styled.div<{ width: number }>`
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 50px);
  align-items: stretch;
  gap: 4px 8px;

  ${({ width }) =>
    width < 400 &&
    css`
      div:nth-child(4) {
        flex-basis: 47%;
      }
    `}
`;

const SettingItem = styled.div<{ isCurrentItem: boolean; width: number }>`
  display: flex;
  padding: ${({ width }) => (width > 400 ? "5px 12px" : "4px")};
  flex-direction: column;
  align-items: center;
  gap: 2px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ isCurrentItem }) => (isCurrentItem ? "var(--gray15)" : "transparent")};

  ${({ isCurrentItem }) =>
    isCurrentItem &&
    css`
      svg {
        path {
          stroke: var(--blue6);
        }
        circle {
          fill: var(--blue6);
        }
      }
      p {
        color: var(--blue6);
      }
    `}

  &:hover {
    background-color: var(--gray13);
    svg {
      path {
        stroke: var(--blue6);
      }
      rect,
      circle {
        fill: var(--blue6);
      }
    }
    p {
      color: var(--blue6);
    }
  }

  &:active {
    background-color: var(--gray15);
    svg {
      path {
        stroke: var(--blue6);
      }
      rect,
      circle {
        fill: var(--blue6);
      }
    }
    p {
      color: var(--blue6);
    }
  }
`;

export default ImageMarkupTools;
