import EditorJS, { BlockAPI } from "@editorjs/editorjs";
import { BlockToolConstructorOptions } from "@editorjs/editorjs";
import * as React from "react";
import { API, BlockTool, ToolSettings } from "@editorjs/editorjs";
import store from "redux/store";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import MobileAutoNoteView from "./MobileAutoNoteView";

export interface MobileAutoNoteProps {
  id: string;
  text: string;
  isLoading: boolean;
}

class MobileAutoNoteBlock implements BlockTool {
  api: API;
  data: MobileAutoNoteProps;
  readOnly: boolean;
  block: BlockAPI | undefined;
  wrapper: HTMLDivElement;
  config: ToolSettings;

  constructor({ api, data, config, readOnly, block }: BlockToolConstructorOptions<MobileAutoNoteProps>) {
    this.api = api;
    this.data = data;
    this.config = config;
    this.readOnly = readOnly;
    this.block = block;
    this.wrapper = document.createElement("div");
  }

  render(): HTMLDivElement {
    const wrapper = this.wrapper!;
    const root = createRoot(wrapper);

    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <MobileAutoNoteView data={this.data} editorAPI={this.api} currentBlock={this.block} />
        </Provider>
      </React.StrictMode>
    );

    return wrapper;
  }

  save(blockContent: HTMLElement): MobileAutoNoteProps {
    const textParagraph: HTMLParagraphElement | null = blockContent.querySelector("[data-type='auto-note-text']");
    if (!textParagraph) {
      console.error("Failed to save auto note block");
      return { ...this.data };
    }

    return {
      ...this.data,
      text: textParagraph.innerText,
    };
  }

  validate(savedData: MobileAutoNoteProps): boolean {
    if (typeof savedData.text !== "string") return false;
    return true;
  }

  renderSettings() {
    return [];
  }

  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }
}

export default MobileAutoNoteBlock;
