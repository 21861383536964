// auth 2.0
import slidAxios from "./base";
import { handleAuthErrors, isAuthError } from "./errorUtils";
const documentHistory_endpoint = `history/`;

/**
 * Gets the history for a document
 * [GET] /api/<version>/history/<slug:document_key>/
 * Now it returns all the history ordered by created_time DESC
 */
export const ServerInterface_getDocumentHistoryList = async ({ documentKey }) => {
  let documentData = null;
  let documentPostResponse = null;
  try {
    documentPostResponse = await slidAxios.get(`${documentHistory_endpoint}${documentKey}/`);
    documentData = documentPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          documentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          documentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // tried to get history for a folder
          documentData.error_message = "FOLDER_HISTORY_NOT_SUPPORTED";
          break;
        case 404:
          documentData.error_message = "HISTORY_DOCUMENT_NOT_FOUND";
          break;
        case 401:
          //user does not own the document
          documentData.error_message = "HISTORY_UNAUTHORIZED_USER";
          break;
        default:
          documentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      documentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      documentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentData;
  }
};

/**
 * This function creates a document history
 * [POST] /api/<version>/history/<slug:document_key>/
 * returns the created document history's data with fields: 
 *  "document",
    "user",
    "document_key",
    "title",
    "content",
    "created_time",
    "updated_time",
    "is_deleted",
    "is_public",
    "more_info",
 */
export const ServerInterface_createDocumentHistory = async ({ documentKey }) => {
  const payload = {
    document_key: documentKey,
  };
  let documentData = null;
  try {
    const documentPostResponse = await slidAxios.post(`${documentHistory_endpoint}${documentKey}/`, payload);
    documentData = documentPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          documentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          documentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          switch (error.response.data.message) {
            case "Folder Cant be stored Currently":
              documentData.error_message = "FOLDER_HISTORY_NOT_SUPPORTED";
              break;
            case "Invalid Data Format to Save":
              documentData.error_message = "INVALID_HISTORY_DATA";
              break;
            default:
              documentData.error_message = "FAILED_TO_CREATE_HISTORY";
              break;
          }
          break;
        case 404:
          documentData.error_message = "HISTORY_DOCUMENT_NOT_FOUND";
          break;
        case 401:
          //user does not own the document
          documentData.error_message = "HISTORY_UNAUTHORIZED_USER";
          break;
        default:
          documentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      documentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      documentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentData;
  }
};
