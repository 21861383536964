// auth 2.0
import { Dispatch } from "@reduxjs/toolkit";
import { ServerInterface_createPaymentForAppleIAP } from "api/appleIapPaymentsApi";
import { ServerInterface_getPayment, ServerInterface_createPayment, ServerInterface_createStripeCheckout } from "api/paymentsApi";
import { ServerInterface_updateSubscription, ServerInterface_getUserSubscriptionData } from "api/subscriptionApi";
import { ServerInterface_getReferralCode } from "api/referralsApi";
import { ServerInterface_getDayPass, ServerInterface_activateDayPass } from "api/daypassApi";
import * as Sentry from "@sentry/browser";
import {
  SET_PAYMENTS_HISTORY_DATA,
  FETCH_SUBSCRIPTION,
  SET_ERROR_MESSAGE,
  UPDATE_SUBSCRIPTION,
  SET_SHOW_REFERRAL_POPUP,
  SET_PAYMENT_LENGTH,
  SET_REFERRAL_CODE_DATA,
  SET_USER_COUNTRY,
  SET_DAYPASS_DATA,
  SET_PLAN_TYPE,
  SET_PLAN_LENGTH,
  SET_MEMBERSHIP,
  SET_IS_CASHBACK_USER,
  SET_IS_ELIGIBLE_FOR_CASHBACK,
  SET_IS_CASHBACK_OPTION_CHECKED,
  SET_IS_CHECKOUT_SECTION_VISIBLE_ON_MOBILE,
  SET_EXPERT_CODE,
  SET_SURVEY_INFO,
} from "./actionTypes";
import { getUserIdToken } from "utils/auth/cognitoAuthToolkit";
import { fetchUser } from "./slidGlobalActions";
import { PlanLengthType, PlanMembershipType, PlanTypeProps } from "components/pricing/types";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking";

export interface PaymentData {
  payment_info: {
    email: string;
    name: string;
    phone: string;
  };
  amount: number;
  start_date: string;
  end_date: string;
  is_recurring: 0;
  payment_key: string;
  payment_length: number;
  payment_gateway: string;
  membership: "basic" | "pro";
  lang: "ko" | "en";
  promotion_id?: undefined | null | string;
}

export const fetchSubscription = () => async (dispatch: Dispatch) => {
  let subscriptionData: any = await ServerInterface_getUserSubscriptionData();
  if (subscriptionData.error_message) {
    if (subscriptionData.error_message !== "SUBSCRIPTION_NOT_FOUND") {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: subscriptionData.error_message,
      });
    }
    // To prevent subscriptionData has unrelated data
    subscriptionData = null;
  }
  dispatch({
    type: FETCH_SUBSCRIPTION,
    payload: subscriptionData,
  });
};

export const updateSubscription =
  ({ data }: { data: any }) =>
  async (dispatch: Dispatch) => {
    let subscriptionData: any = await ServerInterface_updateSubscription({ data });
    if (subscriptionData.error_message) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: subscriptionData.error_message,
      });
      return subscriptionData;
    }
    dispatch({
      type: UPDATE_SUBSCRIPTION,
      payload: subscriptionData,
    });
    return subscriptionData;
  };

export const setShowReferralPopup = (showReferralPopup: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SHOW_REFERRAL_POPUP,
    payload: showReferralPopup,
  });
};

export const setPaymentLength = (paymentLength: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PAYMENT_LENGTH,
    payload: paymentLength,
  });
};

export const getPaymentsHistoryData = () => async (dispatch: Dispatch) => {
  const paymentsHistoryData = await ServerInterface_getPayment();
  if (paymentsHistoryData["error_message"]) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: paymentsHistoryData["error_message"],
    });
    return paymentsHistoryData;
  }
  dispatch({
    type: SET_PAYMENTS_HISTORY_DATA,
    payload: paymentsHistoryData,
  });
  return paymentsHistoryData;
};

export const createPaymentData =
  ({ data }: any) =>
  async (dispatch: Dispatch) => {
    const createPaymentData = await ServerInterface_createPayment({ data });
    if (createPaymentData["error_message"]) {
      dispatch({
        type: SET_ERROR_MESSAGE,
        payload: createPaymentData["error_message"],
      });
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        scope.setExtra("paymentInfo", data.paymentInfo);
        scope.setExtra("api_error_message", createPaymentData["error_message"]);
        Sentry.captureMessage("SLID_WEB_POST_PAYMENT_ERROR");
      });
      return;
    }
    await dispatch<any>(getPaymentsHistoryData());
    return createPaymentData;
  };


interface StripePaymentData {
  payment_info: {
    email?: string;
    name?: string;
    phone?: string;
  };
  payment_gateway: "stripe";
  payment_key: string;
  amount: number;
  membership: "basic" | "pro" | "premium" | "standard";
  payment_length: number;
  start_date: string;
  end_date: string;
  is_recurring: boolean;
  lang: "ko" | "en";
  pricing_id: string;
}

export const createStripePaymentData = (data: StripePaymentData) => async (dispatch: Dispatch) => {
  const checkoutURL = await ServerInterface_createStripeCheckout({ data });
  if (checkoutURL["error_message"]) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: checkoutURL["error_message"],
    });
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      scope.setExtra("paymentInfo", data.payment_info);
      scope.setExtra("api_error_message", checkoutURL["error_message"]);
      Sentry.captureMessage("SLID_WEB_POST_PAYMENT_ERROR");
    });
    return checkoutURL;
  }

  return checkoutURL;
};

interface DayPassObject {
  started_at: string; // ISOString
  expires_at: string; // ISOString
  is_started: boolean;
  is_expired: boolean;
}

export interface DayPassData {
  startedAt: string | null;
  expiresAt: string | null;
  isUsingDayPass: boolean;
  currentItem: DayPassObject | null;
  usedItems: DayPassObject[];
  unusedItems: DayPassObject[];
}

export const activateDayPass = () => async (dispatch: Dispatch) => {
  const dayPassGetResponse: {
    data?: DayPassObject[];
    error_message?: string;
  } = await ServerInterface_activateDayPass();

  if (dayPassGetResponse["error_message"]) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: dayPassGetResponse["error_message"],
    });
    dispatch({
      type: SET_DAYPASS_DATA,
      payload: null,
    });
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      Sentry.captureMessage("SLID_WEB_GET_DAY_PASS_ERROR");
    });
    return {
      error_message: dayPassGetResponse["error_message"],
    };
  }

  trackEvent({ eventType: eventTypes.success._24H_PASS_ACTIVATION });

  const dayPassData: DayPassData = { startedAt: null, expiresAt: null, isUsingDayPass: false, currentItem: null, usedItems: [], unusedItems: [] };
  dayPassData.unusedItems = dayPassGetResponse.data?.filter((item) => !item.is_started) || [];
  dayPassData.usedItems = dayPassGetResponse.data?.filter((item) => item.is_started && item.is_expired) || [];

  let activeItem = dayPassGetResponse.data?.find((item) => item.is_started && !item.is_expired) || null;
  if (activeItem !== null) {
    dayPassData.startedAt = activeItem.started_at;
    dayPassData.expiresAt = new Date(new Date(activeItem.started_at).getTime() + 1000 * 60 * 60 * 24).toISOString();
    dayPassData.isUsingDayPass = true;
    dayPassData.currentItem = activeItem;
  }

  dispatch({
    type: SET_DAYPASS_DATA,
    payload: dayPassData,
  });
  dispatch<any>(fetchUser());
};

// TODO: Update payload data for consistency
export const getDayPassData = () => async (dispatch: Dispatch) => {
  const dayPassGetResponse: {
    data?: DayPassObject[];
    error_message?: string;
  } = await ServerInterface_getDayPass();
  if (dayPassGetResponse["error_message"]) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: dayPassGetResponse["error_message"],
    });
    dispatch({
      type: SET_DAYPASS_DATA,
      payload: null,
    });
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      Sentry.captureMessage("SLID_WEB_GET_DAY_PASS_ERROR");
    });
    return {
      error_message: dayPassGetResponse["error_message"],
    };
  }

  const dayPassData: DayPassData = { startedAt: null, expiresAt: null, isUsingDayPass: false, currentItem: null, usedItems: [], unusedItems: [] };
  dayPassData.unusedItems = dayPassGetResponse.data?.filter((item) => !item.is_started) || [];
  dayPassData.usedItems = dayPassGetResponse.data?.filter((item) => item.is_started && item.is_expired) || [];

  let activeItem = dayPassGetResponse.data?.find((item) => item.is_started && !item.is_expired) || null;
  if (activeItem !== null) {
    dayPassData.startedAt = activeItem.started_at;
    dayPassData.expiresAt = new Date(new Date(activeItem.started_at).getTime() + 1000 * 60 * 60 * 24).toISOString();
    dayPassData.isUsingDayPass = true;
    dayPassData.currentItem = activeItem;
  }

  dispatch({
    type: SET_DAYPASS_DATA,
    payload: dayPassData,
  });

  return dayPassData;
};

export const setReferralCodeData =
  ({ referralCode }: { referralCode: string }) =>
  async (dispatch: Dispatch) => {
    const referralCodeData = await ServerInterface_getReferralCode({
      referralCode,
    });

    if (referralCodeData["error_message"]) {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("SLID_WEB_GET_REFERRAL_CODE_ERROR");
      });
    }

    await dispatch({
      type: SET_REFERRAL_CODE_DATA,
      payload: referralCodeData,
    });
    return referralCodeData;
  };

export const setUserCountry = (userCountry: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_USER_COUNTRY,
    payload: userCountry,
  });
};

export const setPlanType = (planType: PlanTypeProps) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PLAN_TYPE,
    payload: planType,
  });
};

export const setPlanLength = (planLength: PlanLengthType) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PLAN_LENGTH,
    payload: planLength,
  });
};

export const setMembership = (membership: PlanMembershipType) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MEMBERSHIP,
    payload: membership,
  });
};

export const setIsCashbackUser = (isCashbackUser: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_CASHBACK_USER,
    payload: isCashbackUser,
  });
};

export const setIsEligibleForCashback = (isEligibleForCashback: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_ELIGIBLE_FOR_CASHBACK,
    payload: isEligibleForCashback,
  });
};

export const setIsCashbackOptionChecked = (isCashbackOptionChecked: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_CASHBACK_OPTION_CHECKED,
    payload: isCashbackOptionChecked,
  });
};

export const setIsCheckoutSectionVisibleOnMobile = (isCheckoutSectionVisibleOnMobile: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_IS_CHECKOUT_SECTION_VISIBLE_ON_MOBILE,
    payload: isCheckoutSectionVisibleOnMobile,
  });
};

export const setExpertCode = (expertCode: string | null) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_EXPERT_CODE,
    payload: expertCode,
  });
};

export const setSurveyInfo = (surveyInfo: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SURVEY_INFO,
    payload: surveyInfo,
  });
};
