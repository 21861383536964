// auth 2.0
import React from "react";
import styled from "styled-components";
import { useAppSelector } from "hooks";
import LoadingScreenContent from "./LoadingScreenContent";

const EditorLoadingScreen = () => {
  const { isEditorLoading } = useAppSelector((state) => state.vdocs);
  return (
    <EditorLoadingWrapper show={isEditorLoading}>
      <LoadingScreenContent />
    </EditorLoadingWrapper>
  );
};

export default EditorLoadingScreen;

const EditorLoadingWrapper = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;
