import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getTranscriptLanguageCode } from "utils/stt";
import {
  sendRestartSTTRequestToParentWindow,
  sendStartNewSTTRequestToParentWindow,
  sendStartSTTRequestToParentWindow,
  sendStopSTTRequestToParentWindow,
} from "utils/extensionInterface/sendToExtension";

const name = "autoNotes";

interface AutoNotesSTTBlockData {
  finalizedText?: string;
  text: string;
  isFinal: boolean;
}

interface State {
  autoNotesVideoLang: string;
  autoNotesResultLang: string;
  isAutoNotesToggledOn: boolean;
  isAutoNotesButtonClickedOnce: boolean;
  isAutoNotesSettingsOpen: boolean;
  showPermissionGuideInAutoNotesSettingsView: boolean;
  isGettingAutoNotesMediaPermission: boolean;
  isAutoNotesActive: boolean;
  autoNotesSTTError: string | null;
  activeAutoNotesSTTBlock: AutoNotesSTTBlockData | null;
  previousAutoNotesSTTBlocks: AutoNotesSTTBlockData[];
  isAutoNotesVisible: boolean;
  isAutoNotesSupported: boolean;
  isSocketConnected: boolean;
  isWhisperAutoNotesSupported: boolean;
  isAutoNotesBlockInserted: boolean;
}

const initialState: State = {
  autoNotesVideoLang: "",
  autoNotesResultLang: "",
  isAutoNotesToggledOn: false,
  isAutoNotesButtonClickedOnce: false,
  isAutoNotesSettingsOpen: false,
  isAutoNotesActive: false,
  showPermissionGuideInAutoNotesSettingsView: false,
  isGettingAutoNotesMediaPermission: false,
  autoNotesSTTError: null,
  activeAutoNotesSTTBlock: null,
  previousAutoNotesSTTBlocks: [],
  isAutoNotesVisible: true,
  isAutoNotesSupported: false,
  isSocketConnected: false,
  isWhisperAutoNotesSupported: false,
  isAutoNotesBlockInserted: false,
};

export const autoNotesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setToggleAutoNotes: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesToggledOn = action.payload;
    },
    setAutoNotesVideoLang: (state, action: PayloadAction<string>) => {
      state.autoNotesVideoLang = action.payload;
    },
    setAutoNotesResultLang: (state, action: PayloadAction<string>) => {
      state.autoNotesResultLang = action.payload;
    },
    setIsAutoNotesSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesSettingsOpen = action.payload;
    },
    setIsAutoNotesButtonClickedOnce: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesButtonClickedOnce = action.payload;
    },
    // setisAutoNotesActive: (state, action: PayloadAction<boolean>) => {
    // state.isAutoNotesActive = action.payload;
    // },
    setShowPermissionGuideInAutoNotesSettingsView: (state, action: PayloadAction<boolean>) => {
      state.showPermissionGuideInAutoNotesSettingsView = action.payload;
    },
    setIsGettingAutoNotesMediaPermission: (state, action: PayloadAction<boolean>) => {
      state.isGettingAutoNotesMediaPermission = action.payload;
    },
    setStartAutoNotes: (state, action: PayloadAction<string | undefined>) => {
      const language = state.autoNotesVideoLang;
      const transcriptLanguage = getTranscriptLanguageCode(language);

      const isWhisperAutoNotesSupported = state.isWhisperAutoNotesSupported;

      if (isWhisperAutoNotesSupported) {
        sendStartNewSTTRequestToParentWindow(action.payload, true);
      } else {
        sendStartSTTRequestToParentWindow(transcriptLanguage, true);
      }

      state.isAutoNotesActive = true;
    },
    setAutoNotesSTTError: (state, action: PayloadAction<string>) => {
      state.autoNotesSTTError = action.payload;
    },
    setRestartAutoNotesSTT: (state) => {
      sendRestartSTTRequestToParentWindow();
    },
    setEndAutoNotesSTT: (state) => {
      // const language = state.autoNotesVideoLang;
      // const transcriptLanguage = getTranscriptLanguageCode(language);
      // sendStartSTTRequestToParentWindow(transcriptLanguage, true);
      // state.isAutoNotesSTTActive = true;
      sendStopSTTRequestToParentWindow();
    },
    setStopAutoNotes: (state) => {
      state.isAutoNotesToggledOn = false;
      state.isAutoNotesActive = false;
      sendStopSTTRequestToParentWindow();
    },
    setIsAutoNotesVisible: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesVisible = action.payload;
    },
    setIsAutoNotesSupported: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesSupported = action.payload;
    },
    setIsSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.isSocketConnected = action.payload;
    },
    setIsWhisperAutoNotesSupported: (state, action: PayloadAction<boolean>) => {
      state.isWhisperAutoNotesSupported = action.payload;
    },
    setIsAutoNotesBlockInserted: (state, action: PayloadAction<boolean>) => {
      state.isAutoNotesBlockInserted = action.payload;
    },
  },
});

export const {
  setToggleAutoNotes,
  setAutoNotesVideoLang,
  setAutoNotesResultLang,
  setIsAutoNotesSettingsOpen,
  setShowPermissionGuideInAutoNotesSettingsView,
  setIsGettingAutoNotesMediaPermission,
  setStartAutoNotes,
  setRestartAutoNotesSTT,
  setAutoNotesSTTError,
  setEndAutoNotesSTT,
  setStopAutoNotes,
  setIsAutoNotesVisible,
  setIsAutoNotesSupported,
  setIsSocketConnected,
  setIsWhisperAutoNotesSupported,
  setIsAutoNotesBlockInserted,
  setIsAutoNotesButtonClickedOnce,
} = autoNotesSlice.actions;

export default autoNotesSlice.reducer;
