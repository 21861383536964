//auth 2.0
import "./index.css";

const breaklineIcon = `<svg width="16" height="2" viewBox="0 0 16 2" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.833252 1C0.833252 0.723858 1.05711 0.5 1.33325 0.5H14.6666C14.9427 0.5 15.1666 0.723858 15.1666 1C15.1666 1.27614 14.9427 1.5 14.6666 1.5H1.33325C1.05711 1.5 0.833252 1.27614 0.833252 1Z" fill="currentColor" /></svg>`;

/**
 * Break Line and divider Block for the Editor.js.
 *
 * @typedef {object} BreakLineData
 * @description Tool's input and output data format
 * @property {boolean} divider — Add a divider line.
 */
export default class DividerTool {
  /**
   * @param {{data: object, api: object}}
   *   data — Previously saved data
   *   api - Editor.js API
   */
  constructor({ data, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.CSS = {
      block: this.api.styles.block,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
      wrapper: "break-line",
      divider: "break-line__divider",
    };

    this.nodes = {
      wrapper: null,
      divider: null,
    };

    this.tuneButton = null;

    this._data = {
      divider: !!data.divider,
    };
  }

  /**
   * Renders Tool's view
   * @returns {HTMLDivElement}
   */
  render() {
    const wrapper = document.createElement("div");
    const divider = document.createElement("div");
    if (this.data.divider) divider.classList.add(this.CSS.divider);

    wrapper.classList.add(this.CSS.wrapper, this.CSS.block);
    divider.classList.add(this.CSS.divider, this.CSS.block);

    wrapper.appendChild(divider);

    this.nodes.wrapper = wrapper;
    this.nodes.divider = divider;
    const dummyInput = document.createElement("input");
    dummyInput.className = "dummy-input";
    this.nodes.wrapper.appendChild(dummyInput);

    return wrapper;
  }

  /**
   * Returns true to notify core that read-only is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Saves Block data
   * @returns {BreakLineData} - saved data
   */
  save() {
    return this.data;
  }

  /**
   * Gets current Tools`s data
   * @returns {BreakLineData} Current data
   */
  get data() {
    return this._data;
  }

  /**
   * Sets Tools`s data
   *
   * @param {BreakLineData} data — data to set
   */
  set data(data) {
    this._data = data || {};
  }

  /**
   * Icon and title for displaying at the Toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: breaklineIcon,
      title: "Divider",
    };
  }

  static convertToPlainHTML(data) {
    return "<hr>";
  }
}
