import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const MarkupPencilIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 40} height={height ? height : 40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2998 31H9.95651C9.70283 31 9.45954 30.8992 9.28016 30.7198C9.10078 30.5405 9 30.2972 9 30.0435V24.7002C9.00012 24.4468 9.10074 24.2039 9.27978 24.0246L24.0245 9.27994C24.2038 9.10069 24.447 9 24.7006 9C24.9542 9 25.1974 9.10069 25.3767 9.27994L30.7201 14.6197C30.8993 14.799 31 15.0422 31 15.2958C31 15.5494 30.8993 15.7926 30.7201 15.972L15.9754 30.7202C15.7961 30.8993 15.5532 30.9999 15.2998 31Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20.5435 12.855L27 19" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.06152 24.4795L15.5 30.4998" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgWrapper>
  );
};
