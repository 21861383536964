import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";
// auth 2.0
import axios from "axios";
import env from "config/env";

axios.defaults.withCredentials = true;
const api_version = "v8";
const SlidServerUrl = env.end_point_url.slid_api;
const trial_endpoint = `${SlidServerUrl}/${api_version}/trial/`;

export const ServerInterface_getUserTrialData = async () => {
  /* return obj
  {
    "free_trial_key",
    "start_date",
    "end_date",
    "total_trial_days",
    "is_expired",
  }
  */
  let trialData = {};
  try {
    const trialDataResponse = await slidAxios.get(trial_endpoint);
    trialData = trialDataResponse.data;
  } catch (error) {
    console.error(error);
    if (!trialData) trialData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          trialData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          trialData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          // "User's free trial not found"
          trialData.error_message = "TRIAL_NOT_FOUND";
          break;
        default:
          trialData.error_message = "UNKNOWN_ERROR";
          //TODO: log to sentry as it might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      trialData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      trialData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return trialData;
  }
};
