import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const TextBoxIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 48} height={height ? height : 48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.0474 34.1689V14.7759" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.1687 34.1685H27.9259" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.739 18.6545V14.7759H33.3563V18.6545" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgWrapper>
  );
};
