import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as Hackle from "@hackler/javascript-sdk";

import translationEn from "./en/index";
import translationKo from "./ko/index";
import env from "config/env";
import store from "redux/store";

export const defaultNS = "common";

const resources = {
  en: translationEn,
  ko: translationKo,
} as const;

const userLanguage = window.navigator.language;
const hackleClient = Hackle.createInstance(env.api_key.hackle_key);

i18n
  .use({
    type: "postProcessor",
    name: "Set A/B testing",
    process: (value: any, key: any, options: any, translator: any) => {
      const experimentKeyObject: Object = store.getState().slidGlobal.hackleExperimentKeys;
      const lang = translator.language === "ko" ? "ko" : "en";

      if (experimentKeyObject.hasOwnProperty(key[0])) {
        const text: string = key[0];
        const experimentKey: any = experimentKeyObject[text as keyof typeof experimentKeyObject];
        const decision = hackleClient.variationDetail(experimentKey);
        const parameterValue = decision.get(key[0] + "_" + lang, value);
        return parameterValue;
      } else return value;
    },
  })
  .use(initReactI18next)
  .init({
    resources,
    defaultNS,
    ns: Object.keys(translationEn),
    lng: userLanguage || "en",
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    postProcess: ["Set A/B testing"],
  });

export default i18n;
