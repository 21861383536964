import React, { FC, useEffect, useState, ReactNode } from "react";
import ReactDOM from "react-dom";

interface ModalPortalProps {
  children: ReactNode;
  target: string; // eg. body, #editor-container
}

const ModalPortal = ({ children, target }: ModalPortalProps) => {
  const [el] = useState(document.createElement("div"));

  useEffect(() => {
    const targetEl = document.querySelector(target) || document.body;
    targetEl.appendChild(el);

    return () => {
      targetEl.removeChild(el);
    };
  }, [el, target]);

  return ReactDOM.createPortal(children, el);
};

export default ModalPortal;
