import { SlidFolderDocument } from "types/document";
import { ContextMenuType, NoteLocationType } from "types/myNotes";
import { create } from "zustand";

interface SlidNewDocumentProps extends SlidFolderDocument {
  thumbnail_text?: string;
  thumbnail_url?: string;
}

interface MyNoteState {
  selectedDocuments: SlidNewDocumentProps[];
  addToSelectedDocument: (document: SlidNewDocumentProps) => SlidNewDocumentProps[];
  removeFromSelectedDocument: (documentKey: string) => void;
  resetSelectedDocuments: () => void;
  contextMenuType: ContextMenuType;
  setContextMenuType: (menuType: ContextMenuType) => void;
  contextMenuDocumentKey: string;
  setContextMenuDocumentKey: (documentKey: string) => void;
  folderPathMap: any;
  setFolderPathMap: (pathMap: any) => void;
  setDeleteNoteLocation: (location: NoteLocationType) => void;
  deleteNoteLocation: NoteLocationType;
}

const useMyNoteStore = create<MyNoteState>()((set) => ({
  selectedDocuments: [],
  contextMenuType: "My notes",
  addToSelectedDocument: (document) => {
    let updatedDocuments;
    set((state) => {
      if (state.selectedDocuments.some((doc) => doc.document_key === document.document_key)) {
        updatedDocuments = state.selectedDocuments;
      } else {
        updatedDocuments = [...state.selectedDocuments, document];
      }
      return {
        selectedDocuments: updatedDocuments,
      };
    });
    return updatedDocuments;
  },
  removeFromSelectedDocument: (documentKey) =>
    set((state) => ({
      selectedDocuments: state.selectedDocuments.filter((doc) => doc.document_key !== documentKey),
    })),
  resetSelectedDocuments: () => set({ selectedDocuments: [] }),
  setContextMenuType: (menuType: ContextMenuType) => set({ contextMenuType: menuType }),
  contextMenuDocumentKey: "",
  setContextMenuDocumentKey: (documentKey: string) => set({ contextMenuDocumentKey: documentKey }),
  folderPathMap: {},
  setFolderPathMap: (pathMap: any) => set({ folderPathMap: pathMap }),
  setDeleteNoteLocation: (location: NoteLocationType) => set({ deleteNoteLocation: location }),
  deleteNoteLocation: "My notes",
}));

export default useMyNoteStore;
