import { SelectEventType } from "./selectEventType";
import { AddEventType } from "./addEventType";
import { VisitEventType } from "./visitEventType";
import { HoverEventType } from "./hoverEventType";
import { SuccessEventType } from "./successEventType";
import { ClickEventType } from "./clickEventType";
import { ShowEventType } from "./showEventType";
import { SearchEventType } from "./searchEventType";

//TODO : In near feature, I will update using this comments.
// const PAGE = ['PRICING_PAGE', ... ]
// const VERB = ['CLICK', ...]
// const OBJECTIVE = ['...',]

// template literal
// interface EVENT_TYPE {
//     lorem: `${PAGE}_${}_in_${}`;
// };

export const eventTypes = {
  click: ClickEventType,
  hover: HoverEventType,
  visit: VisitEventType,
  show: ShowEventType,
  success: SuccessEventType,
  add: AddEventType,
  select: SelectEventType,
  search: SearchEventType,
};
