import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppSelector } from "hooks";
import {
  checkUserParticipatedStudyChallenge,
  checkUserSubmitStudyChallengeNote,
  createStudyChallengeNote,
  getStudyChallengeNotes,
  getStudyChallengeParticipants,
  getStudyChallengeStatus,
  getUserStudyGoal,
  updateStudyChallengeNote,
  updateUserStudyGoal,
} from "query-api/study-challenge";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/browser";

export const useStudyChallengeNote = () => {
  const { userData } = useAppSelector((state) => state.slidGlobal);

  const queryClient = useQueryClient();

  const { currentDocument } = useAppSelector((state) => state.vdocs);

  const documentKey = currentDocument?.["document_key"];

  const { eventName } = useCurrentStudyChallenge();
  const { search } = useLocation();
  const eventNameFromURL = new URLSearchParams(search).get("eventName");

  const targetEventName = eventName ? eventName : eventNameFromURL!;

  const { data: isNoteSubmitted } = useQuery({
    queryKey: ["studyChallenge", "note", documentKey, userData?.user_key],
    queryFn: async () => {
      const response = await checkUserSubmitStudyChallengeNote(targetEventName!, documentKey);
      return response.data;
    },
    enabled: !!documentKey && !!targetEventName,
    select: (data) => data.submitted,
  });

  const { data: studyChallengeNotes } = useQuery({
    queryKey: ["studyChallenge", "notes", userData?.user_key],
    queryFn: async () => {
      const response = await getStudyChallengeNotes(targetEventName);
      return response.data;
    },
    enabled: !!targetEventName,
  });

  const { mutateAsync: createStudyChallengeNoteMutation } = useMutation({
    mutationFn: async () => {
      return await createStudyChallengeNote(eventName!, documentKey);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["studyChallenge"]);
    },
    onError(error, variables, context) {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        scope.setExtra("error_msg", error);
        Sentry.captureMessage("createStudyChallengeNote error");
      });
      console.log("error", error);
    },
  });

  const { mutateAsync: updateStudyChallengeNoteMutation } = useMutation<unknown, unknown>({
    mutationFn: async () => {
      return await updateStudyChallengeNote(eventName!, documentKey);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["studyChallenge"]);
    },
  });

  return { studyChallengeNotes, isNoteSubmitted, submitStudyChallengeNote: createStudyChallengeNoteMutation, reSubmitStudyChallengeNote: updateStudyChallengeNoteMutation };
};

export const useUserStudyChallenge = () => {
  const { userData } = useAppSelector((state) => state.slidGlobal);

  const queryClient = useQueryClient();

  const { isUserParticipated, eventName } = useCurrentStudyChallenge();

  const { data: userStudyGoal } = useQuery({
    queryKey: ["studyGoal", eventName, userData?.user_key],
    queryFn: async () => {
      const response = await getUserStudyGoal(eventName!);
      return response.data;
    },
    enabled: !!isUserParticipated,
    select: (data) => data.userStudyGoal,
  });

  const { mutate: updateUserStudyGoalMutation } = useMutation({
    mutationFn: async (newGoal: string) => {
      return await updateUserStudyGoal(eventName!, { userStudyGoal: newGoal });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["studyGoal"]);
    },
  });

  return { isUserParticipated, userStudyGoal, updateUserStudyGoal: updateUserStudyGoalMutation };
};

export const useStudyChallengeStatus = () => {
  const { userData } = useAppSelector((state) => state.slidGlobal);

  const { eventName } = useCurrentStudyChallenge();

  const { search } = useLocation();
  const eventNameFromURL = new URLSearchParams(search).get("eventName");

  const targetEventName = eventName ? eventName : eventNameFromURL!;

  const { data: StudyChallengeStatus } = useQuery({
    queryKey: ["studyChallenge", "status", eventName, userData?.user_key],
    queryFn: async () => {
      const response = await getStudyChallengeStatus(targetEventName);
      return response.data;
    },
    enabled: !!userData?.user_key && !!targetEventName,
  });

  return { StudyChallengeStatus };
};

export const useStudyChallengeParticipants = () => {
  const { userData } = useAppSelector((state) => state.slidGlobal);

  const { eventName } = useCurrentStudyChallenge();

  const { search } = useLocation();
  const eventNameFromURL = new URLSearchParams(search).get("eventName");

  const { data: StudyChallengeParticipants, isLoading } = useQuery({
    queryKey: ["studyChallenge", "participants", eventName, userData?.user_key],
    queryFn: async () => {
      const response = await getStudyChallengeParticipants(eventName ? eventName : eventNameFromURL!);
      return response.data;
    },
    enabled: !!userData?.user_key,
    select: (participants) => {
      const myUserName = userData?.username;

      participants.sort((a, b) => {
        if (a.username === myUserName) return -1;
        if (b.username === myUserName) return 1;
        return 0;
      });

      return participants;
    },
  });

  return { StudyChallengeParticipants, isLoading };
};

export const useCurrentStudyChallenge = () => {
  const { userData } = useAppSelector((state) => state.slidGlobal);

  const { data: currentStudyChallenge } = useQuery({
    queryKey: ["isUserParticipated", userData?.user_key],
    queryFn: async () => {
      const response = await checkUserParticipatedStudyChallenge();
      return response.data;
    },
    enabled: !!userData?.user_key,
    select: (data) => {
      const is14DaysStudy = (eventName: string) => eventName.startsWith("14_DAYS_STUDY");

      if (data.event_name && is14DaysStudy(data.event_name)) {
        data.enrolled = false;
        data.event_name = "";
      }

      return {
        isParticipated: data.enrolled,
        eventName: data.event_name,
      };
    },
  });

  return { isUserParticipated: currentStudyChallenge?.isParticipated, eventName: currentStudyChallenge?.eventName };
};
