import React from "react";
import Lottie from "react-lottie";
import animationData from "components/animations/load_more.json";

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  renderer: "svg",
};

const LoadMoreContainer = () => {
  return <Lottie options={defaultLottieOptions} height={80} width={80} />;
};

export default LoadMoreContainer;
