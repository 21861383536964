// auth 2.0
export const insertParagraphToFirstBlock = ({ editorInstance }: any) => {
  editorInstance.blocks.insert("paragraph", "", {}, 0, true);
  editorInstance.caret.setToFirstBlock();
};

export const addCodeBlock = ({ editorAPI, text }: { editorAPI: any; text: string }) => {
  const currentBlockIndex = editorAPI.blocks.getCurrentBlockIndex();
  editorAPI.blocks.insert(
    "codeTool",
    {
      code: text,
    },
    undefined,
    currentBlockIndex + 1,
    true
  );
  editorAPI.caret.setToBlock(currentBlockIndex + 1);
};

export const i18nKo = {
  messages: {
    ui: {
      blockTunes: {
        toggler: {
          "Click to tune": "블록설정",
          "or drag to move": "",
        },
      },
      inlineToolbar: {
        converter: {
          "Convert to": "전환",
        },
      },
      toolbar: {
        toolbox: {
          Add: "추가",
        },
      },
    },
    toolNames: {
      Paragraph: "텍스트",
      Heading: "제목",
      "Heading 1": "제목 1",
      "Heading 2": "제목 2",
      "Heading 3": "제목 3",
      List: "글머리 기호 목록",
      "Numbered List": "번호 매기기 목록",
      Checklist: "할 일 목록",
      Code: "코드",
      Math: "수학 공식 블록",
      Divider: "구분선",
      Text: "텍스트",
      Link: "링크",
      Bold: "굵기",
      Italic: "기울기",
      Underline: "밑줄",
      Marker: "형광펜",
      InlineCode: "인라인 코드",
      TextColor: "글씨색상",
      ImageUploader: "이미지",
      Table: "테이블",
      LinkTool: "링크 미리보기",
    },
    tools: {
      link: {
        "Add a link": "링크를 입력해 주세요",
        Remove: "링크 제거",
      },
      stub: {
        "The block can not be displayed correctly.": "",
      },
      table: {
        Heading: "제목",
        "With headings": "제목 표",
        "Without headings": "일반 표",
        "Add column to left": "왼쪽에 열 삽입",
        "Add column to right": "오른쪽에 열 삽입",
        "Delete column": "열 삭제",
        "Add row above": "위에 행 삽입",
        "Add row below": "아래 행 삽입",
        "Delete row": "행 삭제",
      },
      linkTool: {
        "Wrong response format from the server": "서버에서 오류가 발생했습니다.",
        "Couldn't get this link data, try the other one": "링크 정보를 가져올 수 없습니다.\n다른 링크를 시도해주세요.",
        Link: "링크",
        "Change to image view": "이미지 링크로 전환",
        "Change to text view": "텍스트 링크로 전환",
      },
    },
    blockTunes: {
      delete: {
        Delete: "삭제",
      },
      moveUp: {
        "Move up": "한 칸 위로",
      },
      moveDown: {
        "Move down": "한 칸 아래로",
      },
    },
  },
};

export const i18nEn = {
  messages: {
    ui: {
      blockTunes: {
        toggler: {
          "Click to tune": "Click to tune",
          "or drag to move": "",
        },
      },
      inlineToolbar: {
        converter: {
          "Convert to": "Convert to",
        },
      },
      toolbar: {
        toolbox: {
          Add: "Add",
        },
      },
    },
    toolNames: {
      Paragraph: "Paragraph",
      Heading: "Heading",
      "Heading 1": "Heading 1",
      "Heading 2": "Heading 2",
      "Heading 3": "Heading 3",
      List: "List",
      "Numbered List": "Numbered List",
      Checklist: "Checklist",
      Code: "Code",
      Math: "Math",
      Divider: "Divider",
      Text: "Text",
      Link: "Link",
      Bold: "Bold",
      Italic: "Italic",
      Underline: "Underline",
      Marker: "Marker",
      TextColor: "Text Color",
      ImageUploader: "Image",
      Table: "Table",
      LinkTool: "LinkPreview",
    },
    tools: {
      link: {
        "Add a link": "Add a link",
        Remove: "Remove",
      },
      stub: {
        "The block can not be displayed correctly.": "",
      },
      table: {
        Heading: "Heading",
        "With headings": "With headings",
        "Without headings": "Without headings",
        "Add column to left": "Add column to left",
        "Add column to right": "Add column to right",
        "Delete column": "Delete column",
        "Add row above": "Add row above",
        "Add row below": "Add row below",
        "Delete row": "Delete row",
      },
      linkTool: {
        "Wrong response format from the server": "",
        "Couldn't get this link data, try the other one": "Couldn't get this link data,\nplease try the other one",
        Link: "",
        "Change to image view": "Convert to image link",
        "Change to text view": "Convert to text link",
      },
    },
    blockTunes: {
      delete: {
        Delete: "Delete",
      },
      moveUp: {
        "Move up": "Move up",
      },
      moveDown: {
        "Move down": "Move down",
      },
    },
  },
};
