import { Typography13, Icon, Typography20 } from "@slid/slid-ips";
import { MembershipType } from "utils/privilegeManager";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "hooks";
import styled from "styled-components";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

export const CLICK_CLOSE_ON_BANNER_LOCAL_STORAGE = "click-close-button-on-notice-banner";

type NotesSecurityNoticeBanner = {
  title: string;
  content: string;
  purchasedBefore?: boolean;
};

const NotesSecurityNoticeBanner = ({ title, content, purchasedBefore }: NotesSecurityNoticeBanner) => {
  const { lang, userData } = useAppSelector((state) => state.slidGlobal);
  const [isUserInBreakMode, setIsUserInBreakMode] = useState<boolean>(false);
  const [isBannerClosed, setIsBannerClosed] = useState<boolean>(false);

  useEffect(() => {
    if (userData && userData.payment === MembershipType.trial) trackEvent({ eventType: eventTypes.show.FREE_TRIAL_BANNER_IN_MY_NOTES });
  }, []);

  useEffect(() => {
    if (userData) {
      setIsUserInBreakMode(userData.payment === MembershipType.break);
    }
  }, [userData]);

  if ((isUserInBreakMode && window.localStorage.getItem(CLICK_CLOSE_ON_BANNER_LOCAL_STORAGE) !== "false") || isBannerClosed) return null;

  return (
    <InnerContainer>
      <>
        <Icon icon={`check24`} color={`--blue7`} />
        <TextArea>
          <Typography20 weight={700} text={title} color="--gray15" />
          <Typography13 weight={400} text={content} color="--gray9" marginTop={lang === "ko" ? "8px" : "5px"} />
        </TextArea>
      </>
      {/* Show close button only for break mode user who have paid before */}
      {isUserInBreakMode && purchasedBefore && (
        <CloseIconButton
          icon={`x24`}
          color={`--blue4`}
          onClick={() => {
            window.localStorage.clear();
            setIsBannerClosed(true);
          }}
        />
      )}
    </InnerContainer>
  );
};

const InnerContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 20px;
  background-color: var(--blue1);
  border: 1px solid var(--blue2);
  border-radius: 8px;

  display: flex;
  gap: 4px;
  align-items: flex-start;
`;

const TextArea = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
`;

const CloseIconButton = styled(Icon)`
  && {
    cursor: pointer;
  }
`;

export default NotesSecurityNoticeBanner;
