import { useEffect, useState } from "react";
import { useAppSelector } from "hooks";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import useEditorStore from "store/useEditorStore";
import { getCurrentDocumentKey } from "utils/editor/util";

const useSLTData = () => {
  const { isManualAddingMode, transcriptData } = useWhisperSLTStore();
  const { editorInstance } = useAppSelector((state) => state.vdocs);

  const [insertedIds, setInsertedIds] = useState(new Set());

  const { updateNoteContent } = useEditorStore();

  useEffect(() => {
    if (isManualAddingMode || !editorInstance || !transcriptData.length) return;

    const insertTranscriptData = async () => {
      const { blocks } = editorInstance;

      updateNoteContent(true);

      const currentDocumentKey = await getCurrentDocumentKey();
      if (!currentDocumentKey) return false;

      if (transcriptData.length > 0) {
        const lastData = transcriptData[transcriptData.length - 1];
        if (!insertedIds.has(lastData.id)) {
          blocks.insert("paragraph", { text: lastData.text, id: lastData.id }, {}, blocks.getBlocksCount() + 1, true);
          setInsertedIds(new Set(Array.from(insertedIds).concat(lastData.id)));
        }
      }
    };

    insertTranscriptData();
  }, [transcriptData, isManualAddingMode, editorInstance]);
};

export default useSLTData;
