// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const SvgWrapper = styled(Icon)``;

export const SLTStopIcon = () => {
  return (
    <SvgWrapper width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM8 4H4V8H8V4Z" fill="#F93D3F" />
    </SvgWrapper>
  );
};
