import React, { useEffect, useState } from "react";
import SearchBoxHeader from "../SearchBoxHeader";
import styled from "styled-components";
import Twemoji from "react-twemoji";
import { Typography13, Typography15 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import SearchBoxRecentNoteSection from "./SearchBoxRecentNoteSection";
import { useRecentDocuments } from "hooks/queries";

const SearchBoxPlaceholder = () => {
  const { t } = useTranslation("Search");
  const { data } = useRecentDocuments();
  const [showRecentNotes, setShowRecentNotes] = useState<boolean>(false);

  useEffect(() => {
    if (data?.recent_documents && data.number_of_results > 0) {
      setShowRecentNotes(true);
    } else {
      setShowRecentNotes(false);
    }
  }, [data]);

  return (
    <>
      <SearchBoxHeader />
      <PlaceholderWrapper showRecentNote={showRecentNotes}>
        <Twemoji options={{ className: "twemoji" }}>
          <span role="img" aria-label="face with monocle">
            🧐
          </span>
        </Twemoji>
        <Typography15 text={t("DefaultPlaceholderTitle")} color={`--gray17`} weight={700} marginTop={`24px`} />
        <Typography13 text={t("DefaultPlaceholderDescription")} color={`--blue2_cloudy`} marginTop={`8px`} />
      </PlaceholderWrapper>
      {showRecentNotes && <SearchBoxRecentNoteSection />}
    </>
  );
};

const PlaceholderWrapper = styled.div<{ showRecentNote: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ showRecentNote }) => (showRecentNote ? "44px 0 56px" : "44px 0 120px")};

  .twemoji {
    width: 36px;
    height: 36px;
  }
`;

export default SearchBoxPlaceholder;
