import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const useAccountTabOption = () => {
  const { t } = useTranslation("MyDocsAccountInfo");
  const history = useHistory();
  const [optionTitle, setOptionTitle] = useState<string>(t("NavigationAccountTab"));
  const [windowHash, setWindowHash] = useState<string>(window.location.hash);

  useEffect(() => {
    setWindowHash(window.location.hash);
  }, [window.location.hash]);

  useEffect(() => {
    switch (windowHash) {
      case "#plans":
        setOptionTitle(t("NavigationPlans"));
        break;
      case "#billing":
        setOptionTitle(t("NavigationBilling"));
        break;
      case "#usage":
        setOptionTitle(t("NavigationUsage"));
        break;
      case "#community":
        setOptionTitle(t("NavigationCommunity"));
        break;
      default:
        setOptionTitle(t("NavigationAccountTab"));
        return;
    }
  }, [windowHash, t]);

  const switchOptionMenu = () => {
    if (windowHash) {
      history.push("/account");
    } else {
      history.push("/docs");
    }
  };

  return { optionTitle, windowHash, switchOptionMenu };
};
