import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dim } from "@slid/slid-ips";
import SearchBox from "./SearchBox";
import { setShowSearchModal } from "redux/actions/searchActions";
import { useAppDispatch, useAppSelector } from "hooks";
import { useSearch } from "hooks";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

const SearchModal = () => {
  const dispatch = useAppDispatch();
  const { showSearchModal } = useAppSelector((state) => state.search);
  const { showMyNoteSideMenu } = useAppSelector((state) => state.myNotes);
  const { resetSearchKeywords } = useSearch();
  const isPlainNote = window.location.pathname.includes("docs") && !!document.getElementById("editor-component");
  const [isTrackEventFired, setIsTrackEventFired] = useState<boolean>(false);

  if (!showSearchModal) {
    if (isTrackEventFired) setIsTrackEventFired(false);
    return <></>;
  } else {
    if (!isTrackEventFired) {
      trackEvent({
        eventType: eventTypes.show.SEARCH_MODAL,
        eventProperties: {
          from: isPlainNote ? "Plain Note" : "My Notes",
        },
      });
      setIsTrackEventFired(true);
    }
    return (
      <Wrapper>
        <Dim
          type={"gray9"}
          callBack={() => {
            dispatch(setShowSearchModal(false));
            resetSearchKeywords();
          }}
        />
        <ModalContainer showMyNoteSideMenu={showMyNoteSideMenu} isPlainNote={isPlainNote}>
          <SearchBox />
        </ModalContainer>
      </Wrapper>
    );
  }
};

const Wrapper = styled.div``;

const ModalContainer = styled.div<{ showMyNoteSideMenu: boolean; isPlainNote: boolean }>`
  position: absolute;
  z-index: 9999;
  /** position depends on the side menu appearence and the page type.
   *  1. In My notes page, the position of modal should be in the middle of the screen excluding the side menu width.
   *     Side menu width is 300px when it's shown and 76px when it's hidden.
   *  2. In plain note page, the position of modal should be in the middle of the screen.
   */
  left: ${({ showMyNoteSideMenu, isPlainNote }) => (isPlainNote ? "50%" : showMyNoteSideMenu ? "calc(50% + 150px)" : "calc(50% + 38px)")};
  transform: translateX(-50%);
  top: 40px;

  @media (max-width: 1200px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export default SearchModal;
