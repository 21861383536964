import { Typography13, Typography15 } from "@slid/slid-ips";
import { useSLTButton } from "hooks/whisperSLT/useSLTButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import styled from "styled-components";

const SmartLiveTextProgressInfoView = () => {
  const { isSTTActive, isManualAddingMode, transcriptData } = useWhisperSLTStore();
  const { onClickSLTMoreButton } = useSLTButton();
  const [statusText, setStatusText] = useState<"Typing" | "AddedToNote">("Typing");

  const { t } = useTranslation("SmartLiveText");

  useEffect(() => {
    if (transcriptData.length > 0) {
      setStatusText("AddedToNote");
      setTimeout(() => {
        setStatusText("Typing");
      }, 500);
    }
  }, [transcriptData]);

  if (!isSTTActive || isManualAddingMode) return null;

  return (
    <Container>
      <Tooltip tooltipText={t("TooltipDescription")} />
      <Content>
        {statusText === "Typing" ? <RecordingIcon /> : <CheckIcon />}
        <Typography15 color={statusText === "Typing" ? "--red6" : "--gray7"} weight={700} text={t(statusText)} />
      </Content>
      <Content onClick={onClickSLTMoreButton} isClickable>
        <Typography13 color="--gray12" weight={500} text={t("Setting")} />
      </Content>
    </Container>
  );
};

export default SmartLiveTextProgressInfoView;

const RecordingIcon = () => {
  return (
    <IconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 13.5909C10.2593 13.5909 12.0909 11.7594 12.0909 9.50003C12.0909 7.24068 10.2593 5.40912 8 5.40912C5.74065 5.40912 3.90909 7.24068 3.90909 9.50003C3.90909 11.7594 5.74065 13.5909 8 13.5909ZM8 14.5C10.7614 14.5 13 12.2615 13 9.50003C13 6.73861 10.7614 4.50003 8 4.50003C5.23858 4.50003 3 6.73861 3 9.50003C3 12.2615 5.23858 14.5 8 14.5Z"
          fill="#F93D3F"
        />
        <path
          d="M10.7277 9.49998C10.7277 11.0062 9.5067 12.2273 8.00047 12.2273C6.49423 12.2273 5.27319 11.0062 5.27319 9.49998C5.27319 7.99375 6.49423 6.77271 8.00047 6.77271C9.5067 6.77271 10.7277 7.99375 10.7277 9.49998Z"
          fill="#F93D3F"
        />
      </svg>
    </IconWrapper>
  );
};

const CheckIcon = () => {
  return (
    <IconWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5429 4.24326C11.8918 4.57762 11.9036 5.13152 11.5692 5.48042L6.53799 10.7304C6.20805 11.0747 5.66316 11.0914 5.31276 10.768L2.46901 8.14295C2.11392 7.81518 2.09177 7.2616 2.41955 6.90651C2.74733 6.55141 3.30091 6.52927 3.656 6.85705L5.86871 8.89955L10.3058 4.26958C10.6401 3.92068 11.194 3.9089 11.5429 4.24326Z"
          fill="#BEC5CD"
        />
      </svg>
    </IconWrapper>
  );
};

const Tooltip = ({ tooltipText }: { tooltipText: string }) => {
  return (
    <TooltipWrapper>
      <div>{tooltipText}</div>
      <svg className="polygon" xmlns="http://www.w3.org/2000/svg" width="11" height="6" viewBox="0 0 11 6" fill="none">
        <g filter="url(#filter0_b_2133_217)">
          <path d="M5.50001 6L0.303864 -9.08524e-07L10.6962 0L5.50001 6Z" fill="#343A40" />
        </g>
        <defs>
          <filter id="filter0_b_2133_217" x="-3.69611" y="-4" width="18.3923" height="14" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2133_217" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2133_217" result="shape" />
          </filter>
        </defs>
      </svg>
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div`
  display: none;
  position: absolute;
  top: -47px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  width: auto; /* Adjusted to fit content size */
  color: var(--lightTheme-grayscale-gray2, #e9ecef);
  text-align: center;
  font-feature-settings: "ss03" on, "clig" off, "liga" off;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
  div {
    display: flex;
    padding: 7px 12px 8px 12px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: var(--lightTheme-grayscale-gray8, #343a40);
    backdrop-filter: blur(2px);
  }
  .polygon {
    position: absolute;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  z-index: 9999;
  display: flex;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  max-width: 470px;
  width: 100%;
  height: 47px;
  position: absolute;
  top: -68px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 12px;
  border: 1px solid var(--gray4);
  background: var(--white);
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.05);
  &:hover ${TooltipWrapper} {
    display: block; /* Show tooltip on hover */
  }
`;

const Content = styled.div<{ isClickable?: boolean }>`
  display: flex;
  justify-content: center;
  gap: 8px;
  user-select: none;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
`;
