// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const PauseIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }} fill="none">
      <rect x="14" y="12" width="4" height="16" fill="#212529" />
      <rect x="22" y="12" width="4" height="16" fill="#212529" />
    </SvgWrapper>
  );
};
