import slidAxios from "./base";
import { handleAuthErrors, isAuthError } from "./errorUtils";
const referrals_endpoint = `referrals/`;

export const ServerInterface_getReferralCode = async ({ referralCode }) => {
  let referralCodeData = null;

  try {
    const referralCodeGetResponse = await slidAxios.get(`${referrals_endpoint}?referral_code=${referralCode}`);
    referralCodeData = referralCodeGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!referralCodeData) referralCodeData = {};

    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          referralCodeData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          referralCodeData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          //"Provided 'referral_code' Not Exists"
          referralCodeData.error_message = "REFERRAL_CODE_NOT_EXISTS";
          break;
        default:
          referralCodeData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      referralCodeData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      referralCodeData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return referralCodeData;
  }
};
