import React from "react";
import Lottie from "react-lottie";
import recording_animation_blue from "components/animations/recording_animation_blue.json";
import waiting_animation from "components/animations/waiting_animation.json";
import { AutoNotesGeneratingIcon, AutoNotesIcon, AutoNotesStopIcon } from "components/icons/autoNotesIcons";
import styled from "styled-components";

const recordingLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: recording_animation_blue,
  renderer: "svg",
};

const waitingLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: waiting_animation,
  renderer: "svg",
};

export type AutoNotesButtonStatus = "default" | "generating" | "waiting";

const AutoNotesButtonIcon = ({ isButtonHovered, buttonStatus }: { isButtonHovered: boolean; buttonStatus: AutoNotesButtonStatus }) => {
  return (
    <IconWrapper>
      {isButtonHovered && buttonStatus !== "default" ? (
        <AutoNotesStopIcon width={23} height={23} />
      ) : buttonStatus === "default" ? (
        <AutoNotesIcon width={29} height={28} color="black" />
      ) : buttonStatus === "generating" ? (
        <Lottie options={recordingLottieOptions} height={28} width={28} />
      ) : buttonStatus === "waiting" ? (
        <Lottie options={waitingLottieOptions} height={28} width={28} />
      ) : (
        <AutoNotesIcon width={28} height={28} />
      )}
    </IconWrapper>
  );
};
export default AutoNotesButtonIcon;

const IconWrapper = styled.div`
  width: 29px;
  height: 28px;
`;
