// auth 2.0
import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";

const searchDocument_endpoint = `search/`;

/*
    This function searches for documents containing the keyword in the title or content
    [GET] /api/<version>/search/?category=document&target=title_content&keyword=${data.keyword}
    returns the list of documents with all fields minus content & pure_text
*/
export const ServerInterface_searchDocument = async ({ data }) => {
  let documentData = null;
  try {
    const documentGetResponse = await slidAxios.get(`${searchDocument_endpoint}?category=document&target=title_content&keyword=${data.keyword}`);
    documentData = documentGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        case 405:
          documentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          documentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 400:
          switch (error.response.data.code) {
            case "Invalid Search Category":
              documentData.error_message = "INVALID_SEARCH_CATEGORY";
              break;
            case "Invalid Search Target":
              documentData.error_message = "INVALID_SEARCH_TARGET";
              break;
            default:
              documentData.error_message = "INVALID_SEARCH_PARAMETERS";
              break;
          }
          break;
        default:
          documentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      // NETWORK_ERROR
      documentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      // request sent but no response
      documentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentData;
  }
};
