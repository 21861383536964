/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, memo } from "react";
import styled from "styled-components";
import "utils/cssElement/toggleSwitch.css";
import { InputGroup, FormControl, Accordion, Button as BootstrapButton } from "react-bootstrap";
import { useUpdateEffect } from "utils/customHook/customHook.js";
import env from "config/env";
import { useAppDispatch, useAppSelector } from "hooks";
import { patchDocument, setShowSharePopup } from "redux/actions/vdocsActions";
import { sendAmplitudeData } from "utils/amplitude";
import { trackEvent } from "utils/eventTracking";
import { titleToUrlSanitizer } from "utils/utils";
import { useTranslation } from "react-i18next";
import { Typography15, Typography20, Button, Typography13, useToast } from "@slid/slid-ips";
import { useMediaQuery } from "react-responsive";
import ShareInvitationOption from "./ShareInvitationOption";

const SLID_WEB_SITE_URL = env.end_point_url.slid_web_site;

const DocumentShare = memo(() => {
  const { t } = useTranslation("Modal");
  const { showToast } = useToast();
  const dispatch = useAppDispatch();
  const { lang, userData } = useAppSelector((state) => state.slidGlobal);
  const { currentDocument, isReadOnly } = useAppSelector((state) => state.vdocs);
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });

  const [isPublic, setIsPublic] = useState(currentDocument ? currentDocument["is_public"] : false);
  const [shareVdocs, setShareVdocs] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isShareButtonClicked, setIsShareButtonClicked] = useState(false);

  // related to publishing
  const [isPublished, setIsPublished] = useState(false);
  const [SEODescription, setSEODescription] = useState("");
  const [SEOKeywords, setSEOKeywords] = useState("");
  const [recommendedNoteKeys, setRecommendedNoteKeys] = useState("");
  const isPublicInitialValueRef = useRef(isPublic);

  const isVideoNote = window.location.pathname.includes("/vdocs/");
  const isPlainNote = window.location.pathname.includes("/docs/");

  // update is_public attribute on server only when is_public option is changed.
  useEffect(() => {
    if (isPublic !== isPublicInitialValueRef.current) {
      isPublicInitialValueRef.current = isPublic;
      toggleDocumentSharingOption(isPublic);
    }
    setShareVdocs(isPublic && currentDocument.mapped_videos && currentDocument.mapped_videos.length > 0);
  }, [isPublic]);

  /*-------SEO metadata logic------ */
  useEffect(() => {
    if (!currentDocument) return;
    const documentMoreInfo = currentDocument["more_info"] ? JSON.parse(currentDocument["more_info"]) : null;
    if (!documentMoreInfo) return;
    const isPublished = currentDocument["is_public"] && documentMoreInfo["is_published"];
    const SEODescription = documentMoreInfo["seo_description"] || "";
    const SEOKeywords = documentMoreInfo["seo_keywords"] || "";
    const recommendedNoteKeys = documentMoreInfo["recommended_note_keys"] || "";
    setIsPublished(isPublished);
    setSEODescription(SEODescription);
    setSEOKeywords(SEOKeywords);
    setRecommendedNoteKeys(recommendedNoteKeys);
  }, [currentDocument]);

  useUpdateEffect(() => {
    updateDocumentMoreInfo("isPublished");
  }, [isPublished]);

  const updateDocumentMoreInfo = async (field) => {
    const currentMoreInfo = currentDocument["more_info"] ? JSON.parse(currentDocument["more_info"]) : {};
    const newMoreInfo = {
      ...currentMoreInfo,
      is_published: isPublished,
      seo_description: SEODescription,
      seo_keywords: SEOKeywords,
      recommended_note_keys: recommendedNoteKeys,
    };
    await dispatch(
      patchDocument({
        documentKey: currentDocument["document_key"],
        options: {
          more_info: JSON.stringify(newMoreInfo),
        },
      })
    );
    field !== "isPublished" &&
      setTimeout(() => {
        if (lang === "ko" && field === "SEOKeywords") alert("검색 키워드가 업데이트되었습니다!");
        if (lang !== "ko" && field === "SEOKeywords") alert("Keywords Updated!");
        if (lang === "ko" && field === "SEODescription") alert("노트에 관한 설명이 업데이트되었습니다!");
        if (lang !== "ko" && field === "SEODescription") alert("Note Description Updated!");
        if (lang === "ko" && field === "recommendedNoteKeys") alert("추천 노트 키가 업데이트되었습니다!");
        if (lang !== "ko" && field === "recommendedNoteKeys") alert("Recommended Note Keys Updated!");
      }, 100);
  };
  /*------------seo-meta-logic-ends------------------*/

  const toggleDocumentSharingOption = async (isPublic) => {
    const updatedDocument = await dispatch(
      patchDocument({
        documentKey: currentDocument["document_key"],
        options: {
          is_public: isPublic,
        },
      })
    );
    // if updating document failed, revert isPublic.
    if (updatedDocument.error_message) {
      setIsPublic(!isPublic);
    }
  };

  const onClickCopyBtn = () => {
    if (isCopied) return;
    try {
      // copy to clipboard
      const shareLink = getShareLink();
      if (!shareLink) return;
      navigator.clipboard.writeText(shareLink);
      setIsCopied(true);

      sendAmplitudeData("SLID_2_COPY_SHARE_LINK");
      trackEvent({
        eventType: "Copy share note link",
        eventProperties: {
          from: isVideoNote ? "Video Note" : isPlainNote ? "Plain Note" : "My Notes",
        },
      });

      // reset
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (err) {
      // unable to copy
      setIsCopied(false);
    }
  };

  const getShareLink = () => {
    const prefix = `${SLID_WEB_SITE_URL}/share/${shareVdocs ? "vdocs" : "docs"}`;
    const title = currentDocument && currentDocument["title"] ? currentDocument["title"] : "";
    const sanitized_title = titleToUrlSanitizer(title);
    const documentKey = currentDocument["document_key"] ? currentDocument["document_key"] : "";
    return prefix + `/${sanitized_title ? sanitized_title + "-" : ""}` + documentKey;
  };

  return (
    <SharePopup isPCPlainNote={!isMobile && isPlainNote} isMobile={isMobile}>
      <PopupContent isPublic={isPublic}>
        <Typography20 text={t(!isPublic || !isShareButtonClicked ? "ShareNotePopupTitle1" : "ShareNotePopupTitle2")} color={`--gray17`} weight={700} />
        <Typography15
          text={t(isPublic ? `ShareNotePopupDesc2${isMobile ? "Mobile" : ""}` : `ShareNotePopupDesc1${isMobile ? "Mobile" : ""}`)}
          color={`--gray17`}
          marginTop={isMobile ? "12px" : "4px"}
        />
        <PopupButtonWrapper isPublic={isPublic}>
          <ShareWithVideoWrapper showCheckbox={isPublic && currentDocument["mapped_videos"]?.length > 0}>
            <ShareWithVideoCheckbox
              type="checkbox"
              checked={isPublic && shareVdocs}
              onChange={() => {
                trackEvent({
                  eventType: "Click VIEW THIS NOTE WITH VIDEO in note share modal",
                  eventProperties: {
                    result_state: !shareVdocs ? "ON" : "OFF",
                  },
                });
                setShareVdocs(!shareVdocs);
              }}
              onClick={(event) => {
                event.nativeEvent.stopImmediatePropagation();
              }}
              id="checkbox"
            />
            <ShareWithVideoLabel
              htmlFor="checkbox"
              onClick={(event) => {
                event.nativeEvent.stopImmediatePropagation();
              }}
            >
              <Typography13 text={t("ShareNotePopupShareWithVideo")} color={`--gray13`} />
            </ShareWithVideoLabel>
          </ShareWithVideoWrapper>
          <ShareNoteButton
            marginTop={`28px`}
            text={t(!isPublic ? "ShareNotePopupLinkGenerateButton" : isCopied ? "NoteCopied" : "ShareNotePopupLinkCopyButton")}
            appearance={`primary`}
            size={`medium`}
            onClick={(event) => {
              event.nativeEvent.stopImmediatePropagation();
              if (!isPublic) {
                // the button is for generating link
                trackEvent({
                  eventType: "Click CREATE SHARABLE LINK in note share modal",
                  eventProperties: {
                    from: isVideoNote ? "Video Note" : isPlainNote && isReadOnly ? "View Note" : isPlainNote && !isReadOnly ? "Edit Note" : "My Notes",
                  },
                });
                setIsPublic(true);
                setIsShareButtonClicked(true);
              } else {
                // the button is for copying link
                trackEvent({
                  eventType: "Click COPY LINK in note share modal",
                  eventProperties: {
                    type: shareVdocs ? "Video Note" : "Plain Note",
                  },
                });
                onClickCopyBtn();
              }
            }}
            style={{ backgroundColor: isCopied && "var(--blue7)", width: !isPublic || isMobile ? "100%" : "" }}
          />
          {isPublic && isMobile && (
            <ShareNoteButton
              marginTop={`8px`}
              text={t("SwitchToPrivateNote")}
              appearance={`secondary`}
              size={`medium`}
              callback={() => {
                trackEvent({
                  eventType: "Click SWITCH TO PRIVATE in note share modal",
                });
                setIsPublic(false);
                setTimeout(() => {
                  dispatch(setShowSharePopup(false));
                  showToast(t("StopSharingNoteToast"));
                });
              }}
              style={{ width: isMobile && "100%" }}
            />
          )}
        </PopupButtonWrapper>
      </PopupContent>
      {/* {isPublic && <ShareInvitationOption />} */}
      {isPublic && !isMobile && (
        <StopSharingWrapper>
          <Typography13 text={t("StopSharingNoteDesc")} color={`--gray9`} />
          <StopSharingButton
            onClick={() => {
              trackEvent({
                eventType: "Click SWITCH TO PRIVATE in note share modal",
              });
              setIsPublic(false);
              setTimeout(() => {
                dispatch(setShowSharePopup(false));
                showToast(t("StopSharingNoteToast"));
              });
            }}
            title={t("StopSharingNoteButton")}
          >
            {t("StopSharingNoteButton")}
          </StopSharingButton>
        </StopSharingWrapper>
      )}
      {isPublic && userData && userData.email && userData.email.includes("@slid.cc") && (
        <LinkShareContainer>
          <PopupButtonWrapper
            onClick={(event) => {
              event.nativeEvent.stopImmediatePropagation();
              setIsPublished(!isPublished);
            }}
          >
            <Typography20 text={t("PublishToWeb")} color={`--gray17`} weight={700} />
            <ToggleWrapper>
              <ToggleControl className="toggle-control">
                <ToggleInput type="checkbox" checked={isPublished} disabled />
                <ControlButton className="control"></ControlButton>
              </ToggleControl>
            </ToggleWrapper>
          </PopupButtonWrapper>
          <Typography15 text={t("MakeNoteSearchable")} color={`--gray17`} />
          <Accordion activeKey={isPublished ? "1" : "0"}>
            <Accordion.Collapse eventKey={"1"}>
              <>
                <LinkInputContainer>
                  <InputGroup>
                    <FormControl
                      readOnly={!isPublished}
                      disabled={!isPublished}
                      as={"textarea"}
                      rows={3}
                      placeholder={lang === "ko" ? "노트에 관한 설명을 추가해주세요" : "Enter a brief description of your note"}
                      value={SEODescription}
                      onChange={(event) => {
                        setSEODescription(event.target.value);
                      }}
                      onClick={(event) => {
                        event.nativeEvent.stopImmediatePropagation();
                      }}
                    />
                    <BootstrapButton
                      variant="light"
                      onClick={(event) => {
                        event.nativeEvent.stopImmediatePropagation();
                        if (!SEODescription) return;
                        updateDocumentMoreInfo("SEODescription");
                      }}
                      disabled={!isPublic}
                    >
                      Save
                    </BootstrapButton>
                  </InputGroup>
                </LinkInputContainer>
                <LinkInputContainer>
                  <InputGroup>
                    <FormControl
                      readOnly={!isPublished}
                      disabled={!isPublished}
                      placeholder={lang === "ko" ? "검색 키워드 추가 (콤마로 구분 예시: 코딩, 자바스크립트, 리액트)" : "Comma separated SEO Keywords ex: coding,javascript,react"}
                      as={"textarea"}
                      rows={2}
                      value={SEOKeywords}
                      onChange={(event) => setSEOKeywords(event.target.value)}
                      onClick={(event) => {
                        event.nativeEvent.stopImmediatePropagation();
                      }}
                    />
                    <BootstrapButton
                      variant="light"
                      onClick={(event) => {
                        event.nativeEvent.stopImmediatePropagation();
                        if (!SEOKeywords) return;
                        updateDocumentMoreInfo("SEOKeywords");
                      }}
                      disabled={!isPublished}
                    >
                      Save
                    </BootstrapButton>
                  </InputGroup>
                </LinkInputContainer>
                <LinkInputContainer>
                  <InputGroup>
                    <FormControl
                      readOnly={!isPublished}
                      disabled={!isPublished}
                      placeholder={lang === "ko" ? "추천 노트 키 (콤마로 구분)" : "Recommended note keys (comma separated)"}
                      as={"textarea"}
                      rows={3}
                      value={recommendedNoteKeys}
                      onChange={(event) => setRecommendedNoteKeys(event.target.value)}
                      onClick={(event) => {
                        event.nativeEvent.stopImmediatePropagation();
                      }}
                    />
                    <BootstrapButton
                      variant="light"
                      onClick={(event) => {
                        event.nativeEvent.stopImmediatePropagation();
                        if (!recommendedNoteKeys) return;
                        updateDocumentMoreInfo("recommendedNoteKeys");
                      }}
                      disabled={!isPublished}
                    >
                      Save
                    </BootstrapButton>
                  </InputGroup>
                </LinkInputContainer>
              </>
            </Accordion.Collapse>
          </Accordion>
        </LinkShareContainer>
      )}
    </SharePopup>
  );
});

const SharePopup = styled.div`
  position: absolute;
  left: 50%;
  /* in plain note on PCs, the modal is located under option buttons */
  top: ${({ isPCPlainNote }) => (isPCPlainNote ? "41px" : "50%")};
  transform: ${({ isPCPlainNote }) => (isPCPlainNote ? "translate(-50%, 0);" : "translate(-50%, -50%);")};

  background-color: #fff;
  box-shadow: var(--boxShadow3);
  border-radius: 8px;
  min-width: 40rem;
  width: 42rem;
  z-index: 99999;

  @media screen and (max-width: 799px) {
    width: 85vw;
    min-width: initial;
    max-width: 400px;
  }
`;

const PopupContent = styled.div`
  padding: ${({ isPublic }) => (isPublic ? "24px 24px 16px 32px" : "24px 20px 24px 32px")};

  @media screen and (max-width: 799px) {
    padding: 32px 20px 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    * {
      text-align: center;
    }
  }
`;

const PopupButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and (max-width: 799px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: ${({ isPublic }) => (isPublic ? "24px" : "")};
  }
`;

const ShareWithVideoWrapper = styled.div`
  visibility: ${({ showCheckbox }) => (showCheckbox ? "visible" : "hidden")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  *:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 799px) {
    display: ${({ showCheckbox }) => (showCheckbox ? "flex" : "none")};
    align-items: center;
    justify-content: center;
  }
`;

const ShareWithVideoCheckbox = styled.input``;

const ShareWithVideoLabel = styled.label``;

const ShareNoteButton = styled(Button)``;

const StopSharingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: var(--gray2);
  padding: 12px 24px 16px 32px;
  border-radius: 0 0 8px 8px;
`;

const StopSharingButton = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: var(--gray9);
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin-left: 12px;
  &:hover {
    font-weight: 700;
  }

  /* the below is to prevent font weight changes modal width */
  &::after {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`;

const LinkShareContainer = styled.div`
  padding: 24px 24px 24px 32px;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleControl = styled.label`
  display: block;
  position: relative;
  padding-left: 48px;
  margin-bottom: 28px;
  cursor: pointer;
  font-size: 2.2rem;
  user-select: none;
`;

const ToggleInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: var(--blue8);

    &:after {
      left: 24px;
    }
  }
`;

const ControlButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 48px;
  border-radius: 34px;
  background-color: var(--gray5);
  transition: background-color 0.15s ease-in;

  &:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    transition: left 0.15s ease-in;
  }
`;

const LinkInputContainer = styled.div`
  margin-top: 18px;
  width: 100%;
`;
export default DocumentShare;
