// auth 2.0
import * as Sentry from "@sentry/browser";
import env from "config/env";
import Cookie from "js-cookie";
import { deviceType, isIPad13 } from "react-device-detect";
import { customAlphabet } from "nanoid/non-secure";

const SLID_EXTENSION = env.end_point_url.slid_extension;
const EDGE_EXTENSION = env.end_point_url.edge_extension;

export const getTextDataToMerge = (data) => {
  if (!data) return "";

  if ("items" in data) {
    return data.items[0].text;
  }
  return data.text;
};

export const setCursorAtPosition = (elem, offset) => {
  let range = document.createRange();
  let sel = window.getSelection();

  range.setStart(elem.firstChild, offset);
  range.collapse(true);

  sel.removeAllRanges();
  sel.addRange(range);
};

export const convertToArrow = (event) => {
  // Maps triggering keys to corresponding conversions based on the preceding character
  const convertArrowMap = {
    ">": { "-": "→", "=": "⇒", "←": "↔️", "⇐": "⇔️", "<": "↔️" },
    "-": { "<": "←" },
    "=": { "<": "⇐" },
  };

  if (!convertArrowMap[event.key]) return;

  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0 && selection.focusNode) {
    const focusNode = selection.focusNode;
    const focusOffset = selection.focusOffset;

    // Check if conversion is applicable
    if (isConversionApplicable(focusNode, focusOffset, event.key, convertArrowMap)) {
      performTextReplacement(event, focusNode, focusOffset, convertArrowMap);
    }
  }
};

function isConversionApplicable(focusNode, focusOffset, key, convertMap) {
  if (!focusNode.nodeValue || focusOffset === 0) return false;
  const precedingChar = focusNode.nodeValue[focusOffset - 1];
  return Boolean(convertMap[key][precedingChar]);
}

function performTextReplacement(event, focusNode, focusOffset, convertMap) {
  event.preventDefault(); // Prevent the character from being inserted

  const selection = window.getSelection();
  const range = selection.getRangeAt(0);
  const precedingChar = focusNode.nodeValue[focusOffset - 1];
  const replacementText = convertMap[event.key][precedingChar];
  focusNode.nodeValue = focusNode.nodeValue.substring(0, focusOffset - 1) + replacementText + focusNode.nodeValue.substring(focusOffset);

  // Create a new range and set the cursor position after the replacement text
  range.setStart(focusNode, focusOffset);
  range.setEnd(focusNode, focusOffset);
  selection.removeAllRanges();
  selection.addRange(range);
}

export const getRandomString = (length) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
export const toUnicode = (theString) => {
  let unicodeString = "";
  for (let i = 0; i < theString.length; i++) {
    let theUnicode = theString.charCodeAt(i).toString(16).toUpperCase();
    while (theUnicode.length < 4) {
      theUnicode = "0" + theUnicode;
    }
    theUnicode = "\\u" + theUnicode;
    unicodeString += theUnicode;
  }
  return unicodeString;
};

export const setClipboard = (value) => {
  var tempInput = document.createElement("textarea");
  tempInput.style = "height: 0; overflow:hidden;";
  tempInput.innerText = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isElectron = () => {
  /* check is we are launching from the desktop app */

  // Renderer process
  if (typeof window !== "undefined" && typeof window.process === "object" && window.process.type === "renderer") {
    return true;
  }
  // Main process
  if (typeof process !== "undefined" && typeof process.versions === "object" && !!process.versions.electron) {
    return true;
  }
  // Detect the user agent when the `nodeIntegration` option is set to true
  if (typeof navigator === "object" && typeof navigator.userAgent === "string" && navigator.userAgent.indexOf("Electron") >= 0) {
    return true;
  }
  return false;
};

export const isIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const findLatestOfTwoVersions = (version1, version2) => {
  // returns the more recent of the two versions
  // assumes the versions are in format: xx.yy.zz

  const version1Segments = version1.split(".");
  const version2Segments = version2.split(".");

  for (let i = 0; i < 3; i++) {
    const intInVersion1 = parseInt(version1Segments[i]);
    const intInVersion2 = parseInt(version2Segments[i]);
    if (intInVersion1 > intInVersion2) return version1;
    if (intInVersion2 > intInVersion1) return version2;
  }

  // versions are the same
  return version1;
};

// check receivedData from new version or stable version (extension).
export const convertReceivedDataIntoObjectType = (messageData) => {
  try {
    if (typeof messageData === "string") {
      return JSON.parse(messageData);
    } else {
      // should be Object type
      return messageData;
    }
  } catch (error) {
    // case: messageData is string but not object string.
  }
};

export const cloneDeep = (obj) => {
  let copy = {};
  if (Array.isArray(obj)) {
    copy = obj.slice().map((v) => {
      return cloneDeep(v);
    });
  } else if (typeof obj === "object" && obj !== null) {
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = cloneDeep(obj[attr]);
      }
    }
  } else {
    copy = obj;
  }
  return copy;
};

export const strictParseInt = (value) => {
  if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) return Number(value);
  return NaN;
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const romanize = (num) => {
  if (isNaN(num)) return NaN;
  const digits = String(+num).split(""),
    key = ["", "c", "cc", "ccc", "cd", "d", "dc", "dcc", "dccc", "cm", "", "x", "xx", "xxx", "xl", "l", "lx", "lxx", "lxxx", "xc", "", "i", "ii", "iii", "iv", "v", "vi", "vii", "viii", "ix"];
  let roman = "",
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
};

export const alphabetize = (num) => {
  let ordA = "a".charCodeAt(0);
  let ordZ = "z".charCodeAt(0);
  let len = ordZ - ordA;

  let s = "";
  while (num >= 0) {
    s = String.fromCharCode((num % len) + ordA - 1) + s;
    num = Math.round(num / len) - 1;
  }
  return s;
};

export const makeElement = (tagName, classNames = null, attributes = {}) => {
  const el = document.createElement(tagName);
  el.spellcheck = false;

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  return el;
};

export function getCaretPos(element) {
  let caretOffset = 0;
  const doc = element.ownerDocument || element.document;
  const win = doc.defaultView || doc.parentWindow;
  let sel;
  if (typeof win.getSelection != "undefined") {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type != "Control") {
    const textRange = sel.createRange();
    const preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint("EndToEnd", textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}

export const sendMessageToPrimary = ({ message, port }) => {
  /*
    Sends messages to the primary window of Slid Desktop
    */
  if (port) {
    port.postMessage(message);
    return true;
  } else {
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      Sentry.captureMessage("SLID_DESKTOP_IFRAME_DID_NOT_RECEIVE_PORT");
    });
    return false;
  }
};

export const isMobileApp = () => {
  // NOTE: Since we are showing modal for termination of service, we need to keep this logic.
  return window?.navigator?.userAgent.includes("MobileApp");
};

export const isSlidPocketMobileApp = () => {
  return window?.navigator?.userAgent.includes("SlidPocket");
};

export const isTouchDevice = () => {
  return "ontouchstart" in window;
};

export const getApplicationType = () => {
  let applicationType;
  if (isElectron()) {
    applicationType = "desktop";
  } else if (isIframe()) {
    applicationType = "extension";
  } else if (isMobileApp()) {
    if (isTabletApp()) applicationType = "tablet";
    else applicationType = "mobile";
  } else if (isSlidPocketMobileApp()) {
    applicationType = "slidpocket";
  } else {
    applicationType = "web";
  }
  return applicationType;
};

export const getBrowserType = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  if (agent.indexOf("edg") !== -1) {
    return "Edge";
  } else if (agent.indexOf("whale") !== -1) {
    return "Whale";
  } else {
    return "Chrome";
  }
};

export const openExtensionWebStoreByBrowser = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  // Since whale store is not working properly with installing extension, open chrome store also on whale browser
  // if (agent.indexOf("whale") !== -1) {
  //   window.location = WHALE_EXTENSION;
  // }
  if (agent.indexOf("edg") !== -1) {
    window.location = EDGE_EXTENSION;
  } else {
    window.location = SLID_EXTENSION;
  }
};

export const openExtensionWebStoreInNewTab = () => {
  const agent = window.navigator.userAgent.toLowerCase();
  // Since whale store is not working properly with installing extension, open chrome store also on whale browser
  // if (agent.indexOf("whale") !== -1) {
  //   window.open(WHALE_EXTENSION);
  // }
  if (agent.indexOf("edg") !== -1) {
    window.open(EDGE_EXTENSION);
  } else {
    window.open(SLID_EXTENSION);
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getUserLocationInfo = () => {
  return fetch("https://get.geojs.io/v1/ip/country.json").then((res) => res.json());
};

export const copyToClipboard = (contentToCopy) => {
  const tempTextareaForCopy = document.createElement("textarea");
  tempTextareaForCopy.position = "absolute";
  tempTextareaForCopy.top = `-9999999px`;
  tempTextareaForCopy.left = `-9999999px`;
  document.body.appendChild(tempTextareaForCopy);
  tempTextareaForCopy.value = contentToCopy;
  // TODO: When we use this method inside Dropdown.item, tempTextareaForCopy.select() is not working without setTimeout.
  // We don't know the exact reason. Please Refactor someday.
  setTimeout(() => {
    tempTextareaForCopy.select();
    document.execCommand("copy");
    tempTextareaForCopy.remove();
  });
};

export const checkIsOnboardingNeededUser = (userData) => {
  if (!userData || !userData.created_time) return false;
  const currentTime = Date.now();
  const createdTime = new Date(userData.created_time);
  // Set as new user when gap between current time and account created time is less than 3 minute
  return currentTime - createdTime.getTime() < 60000 * 3;
};

/**
 *
 * @param {string} title
 * @return {string} sanitizer title
 * 1. Turn the title to lowercase
 * 2. Separate words by empty space
 * 3. Remove all special characters
 * 4. Filter out empty words
 * 5. Combine all words into one string with - as separator. (length is limited to 50)
 */
export const titleToUrlSanitizer = (title) => {
  const url = title.toLowerCase().split(" "); // 1, 2
  const urlWithoutSpecialCharacters = url.map((word) => {
    return word.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/gi, "");
  }); // 3

  const sanitizedUrl = urlWithoutSpecialCharacters.filter((word) => word.length > 0); // 4
  return sanitizedUrl.join("-").substring(0, 100); // 5
};

export const getEventLocationMode = () => {
  const location = window.location.pathname;
  if (location.includes("/vdocs")) return "video note";
  else return "plain note";
};

export const getCookie = (name) => {
  return Cookie.get(name);
};

export const getGracePeriodAppliedDate = ({ originDate, gracePeriods }) => {
  let totalGracePeriodDays = 0;
  gracePeriods.map((gracePeriod) => (totalGracePeriodDays += gracePeriod.days));

  return new Date(originDate.getFullYear(), originDate.getMonth(), originDate.getDate() + totalGracePeriodDays);
};

export function isIOS() {
  let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
  return isIOS;
}

export const getFormattedTime = (milliSeconds, formatType = "default", language) => {
  let time = "00:00:00";
  let separatedTime = language === "ko" ? "00시간 00분" : "--H --M";
  if (!milliSeconds || milliSeconds < 1000) return time;

  const totalSeconds = parseInt(Math.floor(milliSeconds / 1000));
  const totalMinutes = parseInt(Math.floor(totalSeconds / 60));
  const totalHours = parseInt(Math.floor(totalMinutes / 60));

  const seconds = parseInt(totalSeconds % 60);
  const minutes = parseInt(totalMinutes % 60);
  const hours = parseInt(totalHours);

  const formattedSeconds = ("0" + seconds).slice(-2);
  const formattedMinutes = ("0" + minutes).slice(-2);
  const formattedHours = ("0" + hours).slice(-2);

  if (hours > 0) {
    time = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    separatedTime = language === "ko" ? `${formattedHours}시간 ${formattedMinutes}분` : `${formattedHours}H ${formattedMinutes}M`;
  } else if (minutes > 0) {
    time = `00:${formattedMinutes}:${formattedSeconds}`;
    separatedTime = language === "ko" ? `00시간 ${formattedMinutes}분` : `--H ${formattedMinutes}M`;
  } else if (seconds > 0) {
    time = `00:00:${formattedSeconds}`;
  }

  return formatType === "separated" ? separatedTime : time;
};
export const getIpadHomeButtonOrNot = (deviceId) => {
  if (!deviceId.includes("iPad")) return null;
  switch (deviceId) {
    // Without home button
    case "iPad13,18": // iPad 10th Gen
    case "iPad13,19": // iPad 10th Gen

    case "iPad13,1": // iPad Air 4th Gen (WiFi)
    case "iPad13,2": // iPad Air 4th Gen (WiFi+Cellular)
    case "iPad13,16": // iPad Air 5th Gen (WiFi)
    case "iPad13,17": // iPad Air 5th Gen (WiFi+Cellular)

    case "iPad8,1": // iPad Pro 11 inch 3rd Gen (WiFi)
    case "iPad8,2": // iPad Pro 11 inch 3rd Gen (1TB, WiFi)
    case "iPad8,3": // iPad Pro 11 inch 3rd Gen (WiFi+Cellular)
    case "iPad8,4": // iPad Pro 11 inch 3rd Gen (1TB, WiFi+Cellular)
    case "iPad8,5": // iPad Pro 12.9 inch 3rd Gen (WiFi)
    case "iPad8,6": // iPad Pro 12.9 inch 3rd Gen (1TB, WiFi)
    case "iPad8,7": // iPad Pro 12.9 inch 3rd Gen (WiFi+Cellular)
    case "iPad8,8": // iPad Pro 12.9 inch 3rd Gen (1TB, WiFi+Cellular)
    case "iPad8,9": // iPad Pro 11 inch 4th Gen (WiFi)
    case "iPad8,10": // iPad Pro 11 inch 4th Gen (WiFi+Cellular)
    case "iPad8,11": // iPad Pro 12.9 inch 4th Gen (WiFi)
    case "iPad8,12": // iPad Pro 12.9 inch 4th Gen (WiFi+Cellular)

    case "iPad14,3-A": // iPad Pro 11 inch 4th Gen
    case "iPad14,3-B": // iPad Pro 11 inch 4th Gen
    case "iPad14,4-A": // iPad Pro 11 inch 4th Gen
    case "iPad14,4-B": // iPad Pro 11 inch 4th Gen

    case "iPad13,4": // iPad Pro 11 inch 5th Gen
    case "iPad13,5": // iPad Pro 11 inch 5th Gen
    case "iPad13,6": // iPad Pro 11 inch 5th Gen
    case "iPad13,7": // iPad Pro 11 inch 5th Gen
    case "iPad13,8": // iPad Pro 12.9 inch 5th Gen
    case "iPad13,9": // iPad Pro 12.9 inch 5th Gen
    case "iPad13,10": // iPad Pro 12.9 inch 5th Gen
    case "iPad13,11": // iPad Pro 12.9 inch 5th Gen

    case "iPad14,5": // iPad Pro 12.9 inch 6th Gen
    case "iPad14,5-A": // iPad Pro 12.9 inch 6th Gen
    case "iPad14,5-B": // iPad Pro 12.9 inch 6th Gen
    case "iPad14,6-A": // iPad Pro 12.9 inch 6th Gen
    case "iPad14,6-B": // iPad Pro 12.9 inch 6th Gen
      return "WithoutHomeButton";

    // With home button
    case "iPad1,1": // iPad
    case "iPad1,2": // iPad 3G
    case "iPad2,1": // 2nd Gen iPad
    case "iPad2,2": // 2nd Gen iPad GSM
    case "iPad2,3": // 2nd Gen iPad CDMA
    case "iPad2,4": // 2nd Gen iPad New Revision
    case "iPad3,1": // 3rd Gen iPad
    case "iPad3,2": // 3rd Gen iPad CDMA
    case "iPad3,3": // 3rd Gen iPad GSM
    case "iPad3,4": // 4th Gen iPad
    case "iPad3,5": // 4th Gen iPad GSM+LTE
    case "iPad3,6": // 4th Gen iPad CDMA+LTE
    case "iPad6,11": // iPad (2017)
    case "iPad6,12": // iPad (2017)
    case "iPad7,5": // iPad 6th Gen (WiFi)
    case "iPad7,6": // iPad 6th Gen (WiFi+Cellular)
    case "iPad7,11": // iPad 7th Gen 10.2-inch (WiFi)
    case "iPad7,12": // iPad 7th Gen 10.2-inch (WiFi+Cellular)
    case "iPad11,6": // iPad 8th Gen (WiFi)
    case "iPad11,7": // iPad 8th Gen (WiFi+Cellular)
    case "iPad12,1": // iPad 9th Gen (WiFi)
    case "iPad12,2": // iPad 9th Gen (WiFi+Cellular)

    case "iPad2,5": // iPad mini
    case "iPad2,6": // iPad mini GSM+LTE
    case "iPad2,7": // iPad mini CDMA+LTE
    case "iPad4,4": // iPad mini Retina (WiFi)
    case "iPad4,5": // iPad mini Retina (GSM+CDMA)
    case "iPad4,6": // iPad mini Retina (China)
    case "iPad4,7": // iPad mini 3 (WiFi)
    case "iPad4,8": // iPad mini 3 (GSM+CDMA)
    case "iPad4,9": // iPad Mini 3 (China)
    case "iPad5,1": // iPad mini 4 (WiFi)
    case "iPad5,2": // 4th Gen iPad mini (WiFi+Cellular)
    case "iPad11,1": // iPad mini 5th Gen (WiFi)
    case "iPad11,2": // iPad mini 5th Gen

    case "iPad4,1": // iPad Air (WiFi)
    case "iPad4,2": // iPad Air (GSM+CDMA)
    case "iPad4,3": // 1st Gen iPad Air (China)
    case "iPad5,3": // iPad Air 2 (WiFi)
    case "iPad5,4": // iPad Air 2 (Cellular)
    case "iPad11,3": // iPad Air 3rd Gen (WiFi)
    case "iPad11,4": // iPad Air 3rd Gen

    case "iPad6,3": // iPad Pro (9.7 inch, WiFi)
    case "iPad6,4": // iPad Pro (9.7 inch, WiFi+LTE)
    case "iPad6,7": // iPad Pro (12.9 inch, WiFi)
    case "iPad6,8": // iPad Pro (12.9 inch, WiFi+LTE)
    case "iPad7,1": // iPad Pro 2nd Gen (WiFi)
    case "iPad7,2": // iPad Pro 2nd Gen (WiFi+Cellular)
    case "iPad7,3": // iPad Pro 10.5-inch 2nd Gen
    case "iPad7,4": // iPad Pro 10.5-inch 2nd Gen
      return "WithHomeButtonA";

    case "iPad14,1": // iPad mini 6th Gen (WiFi)
    case "iPad14,2": // iPad mini 6th Gen (WiFi+Cellular)
      return "WithHomeButtonB";

    default:
      return null;
  }
};

export const isTabletApp = () => {
  // NOTE: Since we are showing modal for termination of service, we need to keep this logic.
  return window?.navigator?.userAgent.includes("MobileApp") && (deviceType === "tablet" || isIPad13);
};

export const redirectToMyNotes = ({ history, iframePort }) => {
  const applicationType = getApplicationType();

  if (applicationType === "web" || isMobileApp()) {
    history.push("/docs");
  } else if (applicationType === "desktop") {
    if (iframePort) {
      iframePort.postMessage({
        type: "IFRAME_TO_PRIMARY_OPEN_MY_DOCS",
        payload: null,
      });
    } else {
      window.open(`/docs`);
    }
  } else {
    window.open("/docs", "_blank");
  }
};

export const downloadFromURI = (uri, title, ext) => {
  fetch(uri, { method: "GET" })
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${title ? title : "Untitled"}.${ext}`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch((err) => {
      console.error("err: ", err);
    });
};

export const parseUserDataMoreInfo = (userData) => {
  let userDataMoreInfo = {};
  try {
    userDataMoreInfo = JSON.parse(userData.more_info) || {};
  } catch {
    Sentry.withScope((scope) => {
      scope.setLevel("error");
      scope.setExtra("message", "JSON parse error (user/more_info)");
      Sentry.captureMessage("SLID_WEB_JSON_PARSE_ERROR");
    });
  } finally {
    return userDataMoreInfo;
  }
};

// 7-character random string
export const nanoid = customAlphabet("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", 7);
