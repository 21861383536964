import slidAxios from "./base";
import { handleAuthErrors, isAuthError } from "./errorUtils";
const ocr_endpoint = `ocrs/`;

export const ServerInterface_registerClipOCR = async ({ data }) => {
  const payload = data;
  let clipOCRRegisterData = null;

  try {
    const clipOCRPostResponse = await slidAxios.post(ocr_endpoint, payload);
    clipOCRRegisterData = clipOCRPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!clipOCRRegisterData) clipOCRRegisterData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          clipOCRRegisterData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          clipOCRRegisterData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // clip key not provided or duplicate request
          clipOCRRegisterData.error_message = "OCR_REGISTER_FAILED";
          break;
        default:
          clipOCRRegisterData.error_message = "UNKNOWN_ERROR";
          //TODO: log to sentry as it might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      clipOCRRegisterData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      clipOCRRegisterData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return clipOCRRegisterData;
  }
};

export const ServerInterface_getClipOCRResults = async ({ clip_key }) => {
  let clipOCRResultData = null;
  try {
    const clipOCRResultGetResponse = await slidAxios.get(`${ocr_endpoint}${clip_key}/`);
    clipOCRResultData = clipOCRResultGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!clipOCRResultData) clipOCRResultData = {};

    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          clipOCRResultData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          clipOCRResultData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          // matching clip is not found
          clipOCRResultData.error_message = "CLIP_NOT_FOUND";
          break;
        default:
          clipOCRResultData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      clipOCRResultData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      clipOCRResultData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return clipOCRResultData;
  }
};
