import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Typography15, Typography11, Icon } from "@slid/slid-ips";
import { MenuButton } from "./MenuButton";
import { MenuProps, MenuSizeType } from "./menu";
import { useTranslation } from "react-i18next";
import { trackEvent } from "utils/eventTracking";

export const LanguageSelectMenu = ({ icon, itemList, color, menuValue, ...HTMLDivElement }: MenuProps) => {
  const { t } = useTranslation("VideoNote");
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const [isLanguageMenuHovered, setIsLanguageMenuHovered] = useState<boolean>(false);

  return (
    <MenuWrapper
      onBlur={() => {
        setIsMenuActive(false);
      }}
      onMouseEnter={() => {
        if (!isLanguageMenuHovered) {
          trackEvent({ eventType: "Hover SET LIVETEXT LANGUAGE in video note page" });
          setIsLanguageMenuHovered(true);
        }
      }}
      {...HTMLDivElement}
    >
      <MenuButton
        color={color}
        icon={icon}
        menuValue={menuValue}
        isMenuActive={isMenuActive}
        size={MenuSizeType.Large}
        onClick={() => {
          setIsMenuActive(!isMenuActive);
          trackEvent({ eventType: "Click SET LIVETEXT LANGUAGE in video note page" });
        }}
        isDropDownMenu={true}
      />
      <AnimatePresence>
        {isMenuActive && (
          <MenuItemGroup data-testid={`dropdown-box`} variants={menuUlVariants} initial="initial" animate="animate" exit="initial">
            <MenuTitle>
              <Typography11 text={t("RecognizedLanguage")} color={`--gray9`} />
            </MenuTitle>
            {itemList.map((item, idx) => {
              return (
                <ItemWrapper
                  onClick={() => {
                    item.callback && item.callback();
                  }}
                  key={idx}
                  variants={menuListVariants}
                  initial="initial"
                  animate="animate"
                  exit="initial"
                >
                  <TextWrapper>
                    <Typography15 text={item.text} color={`--gray15`} weight={menuValue === item.text ? 700 : 400} />
                    {item.subText && <Typography15 text={item.subText} color={`--gray9`} weight={menuValue === item.text ? 700 : 400} />}
                  </TextWrapper>
                  {menuValue === item.text && <Icon icon={"thickCheck16"} color={`--gray15`} />}
                </ItemWrapper>
              );
            })}
          </MenuItemGroup>
        )}
      </AnimatePresence>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  user-select: none;
`;

const MenuItemGroup = styled(motion.ul)`
  /* S of reset ul */
  list-style: none;
  padding: 0;
  margin: 0;
  /* E of reset ul */

  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px 0;
  margin-left: 8px;
  min-width: 160px;
  background: var(--gray1);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 8px;
`;

const MenuTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 4px 12px;
`;

const ItemWrapper = styled(motion.li)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 4px 12px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: var(--gray4);
  }

  &:active {
    background-color: var(--gray3);
  }
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 4px;
  min-width: 200px;
`;

const menuUlVariants = {
  initial: {
    height: 0,

    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  },

  animate: {
    height: "auto",

    transition: {
      duration: 0.15,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const menuListVariants = {
  initial: {
    opacity: 0,
    y: 2,

    transition: {
      duration: 0.1,
    },
  },

  animate: {
    opacity: 1,
    y: 0,

    transition: {
      delay: 0.15,
      duration: 0.1,
      ease: "easeInOut",
    },
  },
};
