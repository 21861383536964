import { MediaRecordingChangeDirection } from "components/popups/DisableMediaRecordingModal";
import { useAppDispatch } from "hooks";
import { setMediaRecordingChangeDirection, setShowDisableMediaRecordingModal } from "redux/actions/vdocsActions";
import useWhisperSLTStore from "store/useWhisperSLTStore";

const useSLTMediaEffects = () => {
  const { setIsSTTToggledOn } = useWhisperSLTStore();
  const dispatch = useAppDispatch();

  const manageClipRecordingEffects = (direction: MediaRecordingChangeDirection) => {
    setIsSTTToggledOn(false);
    dispatch(setMediaRecordingChangeDirection(direction));
    dispatch(setShowDisableMediaRecordingModal(true));
  };

  return { manageClipRecordingEffects };
};

export default useSLTMediaEffects;
