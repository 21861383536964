import {
  BrazilianFlagIcon,
  ChineseFlagIcon,
  FrenchFlagIcon,
  GermanFlagIcon,
  IndianFlagIcon,
  JapaneseFlagIcon,
  KoreanFlagIcon,
  SpanishFlagIcon,
  USFlagIcon,
  DutchFlagIcon,
} from "components/icons/autoNotesIcons";
import { SvgIconProps } from "components/icons/types";

export interface ISLTSupportedLanguage {
  text: string;
  subText: string;
  icon?: React.FC<SvgIconProps>;
}

export const SLT_SUPPORTED_LANGUAGES: ISLTSupportedLanguage[] = [
  {
    text: "English",
    subText: "",
    icon: USFlagIcon,
  },
  {
    text: "한국어",
    subText: "(Korean)",
    icon: KoreanFlagIcon,
  },
  {
    text: "中文",
    subText: "(Simplified Chinese)",
    icon: ChineseFlagIcon,
  },
  {
    text: "हिन्दी",
    subText: "(Hindi)",
    icon: IndianFlagIcon,
  },
  {
    text: "Español",
    subText: "(Spanish)",
    icon: SpanishFlagIcon,
  },
  {
    text: "日本語",
    subText: "(Japanese)",
    icon: JapaneseFlagIcon,
  },
  {
    text: "Français",
    subText: "(French)",
    icon: FrenchFlagIcon,
  },
  {
    text: "Português",
    subText: "(Brasil)",
    icon: BrazilianFlagIcon,
  },
  {
    text: "Deutsch",
    subText: "(German)",
    icon: GermanFlagIcon,
  },
  {
    text: "Dutch",
    subText: "(Dutch)",
    icon: DutchFlagIcon,
  },
];
