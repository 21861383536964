import React from "react";
import styled from "styled-components";
import { Icon, Typography15, Typography13 } from "@slid/slid-ips";
import { motion } from "framer-motion";
import { MenuSizeType } from "./menu";

export interface MenuButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  size?: MenuSizeType;
  icon?: JSX.Element;
  color: "--gray15" | "--gray3" | "--gray9";
  isMenuActive?: boolean;
  isDropDownMenu?: boolean;
  menuValue: string;
}

export const MenuButton = ({ icon, size = MenuSizeType.Regular, color, isMenuActive = false, isDropDownMenu = false, menuValue, ...HTMLButtonElement }: MenuButtonProps) => {
  return (
    <MenuButtonContainer data-testid={"dropdown-menu-button"} {...HTMLButtonElement}>
      {icon && <IconContainer>{icon}</IconContainer>}
      {size === MenuSizeType.Large ? (
        <LargeMenuValueText text={menuValue} color={color} weight={700} data-testid={`dropdown-value`} />
      ) : (
        <MenuValueText text={menuValue} color={color} weight={700} data-testid={`dropdown-value`} />
      )}

      {isDropDownMenu && (
        <DropdownIconContainer variants={rotation} animate={isMenuActive ? "open" : "closed"} transition={{ duration: 0.3 }}>
          {size === MenuSizeType.Large ? <Icon icon={"chevronDown24"} color={color} /> : <Icon icon={"chevronDown16"} color={color} />}
        </DropdownIconContainer>
      )}
    </MenuButtonContainer>
  );
};

const MenuButtonContainer = styled.button`
  /* s of reset button */
  background: inherit;
  border: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline-style: none;

  &:focus {
    outline: 0;
    border: none;
  }
  /* e of reset button  */

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  border-radius: 8px;
  max-width: fit-content;
  cursor: pointer;

  &:hover {
    background-color: var(--gray15);
  }

  &:active {
    background-color: var(--gray13);
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-right: 4px;
`;

const LargeMenuValueText = styled(Typography15)`
  && {
    text-align: center;
    white-space: nowrap;
  }
`;

const MenuValueText = styled(Typography13)`
  && {
    text-align: center;
    white-space: nowrap;
  }
`;

const DropdownIconContainer = styled(motion.div)`
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const rotation = {
  closed: { rotate: 0 },
  open: { rotate: 180 },
};
