import { Icon, Typography13 } from "@slid/slid-ips";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { trackEvent } from "utils/eventTracking";
import { SlidFeatures } from "utils/privilegeManager";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks";
import styled from "styled-components";
import { eventTypes } from "types/eventTracking";
import useSLTMediaEffects from "hooks/whisperSLTRealTime/useSLTMediaEffects";
import useWhisperSLTStore from "store/useWhisperSLTStore";

const SmartLiveTextModeToggleButton = () => {
  const { t } = useTranslation("SmartLiveText");
  const { isManualAddingMode, isSTTToggledOn, setIsManualAddingMode, setIsSTTToggledOn } = useWhisperSLTStore();
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const { manageClipRecordingEffects } = useSLTMediaEffects();
  const { isRecordActive } = useAppSelector((state) => state.vdocs);
  const { isAutoNotesToggledOn } = useAppSelector((state) => state.autoNotes);

  const handleToggleChange = () => {
    if (!isManualAddingMode) {
      trackEvent({
        eventType: eventTypes.click.ADD_MANUALLY_IN_AUTO_TYPING_SIDEBAR,
      });
      setIsManualAddingMode(true);
      window.localStorage.setItem("slid-stt-manual-adding-mode", "true");
    } else {
      trackEvent({
        eventType: eventTypes.click.AUTO_ADD_IN_AUTO_TYPING_SIDEBAR,
      });
      setIsManualAddingMode(false);
      window.localStorage.setItem("slid-stt-manual-adding-mode", "false");
    }
  };

  if (isSTTToggledOn) {
    return (
      <ButtonWrapper>
        <SwitchLabel>
          <SwitchInput type="checkbox" onChange={() => handleToggleChange()} checked={!isManualAddingMode} />
          <Slider />
          <OptionTextWrapper>
            <OptionText>
              <Typography13 text={t(isManualAddingMode ? "ManualAddingMode" : "ManualAddingModeShort")} color={isManualAddingMode ? `--white` : `--gray9`} weight={isManualAddingMode ? 700 : 400} />
            </OptionText>
            <OptionText>
              <Typography13
                text={t(!isManualAddingMode ? "AutomaticAddingMode" : "AutomaticAddingModeShort")}
                color={!isManualAddingMode ? `--white` : `--gray9`}
                weight={!isManualAddingMode ? 700 : 400}
              />
            </OptionText>
          </OptionTextWrapper>
        </SwitchLabel>
      </ButtonWrapper>
    );
  } else {
    return (
      <ButtonWrapper>
        <StartSTTButton
          onClick={() => {
            trackEvent({
              eventType: eventTypes.click.AUTO_TYPING_IN_VIDEO_NOTE_PAGE,
              eventProperties: {
                location: "auto-typing side bar",
              },
            });
            if (!confirmPrivilege(SlidFeatures.smartLiveText)) return showInsufficientPrivilegeModal();

            if (isRecordActive) {
              manageClipRecordingEffects("SNIPPET_TO_SLT");
              return;
            } else if (isAutoNotesToggledOn) {
              manageClipRecordingEffects("AUTO_NOTES_TO_SLT");
              return;
            }
            setIsSTTToggledOn(true);
          }}
        >
          <Icon icon={`autoTyping28`} color={`--white`} subColor={`--blue4`} />
          <Typography13 text={t("RestartSLTButton")} color={`--white`} weight={700} />
        </StartSTTButton>
      </ButtonWrapper>
    );
  }
};

const ButtonWrapper = styled.div`
  padding: 0 16px 16px;
  background-color: #191c20;
  * {
    cursor: pointer;
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  border-radius: 8px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span:before {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray19);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 8px;

  &:before {
    position: absolute;
    content: "";
    height: 34px;
    width: calc(50% - 3px);
    left: 3px;
    bottom: 3px;
    background-color: var(--blue1_cloudy);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 8px;
  }
`;

const OptionTextWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 3px;
  left: 3px;
  width: 100%;
`;

const OptionText = styled.div`
  width: calc(50% - 3px);
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartSTTButton = styled.div`
  width: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--blue9);

  &:hover {
    background: var(--blue12);
  }

  &:active {
    background: var(--blue11);
  }
`;

export default SmartLiveTextModeToggleButton;
