import { escapeHtml } from "./utils";

export function parseMarkdownToParagraph(blocks) {
  let itemText = handleParagraph(blocks);
  return {
    data: {
      text: itemText,
    },
    type: "paragraph",
  };
}

export function handleNestedTextChildren(blocks) {
  let itemText = "";
  for (const content of blocks.children) {
    if (content.type === "text") {
      itemText += escapeHtml(content.value);
    } else if (content.type === "emphasis") {
      itemText += parseEmphasisBlock(content);
    } else if (content.type === "strong") {
      itemText += parseStrongBlock(content);
    } else if (content.type === "inlineCode") {
      itemText += parseInlineCodeBlock(content);
    } else if (content.type === "html") {
      itemText += parseHTMLBlock(content);
    } else {
      //TODO: log to sentry - all possible blocks must be supported.
      console.log("unknown node type in paragraph", content);
    }
  }

  return itemText;
}

export function handleParagraph(blocks) {
  let itemText = "";
  if (blocks.type === "paragraph") {
    itemText = handleNestedTextChildren(blocks);
  }
  return itemText;
}

function parseEmphasisBlock(block) {
  let itemText = "";
  for (const content of block.children) {
    if (content.type === "text") {
      itemText += escapeHtml(content.value);
    } else if (content.type === "inlineCode") {
      itemText += parseInlineCodeBlock(content);
    } else if (content.type === "html") {
      itemText += parseHTMLBlock(content);
    } else if (content.type === "strong") {
      itemText += parseStrongBlock(content);
    }
  }
  return "<i>" + itemText + "</i>";
}

function parseStrongBlock(block) {
  let itemText = "";
  for (const content of block.children) {
    if (content.type === "text") {
      itemText += escapeHtml(content.value);
    } else if (content.type === "inlineCode") {
      itemText += parseInlineCodeBlock(content);
    } else if (content.type === "html") {
      itemText += parseHTMLBlock(content);
    } else if (content.type === "emphasis") {
      itemText += parseEmphasisBlock(content);
    }
  }
  return "<b>" + itemText + "</b>";
}

function parseInlineCodeBlock(block) {
  return '<code class="inline-code">' + escapeHtml(block.value) + "</code>";
}

function parseHTMLBlock(block) {
  return escapeHtml(block.value);
}
