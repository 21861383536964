import { Typography13, Typography15 } from "@slid/slid-ips";
import React from "react";
import styled from "styled-components";

interface HighLightTextProps {
  highLightText: string;
  contents: string;
  type: string;
}

const HighlightText = ({ highLightText, contents, type }: HighLightTextProps) => {
  const parts = contents.split(new RegExp(`(${highLightText.replace(/([.?*+^$[\]\\(){}|-])/g, "")})`, "gi"));
  return (
    <HighlightTextWrapper type={type}>
      {parts.map((part, i) =>
        type === "title" ? (
          <Typography15 type={"span"} text={part} color={part.toLowerCase() === highLightText.toLowerCase() ? "--gray17" : "--gray9"} key={i} weight={700} />
        ) : (
          <Typography13 type={"span"} text={part} color={part.toLowerCase() === highLightText.toLowerCase() ? "--gray17" : "--gray9"} key={i} />
        )
      )}
    </HighlightTextWrapper>
  );
};

const HighlightTextWrapper = styled.p<{ type: string }>`
  max-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: ${({ type }) => (type === "title" ? "1.5rem" : "1.3rem")};
  line-height: ${({ type }) => (type === "title" ? "2.3rem" : "1.8rem")};

  span {
    white-space: nowrap;
  }
`;

export default HighlightText;
