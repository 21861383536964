// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const SkipForwardIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }} fill="none">
      <path d="M18 11H22V7" stroke="#F1F3F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19.7724 19.6545C18.6445 20.774 17.2073 21.5366 15.6424 21.8458C14.0775 22.155 12.4553 21.997 10.981 21.3917C9.50667 20.7863 8.2465 19.761 7.35987 18.4452C6.47325 17.1295 6 15.5825 6 14C6 12.4175 6.47325 10.8705 7.35987 9.55476C8.2465 8.23903 9.50667 7.21365 10.981 6.60834C12.4553 6.00303 14.0775 5.84499 15.6424 6.1542C15.9761 6.22013 16.3039 6.30668 16.6243 6.4129C18.9793 7.1939 20.517 9.34416 22 11.3333V11.3333"
        stroke="#F1F3F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
