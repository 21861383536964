import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { setIsAutoNotesSettingsOpen } from "redux/modules/autoNotesSlice";
import { eventTypes } from "types/eventTracking";
import { trackEvent } from "utils/eventTracking";

type AutoNoteProgressStatus = "generating" | "waiting" | "addedToNote";

export const useAutoNotesProgressInfo = () => {
  const { isVideoPlaying } = useAppSelector((state) => state.vdocs);
  const dispatch = useAppDispatch();
  const [progressStatus, setProgressStatus] = useState<AutoNoteProgressStatus>("waiting");
  const { isAutoNotesSettingsOpen, isAutoNotesActive, isAutoNotesToggledOn, isAutoNotesBlockInserted } = useAppSelector((state) => state.autoNotes);

  useEffect(() => {
    if (isAutoNotesBlockInserted) {
      setProgressStatus("addedToNote");
    } else if (isAutoNotesActive && isVideoPlaying) {
      setProgressStatus("generating");
    } else if (isAutoNotesToggledOn) {
      setProgressStatus("waiting");
    }
  }, [isAutoNotesActive, isVideoPlaying, isAutoNotesBlockInserted, isAutoNotesToggledOn]);

  const onClickAutoNotesSettingButton = (e) => {
    trackEvent({
      eventType: eventTypes.click.AUTO_NOTES_SETTING_IN_VIDEO_NOTE_PAGE,
    });

    e.preventDefault();
    e.stopPropagation();
    dispatch(setIsAutoNotesSettingsOpen(true));
  };

  return {
    onClickAutoNotesSettingButton,
    progressStatus,
  };
};
