// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const SkipBackwardIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }} fill="none">
      <path d="M10 11H6V7" stroke="#F1F3F5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.22764 19.6545C9.35547 20.774 10.7927 21.5366 12.3576 21.8458C13.9225 22.155 15.5447 21.997 17.019 21.3917C18.4933 20.7863 19.7535 19.761 20.6401 18.4452C21.5268 17.1295 22 15.5825 22 14C22 12.4175 21.5268 10.8705 20.6401 9.55476C19.7535 8.23903 18.4933 7.21365 17.019 6.60834C15.5447 6.00303 13.9225 5.84499 12.3576 6.1542C12.0239 6.22013 11.6961 6.30668 11.3757 6.4129C9.0207 7.1939 7.48304 9.34416 6 11.3333V11.3333"
        stroke="#F1F3F5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
