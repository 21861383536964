//auth 2.0
/*
              EditorJS LaTeX
      Created By: MD Gaziur Rahman Noor
    Adds LaTex block support to EditorJS
*/
import "./index.css";
import katex from "katex";
import "katex/dist/katex.min.css";
import { isTouchDevice } from "utils/utils";
export default class LaTeXTool {
  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, readOnly }) {
    //Get the saved data if exists
    this.data = data.math;
    this.readOnly = readOnly;
  }

  static get toolbox() {
    return {
      title: "Math",
      icon: `<svg width="16" height="10" viewBox="0 0 16 10" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.296811 0.0429749C0.549154 -0.0691772 0.844634 0.0444693 0.956786 0.296811L4.46653 8.19374L7.49475 0.320391C7.56901 0.127295 7.75453 -0.000119348 7.96142 -0.000119348H15.4999C15.776 -0.000119348 15.9999 0.223738 15.9999 0.499881V2.18738C15.9999 2.46352 15.776 2.68738 15.4999 2.68738C15.2237 2.68738 14.9999 2.46352 14.9999 2.18738V0.999881H8.30482L4.96655 9.67937C4.89396 9.86812 4.71481 9.99456 4.51264 9.99972C4.31048 10.0049 4.12511 9.88775 4.04297 9.70295L0.0429749 0.70295C-0.0691772 0.450608 0.0444693 0.155127 0.296811 0.0429749Z" fill="currentColor" /></svg>`,
    };
  }

  render() {
    //Create all the DOM elements
    const wrapper = document.createElement("div");
    const preview = document.createElement("p");
    const input = document.createElement("input");

    const setCypressSettings = () => {
      wrapper.setAttribute("slid-cy", "editor-latex-wrapper");
      preview.setAttribute("slid-cy", "editor-latex-preview");
      input.setAttribute("slid-cy", "editor-latex-input");
    };

    if (typeof window.katex === "undefined") {
      let errorMessageSpan = document.createElement("span");
      errorMessageSpan.className = "errorMessage";
      errorMessageSpan.innerText = "[Erorr] KaTeX is not found! Add KaTeX to this webpage to continue!";
      return errorMessageSpan;
    }

    wrapper.classList.add("math-input-wrapper");
    preview.contentEditable = false;
    preview.classList.add("math-preview");
    preview.draggable = !this.readOnly && !isTouchDevice();
    preview.ondragstart = (event) => {
      event.dataTransfer.setData("text/plain", "");
    };

    input.classList.add("math-input");

    if (this.readOnly) {
      input.classList.add("math-input-hide");
    }

    if (!this.readOnly) {
      preview.addEventListener(
        "click",
        () => {
          if (input.classList.contains("math-input-hide")) {
            input.classList.remove("math-input-hide");
            input.focus();
          }
        },
        { once: true }
      );

      input.addEventListener("focusout", (e) => {
        if (!input.classList.contains("math-input-hide")) {
          input.classList.add("math-input-hide");
          setTimeout(() => {
            preview.addEventListener(
              "click",
              () => {
                if (input.classList.contains("math-input-hide")) {
                  input.classList.remove("math-input-hide");
                  input.focus();
                }
              },
              { once: true }
            );
          }, 300);
        }
      });
    }

    //Load the data if exists
    input.value = this.data ? this.data : "";

    // hide input when data exists
    if (this.data) {
      input.classList.add("math-input-hide");
    }

    //Set the placeholder text for LaTeX expression input
    input.setAttribute("placeholder", "Enter LaTeX here");

    //Will render LaTeX if there is any in saved data
    window.katex.render(input.value, preview, {
      throwOnError: false,
      displayMode: true,
    });

    input.addEventListener("keyup", (e) => {
      //Prevent default actions
      e.preventDefault();

      //Render LaTeX expression
      window.katex.render(input.value, preview, {
        throwOnError: false,
        displayMode: true,
      });
    });

    wrapper.appendChild(preview);
    wrapper.appendChild(input);

    setCypressSettings();
    return wrapper;
  }

  save(blockContent) {
    return {
      math: blockContent.childNodes[1].value,
    };
  }
  static convertToPlainHTML(data) {
    const math = katex.renderToString(data.math, { displayMode: true, output: "mathml", throwOnError: false });
    const parser = new DOMParser();
    const annotationList = parser.parseFromString(math, "text/xml").getElementsByTagName("annotation");

    if (annotationList.length > 0) {
      const mathText = annotationList[0].innerHTML;
      return `<div>${mathText}</div>`;
    }

    return `<div>${""}</div>`;
  }
}
