import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const HighlighterSettingIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 40} height={height ? height : 40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.1917 8.08221L32.1402 13.0331L20.1207 27.174L13.0434 20.0972L27.1917 8.08221Z" stroke={color} strokeWidth="2" strokeLinejoin="round" />
      <path d="M7.87926 30.1419L11.1556 26.9461L13.984 29.7746L12.5 31L8.22836 30.9998C7.77991 30.9998 7.55824 30.455 7.87926 30.1419Z" stroke={color} strokeWidth="2" strokeLinejoin="round" />
      <path
        d="M12.341 20.8116L13.0481 20.1045L20.1192 27.1756L19.4121 27.8827L15.3988 28.8068L13.9648 29.7944L11.1364 26.9659L12.1337 25.5417L12.341 20.8116Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
