import { patchDocument } from "../redux/actions/vdocsActions";
import { useConfirmPrivilege } from "../utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "../utils/privilegeManager";
import { alertDownloadingPDFFailed, alertProcessingDownloadingPDF } from "../components/alerts";
import { ServerInterface_startPDFDownloading, ServerInterface_checkPDFDownloadStatus } from "api/pdfDownloadAPI";
import Sweetalert from "sweetalert2";
import { sendAmplitudeData } from "../utils/amplitude";
import { downloadFromURI } from "../utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";

export const useNoteDownload = () => {
  const dispatch = useAppDispatch();
  const { currentDocument } = useAppSelector((state) => state.vdocs);
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();

  const downloadCurrentNoteToPDF = async () => {
    if (!confirmPrivilege(SlidFeatures.pdfExport)) return showInsufficientPrivilegeModal();
    let wasDocumentPublic = currentDocument["is_public"];

    alertProcessingDownloadingPDF();
    const startPdfDownloadingResult: any = await ServerInterface_startPDFDownloading({
      data: {
        document_key: currentDocument.document_key,
      },
    });
    if (!startPdfDownloadingResult) return alertDownloadingPDFFailed();
    const pdf_download_key = startPdfDownloadingResult.data?.pdf_download_key;
    if (!pdf_download_key) return alertDownloadingPDFFailed();

    let isDownloaded = false;
    const checkPDFDownloadStatusIntervalId = setInterval(async () => {
      const result: any = await ServerInterface_checkPDFDownloadStatus({ pdf_download_key });
      if (result?.data?.status === "SUCCEEDED" || result?.data?.status === "FAILED") {
        if (!wasDocumentPublic) {
          // After downloading, make document private again.
          await dispatch(
            patchDocument({
              documentKey: currentDocument["document_key"],
              options: {
                is_public: false,
              },
            })
          );
        }
      }
      if (result?.data?.status === "SUCCEEDED" && result?.data?.pdf_result_url) {
        clearInterval(checkPDFDownloadStatusIntervalId);
        Sweetalert.close();
        sendAmplitudeData(`SLID_1_DOWNLOAD_DOCUMENT`, {
          type: `pdf`,
        });
        sendAmplitudeData(`Download note`, {
          type: `pdf`,
        });
        if (!isDownloaded) {
          downloadFromURI(result.data.pdf_result_url, currentDocument.title, "pdf");
          isDownloaded = true;
        }
      } else if (result?.data?.status === "FAILED") {
        clearInterval(checkPDFDownloadStatusIntervalId);
        alertDownloadingPDFFailed();
      }
    }, 1000);
  };

  return { downloadCurrentNoteToPDF };
};
