import { createGlobalStyle } from "styled-components";

const styled = { createGlobalStyle };

const GlobalStyle = styled.createGlobalStyle<{ isMobile: boolean; location: string }>`
  :root {
    font-family: inherit;
  }

  html {
    /* 62.5% equals to 10px: 1rem = 10px */
    font-size: 62.5%;

    @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 799px) {
      /* this code is for handling DPR issue */
      /* 70% equals to 11.2px: 1rem = 11.2px */
      font-size: ${({ isMobile }) => (isMobile ? "70%" : "62.5%")};
    }
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-family: "SlidTextStyle", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  }

  body {
    font-size: 1.6rem;
  }

  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  audio,
  video {
    margin: 0;
    padding: 0;
    font-size: 100%;
  }

  a {
    color: var(--blue8);

    &:hover {
      color: var(--blue9);
    }
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  /* adjust bootstrap css */
  .form-control,
  .custom-select,
  .dropdown-menu {
    font-size: inherit;
  }

  .toast-body {
    font-size: 1.4rem !important;
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .spinner-border {
    width: 32px;
    height: 32px;
  }

  .spinner-border-sm {
    width: 11px;
    height: 11px;
  }

  .btn {
    font-size: 1.6rem;
    padding: 0.375em 0.75em;
    border-radius: 0.25em;
  }

  /* adjust sweet alert css */
  .swal2-popup {
    font-size: 1.6rem;

    div {
      font-size: 1.6rem;
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 799px) {
      font-size: 16px;
      div,
      span {
        font-size: 16px;
      }
    }
  }

  .swal2-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg fill='var(--gray15)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    padding: 1rem;
  }

  /* adjust editor tooltip css */
  .ct__content div {
    font-size: 12px !important;
  }
`;

export default GlobalStyle;
