//auth 2.0
import "./index.css";

export default class Audio {
  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.config = config;
    this.readOnly = readOnly;
    this.CSS = {
      baseClass: this.api.styles.block,
      input: this.api.styles.input,
      wrapper: `ce-audio-wrapper`,
    };
    this.data = data;
  }
  render() {
    return this.drawView();
  }

  renderSettings() {
    const wrapper = [];

    let settings = [];

    settings.forEach((tune) => {
      let button = document.createElement("div");
      const iconContainer = document.createElement("div");
      const buttonTitle = document.createElement("span");

      iconContainer.classList.add("ce-settings__button-icon-container");
      buttonTitle.classList.add("ce-settings__button-text");
      button.classList.add("ce-settings__button");

      iconContainer.innerHTML = tune.icon;
      buttonTitle.innerHTML = tune.title;

      button.appendChild(iconContainer);
      button.appendChild(buttonTitle);
      button.addEventListener("click", tune.click.bind(this));

      wrapper.push(button);
    });

    return wrapper;
  }
  save() {
    return this.data;
  }

  /**
   * Create Tool's view
   *
   * @returns {HTMLElement}
   * @private
   */
  drawView() {
    const wrapper = document.createElement("div");
    wrapper.classList.add(this.CSS.wrapper);

    const audio = document.createElement("audio");
    audio.controls = true;
    audio.src = this.data.src;
    audio.preload = "auto";

    wrapper.appendChild(audio);

    return wrapper;
  }
  /**
   * Returns Tool`s data from private property
   *
   * @returns {CodeData}
   */
  get data() {
    return this._data;
  }

  /**
   * Set Tool`s data to private property and update view
   *
   * @param {CodeData} data - saved tool data
   */
  set data(data) {
    this._data = data;
  }
}
