import { Icon, Tooltip, Typography11, Typography16 } from "@slid/slid-ips";
import { SLTStopIcon } from "components/icons/SLTStopIcon";
import { useAppSelector } from "hooks";
import { useAutoNotesButton } from "hooks/useAutoNotesButton";
import { useAutoNotesProgressInfo } from "hooks/useAutoNotesProgressInfo";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { css, keyframes } from "styled-components";
import { AutoNotesSTTBlock } from "../types";

const RealTimeWhisperAutoNoteProgressInfo = ({ activeAutoNotesSTTBlock }: { activeAutoNotesSTTBlock: AutoNotesSTTBlock }) => {
  const { onClickAutoNotesSettingButton } = useAutoNotesProgressInfo();
  const { onToggleAutoNotes } = useAutoNotesButton();
  const { isAutoNotesActive, isAutoNotesToggledOn } = useAppSelector((state) => state.autoNotes);

  const { t } = useTranslation("AutoNotes");

  const [displayText, setDisplayText] = useState("");
  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (contentRef.current && activeAutoNotesSTTBlock?.finalizedText) {
      const lineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight);
      const maxHeight = lineHeight * 3.1;
      const text = activeAutoNotesSTTBlock.finalizedText;

      contentRef.current.innerText = text;

      if (contentRef.current.scrollHeight > maxHeight) {
        let start = 0;
        while (contentRef.current.scrollHeight > maxHeight && start < text.length) {
          start += 10;
          contentRef.current.innerText = "..." + text.slice(start);
        }
        setDisplayText("..." + text.slice(start));
      } else {
        setDisplayText(text);
      }
    } else {
      setDisplayText("");
    }
  }, [activeAutoNotesSTTBlock]);

  if (!isAutoNotesActive || !isAutoNotesToggledOn) return null;

  return (
    <Container>
      <TitleWrapper>
        <Typography16 text={t("AutoNotes")} color={`--gray17`} weight={700} type={`span`} />
        <Tooltip title={t("AutoNotesSettings")}>
          <MoreIcon onClick={onClickAutoNotesSettingButton} icon={`more24`} width={24} height={24} color="--gray15" />
        </Tooltip>
      </TitleWrapper>

      <SLTContentText ref={contentRef} data-type={`slt-text`} data-content={isAutoNotesActive ? t("AutoNotesTyping") : t("AutoNotesWaiting")} showPseudoData={true}>
        {displayText}
      </SLTContentText>

      <StopButton onClick={onToggleAutoNotes}>
        <StopButtonIcon>
          <SLTStopIcon />
        </StopButtonIcon>
        <Typography11 text={t("EndAutoNotes")} color={`--gray17`} weight={500} />
      </StopButton>
    </Container>
  );
};

export default RealTimeWhisperAutoNoteProgressInfo;

const Container = styled.div`
  border-radius: 12px;
  border: 2px solid var(--blue7);
  background: var(--SGray-Colors-white, #fff);
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.08);

  position: absolute;

  z-index: 1058;
  display: flex;
  flex-direction: column;

  padding: 16px 20px;
  align-items: flex-start;
  max-width: 700px;
  width: 87%;
  height: auto;
  left: 50%;

  bottom: 94px;
  transform: translateX(-50%);
  gap: 8px;
`;

const blink = keyframes`
  50% {
    border-color: var(--gray1);
  }
`;

const SLTContentText = styled.p<{ showPseudoData: boolean }>`
  color: var(--gray8);
  font-size: 1.5rem;
  line-height: 2.3rem;
  width: 100%;
  max-height: 69px;
  box-shadow: none;
  white-space: inherit;
  word-wrap: normal;
  resize: none !important;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;

  min-height: 2.3rem;
  overflow-y: hidden;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

  ${({ showPseudoData }) =>
    showPseudoData &&
    css`
      color: var(--gray17);

      &::after {
        font-size: 1.3rem;
        padding-left: 8px;
        content: attr(data-content);
        color: var(--gray7);
        border-left: 1px solid var(--gray14);
        animation: ${blink} 0.8s infinite;
      }
    `}
`;

const StopButton = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--gray4);
  cursor: pointer;
  color: var(--gray17);

  &:hover {
    background: var(--red1);
    border: 1px solid var(--red1_opacity);
    transition: all 0.2s ease-in-out;

    p {
      color: var(--red7);
      transition: all 0.2s ease-in-out;
    }
  }
`;

const StopButtonIcon = styled.div`
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  justify-content: space-between;
`;

const MoreIcon = styled(Icon)`
  cursor: pointer;
`;
