import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const HighlighterIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 48} height={height ? height : 48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.1917 12.0822L36.1402 17.0331L24.1207 31.174L17.0434 24.0972L31.1917 12.0822Z" stroke={color} strokeWidth="2" strokeLinejoin="round" />
      <path
        d="M11.8617 34.2404L15.1558 30.9463L17.9842 33.7747L16.6946 35.0643L12.2186 35.0939C11.7715 35.0969 11.5456 34.5565 11.8617 34.2404Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M16.341 24.8116L17.0481 24.1045L24.1192 31.1756L23.4121 31.8827L19.3988 32.8068L17.9648 33.7944L15.1364 30.9659L16.1337 29.5417L16.341 24.8116Z"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
