import { HTMLMotionProps } from "framer-motion";

export interface MenuProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: JSX.Element;
  color: "--gray15" | "--gray3" | "--gray9";
  itemList: MenuItemProps[];
  menuValue: string;
}

export interface MenuItemProps {
  text: string;
  subText?: string;
  callback?: Function;
  default?: boolean;
}

export interface MenuListItemProps extends HTMLMotionProps<"li"> {
  size?: MenuSizeType;
  showCheck?: boolean;
  isSelected?: boolean;
  text: string;
}

export enum MenuSizeType {
  Regular = "regular",
  Large = "large",
}

export enum addOptionTypes {
  more = "more",
  toggle = "toggle",
}

export interface PullDownMenuItemProps extends React.HTMLAttributes<HTMLDivElement> {
  appearance: "text" | "line";
  icon?: any;
  description?: string;
  addOption?: addOptionTypes;
  text?: string;
  toggleOn?: boolean;
}
