import { parseUserDataMoreInfo } from "utils/utils";
// auth 2.0
import { initAmplitude, sendAmplitudeData, setAmplitudeUserId, setAmplitudeUserProperties, setAmplitudeUserPropertyOnce } from "../amplitude";
import queryString from "query-string";
import { sendHackleData } from "../hackle";

interface trackEventProps {
  eventType: any;
  eventProperties?: any;
  callback?: any;
}

export const initEventTracking = async (deviceId = null) => {
  const amplitudeDeviceId: any = queryString.parse(window.location.search).adi;
  await initAmplitude(deviceId ? deviceId : amplitudeDeviceId ? amplitudeDeviceId : null);
};

export const trackEvent = ({ eventType, eventProperties, callback }: trackEventProps) => {
  sendAmplitudeData(eventType, eventProperties, callback);
  sendHackleData(eventType, eventProperties);

  // @ts-ignore
  if (window.hj) window.hj("event", eventType);
};

export const setTrackingUserId = (userId: string) => {
  setAmplitudeUserId(userId);
  //@ts-ignore
  if (window.clarity) window.clarity("identify", userId);
};

export const setTrackingUserProperties = (properties: any) => {
  setAmplitudeUserProperties(properties);

  let userAttributes: any = {
    clip_cnt: properties.clip_cnt,
    username: properties.username,
    email: properties.email,
    created_time: properties.created_time,
    last_action_time: properties.last_action_time,
    document_cnt: properties.document_cnt,
    payment: properties.payment,
    country: properties.country,
  };

  if (Object.keys(properties).length !== 0) {
    const { birthday, phone_number }: any = parseUserDataMoreInfo(properties);

    if (birthday) {
      userAttributes.birthday = birthday;
    }
    if (phone_number) {
      userAttributes.phone_number = phone_number;
    }
  }

  // @ts-ignore
  if (window.hj) window.hj("identify", null, userAttributes);
};

export const setTrackingUserPropertyOnce = (key: any, value: any) => {
  setAmplitudeUserPropertyOnce(key, value);
};
