// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const SearchIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper style={{ width, height }} viewBox={`0 0 28 28`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 20C16.866 20 20 16.866 20 13C20 9.13401 16.866 6 13 6C9.13401 6 6 9.13401 6 13C6 16.866 9.13401 20 13 20Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M18 18L22 22" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgWrapper>
  );
};
