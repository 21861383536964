import { useAppSelector } from "hooks";
import { useEffect } from "react";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import { getCurrentDocumentKey } from "utils/editor/util";
import { sendIsSTTSessionStartedToParentWindow } from "utils/extensionInterface/sendToExtension";
import { setSTTSocketConnectionStatusListener, setSTTErrorListener, setTranscriptResponseListener } from "utils/extensionInterface/setListenerFromExtension";
import { v4 as uuidv4 } from "uuid";

export const useInitializeSLT = () => {
  const {
    setTranscriptChunk,
    resetTranscriptChunk,
    setTranscriptData,
    isSTTToggledOn,
    setIsManualAddingMode,
    setSttError,
    isSTTActive,
    startSTT,
    stopSTT,
    setIsSTTSessionStarted,
    setIsSTTSocketConnected,
  } = useWhisperSLTStore();

  const { isVideoPlaying, isExtensionMediaPermitted, currentVideo } = useAppSelector((state) => state.vdocs);

  useEffect(() => {
    const startSTTSession = async () => {
      const shouldStartSLT = isExtensionMediaPermitted && isSTTToggledOn && (currentVideo?.videoType === "iframe" || isVideoPlaying);

      if (shouldStartSLT) {
        if (!isSTTActive) {
          const documentKey = await getCurrentDocumentKey();
          startSTT(documentKey);
        }
        sendIsSTTSessionStartedToParentWindow(true);
        setIsSTTSessionStarted(true);
      } else if (isSTTActive) {
        stopSTT();
        sendIsSTTSessionStartedToParentWindow(false);
        setIsSTTSessionStarted(false);
      }
    };

    startSTTSession();
  }, [isVideoPlaying, isExtensionMediaPermitted, isSTTToggledOn, currentVideo]);

  useEffect(() => {
    setSLTDefaultOptions();
  }, []);

  // set listeners for extension events
  useEffect(() => {
    setSTTSocketConnectionStatusListener({
      responseHandler: ({ isSTTSocketConnected }: { isSTTSocketConnected: boolean }) => {
        setIsSTTSocketConnected(isSTTSocketConnected);
      },
    });
    setTranscriptResponseListener({
      responseHandler: (receivedData: { text: string; isFinal: boolean }) => {
        const { text, isFinal } = receivedData;

        if (isFinal === false) {
          const newTranscriptChunk = {
            id: uuidv4(),
            text,
            isFinal,
          };

          setTranscriptChunk(newTranscriptChunk);
        } else {
          const newTranscriptBlock = {
            id: uuidv4(),
            text,
            isFinal,
          };
          setTranscriptData(newTranscriptBlock);
          resetTranscriptChunk();
        }
      },
    });
    setSTTErrorListener({
      responseHandler: (receivedData: any) => {
        setSttError(receivedData.error);
      },
    });
  }, []);

  const setSLTAddingModeFromUserDefaultAddingMode = () => {
    const isManualAddingMode = window.localStorage.getItem("slid-stt-manual-adding-mode");
    if (isManualAddingMode === "true") {
      setIsManualAddingMode(true);
    } else {
      setIsManualAddingMode(false);
    }
  };

  const setSLTDefaultOptions = () => {
    setSLTAddingModeFromUserDefaultAddingMode();
  };

  return {};
};
