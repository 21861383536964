// auth 2.0

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import env from "config/env";

axios.defaults.withCredentials = false;

const AWS_CAPTURE_S3 = env.end_point_url.aws_capture_s3;

export const getPresignedURL_and_key = async ({ path, file_format }) => {
  /* calls the api_gateway that invokes the lambda function which generates the presigned url */
  const API_ENDPOINT = env.end_point_url.get_presigned_url_api;
  const raw_response = await axios({
    method: "GET",
    url: `${API_ENDPOINT}?path=${path}&format=${file_format}`,
  });
  const response = {
    presignedURL: raw_response.data.uploadURL,
    key: raw_response.data.Key,
  };
  return response;
};

export const uploadBase64Image = async ({ path, base64 }) => {
  const { presignedURL, key } = await getPresignedURL_and_key({ path: path, file_format: "png" });
  const file = await base64ToFile(base64, `${uuidv4()}.png`);
  try {
    const uploadResult = await fetch(presignedURL, {
      method: "PUT",
      body: file,
    });
    const img_src = AWS_CAPTURE_S3 + key;
    return img_src;
  } catch (error) {
    console.log(`Cannot upload file to S3: ${error}`);
    return;
  }
};

export const uploadFileImage = async ({ path, file }) => {
  const file_name_split = file.name.split(".");
  const file_format = file_name_split[file_name_split.length - 1];
  const { presignedURL, key } = await getPresignedURL_and_key({ path, file_format });
  try {
    /* uploads the given file to the pre-signed s3 url, and returns the img url */
    const uploadResult = await fetch(presignedURL, {
      method: "PUT",
      body: file,
    });
    return AWS_CAPTURE_S3 + key;
  } catch (error) {
    console.log(`Cannot upload file to S3: ${error}`);
    return;
  }
};

export const uploadFile = async ({ path, file }) => {
  const file_name_split = file.name.split(".");
  const file_format = file_name_split[file_name_split.length - 1];
  const { presignedURL, key } = await getPresignedURL_and_key({ path, file_format });
  try {
    /* uploads the given file to the pre-signed s3 url, and returns the img url */
    const uploadResult = await fetch(presignedURL, {
      method: "PUT",
      body: file,
    });
    return AWS_CAPTURE_S3 + key;
  } catch (error) {
    console.log(`Cannot upload file to S3: ${error}`);
    return;
  }
};

const base64ToFile = (url, filename, mimeType) => {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};
