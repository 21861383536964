import EditorOptionDropdownMenu from "components/DropdownMenus/EditorOptionDropdownMenu";
import { useAppDispatch, useAppSelector } from "hooks";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Typography17, Icon, Tooltip } from "@slid/slid-ips";
import { isTouchDevice } from "utils/utils";
import { AnimatePresence, motion } from "framer-motion";

import styles from "../VideoDocumentEditor.module.scss";
import { useTranslation } from "react-i18next";
import { setIsEditorOptionOpen, setIsListOpen } from "redux/actions/vdocsActions";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";
import MyNotesButton from "components/buttons/MyNotesButton";
import { useHistory } from "react-router-dom";
import { setDefaultSettingCookie } from "utils/cookies/cookies";
import useMyNoteStore from "store/useMyNoteStore";
import { trackEvent } from "utils/eventTracking";
import { useSortDocument } from "hooks/useSortDocument";

const rotation = {
  closed: { rotate: 0 },
  open: { rotate: 180 },
};

const menuUlVariants = {
  initial: {
    height: 0,

    transition: {
      duration: 0.2,
      ease: "easeInOut",
    },
  },
  animate: {
    height: "auto",

    transition: {
      duration: 0.15,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

interface VideoNoteHeaderProps {
  currentCumFolderName: string;
  isFolderSelectorActive: boolean;
  setState: any;
  isMobile: boolean;
  openRecentNote: boolean;
  onDocumentFolderChange: any;
  openNewFolderPopup: any;
  saveDocument: any;
}

const VideoNoteHeader = (props: VideoNoteHeaderProps) => {
  const { currentCumFolderName, isFolderSelectorActive, setState, isMobile, openRecentNote, onDocumentFolderChange, openNewFolderPopup, saveDocument } = props;

  const [currentCumFolderList, setCurrentCumFolderList] = useState([]);

  const { showInsufficientPrivilegeModal, confirmPrivilege } = useConfirmPrivilege();
  const { setDeleteNoteLocation } = useMyNoteStore();

  const dispatch = useAppDispatch();

  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const { isEditorOptionOpen, currentDocument, documents } = useAppSelector((state) => state.vdocs);
  const { t } = useTranslation();
  const history = useHistory();

  const { recursiveSortDocuments } = useSortDocument();

  useEffect(() => {
    if (!documents || documents.length === 0) return;
    getFolderOptionTags();
  }, [documents]);

  const getFolderOptionTags = () => {
    let folderOptionTags = [];
    if (!documents) return folderOptionTags;

    folderOptionTags = getChildOptionTags({
      folder: {
        documents,
      },
      cumFolderName: null,
    });

    setCurrentCumFolderList(folderOptionTags);

    return folderOptionTags;
  };

  const getChildOptionTags = ({ folder, cumFolderName }) => {
    let childOptionTags: any = [];

    const sortedDocument = recursiveSortDocuments(folder.documents);

    sortedDocument.forEach((item) => {
      let newCumFolderName = cumFolderName ? `${cumFolderName} / ${item["title"]}` : item["title"];
      if (item["is_folder"]) {
        childOptionTags.push(
          <li
            key={item["document_key"]}
            className={`${styles[`note-folder-list-item`]}`}
            onClick={() => {
              if (!confirmPrivilege(SlidFeatures.folderMove)) return showInsufficientPrivilegeModal();
              onDocumentFolderChange(item["document_key"]);
              setState({ isFolderSelectorActive: false });
            }}
          >
            <Typography17 text={newCumFolderName} color={`--gray15`} weight={currentDocument && item["document_key"] === currentDocument["parent_key"] ? 700 : 400} />
          </li>
        );

        childOptionTags = [
          ...childOptionTags,
          ...getChildOptionTags({
            folder: item,
            cumFolderName: newCumFolderName,
          }),
        ];
      }
    });
    return childOptionTags;
  };

  const handleOpenRecentNote = () => {
    const newOpenRecentNoteValue = !openRecentNote;
    setState(
      {
        openRecentNote: newOpenRecentNoteValue,
      },
      () => {
        setDefaultSettingCookie({
          property: `openRecentNote`,
          value: newOpenRecentNoteValue ? "true" : "false",
        });
      }
    );
  };

  return (
    <HeaderContainer applicationType={applicationType}>
      <div className={`${styles[`header-left-container`]}`}>
        {/**TODO : Change 2.0.0 to remote config */}
        <Tooltip title={t("OpenNoteList", { ns: "Header" })} placement={`bottom-start`} onShow={() => !isTouchDevice()}>
          <div
            draggable={false}
            className={styles[`list-open-icon`]}
            onClick={() => {
              dispatch(setIsListOpen(true));
            }}
            slid-cy={`editor-list-toggle-btn`}
          >
            <Icon icon={`list24`} color={`--gray15`} />
          </div>
        </Tooltip>
        {(!isMobile || applicationType === "desktop") && (
          <div className={`${styles[`note-folder-selector-container`]}`}>
            <button
              className={`${styles[`note-folder-selector-button`]}`}
              onClick={() => {
                setState({
                  isFolderSelectorActive: !isFolderSelectorActive,
                });
              }}
            >
              <Typography17 text={currentDocument ? currentCumFolderName : t("NoFolder", { ns: "VideoNote" })} color={`--gray15`} weight={700} />
              <motion.div variants={rotation} animate={isFolderSelectorActive ? "open" : "closed"} transition={{ duration: 0.3 }} className={`${styles[`note-folder-selector-button-icon-container`]}`}>
                <Icon icon={`chevronDown24`} color={`--gray15`} />
              </motion.div>
            </button>
            {isFolderSelectorActive && (
              <AnimatePresence>
                <motion.ul variants={menuUlVariants} initial="initial" animate="animate" exit="initial" className={`${styles[`note-folder-selector-dropdown`]}`}>
                  <li
                    className={`${styles[`note-folder-list-item`]}`}
                    onClick={() => {
                      if (!confirmPrivilege(SlidFeatures.folderCreation)) return showInsufficientPrivilegeModal();
                      openNewFolderPopup();
                      setState({ isFolderSelectorActive: false });
                    }}
                  >
                    <Typography17 text={t("SaveInNewFolder", { ns: "VideoNote" })} color={`--gray9`} />
                  </li>
                  <li
                    className={`${styles[`note-folder-list-item`]}`}
                    onClick={() => {
                      if (!confirmPrivilege(SlidFeatures.folderMove)) return showInsufficientPrivilegeModal();
                      onDocumentFolderChange("root");
                      setState({ isFolderSelectorActive: false });
                    }}
                  >
                    <Typography17 text={t("NoFolder", { ns: "VideoNote" })} color={`--gray9`} />
                  </li>
                  {currentCumFolderList}
                </motion.ul>
              </AnimatePresence>
            )}
          </div>
        )}
      </div>
      <div className={styles[`header-right-container`]}>
        {(!isMobile || applicationType === "desktop") && (
          <div data-testid={`cognito-button-container`} className={`${styles[`my-note-button`]} video-note-my-note-button`}>
            <MyNotesButton />
          </div>
        )}
        <Tooltip title={t("EditorOptionMenuTooltip", { ns: "Header" })} placement={`bottom-end`} onShow={() => !isTouchDevice()}>
          <div
            className={styles[`video-note-dropdown-button-container`]}
            onClick={async () => {
              trackEvent({
                eventType: "Click editor option dropdown menu",
              });
              if (isMobile && applicationType !== "desktop") {
                await saveDocument();
                setDeleteNoteLocation("Video note page");
                history.push(`/note/options/${currentDocument.document_key}`);
              } else {
                dispatch(setIsEditorOptionOpen(!isEditorOptionOpen));
              }
            }}
          >
            <Icon icon={`more24`} color={`--gray15`} />
          </div>
        </Tooltip>
        {isEditorOptionOpen && <EditorOptionDropdownMenu handleOpenRecentNote={handleOpenRecentNote} openRecentNote={openRecentNote} />}
      </div>
    </HeaderContainer>
  );
};

export default VideoNoteHeader;

const HeaderContainer = styled.div<{ applicationType: string }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.applicationType !== "desktop" &&
    css`
      @media screen and (max-width: 799px) {
        padding: 0px 12px;
      }
    `}
`;

const MobileVersionLabel = styled.div`
  margin-left: -12px;
  margin-right: 8px;
  padding: 4px 6px;
  background-color: var(--gray2);
  border-radius: 6px;
  z-index: 999;
`;

const MobileVersionText = styled.p`
  margin: 1px 0 0 0;
  font-weight: 700;
  font-size: 0.7rem;
  line-height: 1.6rem;
  color: var(--gray7);
`;
