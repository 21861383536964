import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const MarkupLineIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 40} height={height ? height : 40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M31 9C30.2511 9.74894 16.0213 23.9787 9 31" stroke={color} strokeWidth="2" strokeLinecap="round" />
      </g>
    </SvgWrapper>
  );
};
