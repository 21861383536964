import { Button, Link, Typography13, Typography24 } from "@slid/slid-ips";
import { useAppDispatch, useAppSelector } from "hooks";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { setShowExtensionMediaPermissionModal } from "redux/actions/vdocsActions";

import { RootState } from "redux/store";

import styled from "styled-components";

const SmartLiveTextViewPermissionGuide = () => {
  const { t } = useTranslation(["SmartLiveText", "VideoNote"]);
  const { sttData, isSTTActive, showPermissionGuideInTextView } = useSelector((state: RootState) => state.sttReducer);
  const { isExtensionMediaPermitted } = useAppSelector((state) => state.vdocs);
  const dispatch = useAppDispatch();

  const { showExtensionMediaPermissionModal } = useAppSelector((state: RootState) => state.vdocs);

  if (isSTTActive || sttData?.length !== 0 || !showPermissionGuideInTextView || isExtensionMediaPermitted) return null;

  return (
    <TranscriptGuideDim isOpacityModified={showExtensionMediaPermissionModal}>
      <PermissionGuideContainer>
        <Typography24
          // @ts-ignore
          text={
            <Trans components={{ permissionBlue: <Typography24 text={t("PermissionBlueText", { ns: "VideoNote" })} color={`--blue8`} style={{ display: "inline" }} /> }}>
              {t("PermissionGuideInTextView", { ns: "VideoNote" })}
            </Trans>
          }
          color={`--white`}
          style={{ textAlign: "center" }}
        />
        <Typography13 text={t("PermissionGuideInTextViewTip", { ns: "VideoNote" })} style={{ textAlign: "center" }} color={`--gray9`} marginTop={"16px"} />
        <AllowPermissionButton
          size={`medium`}
          appearance={`line`}
          customColor={`--gray9`}
          text={t("AllowPermissionButtonText", { ns: "VideoNote" })}
          callback={() => {
            dispatch(setShowExtensionMediaPermissionModal(true));
          }}
          marginTop={`32px`}
        />
      </PermissionGuideContainer>
      <Link
        infoText={t("NeedHelp", { ns: "VideoNote" })}
        color={`--blue4`}
        linkText={t("LiveChatSupport", { ns: "VideoNote" })}
        href={`https://slid.channel.io/lounge`}
        newTab={true}
        style={{ position: "absolute", bottom: "26px", margin: "auto 0" }}
      />
    </TranscriptGuideDim>
  );
};

const TranscriptGuideDim = styled.div<{ isOpacityModified?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  opacity: ${({ isOpacityModified }) => isOpacityModified && 0.4};
  left: 0;
  top: 72px;
  width: 100%;
  height: calc(100% - 72px);
  backdrop-filter: blur(6px);
  background: rgba(33, 37, 41, 0.4);
`;

const PermissionGuideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AllowPermissionButton = styled(Button)`
  && {
    &:disabled {
      background-color: var(--gray15);
      p {
        color: var(--gray9);
      }
      cursor: default;
    }
    &:hover {
      background-color: var(--gray15);
    }
    &:active {
      background-color: var(--gray17);
    }
  }
`;

export default SmartLiveTextViewPermissionGuide;
