import React from "react";
import { ModalTypes, CustomModalProps } from "@slid/slid-ips";
import ExtensionOnboardingModalContainer from "./components";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

interface showExtensionOnboardingModalProps {
  showModal: (props: CustomModalProps) => void;
}

/**
 * This function is used to show the extension onboarding modal,
 * right after the onboarding demo page.
 */
export const showExtensionOnboardingModal = async (props: showExtensionOnboardingModalProps) => {
  const { showModal } = props;
  trackEvent({ eventType: eventTypes.show.WEBSITE_SELECT_MODAL_IN_DEMO_VIDEO_NOTE_PAGE });
  const modalProps: CustomModalProps = {
    type: ModalTypes.customModal,
    closableDim: false,
    customComponent: <ExtensionOnboardingModalContainer />,
  };
  showModal(modalProps);
};
