import slidAxios from "./base";
import { handleAuthErrors, isAuthError } from "./errorUtils";
const slid_expert_endpoint = `slid-expert/`;

export const ServerInterface_slidExpertAccountToggle = async ({ accountType }) => {
  const payload = {
    account_type: accountType,
  };
  let accountToggleResponse = {};
  try {
    accountToggleResponse = await slidAxios.post(slid_expert_endpoint, payload);
  } catch (error) {
    console.error(error);
    if (!accountToggleResponse) accountToggleResponse = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          accountToggleResponse.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // for missing field and incorrect data format
          accountToggleResponse.error_message = "SLID_EXPERT_INVALID_DATA";
          break;
        case 403:
          accountToggleResponse.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          accountToggleResponse.error_message = "UNKNOWN_ERROR";
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      accountToggleResponse.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      accountToggleResponse.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return accountToggleResponse;
  }
};
