import { handleNestedTextChildren } from "./ParagraphTypeParser";

export function handleHeader(blocks) {
  let itemText = "";
  itemText = handleNestedTextChildren(blocks);
  return itemText;
}

export function parseMarkdownToHeader(node) {
  let headerData = {};

  switch (node.depth) {
    case 1:
      headerData = {
        data: {
          level: 2,
          text: handleHeader(node),
        },
        type: "header1",
      };

      return headerData;
    case 2:
      headerData = {
        data: {
          level: 3,
          text: handleHeader(node),
        },
        type: "header2",
      };
      return headerData;
    case 3:
      headerData = {
        data: {
          level: 4,
          text: handleHeader(node),
        },

        type: "header3",
      };

      return headerData;
    case 4:
      headerData = {
        data: {
          level: 4,
          text: handleHeader(node),
        },

        type: "header3",
      };

      return headerData;
    case 5:
      headerData = {
        data: {
          level: 4,
          text: handleHeader(node),
        },
        type: "header3",
      };

      return headerData;
    case 6:
      headerData = {
        data: {
          level: 4,
          text: handleHeader(node),
        },
        type: "header3",
      };

      return headerData;
    default:
      break;
  }
}
