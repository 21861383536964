import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";
const users_endpoint = `users/`;
const account_endpoint = `account/`;

export const ServerInterface_updatePassword = async ({ data }) => {
  try {
    const response = await slidAxios.patch(account_endpoint, {
      password: data.password,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        case 403:
          return { error_message: "INSUFFICIENT_PRIVILEGES" };
        case 405:
          return { error_message: "METHOD_NOT_ALLOWED" };
        case 400:
          return { error_message: "PASSSWORD_IS_MISSING_OR_INVALID" };
        default:
          return { error_message: "UNKNOWN_ERROR" };
      }
    }
  }
};

export const ServerInterface_fetchUser = async () => {
  let userData = null;
  try {
    const userGetResponse = await slidAxios.get(users_endpoint);
    userData = userGetResponse.data;

    if (!userData.is_temporary) {
      const updated_account_type_response = await slidAxios.get(account_endpoint);
      userData.payment = updated_account_type_response?.data?.payment;
    }
  } catch (error) {
    if (!userData) userData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        case 405:
          userData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          userData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 401: // from GET /account
          userData.error_message = "TEMP_USER_NOT_SUPPORTED"; // very legacy temp user
          break;
        case 400: // from GET /account
          userData.error_message = "FAILED_TO_CREATE_TRIAL";
          break;
        default:
          userData.error_message = "UNKNOWN_ERROR";
          //TODO: log to sentry as it might be due to a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      userData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      userData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return userData;
  }
};

export const ServerInterface_deleteAccount = async () => {
  let userDeleteResponseData = {};
  try {
    const userDeleteResponse = await slidAxios.delete(users_endpoint);
    userDeleteResponseData = userDeleteResponse.data;
  } catch (error) {
    console.error(error);
    if (!userDeleteResponseData) userDeleteResponseData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          userDeleteResponseData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          userDeleteResponseData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          switch (error.response.data.message) {
            case "Could not delete user from cognito":
              userDeleteResponseData.error_message = "FAILED_TO_DELETE_USER_FROM_COGNITO";
              break;
            case "Could not delete user from slid db":
              userDeleteResponseData.error_message = "FAILED_TO_DELETE_USER_FROM_SLID_DB";
              break;
            case "Could not delete subscription":
              userDeleteResponseData.error_message = "FAILED_TO_DELETE_SUBSCRIPTION";
              break;
            default:
              userDeleteResponseData.error_message = "FAILED_TO_DELETE_USER";
          }
          break;
        default:
          userDeleteResponseData.error_message = "UNKNOWN_ERROR";
          //TODO: log to sentry as it might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      userDeleteResponseData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      userDeleteResponseData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return userDeleteResponseData;
  }
};

export const ServerInterface_updateUser = async ({ data }) => {
  const payload = data;
  let updatedUserData = {};

  try {
    const updatedUserDataResponse = await slidAxios.put(users_endpoint, payload);
    updatedUserData = updatedUserDataResponse.data;
  } catch (error) {
    console.error(error);
    if (!updatedUserData) updatedUserData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          updatedUserData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          updatedUserData.error_message = "METHOD_NOT_ALLOWED";
          break;
        default:
          updatedUserData.error_message = "UNKNOWN_ERROR";
          //TODO: log to sentry as it might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      updatedUserData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      updatedUserData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return updatedUserData;
  }
};

/*
  This function:
    - creates a user in the DB
    - start a free trial
    - send signup email
    - apply automatic promotions
    - enroll users in study challenges 
  [POST] /api/<version>/users/
  Returns the created user data
*/
export const ServerInterface_createUser = async ({ device = null, loginType, locale = "en", userCountry = null }) => {
  let userData = null;
  const payload = {
    device: device,
    login_type: loginType,
    locale: locale,
    country: userCountry,
  };
  const studyChallengePayload = {
    event_name: "14_DAYS_STUDY_CHALLENGE",
    date: new Date().toLocaleDateString("pt-br").split("/").reverse().join("-"), // local YYYY-MM-DD format
  };

  try {
    const createUserResponse = await slidAxios.post(users_endpoint, { ...payload, ...studyChallengePayload });
    userData = createUserResponse.data;
  } catch (error) {
    if (!userData) userData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          userData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          userData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 422:
          userData.error_message = "CREATE_USER_INVALID_COGNITO_ID_TOKEN";
          break;
        case 400:
          userData.error_message = "CREATE_USER_INVALID_DATA";
          break;
        default:
          userData.error_message = "UNKNOWN_ERROR";
          //TODO: log to sentry as it might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      userData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      userData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return userData;
  }
};
