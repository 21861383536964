import { useQuery } from "@tanstack/react-query";
import { documentSearch } from "query-api/search";

interface SearchParams {
  keyword: string;
}

export const useDocumentSearch = (props: SearchParams) => {
  const { keyword } = props;

  const { data, isError, isLoading } = useQuery({
    queryKey: ["documentSearch", keyword],
    queryFn: async () => {
      const { data } = await documentSearch({
        keyword,
      });

      return data;
    },
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, isError };
};
