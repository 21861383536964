// auth 2.0
import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "api/errorUtils";

const grace_period_endpoint = `grace-period/`;

export const ServerInterface_fetchGracePeriods = async () => {
  let gracePeriod = null;
  try {
    const gracePeriodResponse = await slidAxios.get(grace_period_endpoint);
    gracePeriod = gracePeriodResponse.data;
  } catch (error) {
    if (!gracePeriod) gracePeriod = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        case 405:
          gracePeriod.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          gracePeriod.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          gracePeriod.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      // NETWORK_ERROR
      gracePeriod.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      // request sent but no response
      gracePeriod.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return gracePeriod;
  }
};
