import { Typography17, Button, Link, Typography28, useModal } from "@slid/slid-ips";
import React, { useEffect, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { trackEvent } from "utils/eventTracking";
import { usePolling } from "utils/customHook/usePolling";
import { setIsWaitingForSignIn } from "redux/actions/demoActions";
import { useAppDispatch, useAppSelector } from "hooks";

const SignupModal = memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { startPolling } = usePolling();
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const [browserWidth, setBrowserWidth] = useState<number>(0);
  const [browserHeight, setBrowserHeight] = useState<number>(0);

  useEffect(() => {
    setBrowserWidth(document.documentElement.offsetWidth);
    setBrowserHeight(document.documentElement.offsetHeight);
  }, []);

  return (
    <>
      {browserWidth && browserHeight && (
        <SignupModalContainer browserHeight={browserHeight} browserWidth={browserWidth}>
          <Typography28 weight={700} color="--gray17" text={t("SignupFirstModalTitle", { ns: "Modal" })} textAlign="center" />
          <SignupModalImage src="/src/design/assets/slid_welcome.png" alt="" />
          <Typography17 weight={400} color="--gray9" text={t("SignupFirstModalText", { ns: "Modal" })} marginTop="24px" textAlign="center" />
          <ButtonConatiner>
            <SocialLoginButton
              appearance="primary"
              size="maxWidthLarge"
              icon={<img src="/src/assets/icon_google_logo_white_24.svg" alt="" />}
              text={t("GoogleAuthButton", { ns: "SignIn" })}
              callback={() => {
                trackEvent({
                  eventType: "Click sign-in in demo modal",
                  eventProperties: { type: "google" },
                });
                if (applicationType === "extension") {
                  sessionStorage.setItem("isFromInstantLaunch", "true");
                  dispatch(setIsWaitingForSignIn(true));

                  setTimeout(() => {
                    startPolling();
                  });
                  window.open(`/extension-auth-redirect?previous=instant-launch&loginType=google`);
                } else {
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  });
                }
              }}
            />
            <SocialLoginButton
              appearance="primary"
              size="maxWidthLarge"
              icon={<img src="/src/assets/icon_apple_logo_white_24.svg" alt="" />}
              text={t("AppleAuthButton", { ns: "SignIn" })}
              callback={() => {
                trackEvent({
                  eventType: "Click sign-in in demo modal",
                  eventProperties: { type: "apple" },
                });

                if (applicationType === "extension") {
                  sessionStorage.setItem("isFromInstantLaunch", "true");
                  dispatch(setIsWaitingForSignIn(true));

                  setTimeout(() => {
                    startPolling();
                  });
                  window.open(`/extension-auth-redirect?previous=instant-launch&loginType=apple`);
                } else {
                  //@ts-ignore
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Apple,
                  });
                }
              }}
            />
            <Button
              appearance="line"
              size="maxWidthLarge"
              text={t("EmailAuthButton", { ns: "SignIn" })}
              callback={() => {
                trackEvent({
                  eventType: "Click sign-in in demo modal",
                  eventProperties: { type: "email" },
                });

                if (applicationType === "extension") {
                  sessionStorage.setItem("isFromInstantLaunch", "true");
                  dispatch(setIsWaitingForSignIn(true));

                  setTimeout(() => {
                    startPolling();
                  });
                  window.open(`/sign-in?previous=instant-launch&from=demo-video-note`);
                } else {
                  closeModal();
                  window.location.assign(`/sign-in?from=demo-video-note`);
                }
              }}
            />
          </ButtonConatiner>
          <Link
            infoText={t("AuthRequiredLinkSignUpDescText", { ns: "Modal" })}
            linkText={t("AuthRequiredLinkSignUpText", { ns: "Modal" })}
            href="#"
            onClick={() => {
              trackEvent({
                eventType: "Click sign-up in demo modal",
              });

              if (applicationType === "extension") {
                dispatch(setIsWaitingForSignIn(true));

                setTimeout(() => {
                  startPolling();
                });
                window.open(`/sign-up?previous=instant-launch&from=demo-video-note`);
              } else {
                closeModal();
                window.location.assign(`/sign-up?from=demo-video-note`);
              }
            }}
            marginTop="32px"
          />
        </SignupModalContainer>
      )}
    </>
  );
});

export default SignupModal;

interface SignupModalStyleProps {
  browserWidth?: number;
  browserHeight?: number;
}

const SignupModalContainer = styled.div<SignupModalStyleProps>`
  width: 496px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 46px;
  background-color: var(--gray1);
  border-radius: 8px;
  box-shadow: var(--boxShadow3);
`;

const SignupModalImage = styled.img`
  width: 320px;
  height: 208.92px;
  margin-top: 24px;
`;

const ButtonConatiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 36px;
`;

const SocialLoginButton = styled(Button)`
  && {
    :hover {
      background-color: var(--blue9);
    }
  }
`;
