import { ModalProps, ModalTypes, OptionModalProps } from "@slid/slid-ips";
import { ShowModalProps } from ".";
import i18n from "config/i18n/i18n";
import { isMobile } from "react-device-detect";
import { ModalType, useModalStore } from "store/useModalStore";
const t = i18n.t.bind(i18n);

export interface ShowMoveToFolderModalProps extends ShowModalProps {
  options: {
    text: string;
    id: string;
    disabled: boolean;
  }[];
  onConfirmMoveToFolder: (selectedItem: any) => Promise<void>;
  resetSelectedDocuments: () => void;
  selectedItemLength: number;
  currentOption: {
    text: string;
    id: string;
    disabled: boolean;
  };
}

interface ShowRemoveDocumentModalProps extends ShowModalProps {
  documentKeys: string[];
  currentFolder?: any;
  onConfirmRemove: () => void;
}

export const showMoveToFolderModal = async ({
  options,
  showModal,
  closeModal,
  history,
  onConfirmMoveToFolder,
  selectedItemLength,
  currentOption,
  resetSelectedDocuments,
}: ShowMoveToFolderModalProps) => {
  if (options.length === 0) return;
  const modalProps: OptionModalProps = {
    type: ModalTypes.optionModal,
    title: t(selectedItemLength > 1 ? "MoveToFolderTitle_plural" : "MoveToFolderTitle", { ns: "MyNotes", count: selectedItemLength }),
    options: options,
    primaryButton: {
      text: t("MoveToFolderPrimaryButton", { ns: "MyNotes" }),
      callBack: async (selectedItem) => {
        closeModal();
        await useModalStore.getState().showModal({
          type: ModalType.LOADING,
          text: "move",
          target: "body",
        });
        await onConfirmMoveToFolder(selectedItem);
        useModalStore.getState().hideModal();
        resetSelectedDocuments();
      },
    },
    secondaryButton: {
      text: t("MoveToFolderCancelButton", { ns: "MyNotes" }),
      callBack: () => {
        closeModal();
      },
    },
    currentOption: currentOption,
  };
  showModal(modalProps);
};

export const showRemoveDocumentModal = async ({ showModal, closeModal, documentKeys, onConfirmRemove }: ShowRemoveDocumentModalProps) => {
  const modalProps: ModalProps = {
    type: ModalTypes.modal,
    title: t("RemoveDocumentsModalTitle", { ns: "MyNotes", count: documentKeys.length }),
    text: t("RemoveDocumentsModalContent", { ns: "MyNotes" }),
    primaryButton: {
      text: t("RemoveDocumentsModalPrimaryButton", { ns: "MyNotes" }),
      callBack: async () => {
        closeModal();
        useModalStore.getState().showModal({
          type: ModalType.LOADING,
          target: "body",
          text: "delete",
        });
        await onConfirmRemove();
        useModalStore.getState().hideModal();
      },
    },
    secondaryButton: {
      text: t("RemoveDocumentsModalCancelButton", { ns: "MyNotes" }),
      callBack: () => {
        closeModal();
      },
    },
    device: isMobile ? "mobile" : "desktop",
  };
  showModal(modalProps);
};
