import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SortingOptionType } from "types/search";
import Cookies from "js-cookie";

const name = "myNotes";

const getSortingOption = () => {
  const SlidCustomSettingCookieName = "SlidCustomSetting";
  const currentCookie = JSON.parse(Cookies.get(SlidCustomSettingCookieName) || "{}");

  return currentCookie.isSortingOptionExist || SortingOptionType.Date;
};

// Define a type for the slice state
interface State {
  showMyNoteSideMenu: boolean;
  sortingOption: SortingOptionType;
  popupState: "SIGNUP" | "SIGNIN" | "SIGNEDIN" | "createFolder" | null;
}

const initialState: State = {
  showMyNoteSideMenu: true,
  sortingOption: getSortingOption(),
  popupState: null,
};

export const myNotesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setShowMyNoteSideMenu: (state, action: PayloadAction<boolean>) => {
      state.showMyNoteSideMenu = action.payload;
    },
    setSortingOption: (state, action: PayloadAction<SortingOptionType>) => {
      state.sortingOption = action.payload;
    },
    setPopupState: (state, action: PayloadAction<"SIGNUP" | "SIGNIN" | "SIGNEDIN" | "createFolder" | null>) => {
      state.popupState = action.payload;
    },
  },
});

export const { setShowMyNoteSideMenu, setSortingOption, setPopupState } = myNotesSlice.actions;

export default myNotesSlice.reducer;
