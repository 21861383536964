import { eventTypes } from "./../types/eventTracking/index";
import { trackEvent } from "../utils/eventTracking/index";
import {
  playOnboardingVideo,
  setActiveButtonType,
  setVisibleSLTBlocksOnSLTView,
  setVisibleSLTBlocksOnNote,
  stopOnboardingVideo,
  setVisibleImageBlocks,
  setVisibleGrabTextBlocks,
  setOverlayMaskMode,
} from "redux/actions/demoActions";
import { useEffect, useState } from "react";
import { STEP1_DATA_KO, STEP3_DATA_KO, STEP1_DATA_EN, STEP3_DATA_EN } from "components/VideoNoteDemo/demoData";
import { useModal, useToast } from "@slid/slid-ips";
import { setShowSmartLiveTextView } from "redux/actions/sttActions";
import { showExtensionOnboardingModal } from "utils/modal/extensionOnboardingModal";
import { OVERLAY_MASK_MODE } from "components/VideoNoteDemo/DemoEditor/overlayMaskConfig";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";

export type OnboardingButtonType = null | "one-click" | "grab-text" | "smart-live-text" | "slt-add-to-note-1" | "slt-add-to-note-2" | "slt-add-to-note-3" | string;

const VIDEO_STEP_INFO_ARR: { start: number; practice: number; end: number; maskMode: keyof typeof OVERLAY_MASK_MODE }[] = [
  // Step 1: 1-click capture
  {
    start: 0,
    practice: 16,
    end: 20,
    maskMode: OVERLAY_MASK_MODE.ONE_CLICK,
  },
  // Step 2: Grab text
  {
    start: 21,
    practice: 30,
    end: 30,
    maskMode: OVERLAY_MASK_MODE.GRAB_TEXT,
  },
  // Step 3: Smart Live Text
  {
    start: 31,
    practice: 41,
    end: 49,
    maskMode: OVERLAY_MASK_MODE.SLT,
  },
  // Step 4: Add to note
  {
    start: 50,
    practice: 51,
    end: 52,
    maskMode: OVERLAY_MASK_MODE.INITIAL,
  },
];

export const useVideoNoteOnboarding = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Demo");
  const [insertSLTBlocks, setInsertSLTBlocks] = useState(false);
  const [insertOneClickImage, setInsertOneClickImage] = useState(false);
  const [insertGrabTextBlocks, setInsertGrabTextBlocks] = useState(false);
  const [activateAddToNoteButton, setActivateAddToNoteButton] = useState(false);
  const { visibleSLTBlocksOnSLTView, visibleSLTBlocksOnNote, visibleImageBlocks, visibleGrabTextBlocks } = useAppSelector((state) => state.demo);
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const { showToast } = useToast();
  const { showModal } = useModal();

  const STEP1_DATA = lang === "ko" ? STEP1_DATA_KO : STEP1_DATA_EN;
  const STEP3_DATA = lang === "ko" ? STEP3_DATA_KO : STEP3_DATA_EN;

  const showClickButtonHighlight = (origin: OnboardingButtonType) => {
    dispatch(stopOnboardingVideo());
    dispatch(setActiveButtonType(origin));

    /**
     * @Description
     * based on origin, set overlay mask mode differently
     * so that specific button can be highlighted
     */
    switch (origin) {
      case "one-click":
        dispatch(setOverlayMaskMode(OVERLAY_MASK_MODE.ONE_CLICK));
        break;
      case "grab-text":
        dispatch(setOverlayMaskMode(OVERLAY_MASK_MODE.GRAB_TEXT));
        break;
      case "smart-live-text":
        dispatch(setOverlayMaskMode(OVERLAY_MASK_MODE.SLT));
        break;
      default:
        dispatch(setOverlayMaskMode(OVERLAY_MASK_MODE.INITIAL));
        break;
    }
  };

  /**
   * @Step1
   * when video reaches 5s, trigger showClickButtonHighlight
   * 1. pause video
   * 2. show 1 click button interaction
   * when button is clicked, trigger handle1ClickButtonClicked
   * 1. insert capture 5 images to note section
   * 2. pause video and show message
   */

  const handle1ClickButtonClicked = () => {
    trackEvent({ eventType: eventTypes.click._1_CLICK_CAPTURE_IN_DEMO_VIDEO_NOTE_PAGE });
    dispatch(setActiveButtonType(null));
    dispatch(playOnboardingVideo());
    setInsertOneClickImage(true);

    // hide overlay mask:
    dispatch(setOverlayMaskMode(OVERLAY_MASK_MODE.HIDE));
    // dispatch(setInsertImageToDemoEditor(true));
  };

  // useEffect for rendering capture images one by one every 1000ms
  useEffect(() => {
    if (!insertOneClickImage) return;
    let interval: NodeJS.Timeout;
    if (visibleImageBlocks.length < STEP1_DATA.length) {
      interval = setInterval(() => {
        dispatch(setVisibleImageBlocks([...visibleImageBlocks, visibleImageBlocks.length + 1]));
      }, 1300);
    }

    return () => {
      clearTimeout(interval);
    };
  }, [visibleImageBlocks, insertOneClickImage]);

  useEffect(() => {
    if (visibleImageBlocks.length === STEP1_DATA.length) {
      setTimeout(() => {
        showToast(t("TextHint1"));
      }, 500);
    }
  }, [visibleImageBlocks]);

  /**
   * @Step2
   * when video reaches 10s, trigger showClickButtonHighlight
   * 1. pause video
   * 2. show grab text button interaction
   * when button is clicked, trigger handleGrabTextButtonClicked
   * 1. insert capture 5 ocr blocks to note
   * 2. pause video and show message
   */

  const handleGrabTextButtonClicked = () => {
    trackEvent({ eventType: eventTypes.click.GRAB_TEXT_IN_DEMO_VIDEO_NOTE_PAGE });
    dispatch(setActiveButtonType(null));
    setInsertGrabTextBlocks(true);

    // hide overlay mask:
    dispatch(setOverlayMaskMode(OVERLAY_MASK_MODE.HIDE));
  };

  // useEffect for rendering grab text boxes one by one every 1000ms
  useEffect(() => {
    if (!insertGrabTextBlocks) return;
    let interval: NodeJS.Timeout;
    if (visibleGrabTextBlocks.length < STEP1_DATA.length) {
      interval = setInterval(() => {
        dispatch(setVisibleGrabTextBlocks([...visibleGrabTextBlocks, visibleGrabTextBlocks.length + 1]));
      }, 1000);
    }

    return () => {
      clearTimeout(interval);
    };
  }, [visibleGrabTextBlocks, insertGrabTextBlocks]);

  useEffect(() => {
    if (visibleGrabTextBlocks.length === STEP1_DATA.length) {
      setTimeout(() => {
        showToast(t("TextHint2"));
      }, 500);
      dispatch(playOnboardingVideo());
    }
  }, [visibleGrabTextBlocks]);

  /**
   * @Step3
   * when video reaches 20s, trigger showClickButtonHighlight
   * 1. pause video
   * 2. show SLT button interaction
   * when button is clicked, trigger handleSLTButtonClicked
   * 1. open slt side bar
   * 2. insert SLT blocks to SLT section
   * 3. auto click add to note button
   * 4. insert SLT text blocks to note section
   * 5. pause video and show message
   */

  const handleSLTButtonClicked = () => {
    trackEvent({ eventType: eventTypes.click.SLT_IN_DEMO_VIDEO_NOTE_PAGE });
    dispatch(playOnboardingVideo());
    dispatch(setActiveButtonType(null));
    setInsertSLTBlocks(true);

    // hide overlay mask:
    dispatch(setOverlayMaskMode(OVERLAY_MASK_MODE.HIDE));
  };

  useEffect(() => {
    if (!insertSLTBlocks) return;

    let interval: NodeJS.Timeout;
    if (visibleSLTBlocksOnSLTView.length < STEP3_DATA.length) {
      interval = setInterval(() => {
        dispatch(setVisibleSLTBlocksOnSLTView([...visibleSLTBlocksOnSLTView, visibleSLTBlocksOnSLTView.length + 1]));
      }, 1000);
    }
    if (visibleSLTBlocksOnSLTView.length === STEP3_DATA.length) {
      setActivateAddToNoteButton(true);
    }
    return () => {
      clearTimeout(interval);
    };
  }, [insertSLTBlocks, visibleSLTBlocksOnSLTView]);

  useEffect(() => {
    if (!activateAddToNoteButton) return;
    let addToNoteInterval: NodeJS.Timeout;
    if (visibleSLTBlocksOnNote.length < STEP3_DATA.length) {
      addToNoteInterval = setInterval(() => {
        dispatch(setActiveButtonType(`slt-add-to-note-${visibleSLTBlocksOnNote.length + 1}`));
        setTimeout(() => {
          dispatch(setActiveButtonType(null));
        }, 1000);
        setTimeout(() => {
          dispatch(setVisibleSLTBlocksOnNote([...visibleSLTBlocksOnNote, visibleSLTBlocksOnNote.length + 1]));
        }, 500);
      }, 1000);
    } else if (visibleSLTBlocksOnNote.length === STEP3_DATA.length) {
      setTimeout(() => {
        dispatch(setActiveButtonType(null));
        showToast(t("TextHint3"));
        dispatch(setShowSmartLiveTextView(false));
      }, 1300);
    }

    return () => {
      clearTimeout(addToNoteInterval);
    };
  }, [activateAddToNoteButton, visibleSLTBlocksOnNote]);

  const handleOnboardingStep4 = () => {
    showExtensionOnboardingModal({ showModal });
  };

  return { showClickButtonHighlight, handle1ClickButtonClicked, handleGrabTextButtonClicked, handleSLTButtonClicked, VIDEO_STEP_INFO_ARR, handleOnboardingStep4 };
};
