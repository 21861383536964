// auth 2.0
import store from "redux/store";
import env from "config/env";

export const connectPortToExtension = () => {
  if (env.currentEnv === "production") {
    return window.chrome?.runtime?.connect?.(env.end_point_url.extension_id, { name: "SLID_WEBSITE_TO_BACKGROUND_PORT" });
  } else {
    const extensionId = store.getState()?.slidGlobal?.extensionId;
    if (extensionId) {
      return window.chrome?.runtime?.connect?.(extensionId, { name: "SLID_WEBSITE_TO_BACKGROUND_PORT" });
    }
  }
};
