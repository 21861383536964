import slidAxios from "./base";
import { handleAuthErrors, isAuthError } from "./errorUtils";
const subscription_endpoint = `subscription/`;

export const ServerInterface_updateSubscription = async ({ data }) => {
  /*
  data = {
    subscription_key:"some_valid_key",
    is_active:true/false,
  }
  */

  const payload = data;
  let updateSubscriptionResponse = null;

  try {
    const updateSubscriptionRawResponse = await slidAxios.put(subscription_endpoint, payload);
    updateSubscriptionResponse = updateSubscriptionRawResponse.data;
  } catch (error) {
    if (!updateSubscriptionResponse) updateSubscriptionResponse = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      updateSubscriptionResponse.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          updateSubscriptionResponse.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          //"Missing Subscription Key" or "Invalid Subscription Key" or "Unsupported payment gateway"
          switch (error.response.data.message) {
            case "Missing Subscription Key":
              updateSubscriptionResponse.error_message = "MISSING_SUBSCRIPTION_KEY";
              break;
            case "Invalid Subscription Key":
              updateSubscriptionResponse.error_message = "INVALID_SUBSCRIPTION_KEY";
              break;
            case "Unsupported payment gateway":
              updateSubscriptionResponse.error_message = "UNSUPPORTED_PAYMENT_GATEWAY";
              break;
            default:
              updateSubscriptionResponse.error_message = "SUBSCRIPTION_PUT_ERROR";
              break;
          }
          break;
        case 403:
          updateSubscriptionResponse.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 404:
          updateSubscriptionResponse.error_message = "SUBSCRIPTION_NOT_FOUND";
          break;
        default:
          updateSubscriptionResponse.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      updateSubscriptionResponse.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      updateSubscriptionResponse.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return updateSubscriptionResponse;
  }
};

/**
 * @param {cognitoIdToken} param
 * @returns {
    'subscription_key' : str,
    'payment_gateway' : str (paypal or payple),
    'is_active' : bool,
    'started_at' : timezome aware datetime as str,
    'last_billed_at' : timezome aware datetime as str,
    'next_billing_at' : timezome aware datetime as str,
    'subscription_months' : number,
    'payment_cnt' : number
}
 */
export const ServerInterface_getUserSubscriptionData = async () => {
  let userSubscriptionData = {};
  try {
    const userSubscriptionDataResponse = await slidAxios.get(subscription_endpoint);
    userSubscriptionData = userSubscriptionDataResponse.data;
  } catch (error) {
    console.error(error);
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      userSubscriptionData.data = error.response.data;
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 405:
          userSubscriptionData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          userSubscriptionData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 404:
          userSubscriptionData.error_message = "SUBSCRIPTION_NOT_FOUND";
          break;
        default:
          userSubscriptionData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      userSubscriptionData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      userSubscriptionData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return userSubscriptionData;
  }
};
