import "./index.css";
import Tunes from "./tunes";
import { formatImageTime } from "./image-util";
import { sendAmplitudeData } from "utils/amplitude";
import store from "redux/store";
import { SlidFeatures } from "utils/privilegeManager";
import {
  createDocument,
  getClipOCRResults,
  registerClip,
  registerClipOCR,
  setEditorLastActiveBlockPosition,
  setShowImageFullScreen,
  setNeedToSignUp,
  setShowImageMarkupPopup,
  setVideoIndex,
  setIsListOpen,
  setVideoNoteOnboardingStep,
  setIsImageCroppingModalOpen,
  setImageCroppingModalImageSource,
  setCroppingImageBlockData,
  setImageCropArea,
  setShouldUpdateImageByCrop,
  deleteClip,
} from "redux/actions/vdocsActions";
import { setShowSliddyChat } from "redux/modules/aiSliddySlice";
import { alertAnalyzingTextsFailed, alertAnalyzingTextsFromImage, alertScreenshotNotSaved } from "components/alerts";
import Sweetalert from "sweetalert2";
import { addCodeBlock } from "components/editor/utils";
import { uploadFileImage } from "utils/aws/s3Interface";
import { sendVideoSeekToTimestampRequestToParentWindow } from "utils/extensionInterface/sendToExtension";
import env from "config/env";
import * as Sentry from "@sentry/browser";
import i18n from "config/i18n/i18n";
import { isTouchDevice, toBase64 } from "utils/utils";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import { getCDNSrc } from "utils/image/cdn";

const t = i18n.t.bind(i18n);

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;

export default class ImageTool {
  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly, block }) {
    this.data = data;
    this.config = config;
    this.api = api;
    this.readOnly = readOnly;
    this.block = block;
    this.originalImageSrc = "";

    this.vdocs = store.getState().vdocs;
    this.slidGlobal = store.getState().slidGlobal;
    this.isSlidPocket = this.slidGlobal.applicationType === "slidpocket";
    this.showSliddy = store.getState().aiSliddy.showSliddyChat;
    this.lang = this.slidGlobal.lang;
    this.screenOrientation = this.slidGlobal.screenOrientation;

    this.ocrResponseIntervalId = null;
    this.mode = window.location.pathname.split("/")[1] === "vdocs" ? "vdocs" : "plain docs";
    this.isDemoMode = window.location.pathname.includes("demo");

    this.isMobile = window.matchMedia("(max-width:799px)").matches;

    this.tunes = new Tunes({
      api,
      actions: this.config.actions,
      onChange: (tuneName) => this.tuneToggled(tuneName),
    });
  }

  async updateImageBlock(img, loaderElement) {
    const clipData = await this.data.setClipData();
    const editorImageBlockErrorPlaceholderEn = getCDNSrc("https://slid-public-assets.s3.us-west-1.amazonaws.com/images/editor_image_block_error_placeholder_en.png");
    const editorImageBlockErrorPlaceholderKo = getCDNSrc("https://slid-public-assets.s3.us-west-1.amazonaws.com/images/editor_image_block_error_placeholder_ko.png");

    const onImageUploadError = () => {
      const reduxError = store.getState().slidGlobal.errorMessage;
      if (reduxError && reduxError === "INSUFFICIENT_PRIVILEGES") return;
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        Sentry.captureMessage("Slid web capture image upload failed");
      });
      alertScreenshotNotSaved();
    };

    if (clipData && clipData.src) {
      this.data = {
        ...clipData,
        originalImageSrc: clipData.src,
      };
      this.isUploaded = true;
      img.onload = null;
      img.src = getCDNSrc(this.data.src);
      img.onerror = () => {
        img.src = this.lang === "ko" ? editorImageBlockErrorPlaceholderKo : editorImageBlockErrorPlaceholderEn;
      };

      this.config.saveDocument();
      // remove loader after image is uploaded to S3
      loaderElement.remove();
      this.showImageUploadFinished();
    } else {
      // Remove image block by looking for the id of the block to get image block's index.
      const editorData = await this.api.saver.save();
      const currentBlockIndex = editorData.blocks.findIndex((block) => block.id === this.block.id);
      this.api.blocks.delete(currentBlockIndex);
      // Upload failed
      // show alert and send Sentry error
      onImageUploadError();
    }
  }

  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.draggable = !this.readOnly && !isTouchDevice();
    this.wrapper.className = this.isSlidPocket ? "cdx-block image-block slid-pocket" : "cdx-block image-block";

    // Need to Upload image direct to S3 and replace url
    const editorImageBlockErrorPlaceholderEn = getCDNSrc("https://slid-public-assets.s3.us-west-1.amazonaws.com/images/editor_image_block_error_placeholder_en.png");
    const editorImageBlockErrorPlaceholderKo = getCDNSrc("https://slid-public-assets.s3.us-west-1.amazonaws.com/images/editor_image_block_error_placeholder_ko.png");

    const img = document.createElement("img");
    const imagePanel = document.createElement("div");

    imagePanel.className = "image-panel";

    //listen for resizing on image block to inorder to change css classes on the image panel
    let resizer = new ResizeObserver(handleResize);
    resizer.observe(imagePanel);

    function handleResize(entries) {
      let div = entries[0].target;
      if (Math.floor(entries[0].contentRect.width) < 350) {
        div.classList.add("small");
      } else {
        //remove big class
        div.classList.remove("small");
      }
      if (Math.floor(entries[0].contentRect.width) < 260) {
        div.classList.add("smallest");
      } else {
        div.classList.remove("smallest");
      }
      if (Math.floor(entries[0].contentRect.width) < 235) {
        div.classList.add("slim");
      } else {
        div.classList.remove("slim");
      }
    }

    img.setAttribute("blocktype", "image");
    img.draggable = !this.readOnly && !isTouchDevice();
    img.contentEditable = false;

    // If the block data has rawSrc(base64) property, it is not yet uploaded to S3.
    this.isUploaded = !this.data.hasOwnProperty("rawSrc");

    if (!this.isUploaded) {
      img.src = this.data.rawSrc;
      img.onload = async (event) => {
        if (this.data.type === "demoCapture" || this.isDemoMode) return;
        // Show loading until it is uploaded to S3
        const imgUploadLoaderWrapper = this.createImageUploadLoaderWrapperElement();
        this.wrapper.appendChild(imgUploadLoaderWrapper);
        await this.updateImageBlock(event.target, imgUploadLoaderWrapper);
        // add image panel after image was uploaded
        imagePanel.innerHTML = "";
        imagePanel.appendChild(this.renderTimeStamp());
        imagePanel.appendChild(this.renderPanelButtons());
      };
    } else {
      if (this.data.src) {
        img.src = getCDNSrc(this.data.src);
        img.onerror = () => {
          img.src = this.lang === "ko" ? editorImageBlockErrorPlaceholderKo : editorImageBlockErrorPlaceholderEn;
        };
      } else if (this.data.fullSizeImageSrcForCropping) {
        img.src = getCDNSrc(this.data.fullSizeImageSrcForCropping);
        img.onerror = () => {
          img.src = this.lang === "ko" ? editorImageBlockErrorPlaceholderKo : editorImageBlockErrorPlaceholderEn;
        };
      } else if (this.isSlidPocket) {
        const mobileImageLoader = this.createSlidMobileImageLoaderWrapperElement();
        this.wrapper.appendChild(mobileImageLoader);
        img.style.display = "none";
      } else {
        img.src = this.lang === "ko" ? editorImageBlockErrorPlaceholderKo : editorImageBlockErrorPlaceholderEn;
      }
    }

    img.alt = "screen captures";

    img.addEventListener("click", () => {
      if (this.isSlidPocket) return;
      if (!this.readOnly) {
        store.dispatch(setEditorLastActiveBlockPosition(this.api.blocks.getCurrentBlockIndex()));
      }

      if (this.config.isSharingMode) {
        if (this.data.timestamp !== undefined && this.data.videoInfo) {
          sendAmplitudeData(`Click image timestamp`, {
            mode: "share",
          });
          this.onClickPlayVideoFromTs({
            clipVideoKey: this.data.videoInfo.videoKey,
            clipTs: this.data.timestamp,
            videoInfo: this.data.videoInfo,
          });
        }
      }
    });

    img.addEventListener("dblclick", () => {
      if (this.isSlidPocket) return;
      trackEvent({
        eventType: "Click image full screen",
        eventProperties: {
          mode: this.getEventMode(),
          origin: "image double click",
        },
      });
      this.handleClickFullScreen();
    });

    img.setAttribute("slid-cy", "editor-image");

    img.addEventListener("load", () => {
      // imageCropArea is changed in the ImageCroppingModal
      // if imageCropArea is not null, save it into the data
      // It should be initialized to null, for not being enabled to the other images.
      const imageCropArea = store.getState().vdocs.imageCropArea;
      if (imageCropArea) {
        this.data.imageCropArea = imageCropArea;
        store.dispatch(setImageCropArea(null));
      }
    });
    this.wrapper.appendChild(img);
    // add image panel on existing images if it's not in note history page
    if (!window.location.pathname.includes("/history")) {
      this.wrapper.appendChild(imagePanel);
      imagePanel.appendChild(this.renderTimeStamp());
      imagePanel.appendChild(this.renderPanelButtons());

      this.wrapper.addEventListener("mouseover", () => {
        if (this.config.isSharingMode) this.wrapper.classList.add("shared-note-focused-img");
      });

      this.wrapper.addEventListener("mouseout", () => {
        if (this.config.isSharingMode) this.wrapper.classList.remove("shared-note-focused-img");
      });
    }

    // add dummy input for deleting the block by pressing backspace
    const dummyInput = document.createElement("input");
    dummyInput.className = "dummy-input";
    if (this.slidGlobal.applicationType !== "slidpocket") this.wrapper.appendChild(dummyInput);

    return this.wrapper;
  }
  updateVideoNoteOnboardingStep() {
    if (store.getState().vdocs.videoNoteOnboardingStep === 2) {
      store.dispatch(setVideoNoteOnboardingStep(3));
    }
  }
  handleClickFullScreen() {
    if (this.isDemoMode) {
      trackEvent({
        eventType: "click IMAGE HOVER BUTTON in demo",
        eventProperties: {
          type: "fullscreen",
        },
      });
      store.dispatch(setShowImageFullScreen(true));
      this.config.onClickFullScreen({
        src: this.data.rawSrc,
      });
      return;
    }
    store.dispatch(setShowImageFullScreen(true));
    this.config.onClickFullScreen({
      src: getCDNSrc(this.data.src),
    });
    this.updateVideoNoteOnboardingStep();
  }

  handleClickOcr() {
    if (this.isDemoMode) {
      trackEvent({
        eventType: "click IMAGE HOVER BUTTON in demo",
        eventProperties: {
          type: "grab text",
        },
      });
      store.dispatch(setNeedToSignUp(true));
      return;
    }
    if (!this.config.confirmPrivilege(SlidFeatures.imageOcr)) return this.config.showInsufficientPrivilegeModal();
    this.onClickOcr({
      clipKey: this.data.clipKey,
      blockIndex: this.api.blocks.getCurrentBlockIndex(),
    });
    this.updateVideoNoteOnboardingStep();
  }

  handleClickMarkup() {
    if (this.isDemoMode) {
      trackEvent({
        eventType: "click IMAGE HOVER BUTTON in demo",
        eventProperties: {
          type: "image markup",
        },
      });
      store.dispatch(setNeedToSignUp(true));
      return;
    }
    if (this.showSliddyChat) {
      store.dispatch(setShowSliddyChat(false));
    }

    if (!this.config.confirmPrivilege(SlidFeatures.imageDrawing)) return this.config.showInsufficientPrivilegeModal();
    this.config.onClickMarkup({
      src: this.data.src,
      originalImageSrc: this.data.originalImageSrc ? this.data.originalImageSrc : this.data.fullSizeImageSrcForCropping ? this.data.fullSizeImageSrcForCropping : this.data.src,
      markupImageSrc: this.data.markupImageSrc,
      clipKey: this.data.clipKey,
      blockIndex: this.api.blocks.getCurrentBlockIndex(),
      markupCanvasData: this.data.markupCanvasData,
      fullSizeImageSrcForCropping: this.data.fullSizeImageSrcForCropping,
      imageCropArea: this.data.imageCropArea,
    });
    store.dispatch(setShowImageMarkupPopup(true));
    this.updateVideoNoteOnboardingStep();
  }

  handleClickPlayHere() {
    if (this.isDemoMode) {
      store.dispatch(setIsListOpen(false));
      trackEvent({
        eventType: "click IMAGE HOVER BUTTON in demo",
        eventProperties: {
          type: "timestamp",
        },
      });
      this.onClickPlayVideoFromTs({
        clipTs: this.data.timestamp,
      });
      return;
    }
    if (!this.config.confirmPrivilege(SlidFeatures.imageTimeStamp)) return this.config.showInsufficientPrivilegeModal();
    store.dispatch(setIsListOpen(false));
    this.onClickPlayVideoFromTs({
      clipVideoKey: this.data.videoInfo.videoKey,
      clipTs: this.data.timestamp,
      videoInfo: this.data.videoInfo,
    });
    this.updateVideoNoteOnboardingStep();
  }

  handleClickDelete() {
    const clipKey = this.data.clipKey;
    if (clipKey) store.dispatch(deleteClip({ clipKey }));
    this.api.blocks.delete();
    this.updateVideoNoteOnboardingStep();
  }

  renderTimeStamp() {
    const onMobile = this.screenOrientation === "vertical";
    const showTimeStamp = this.isDemoMode || (this.data.timestamp !== undefined && this.data.videoInfo);
    if (!showTimeStamp || this.isSlidPocket) {
      const placeholder = document.createElement("span");
      return placeholder;
    }

    const icon = {
      name: "play",
      time: this.data.timestamp,
      title: this.lang === "ko" ? "여기부터 재생" : "Play here",
      text: this.lang === "ko" ? "부터 재생" : "Play from",
      svg: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#84BFF7"/>
      <g filter="url(#filter0_d_136_6922)">
      <path d="M7.61418 5.31177C7.28092 5.11181 6.85693 5.35187 6.85693 5.74052V14.26C6.85693 14.6487 7.28092 14.8887 7.61418 14.6888L14.7138 10.429C15.0375 10.2348 15.0375 9.76574 14.7138 9.57153L7.61418 5.31177Z" fill="white"/>
      </g>
      <defs>
      <filter id="filter0_d_136_6922" x="2.85693" y="1.23975" width="16.0996" height="17.5211" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.270588 0 0 0 0 0.576471 0 0 0 0 0.988235 0 0 0 0.6 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_136_6922"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_136_6922" result="shape"/>
      </filter>
      </defs>
      </svg>`,
      click: () => {
        trackEvent({
          eventType: "Click IMAGE TIMESTAMP",
          eventProperties: {
            mode: this.getEventMode(),
            origin: "image options",
          },
        });

        this.handleClickPlayHere();
      },
    };

    const timestamp_button = document.createElement("button");

    timestamp_button.classList.add("image-panel__timestamp-button");

    timestamp_button.addEventListener("click", icon.click);

    const playIcon = document.createElement("div");
    playIcon.classList.add("image-panel__play-icon");

    if (onMobile) playIcon.classList.add("mobile");

    const playIconContainer = document.createElement("div");
    playIconContainer.classList.add("image-panel__icon-container");
    playIconContainer.innerHTML = icon.svg;

    playIcon.appendChild(playIconContainer);

    timestamp_button.appendChild(playIcon);

    const textDiv = document.createElement("div");
    textDiv.classList.add("image-panel__text");

    const timeSpan = document.createElement("span");
    const textSpan = document.createElement("span");

    textSpan.innerHTML = icon.text;

    textSpan.classList.add("image-panel__text-info");

    timeSpan.innerHTML = formatImageTime(icon.time);
    timeSpan.classList.add("image-panel__text-time");

    if (this.lang === "ko") {
      textSpan.classList.add("kor");
    }

    textDiv.appendChild(textSpan);

    textDiv.appendChild(timeSpan);

    timestamp_button.appendChild(textDiv);

    return timestamp_button;
  }

  renderPanelButtons() {
    const onMobile = this.screenOrientation === "vertical";
    let buttonsWrapper = document.createElement("div");
    buttonsWrapper.classList.add("panel-buttons-wrapper");

    let panelButtons = document.createElement("div"); //styled buttons
    panelButtons.classList.add("image-panel__buttons");

    buttonsWrapper.appendChild(panelButtons);

    let panelItems = []; //button item's data to be rendered on panel

    if (this.isDemoMode || (this.data.clipKey && !this.readOnly && !this.isSlidPocket)) {
      panelItems.push({
        name: "text-grab",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.375 3C4.01033 3 3.66059 3.14487 3.40273 3.40273C3.14487 3.66059 3 4.01033 3 4.375V6.25C3 6.52614 2.77614 6.75 2.5 6.75C2.22386 6.75 2 6.52614 2 6.25V4.375C2 3.74511 2.25022 3.14102 2.69562 2.69562C3.14102 2.25022 3.74511 2 4.375 2H6.25C6.52614 2 6.75 2.22386 6.75 2.5C6.75 2.77614 6.52614 3 6.25 3H4.375Z" fill="#343A40"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.5 13.25C2.77614 13.25 3 13.4739 3 13.75V15.625C3 15.9897 3.14487 16.3394 3.40273 16.5973C3.66059 16.8551 4.01033 17 4.375 17H6.25C6.52614 17 6.75 17.2239 6.75 17.5C6.75 17.7761 6.52614 18 6.25 18H4.375C3.74511 18 3.14102 17.7498 2.69562 17.3044C2.25022 16.859 2 16.2549 2 15.625V13.75C2 13.4739 2.22386 13.25 2.5 13.25Z" fill="#343A40"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.25 2.5C13.25 2.22386 13.4739 2 13.75 2H15.625C16.2549 2 16.859 2.25022 17.3044 2.69562C17.7498 3.14102 18 3.74511 18 4.375V6.25C18 6.52614 17.7761 6.75 17.5 6.75C17.2239 6.75 17 6.52614 17 6.25V4.375C17 4.01033 16.8551 3.66059 16.5973 3.40273C16.3394 3.14487 15.9897 3 15.625 3H13.75C13.4739 3 13.25 2.77614 13.25 2.5Z" fill="#343A40"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.5 13.25C17.7761 13.25 18 13.4739 18 13.75V15.625C18 16.2549 17.7498 16.859 17.3044 17.3044C16.859 17.7498 16.2549 18 15.625 18H13.75C13.4739 18 13.25 17.7761 13.25 17.5C13.25 17.2239 13.4739 17 13.75 17H15.625C15.9897 17 16.3394 16.8551 16.5973 16.5973C16.8551 16.3394 17 15.9897 17 15.625V13.75C17 13.4739 17.2239 13.25 17.5 13.25Z" fill="#343A40"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.54688 5.07334C5.54688 4.94654 5.64015 4.84375 5.75521 4.84375H8.35942H10.9635C11.0786 4.84375 11.1718 4.94654 11.1718 5.07334V6.22129C11.1718 6.34809 11.0786 6.45088 10.9635 6.45088C10.8484 6.45088 10.7552 6.34809 10.7552 6.22129V5.30293H8.56776V10.0096H9.40064C9.5157 10.0096 9.60897 10.1124 9.60897 10.2392C9.60897 10.366 9.5157 10.4688 9.40064 10.4688H7.31733C7.20227 10.4688 7.10899 10.366 7.10899 10.2392C7.10899 10.1124 7.20227 10.0096 7.31733 10.0096H8.15109V5.30293H5.96354V6.22129C5.96354 6.34809 5.87026 6.45088 5.75521 6.45088C5.64015 6.45088 5.54688 6.34809 5.54688 6.22129V5.07334Z" fill="#343A40"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.29688 5.07334C5.29688 4.83188 5.47977 4.59375 5.75521 4.59375H10.9635C11.2389 4.59375 11.4218 4.83188 11.4218 5.07334V6.22129C11.4218 6.46275 11.2389 6.70088 10.9635 6.70088C10.6881 6.70088 10.5052 6.46275 10.5052 6.22129V5.55293H8.81776V9.75961H9.40064C9.67607 9.75961 9.85897 9.99774 9.85897 10.2392C9.85897 10.4807 9.67607 10.7188 9.40064 10.7188H7.31733C7.04189 10.7188 6.85899 10.4807 6.85899 10.2392C6.85899 9.99774 7.04189 9.75961 7.31733 9.75961H7.90109V5.55293H6.21354V6.22129C6.21354 6.46275 6.03064 6.70088 5.75521 6.70088C5.47977 6.70088 5.29688 6.46275 5.29688 6.22129V5.07334ZM5.79688 5.09375V5.11658C5.80618 5.10826 5.8161 5.10062 5.82658 5.09375H5.79688ZM8.28805 5.09375C8.31637 5.11233 8.34068 5.13649 8.35942 5.16469C8.37817 5.13649 8.40248 5.11233 8.4308 5.09375H8.28805ZM10.8921 5.09375C10.9026 5.10062 10.9125 5.10826 10.9218 5.11658V5.09375H10.8921ZM8.35942 10.1479C8.34068 10.176 8.31637 10.2002 8.28805 10.2188H8.4308C8.40248 10.2002 8.37817 10.176 8.35942 10.1479Z" fill="#343A40"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3125 7.89062C12.3125 7.61448 12.5364 7.39062 12.8125 7.39062H14.6875C14.9636 7.39062 15.1875 7.61448 15.1875 7.89062C15.1875 8.16677 14.9636 8.39062 14.6875 8.39062H12.8125C12.5364 8.39062 12.3125 8.16677 12.3125 7.89062Z" fill="#ADB5BD"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3125 10.2344C12.3125 9.95823 12.5364 9.73438 12.8125 9.73438H14.6875C14.9636 9.73438 15.1875 9.95823 15.1875 10.2344C15.1875 10.5105 14.9636 10.7344 14.6875 10.7344H12.8125C12.5364 10.7344 12.3125 10.5105 12.3125 10.2344Z" fill="#ADB5BD"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.28125 12.5781C5.28125 12.302 5.50511 12.0781 5.78125 12.0781H14.6875C14.9636 12.0781 15.1875 12.302 15.1875 12.5781C15.1875 12.8543 14.9636 13.0781 14.6875 13.0781H5.78125C5.50511 13.0781 5.28125 12.8543 5.28125 12.5781Z" fill="#ADB5BD"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.28125 14.9219C5.28125 14.6457 5.50511 14.4219 5.78125 14.4219H14.6875C14.9636 14.4219 15.1875 14.6457 15.1875 14.9219C15.1875 15.198 14.9636 15.4219 14.6875 15.4219H5.78125C5.50511 15.4219 5.28125 15.198 5.28125 14.9219Z" fill="#ADB5BD"/>
        </svg>`,
        title: this.lang === "ko" ? "텍스트 추출" : "Text-grab from image",
        click: () => {
          trackEvent({
            eventType: eventTypes.click.GRAB_TEXT,
            eventProperties: {
              mode: this.getEventMode(),
              origin: "image options",
            },
          });
          this.handleClickOcr();
          this.api.tooltip.hide();
        },
      });
    }

    if (this.isDemoMode || (this.data.clipKey && !this.readOnly && !this.isSlidPocket)) {
      panelItems.push({
        name: "markup",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_395_4696)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7253 2.30644C13.3167 1.89785 12.6543 1.89785 12.2457 2.30644L2.30644 12.2457C2.11023 12.4419 2 12.708 2 12.9855V16.9538C2 17.5316 2.46842 18 3.04624 18H7.01451C7.29198 18 7.5581 17.8898 7.75431 17.6936L17.6936 7.75431C18.1021 7.34573 18.1021 6.68329 17.6936 6.2747L13.7253 2.30644ZM11.1751 4.85664L12.9855 3.04624L16.9538 7.01451L15.1434 8.82491L11.1751 4.85664ZM10.4353 5.59644L3.04624 12.9855V16.9538H7.01451L14.4036 9.56471L10.4353 5.59644Z" fill="#343A40"/>
        <path d="M3.5 17.5H17.5" stroke="#343A40" stroke-linecap="round"/>
        </g>
        <defs>
        <clipPath id="clip0_395_4696">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        `,
        title: this.lang === "ko" ? "펜 필기" : "Draw on image",
        click: () => {
          trackEvent({
            eventType: eventTypes.click.IMAGE_MARKUP,
            eventProperties: {
              location: window.location.pathname.split("/")[1] === "vdocs" ? "Video note page" : "My notes",
            },
          });

          this.handleClickMarkup();
          this.api.tooltip.hide();
        },
      });
    }

    if (this.isDemoMode || (this.data.clipKey && !this.isSlidPocket)) {
      panelItems.push({
        name: "fullscreen",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.0625 9.0625C6.0625 8.78636 6.28636 8.5625 6.5625 8.5625H11.5625C11.8386 8.5625 12.0625 8.78636 12.0625 9.0625C12.0625 9.33864 11.8386 9.5625 11.5625 9.5625H6.5625C6.28636 9.5625 6.0625 9.33864 6.0625 9.0625Z" fill="#343A40"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.0625 6.0625C9.33864 6.0625 9.5625 6.28636 9.5625 6.5625V11.5625C9.5625 11.8386 9.33864 12.0625 9.0625 12.0625C8.78636 12.0625 8.5625 11.8386 8.5625 11.5625V6.5625C8.5625 6.28636 8.78636 6.0625 9.0625 6.0625Z" fill="#343A40"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.0625 3C5.71427 3 3 5.71427 3 9.0625C3 12.4107 5.71427 15.125 9.0625 15.125C12.4107 15.125 15.125 12.4107 15.125 9.0625C15.125 5.71427 12.4107 3 9.0625 3ZM2 9.0625C2 5.16199 5.16199 2 9.0625 2C12.963 2 16.125 5.16199 16.125 9.0625C16.125 12.963 12.963 16.125 9.0625 16.125C5.16199 16.125 2 12.963 2 9.0625Z" fill="#343A40"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.3496 13.3496C13.5448 13.1543 13.8614 13.1543 14.0567 13.3496L17.8536 17.1465C18.0488 17.3417 18.0488 17.6583 17.8536 17.8536C17.6583 18.0488 17.3417 18.0488 17.1465 17.8536L13.3496 14.0567C13.1543 13.8614 13.1543 13.5448 13.3496 13.3496Z" fill="#343A40"/>
        </svg>`,
        title: this.lang === "ko" ? "전체화면" : "Full Screen",
        shortcut: this.getEventMode() !== "view note" ? "(space)" : "",
        click: () => {
          trackEvent({
            eventType: "Click image full screen",
            eventProperties: {
              mode: this.getEventMode(),
              origin: "image options",
            },
          });
          this.handleClickFullScreen();
          this.api.tooltip.hide();
        },
      });
    }

    if (this.isDemoMode || (this.data.clipKey && !this.readOnly && !this.isSlidPocket)) {
      const { applicationType } = this.slidGlobal;
      if (applicationType !== "mobile") {
        // Mobile App : None
        panelItems.push({
          name: "crop",
          icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.5 5.5H1.5" stroke="#343A40" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.5 1.5V14.5H18.5" stroke="#343A40" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
              <path d="M14.5 12.5V5.5H7.5" stroke="#343A40" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
              <path d="M14.5 18.5V15" stroke="#343A40" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              `,
          title: this.lang === "ko" ? "이미지 자르기" : "Crop Image",
          shortcut: "",
          click: () => {
            this.api.tooltip.hide();
            trackEvent({
              eventType: "Click crop image",
              eventProperties: {
                mode: this.getEventMode(),
                origin: "image options",
              },
            });
            this.updateVideoNoteOnboardingStep();
            store.dispatch(setIsImageCroppingModalOpen(true));
            store.dispatch(
              setImageCroppingModalImageSource(this.data.markupImageSrc ? this.data.markupImageSrc : this.data.fullSizeImageSrcForCropping ? this.data.fullSizeImageSrcForCropping : this.data.src)
            );

            if (this.data.imageCropArea) {
              store.dispatch(setImageCropArea(this.data.imageCropArea));
            }

            store.dispatch(
              setCroppingImageBlockData({
                id: this.block.id,
                ...this.data,
              })
            );
          },
        });
      }
    }
    if (!this.readOnly) {
      panelItems.push({
        name: "delete",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.625 5C2.625 4.72386 2.84886 4.5 3.125 4.5H16.875C17.1511 4.5 17.375 4.72386 17.375 5C17.375 5.27614 17.1511 5.5 16.875 5.5H3.125C2.84886 5.5 2.625 5.27614 2.625 5Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.125 8.25C8.40114 8.25 8.625 8.47386 8.625 8.75V13.75C8.625 14.0261 8.40114 14.25 8.125 14.25C7.84886 14.25 7.625 14.0261 7.625 13.75V8.75C7.625 8.47386 7.84886 8.25 8.125 8.25Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.875 8.25C12.1511 8.25 12.375 8.47386 12.375 8.75V13.75C12.375 14.0261 12.1511 14.25 11.875 14.25C11.5989 14.25 11.375 14.0261 11.375 13.75V8.75C11.375 8.47386 11.5989 8.25 11.875 8.25Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.375 4.5C4.65114 4.5 4.875 4.72386 4.875 5V16.875C4.875 16.9082 4.88817 16.9399 4.91161 16.9634C4.93505 16.9868 4.96685 17 5 17H15C15.0332 17 15.0649 16.9868 15.0884 16.9634C15.1118 16.9399 15.125 16.9082 15.125 16.875V5C15.125 4.72386 15.3489 4.5 15.625 4.5C15.9011 4.5 16.125 4.72386 16.125 5V16.875C16.125 17.1734 16.0065 17.4595 15.7955 17.6705C15.5845 17.8815 15.2984 18 15 18H5C4.70163 18 4.41548 17.8815 4.2045 17.6705C3.99353 17.4595 3.875 17.1734 3.875 16.875V5C3.875 4.72386 4.09886 4.5 4.375 4.5Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.88756 2.51256C7.21575 2.18437 7.66087 2 8.125 2H11.875C12.3391 2 12.7842 2.18437 13.1124 2.51256C13.4406 2.84075 13.625 3.28587 13.625 3.75V5C13.625 5.27614 13.4011 5.5 13.125 5.5C12.8489 5.5 12.625 5.27614 12.625 5V3.75C12.625 3.55109 12.546 3.36032 12.4053 3.21967C12.2647 3.07902 12.0739 3 11.875 3H8.125C7.92609 3 7.73532 3.07902 7.59467 3.21967C7.45402 3.36032 7.375 3.55109 7.375 3.75V5C7.375 5.27614 7.15114 5.5 6.875 5.5C6.59886 5.5 6.375 5.27614 6.375 5V3.75C6.375 3.28587 6.55937 2.84075 6.88756 2.51256Z" fill="#868E96"/>
        </svg>`,
        title: this.lang === "ko" ? "삭제" : "Delete",
        shortcut: "(backspace)",
        click: () => {
          trackEvent({
            eventType: "Delete image block",
            eventProperties: {
              mode: this.getEventMode(),
              origin: "image options",
            },
          });
          this.handleClickDelete();
          this.api.tooltip.hide();
        },
      });
    }

    panelItems.forEach((panelItem, idx, array) => {
      const button = document.createElement("button");
      button.classList.add("image-panel__button");
      button.id = "#" + panelItem.name;

      const iconContainer = document.createElement("div");

      iconContainer.classList.add("image-panel__icon-container");
      if (onMobile) iconContainer.classList.add("mobile");

      iconContainer.innerHTML = panelItem.icon;

      button.appendChild(iconContainer);

      button.addEventListener("click", panelItem.click.bind(this));

      const tooltipNode = document.createElement("div");
      const tooltipItemTitle = document.createElement("span");
      const tooltipItemShortcut = document.createElement("span");

      tooltipItemTitle.classList.add("image-panel__tooltip-title");
      tooltipItemShortcut.classList.add("image-panel__tooltip-shortcut");

      tooltipItemTitle.textContent = panelItem.title;
      tooltipItemShortcut.textContent = panelItem.shortcut;

      tooltipNode.appendChild(tooltipItemTitle);
      tooltipNode.appendChild(tooltipItemShortcut);

      if (!isTouchDevice()) {
        this.api.tooltip.onHover(button, tooltipNode, {
          placement: "bottom",
        });
      }
      if (panelItem.name === "crop") {
        button.classList.add("crop");
      }
      //if is last element it is appended outside
      if (panelItem.name !== "delete") {
        panelButtons.appendChild(button);
      } else {
        button.classList.add("last");
        buttonsWrapper.appendChild(button);
      }
    });

    return buttonsWrapper;
  }

  getEventMode() {
    const location = window.location.pathname;
    const isEditMode = !this.vdocs.isReadOnly;
    if (location.includes("/vdocs")) return "video note";
    if (location.includes("/docs") && isEditMode) return "edit note";
    if (location.includes("/docs") && !isEditMode) return "view note";
  }

  showImageUploadFinished() {
    const checkMark = document.createElement("div");
    checkMark.innerHTML = `
        <div id="checkmarkWrapper">
          <div class="checkmark__statement">${t("SaveImageCompleteStatement", { ns: "common" })}</div>
          <div>
          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
          </div>
        </div>`;
    this.wrapper.appendChild(checkMark);
    setTimeout(() => {
      this.wrapper.removeChild(checkMark);
    }, 2000);
  }

  createImageUploadLoaderWrapperElement() {
    const imgUploadLoaderWrapper = document.createElement("div");
    imgUploadLoaderWrapper.className = "img-upload-loader-wrapper";

    const imgUploadLoader = document.createElement("div");
    imgUploadLoader.className = "spinner-border spinner-border-sm";

    const savingStatement = document.createElement("div");
    savingStatement.classList = "upload-loading-statement";
    savingStatement.innerText = t("SavingImageStatement", { ns: "common" });
    this.savingStatement = savingStatement;

    imgUploadLoaderWrapper.appendChild(imgUploadLoader);
    imgUploadLoaderWrapper.appendChild(savingStatement);

    return imgUploadLoaderWrapper;
  }

  createSlidMobileImageLoaderWrapperElement() {
    const slidMobileImageLoaderWrapper = document.createElement("div");
    slidMobileImageLoaderWrapper.className = "cdx-loader slid-mobile-image-loader-wrapper";

    return slidMobileImageLoaderWrapper;
  }

  save() {
    return {
      clipKey: this.data.clipKey,
      documentKey: this.data.documentKey,
      timestamp: this.data.timestamp,
      src: this.data.src,
      videoInfo: this.data.videoInfo,
      type: this.data.type,
      markupImageSrc: this.data.markupImageSrc,
      originalImageSrc: this.data.originalImageSrc ? this.data.originalImageSrc : this.data.fullSizeImageSrcForCropping ? this.data.fullSizeImageSrcForCropping : this.data.src,
      markupCanvasData: this.data.markupCanvasData,
      isUploaded: this.isUploaded,
      fullSizeImageSrcForCropping: this.data.fullSizeImageSrcForCropping,
      imageCropArea: this.data.imageCropArea,
    };
  }

  moved(event) {
    if (!document.getElementById("editor-container")) return;
    const currentScrollTop = document.getElementById("editor-container").scrollTop;
    const changedHeight = this.api.blocks.getBlockByIndex(this.api.blocks.getCurrentBlockIndex()).holder.offsetHeight;
    if (event.detail.fromIndex < event.detail.toIndex) {
      document.getElementById("editor-container").scrollTop = currentScrollTop + changedHeight;
    } else {
      document.getElementById("editor-container").scrollTop = currentScrollTop - changedHeight;
    }
  }

  renderSettings() {
    const wrapper = [];

    let settings = [];

    if (this.isDemoMode || this.data.clipKey) {
      settings.push({
        name: "text-grab",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.375 3C4.01033 3 3.66059 3.14487 3.40273 3.40273C3.14487 3.66059 3 4.01033 3 4.375V6.25C3 6.52614 2.77614 6.75 2.5 6.75C2.22386 6.75 2 6.52614 2 6.25V4.375C2 3.74511 2.25022 3.14102 2.69562 2.69562C3.14102 2.25022 3.74511 2 4.375 2H6.25C6.52614 2 6.75 2.22386 6.75 2.5C6.75 2.77614 6.52614 3 6.25 3H4.375Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.5 13.25C2.77614 13.25 3 13.4739 3 13.75V15.625C3 15.9897 3.14487 16.3394 3.40273 16.5973C3.66059 16.8551 4.01033 17 4.375 17H6.25C6.52614 17 6.75 17.2239 6.75 17.5C6.75 17.7761 6.52614 18 6.25 18H4.375C3.74511 18 3.14102 17.7498 2.69562 17.3044C2.25022 16.859 2 16.2549 2 15.625V13.75C2 13.4739 2.22386 13.25 2.5 13.25Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.25 2.5C13.25 2.22386 13.4739 2 13.75 2H15.625C16.2549 2 16.859 2.25022 17.3044 2.69562C17.7498 3.14102 18 3.74511 18 4.375V6.25C18 6.52614 17.7761 6.75 17.5 6.75C17.2239 6.75 17 6.52614 17 6.25V4.375C17 4.01033 16.8551 3.66059 16.5973 3.40273C16.3394 3.14487 15.9897 3 15.625 3H13.75C13.4739 3 13.25 2.77614 13.25 2.5Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.5 13.25C17.7761 13.25 18 13.4739 18 13.75V15.625C18 16.2549 17.7498 16.859 17.3044 17.3044C16.859 17.7498 16.2549 18 15.625 18H13.75C13.4739 18 13.25 17.7761 13.25 17.5C13.25 17.2239 13.4739 17 13.75 17H15.625C15.9897 17 16.3394 16.8551 16.5973 16.5973C16.8551 16.3394 17 15.9897 17 15.625V13.75C17 13.4739 17.2239 13.25 17.5 13.25Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.54688 5.07334C5.54688 4.94654 5.64015 4.84375 5.75521 4.84375H8.35942H10.9635C11.0786 4.84375 11.1718 4.94654 11.1718 5.07334V6.22129C11.1718 6.34809 11.0786 6.45088 10.9635 6.45088C10.8484 6.45088 10.7552 6.34809 10.7552 6.22129V5.30293H8.56776V10.0096H9.40064C9.5157 10.0096 9.60897 10.1124 9.60897 10.2392C9.60897 10.366 9.5157 10.4688 9.40064 10.4688H7.31733C7.20227 10.4688 7.10899 10.366 7.10899 10.2392C7.10899 10.1124 7.20227 10.0096 7.31733 10.0096H8.15109V5.30293H5.96354V6.22129C5.96354 6.34809 5.87026 6.45088 5.75521 6.45088C5.64015 6.45088 5.54688 6.34809 5.54688 6.22129V5.07334Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.29688 5.07334C5.29688 4.83188 5.47977 4.59375 5.75521 4.59375H10.9635C11.2389 4.59375 11.4218 4.83188 11.4218 5.07334V6.22129C11.4218 6.46275 11.2389 6.70088 10.9635 6.70088C10.6881 6.70088 10.5052 6.46275 10.5052 6.22129V5.55293H8.81776V9.75961H9.40064C9.67607 9.75961 9.85897 9.99774 9.85897 10.2392C9.85897 10.4807 9.67607 10.7188 9.40064 10.7188H7.31733C7.04189 10.7188 6.85899 10.4807 6.85899 10.2392C6.85899 9.99774 7.04189 9.75961 7.31733 9.75961H7.90109V5.55293H6.21354V6.22129C6.21354 6.46275 6.03064 6.70088 5.75521 6.70088C5.47977 6.70088 5.29688 6.46275 5.29688 6.22129V5.07334ZM5.79688 5.09375V5.11658C5.80618 5.10826 5.8161 5.10062 5.82658 5.09375H5.79688ZM8.28805 5.09375C8.31637 5.11233 8.34068 5.13649 8.35942 5.16469C8.37817 5.13649 8.40248 5.11233 8.4308 5.09375H8.28805ZM10.8921 5.09375C10.9026 5.10062 10.9125 5.10826 10.9218 5.11658V5.09375H10.8921ZM8.35942 10.1479C8.34068 10.176 8.31637 10.2002 8.28805 10.2188H8.4308C8.40248 10.2002 8.37817 10.176 8.35942 10.1479Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3125 7.89062C12.3125 7.61448 12.5364 7.39062 12.8125 7.39062H14.6875C14.9636 7.39062 15.1875 7.61448 15.1875 7.89062C15.1875 8.16677 14.9636 8.39062 14.6875 8.39062H12.8125C12.5364 8.39062 12.3125 8.16677 12.3125 7.89062Z" fill="#ADB5BD"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.3125 10.2344C12.3125 9.95823 12.5364 9.73438 12.8125 9.73438H14.6875C14.9636 9.73438 15.1875 9.95823 15.1875 10.2344C15.1875 10.5105 14.9636 10.7344 14.6875 10.7344H12.8125C12.5364 10.7344 12.3125 10.5105 12.3125 10.2344Z" fill="#ADB5BD"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.28125 12.5781C5.28125 12.302 5.50511 12.0781 5.78125 12.0781H14.6875C14.9636 12.0781 15.1875 12.302 15.1875 12.5781C15.1875 12.8543 14.9636 13.0781 14.6875 13.0781H5.78125C5.50511 13.0781 5.28125 12.8543 5.28125 12.5781Z" fill="#ADB5BD"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.28125 14.9219C5.28125 14.6457 5.50511 14.4219 5.78125 14.4219H14.6875C14.9636 14.4219 15.1875 14.6457 15.1875 14.9219C15.1875 15.198 14.9636 15.4219 14.6875 15.4219H5.78125C5.50511 15.4219 5.28125 15.198 5.28125 14.9219Z" fill="#ADB5BD"/>
        </svg>`,
        title: this.lang === "ko" ? "텍스트 추출" : "Grab text from image",
        click: () => {
          trackEvent({
            eventType: eventTypes.click.GRAB_TEXT,
            eventProperties: {
              mode: this.getEventMode(),
              origin: "editor toggle",
            },
          });
          this.handleClickOcr();
        },
      });
    }

    if (this.isDemoMode || this.data.clipKey) {
      settings.push({
        name: "markup",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.655 2.49992C13.0455 2.1094 13.6787 2.1094 14.0692 2.49992L17.5 5.93075C17.8905 6.32127 17.8905 6.95444 17.5 7.34496L7.13793 17.707C6.95039 17.8946 6.69604 17.9999 6.43082 17.9999H3C2.44771 17.9999 2 17.5522 2 16.9999V13.5691C2 13.3039 2.10536 13.0495 2.29289 12.862L12.655 2.49992ZM13.3621 3.20703L3 13.5691V16.9999H6.43082L16.7929 6.63786L13.3621 3.20703Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.6408 4.68799C10.8358 4.49244 11.1523 4.49196 11.3479 4.68693L15.1233 8.451C15.3189 8.64597 15.3193 8.96255 15.1244 9.15811C14.9294 9.35366 14.6128 9.35414 14.4173 9.15917L10.6419 5.3951C10.4463 5.20013 10.4458 4.88355 10.6408 4.68799Z" fill="#868E96"/>
        </svg>
        `,
        title: this.lang === "ko" ? "펜 필기" : "Draw on image",
        click: () => {
          trackEvent({
            eventType: "Click image markup",
            eventProperties: {
              mode: this.getEventMode(),
              origin: "editor toggle",
            },
          });
          this.handleClickMarkup();
        },
      });
    }

    if (this.isDemoMode || (this.data.timestamp !== undefined && this.data.videoInfo)) {
      settings.push({
        name: "play",
        icon: `<svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM7.02735 5.08398C6.87392 4.98169 6.67665 4.97215 6.51407 5.05916C6.35149 5.14617 6.25 5.3156 6.25 5.5V10.5C6.25 10.6844 6.35149 10.8538 6.51407 10.9408C6.67665 11.0278 6.87392 11.0183 7.02735 10.916L10.7773 8.41603C10.9164 8.32329 11 8.16718 11 8C11 7.83282 10.9164 7.67671 10.7773 7.58398L7.02735 5.08398ZM9.59861 8L7.25 9.56574V6.43426L9.59861 8Z" /></svg>`,
        title: this.lang === "ko" ? "여기부터 재생" : "Play here",
        click: () => {
          trackEvent({
            eventType: "Click IMAGE TIMESTAMP",
            eventProperties: {
              mode: this.getEventMode(),
              origin: "editor toggle",
            },
          });
          this.handleClickPlayHere();
        },
      });
    }

    if (this.isDemoMode || this.data.clipKey) {
      settings.push({
        name: "fullscreen",
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.0625 9.0625C6.0625 8.78636 6.28636 8.5625 6.5625 8.5625H11.5625C11.8386 8.5625 12.0625 8.78636 12.0625 9.0625C12.0625 9.33864 11.8386 9.5625 11.5625 9.5625H6.5625C6.28636 9.5625 6.0625 9.33864 6.0625 9.0625Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.0625 6.0625C9.33864 6.0625 9.5625 6.28636 9.5625 6.5625V11.5625C9.5625 11.8386 9.33864 12.0625 9.0625 12.0625C8.78636 12.0625 8.5625 11.8386 8.5625 11.5625V6.5625C8.5625 6.28636 8.78636 6.0625 9.0625 6.0625Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.0625 3C5.71427 3 3 5.71427 3 9.0625C3 12.4107 5.71427 15.125 9.0625 15.125C12.4107 15.125 15.125 12.4107 15.125 9.0625C15.125 5.71427 12.4107 3 9.0625 3ZM2 9.0625C2 5.16199 5.16199 2 9.0625 2C12.963 2 16.125 5.16199 16.125 9.0625C16.125 12.963 12.963 16.125 9.0625 16.125C5.16199 16.125 2 12.963 2 9.0625Z" fill="#868E96"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.3496 13.3496C13.5448 13.1543 13.8614 13.1543 14.0567 13.3496L17.8536 17.1465C18.0488 17.3417 18.0488 17.6583 17.8536 17.8536C17.6583 18.0488 17.3417 18.0488 17.1465 17.8536L13.3496 14.0567C13.1543 13.8614 13.1543 13.5448 13.3496 13.3496Z" fill="#868E96"/>
        </svg>
        `,
        title: this.lang === "ko" ? "전체화면" : "Full Screen",
        click: () => {
          trackEvent({
            eventType: "Click image full screen",
            eventProperties: {
              mode: this.getEventMode(),
              origin: "editor toggle",
            },
          });
          this.handleClickFullScreen();
        },
        shortCutText: "space",
      });
    }

    settings.forEach((tune) => {
      let button = document.createElement("div");
      const iconContainer = document.createElement("div");
      const buttonTitle = document.createElement("span");
      const keyboardShortcut = document.createElement("span");
      iconContainer.classList.add("ce-settings__button-icon-container");
      buttonTitle.classList.add("ce-settings__button-text");
      keyboardShortcut.classList.add("ce-settings__button-shortcut-text");

      button.classList.add("ce-settings__button");
      iconContainer.innerHTML = tune.icon;
      buttonTitle.innerHTML = tune.title;
      keyboardShortcut.innerHTML = tune.shortCutText;

      button.appendChild(iconContainer);
      button.appendChild(buttonTitle);
      if (tune.shortCutText) button.appendChild(keyboardShortcut);
      button.addEventListener("click", tune.click.bind(this));

      wrapper.push(button);
    });

    return wrapper;
  }

  async onClickOcr({ clipKey, blockIndex }) {
    alertAnalyzingTextsFromImage({ target: this.isMobile ? "body" : "#editor-wrapper" });
    const editorContent = await this.api.saver.save();
    const currentImageBlock = editorContent.blocks[blockIndex];
    const registerClipOCRResponse = await store.dispatch(
      registerClipOCR({
        clipKey,
        documentKey: currentImageBlock.data.documentKey,
        videoKey: currentImageBlock.data.videoInfo ? currentImageBlock.data.videoInfo.videoKey : undefined,
        imgSrc: currentImageBlock.data.src,
      })
    );

    if (registerClipOCRResponse["error_message"]) {
      Sweetalert.close();

      if (registerClipOCRResponse["error_message"] === "INSUFFICIENT_PRIVILEGES") {
        return; //handled in app.js
      }

      Sentry.withScope((scope) => {
        scope.setLevel("error");
        scope.setExtra("message", "no clip key", "API error is: ", registerClipOCRResponse["error_message"]);
        Sentry.captureMessage("SLID_WEB_IMAGE_OCR_ERROR");
      });

      alertAnalyzingTextsFailed({ target: this.isMobile ? "body" : "#editor-wrapper" });
      return;
    }

    const registerClipOCRKey = registerClipOCRResponse["clip_key"];

    let isOcrAdded = false;

    this.ocrResponseIntervalId = setInterval(async () => {
      const clipOCRGetResult = await store.dispatch(
        await getClipOCRResults({
          clipKey: registerClipOCRKey,
        })
      );

      if (clipOCRGetResult["error_message"]) {
        clearInterval(this.ocrResponseIntervalId);
        Sweetalert.close();

        if (clipOCRGetResult["error_message"] === "INSUFFICIENT_PRIVILEGES") {
          return; //handled in app.js
        }

        Sentry.withScope((scope) => {
          scope.setLevel("error");
          scope.setExtra("message", "no clip key", "API error is: ", clipOCRGetResult["error_message"]);
          Sentry.captureMessage("SLID_WEB_IMAGE_OCR_ERROR");
        });

        alertAnalyzingTextsFailed({ target: this.isMobile ? "body" : "#editor-wrapper" });
        return;
      }

      switch (clipOCRGetResult.status) {
        case "doing":
          // keep waiting
          return;
        case "failed":
          clearInterval(this.ocrResponseIntervalId);
          Sweetalert.close();

          Sentry.withScope((scope) => {
            scope.setLevel("error");
            scope.setExtra("message", "status failed");
            Sentry.captureMessage("SLID_WEB_IMAGE_OCR_ERROR");
          });

          alertAnalyzingTextsFailed({ target: this.isMobile ? "body" : "#editor-wrapper" });
          return;
        case "done":
          clearInterval(this.ocrResponseIntervalId);
          const ocrTextResult = JSON.parse(clipOCRGetResult.results)["full_text_annotation"]["description"];

          if (isOcrAdded) return;
          addCodeBlock({
            editorAPI: this.api,
            text: ocrTextResult,
          });
          isOcrAdded = true;

          Sweetalert.close();

          sendAmplitudeData(`SLID_2_ACTIVATION_CHECK`, {
            feature: `imageOcr`,
          });
          return;
        default:
          return;
      }
    }, 300);
  }

  /**
   * Specify paste substitutes
   *
   * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
   * @returns {{tags: string[], patterns: object<string, RegExp>, files: {extensions: string[], mimeTypes: string[]}}}
   */
  static get pasteConfig() {
    return {
      /**
       * Paste HTML into Editor
       */
      // tags: ["img"],

      /**
       * Paste URL of image into the Editor
       */
      patterns: {
        image: /https?:\/\/\S+\.(gif|jpe?g|tiff|png)$/i,
      },

      /**
       * Drag n drop file from into the Editor
       */
      files: {
        mimeTypes: ["image/*"],
      },
    };
  }

  /**
   * Specify paste handlers
   *
   * @public
   * @see {@link https://github.com/codex-team/editor.js/blob/master/docs/tools.md#paste-handling}
   * @param {CustomEvent} event - editor.js custom paste event
   *                              {@link https://github.com/codex-team/editor.js/blob/master/types/tools/paste-events.d.ts}
   * @returns {void}
   */
  onPaste(event) {
    switch (event.type) {
      case "tag": {
        // if block is pasted
        if (event.detail.data.hasAttribute("blocktype")) {
          switch (event.detail.data.getAttribute("blocktype")) {
            case "image":
              this.handleHTMLPaste({
                currentSrc: event.detail.data.currentSrc,
              });
              return;
            default:
              this.api.blocks.delete();
              return;
          }

          // just img tag is pasted
        } else {
          this.api.blocks.delete();
          return;
        }
      }
      case "pattern": {
        const key = event.detail.key;
        const imgUrl = event.detail.data;

        this.handlePatternPaste(key, imgUrl);
        this.api.blocks.delete();
        break;
      }
      case "file": {
        const file = event.detail.file;
        this.handleFilePaste(file);

        this.api.blocks.delete();
        break;
      }
      default:
        return;
    }
  }

  static convertToPlainHTML(data) {
    const { src, videoInfo, timestamp, markupImageSrc } = data;
    let htmlString = `<img src="${markupImageSrc || src}" alt="${markupImageSrc || src}">`;
    if (!videoInfo) return htmlString;
    if (videoInfo.videoType === "youtube") {
      return htmlString + `<p><em><a href="https://www.youtube.com/watch?v=${videoInfo.videoUnique}&t=${parseInt(timestamp)}s">(${formatImageTime(timestamp ?? 0)})</a></em></p>`;
    }
    return htmlString + `<p><em><a href="${videoInfo.videoOriginUrl}">(${formatImageTime(timestamp ?? 0)})</a></em></p>`;
  }

  // Unused Code.
  async handleHTMLPaste({ currentSrc }) {
    const currentContent = await this.api.saver.save();
    let prevImageBlockData;
    let prevImageBlockIndex;
    currentContent.blocks.forEach((block, index) => {
      if (block.type === "image" && (block.data.src === currentSrc || block.data.markupImageSrc === currentSrc)) {
        prevImageBlockData = block.data;
        prevImageBlockIndex = index;
      }
    });
    currentContent.blocks[this.api.blocks.getCurrentBlockIndex()].data = prevImageBlockData;
    currentContent.blocks.splice(prevImageBlockIndex, 1);
    this.api.blocks.render(currentContent);
  }

  async handlePatternPaste(key, imgUrl) {
    if (!this.config.confirmPrivilege(SlidFeatures.imageUpload)) return this.config.showInsufficientPrivilegeModal();
    const { currentDocument } = this.vdocs;
    const blockIndex = this.api.blocks.getCurrentBlockIndex();

    let currentDocumentKey;

    if (currentDocument) {
      currentDocumentKey = currentDocument["document_key"];
    } else {
      const createdDocument = await store.dispatch(
        createDocument({
          origin: this.mode,
        })
      );
      if (createdDocument.error_message) return;
      currentDocumentKey = createdDocument["document_key"];
    }

    const clipRegisterResponse = await store.dispatch(
      registerClip({
        imgSrc: imgUrl,
        documentKey: currentDocumentKey,
      })
    );

    if (clipRegisterResponse.error_message) return;

    this.api.blocks.insert(
      "image",
      {
        clipKey: clipRegisterResponse["clip_key"],
        documentKey: currentDocumentKey,
        src: imgUrl,
        type: "urlPaste",
      },
      undefined,
      blockIndex,
      false
    );

    this.api.caret.setToNextBlock();
    this.api.blocks.insert(
      "paragraph",
      {
        text: "",
      },
      undefined,
      blockIndex + 1,
      true
    );
    this.api.caret.setToBlock(blockIndex + 1);
    this.api.blocks.getBlockByIndex(blockIndex).holder.scrollIntoView();

    sendAmplitudeData(`Insert image`, {
      origin: this.mode,
      type: "url paste",
    });
  }

  async handleFilePaste(file) {
    if (!this.config.confirmPrivilege(SlidFeatures.imageUpload)) return this.config.showInsufficientPrivilegeModal();
    const { currentDocument } = this.vdocs;
    const blockIndex = this.api.blocks.getCurrentBlockIndex();
    // To show raw image first
    const rawSrc = await toBase64(file);
    const getPasteImageBlockData = async (currentDocumentKey, file) => {
      const uploadedImageSrc = await uploadFileImage({
        path: `image_upload/${currentDocumentKey}`,
        file: file,
      });

      if (!uploadedImageSrc) {
        Sentry.withScope((scope) => {
          scope.setLevel("error");
          scope.setExtra("message", "s3 upload failed");
          Sentry.captureMessage("SLID_WEB_ADD_IMAGE_BY_PASTE_ERROR");
        });
        return;
      }

      const clipRegisterResponse = await store.dispatch(
        registerClip({
          imgSrc: uploadedImageSrc,
          documentKey: currentDocumentKey,
        })
      );

      if (clipRegisterResponse.error_message) return;

      return {
        clipKey: clipRegisterResponse["clip_key"],
        src: uploadedImageSrc,
        documentKey: currentDocumentKey,
        type: "filePaste",
      };
    };

    let currentDocumentKey;

    // check current document
    if (currentDocument) {
      currentDocumentKey = currentDocument["document_key"];
    } else {
      const createdDocument = await store.dispatch(
        createDocument({
          origin: this.mode,
        })
      );
      if (createdDocument.error_message) return;
      currentDocumentKey = createdDocument["document_key"];
    }

    this.api.blocks.insert(
      "image",
      {
        documentKey: currentDocumentKey,
        rawSrc: rawSrc,
        type: "filePaste",
        setClipData: async () => {
          return await getPasteImageBlockData(currentDocumentKey, file);
        },
      },
      undefined,
      blockIndex,
      false
    );
    this.api.caret.setToNextBlock();
    this.api.blocks.insert(
      "paragraph",
      {
        text: "",
      },
      undefined,
      blockIndex + 1,
      true
    );
    this.api.caret.setToBlock(blockIndex + 1);
    this.api.blocks.getBlockByIndex(blockIndex).holder.scrollIntoView();

    sendAmplitudeData(`Insert image`, {
      origin: this.mode,
      type: "image paste",
    });
  }

  tuneToggled(tuneName) {}

  onClickPlayVideoFromTs({ clipTs, clipVideoKey, videoInfo }) {
    const { applicationType } = this.slidGlobal;
    const { currentDocument, videoIndex, videoPlayerRef } = store.getState().vdocs;
    let currentVideo;

    switch (applicationType) {
      case "extension":
        if (this.isDemoMode) {
          sendVideoSeekToTimestampRequestToParentWindow({
            clipTs: clipTs,
          });
          return;
        }
        currentVideo = store.getState().vdocs.currentVideo;
        if (!currentVideo) return;
        const VDOCS_SUPPORTED_VIDEO_TYPES = ["youtube", "vimeo"];
        // if the video is played from same website, seek to timestamp
        const imageVideoType = videoInfo?.videoType;

        if (VDOCS_SUPPORTED_VIDEO_TYPES.includes(imageVideoType) && clipVideoKey) {
          if (currentVideo["videoType"] === videoInfo.videoType && currentVideo["videoUniqueKey"] === videoInfo.videoUnique) {
            sendVideoSeekToTimestampRequestToParentWindow({
              clipTs: clipTs,
            });
          } else {
            window.open(`${SLID_WEB_APP_URL}/vdocs/${currentDocument["document_key"]}?v=${clipVideoKey}&start=${clipTs}`);
          }
        } else {
          sendVideoSeekToTimestampRequestToParentWindow({
            clipTs: clipTs,
          });
        }
        break;
      case "desktop":
        if (!currentDocument["mapped_videos"]) return;
        currentVideo = currentDocument["mapped_videos"][videoIndex];
        if (!currentVideo) return;
        window.open(`${SLID_WEB_APP_URL}/vdocs/${currentDocument["document_key"]}?v=${clipVideoKey}&start=${clipTs}`);
        break;
      case "web":
        if (this.isDemoMode) {
          const demoVideoElement = document.querySelector(".slid-video-player video");
          demoVideoElement.currentTime = clipTs;
          demoVideoElement.play();
          return;
        }

        if (!currentDocument["mapped_videos"]) return;
        currentVideo = currentDocument["mapped_videos"][videoIndex];
        if (!currentVideo) return;

        // if in vdocs
        if (window.location.pathname.split("/")[1] === "vdocs" || window.location.pathname.includes("/share/vdocs")) {
          if (!videoPlayerRef) return;

          if (clipVideoKey === currentVideo["video_key"]) {
            if (!videoPlayerRef.playing) {
              videoPlayerRef.forcePlay();
            }
            videoPlayerRef.seekTo(Math.floor(clipTs));
          } else {
            const videoIndex = currentDocument["mapped_videos"].findIndex((mappedVideo) => {
              return mappedVideo["video_key"] === clipVideoKey;
            });

            // video not found from current document
            // just seekTo current video
            if (videoIndex === -1) {
              videoPlayerRef.seekTo(Math.floor(clipTs));
            } else {
              store.dispatch(setVideoIndex(videoIndex));
              setTimeout(() => {
                if (!videoPlayerRef) return;
                videoPlayerRef.seekTo(Math.floor(clipTs));
              }, 1000);
            }
          }
          // if in docs
        } else {
          if (window.location.pathname.includes("/share/docs")) {
            this.config.history.push(`/share/vdocs/${currentDocument["document_key"]}?v=${clipVideoKey}&start=${parseInt(clipTs)}`);
          } else {
            window.open(`/vdocs/${currentDocument["document_key"]}?v=${clipVideoKey}&start=${parseInt(clipTs)}`, "_self");
          }
        }
        break;
      default:
        return;
    }
  }
}
