import { useEffect, useState } from "react";
import {
  isUserAuthenticated,
  getUserIdToken,
} from "./cognitoAuthToolkit";

export const useIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = await isUserAuthenticated();
      setIsAuthenticated(authenticated);
    };
    checkAuth();
  }, []);

  return { isAuthenticated };
}

export const useCognitoIdToken = () => {
  const [cognitoIdToken, setCognitoIdToken] = useState(null);

  useEffect(() => {
    const getIdToken = async () => {
      const token = await getUserIdToken();
      setCognitoIdToken(token);
    };
    getIdToken();
  }, []);

  return { cognitoIdToken };
}
