// auth 2.0
import slidAxios from "./base";
import { isAuthError, handleAuthErrors } from "./errorUtils";
const documents_endpoint = `documents/`;

export const ServerInterface_getDocuments = async ({ documentKey }) => {
  let documentsData = null;
  try {
    const documentsGetResponse = await slidAxios.get(`${documents_endpoint}${documentKey ? documentKey + "/" : ""}`);
    documentsData = documentsGetResponse.data;
  } catch (error) {
    if (!documentsData) documentsData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        case 405:
          documentsData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 403:
          documentsData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        default:
          documentsData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      // NETWORK_ERROR
      documentsData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      // request sent but no response
      documentsData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentsData;
  }
};

/*
* This function gets the details of 1 specific document.
* It calls [GET] /api/<version>/documents/<slug:document_key>/ endpoint
* returns document data with the following fields:
    "document_key"
    "parent_key"
    "is_folder"
    "title"
    "content"
    "pure_text"
    "created_time"
    "updated_time"
    "is_public"
    "more_info"
    "mapped_videos" (for document)
    "thumbnail_url" (for folder)
    "thumbnail_test" (for folder)
*/
export const ServerInterface_getDocument = async ({ documentKey }) => {
  let documentData = null;
  try {
    const documentGetResponse = await slidAxios.get(`${documents_endpoint}${documentKey}/`);
    documentData = documentGetResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          documentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          documentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          if (error.response.data.message === "DOCUMENT_OR_FOLDER_NOT_FOUND") documentData.error_message = "DOCUMENT_OR_FOLDER_NOT_FOUND";
          else if (error.response.data.message === "Document or Folder is deleted.") documentData.error_message = "DOCUMENT_OR_FOLDER_IS_DELETED";
          else if (error.response.data.message === "UNAUTHORIZED_USER") documentData.error_message = "ANAUTHORIZED_USER";
          else documentData.error_message = "ERROR_GETTING_DOCUMENT";
          break;
        default:
          documentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      documentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      documentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentData;
  }
};

/*
  This function deletes one document. (changes id_deleted to 1)
  It calls [PATCH] /api/<version>/documents/<slug:document_key>/ endpoint
  returns the deleted document data 
*/
export const ServerInterface_deleteDocument = async ({ documentKey }) => {
  const payload = { document_key: documentKey, is_deleted: 1 };
  let documentDeleteData = null;
  try {
    const documentDeleteResponse = await slidAxios.patch(`${documents_endpoint}${documentKey}/`, payload);
    documentDeleteData = documentDeleteResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentDeleteData) documentDeleteData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          documentDeleteData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          documentDeleteData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          switch (error.response.data.message) {
            case "DOCUMENT_OR_FOLDER_NOT_FOUND":
              documentDeleteData.error_message = "DOCUMENT_OR_FOLDER_NOT_FOUND";
              break;
            case "UNAUTHORIZED_USER":
              documentDeleteData.error_message = "ANAUTHORIZED_USER";
              break;
            default:
              documentDeleteData.error_message = "ERROR_DELETING_DOCUMENT";
          }
          break;
        default:
          documentDeleteData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      documentDeleteData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      documentDeleteData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentDeleteData;
  }
};

/*
  This function restores one document. (changes id_deleted to 0)
  It calls [PATCH] /api/<version>/documents/<slug:document_key>/ endpoint
  returns the restored document data
*/
export const ServerInterface_restoreDocument = async ({ documentKey }) => {
  const payload = { document_key: documentKey, is_deleted: 0 };
  let documentData = null;
  try {
    const documentResponse = await slidAxios.patch(`${documents_endpoint}${documentKey}/`, payload);
    documentData = documentResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          documentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          documentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          switch (error.response.data.message) {
            case "DOCUMENT_OR_FOLDER_NOT_FOUND":
              documentData.error_message = "DOCUMENT_OR_FOLDER_NOT_FOUND";
              break;
            case "UNAUTHORIZED_USER":
              documentData.error_message = "ANAUTHORIZED_USER";
              break;
            default:
              documentData.error_message = "ERROR RESTORING DOCUMENT";
          }
          break;
        default:
          documentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      documentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      documentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentData;
  }
};

/*
  This function deletes one document permanently. (changes id_deleted to 2)
  It calls [PATCH] /api/<version>/documents/<slug:document_key>/ endpoint
  returns the permanently deleted document data
*/
export const ServerInterface_deleteDocumentPermanently = async ({ documentKey }) => {
  const payload = { document_key: documentKey, is_deleted: 2 };
  let documentData = null;
  try {
    const documentResponse = await slidAxios.patch(`${documents_endpoint}${documentKey}/`, payload);
    documentData = documentResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          documentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          documentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          switch (error.response.data.message) {
            case "DOCUMENT_OR_FOLDER_NOT_FOUND":
              documentData.error_message = "DOCUMENT_OR_FOLDER_NOT_FOUND";
              break;
            case "UNAUTHORIZED_USER":
              documentData.error_message = "ANAUTHORIZED_USER";
              break;
            default:
              documentData.error_message = "ERROR_PERMANENTLY_DELETING_DOCUMENT";
          }
          break;
        default:
          documentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      documentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      documentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentData;
  }
};

/*
  This function creates a new document.
  It calls [POST] /api/<version>/documents/ endpoint
  returns the created document data
*/
export const ServerInterface_createDocument = async ({ data }) => {
  const payload = data;
  let documentData = null;
  try {
    const documentPostResponse = await slidAxios.post(documents_endpoint, payload);
    documentData = documentPostResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          documentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          documentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          documentData.error_message = "INVALID_DATA";
          break;
        default:
          documentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      documentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      documentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentData;
  }
};

/*
  This function updates a document. Mainly used for updating the title and content of a document.
  It calls [PUT] /api/<version>/documents/<slug:document_key>/ endpoint
  returns the updated document data
*/
export const ServerInterface_updateDocument = async ({ documentKey, data }) => {
  const payload = data;
  let documentData = null;
  try {
    const documentPutResponse = await slidAxios.put(`${documents_endpoint}${documentKey}/`, payload);
    documentData = documentPutResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          documentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          documentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          switch (error.response.data.message) {
            case "DOCUMENT_OR_FOLDER_NOT_FOUND":
              documentData.error_message = "DOCUMENT_OR_FOLDER_NOT_FOUND";
              break;
            case "UNAUTHORIZED_USER":
              documentData.error_message = "ANAUTHORIZED_USER";
              break;
            default:
              documentData.error_message = "ERROR_UPDATING_DOCUMENT";
          }
          break;
        case 400:
          documentData.error_message = "ERROR_GETTING_CLEAN_TEXT_FROM_HTML";
          break;
        default:
          documentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      documentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      documentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentData;
  }
};

/*
  This function updates a document. Mainly used for updating is_public, more_info, and is_deleted
  It calls [PATCH] /api/<version>/documents/<slug:document_key>/ endpoint
  returns the updated document data
*/
export const ServerInterface_patchDocument = async ({ documentKey, data }) => {
  const payload = data;
  let documentData = null;
  try {
    const documentPatchResponse = await slidAxios.patch(`${documents_endpoint}${documentKey}/`, payload);
    documentData = documentPatchResponse.data;
  } catch (error) {
    console.error(error);
    if (!documentData) documentData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          documentData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          documentData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 404:
          switch (error.response.data.message) {
            case "DOCUMENT_OR_FOLDER_NOT_FOUND":
              documentData.error_message = "DOCUMENT_OR_FOLDER_NOT_FOUND";
              break;
            case "UNAUTHORIZED_USER":
              documentData.error_message = "ANAUTHORIZED_USER";
              break;
            default:
              documentData.error_message = "ERROR_UPDATING_DOCUMENT";
          }
          break;
        default:
          documentData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      documentData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      documentData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return documentData;
  }
};

/*
 This function moves a document to a folder.
  It calls [PUT] /api/<version>/documents/ endpoint
  returns a simple success message
*/
export const ServerInterface_moveToFolder = async ({ parentKey, documentKeys }) => {
  const payload = {
    parent_key: parentKey,
    document_keys: documentKeys,
  };
  let moveToFolderData = null;
  try {
    const moveToFolderResponse = await slidAxios.put(documents_endpoint, payload);
    moveToFolderData = moveToFolderResponse.data;
  } catch (error) {
    console.error(error);
    if (!moveToFolderData) moveToFolderData = {};
    if (error.response) {
      if (isAuthError(error)) {
        handleAuthErrors(error);
        return;
      }
      switch (error.response.status) {
        //NOTE: errors at this point will get dispatched to app.js
        case 403:
          moveToFolderData.error_message = "INSUFFICIENT_PRIVILEGES";
          break;
        case 405:
          moveToFolderData.error_message = "METHOD_NOT_ALLOWED";
          break;
        case 400:
          // 3 cases are possible but we shall call all of them "INVALID_PARENT_KEY"
          //  1. missing parent_key
          //  2. parent_key is not a folder
          //  3. parent_key is the same as the document_key
          moveToFolderData.error_message = "INVALID_PARENT_KEY";
          break;
        default:
          moveToFolderData.error_message = "UNKNOWN_ERROR";
          //TODO: log this to sentry as it is unknown to us, might be a bug
          break;
      }
    } else if (error.code === "ERR_NETWORK") {
      moveToFolderData.error_message = "NETWORK_ERROR";
    } else if (error.request) {
      moveToFolderData.error_message = "NO_RESPONSE_FROM_SERVER";
    } else {
      // log to sentry
    }
  } finally {
    return moveToFolderData;
  }
};
