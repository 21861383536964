import { Icon, Typography15 } from "@slid/slid-ips";
import React, { memo } from "react";
import styled from "styled-components";

import { useAppSelector } from "hooks";
import useUpdateSLTLanguage from "hooks/useUpdateSLTLanguage";

const SmartLiveTextLanguageSelectSession = memo(() => {
  const { smartLiveTextLanguage } = useAppSelector((state) => state.sttReducer);
  const { languageList } = useUpdateSLTLanguage();

  return (
    <SessionContainer>
      {languageList.map((item, idx) => {
        return (
          <ItemWrapper
            onClick={() => {
              item.callback && item.callback();
            }}
            key={idx}
          >
            <TextWrapper>
              <StyledIcon icon={`check24`} color={`--blue7`} isCurrentLanguage={item.text === smartLiveTextLanguage} />
              <Typography15 text={item.text} color={`--gray5`} weight={400} />
              {item.subText && <Typography15 text={item.subText} color={`--gray13`} weight={400} />}
            </TextWrapper>
          </ItemWrapper>
        );
      })}
    </SessionContainer>
  );
});

const SessionContainer = styled.ul`
  /* S of reset ul */
  list-style: none;
  padding: 0;
  margin: 0;
  /* E of reset ul */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid var(--gray17);
  border-radius: 12px;
  padding: 8px 0;
`;

const ItemWrapper = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 8px 12px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: var(--gray17);
  }

  &:active {
    background-color: #191c20;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  gap: 4px;
  min-width: 200px;
`;

const StyledIcon = styled(Icon)<{ isCurrentLanguage: boolean }>`
  visibility: ${({ isCurrentLanguage }) => (isCurrentLanguage ? "visible" : "hidden")};
`;

export default SmartLiveTextLanguageSelectSession;
