import { Dispatch } from "@reduxjs/toolkit";
import { SET_SEARCH_KEYWORDS, SET_SEARCH_RESULTS, SET_SHOW_SEARCH_MODAL, SET_SEARCH_CATEGORIES, SET_SEARCH_SORTING_OPTION } from "./actionTypes";
import { SearchCategoryType, SortingOptionType } from "types/search";

export const setShowSearchModal = (showSearchModal: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SHOW_SEARCH_MODAL,
    payload: showSearchModal,
  });
};

export const setSearchKeywords = (searchKeywords: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SEARCH_KEYWORDS,
    payload: searchKeywords,
  });
};

export const setSearchResults = (searchResults: any[]) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SEARCH_RESULTS,
    payload: searchResults,
  });
};

export const setSearchCategories = (searchCategories: { categories: SearchCategoryType[]; selectedCategories: SearchCategoryType[] }) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SEARCH_CATEGORIES,
    payload: searchCategories,
  });
};

export const setSearchSortingOption = (searchSortingOption: SortingOptionType) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SEARCH_SORTING_OPTION,
    payload: searchSortingOption,
  });
};
