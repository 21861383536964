import React from "react";
import styled from "styled-components";
import SortingOptionMenu from "../../DropdownMenus/SortingOptionMenu";
import SearchCategory from "../searchCategory";

const SearchBoxHeader = () => {
  return (
    <Wrapper>
      <SortingOptionMenu location={"search result"} />
      <Divider />
      <SearchCategory location={"search modal"} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px 0;
`;
const Divider = styled.div`
  width: 1px;
  height: 28px;
  background-color: var(--gray3);
  margin: 0 12px 0 4px;
  flex-shrink: 0;
`;

export default SearchBoxHeader;
