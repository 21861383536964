// auth 2.0

import Compressor from "compressorjs";
import { v4 as uuidv4 } from "uuid";
import env from "config/env";

const API_ENDPOINT = env.end_point_url.slid_s3_api;

export const getPresignedURL_and_key = async ({ path, file_format }) => {
  /*
    s3 presigned url allows us to upload obj to s3 directly from client.
  */
  const endpoint = `${API_ENDPOINT}/getPresignedUrl?path=${path}&format=${file_format}`;
  try {
    const raw_response = await fetch(endpoint, {
      method: "GET",
    });
    const json_response = await raw_response.json();
    if (json_response.statusCode === 200) {
      return {
        upload_url: json_response.body.upload_url,
        key: json_response.body.key,
        region_name: json_response.body.region_name,
        bucket_name: json_response.body.bucket_name,
      };
    }
    return;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const uploadBase64Image = async ({ path, base64 }) => {
  /*
    handles slid captured image uploads
  */
  const endpoint = `${API_ENDPOINT}/uploadFileToS3?path=${path}&format=png`;
  const file = await base64ToFile(base64, `${uuidv4()}.jpeg`);
  const compressedFile = await getCompressedFile(file);
  try {
    const raw_response = await fetch(endpoint, {
      method: "POST",
      body: compressedFile,
    });
    const json_response = await raw_response.json();
    if (json_response.statusCode === 200 && json_response.body?.object_url) {
      return json_response.body.object_url;
    }
    return;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const uploadFileImage = async ({ path, file }) => {
  /*
   handles arbitrary image file upload: ex dropping image in editor
  */
  const file_name_split = file.name.split(".");
  const file_format = file_name_split[file_name_split.length - 1];
  const endpoint = `${API_ENDPOINT}/uploadFileToS3?path=${path}&format=${file_format}`;
  const compressedFile = await getCompressedFile(file);
  try {
    const raw_response = await fetch(endpoint, {
      method: "POST",
      body: compressedFile,
    });
    const json_response = await raw_response.json();
    if (json_response.statusCode === 200) {
      return json_response.body.object_url;
    }
    return;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const uploadFile = async ({ path, file }) => {
  /*
    handles arbitrary file upload: ex dropping file in editor
  */
  const file_name_split = file.name.split(".");
  const file_format = file_name_split[file_name_split.length - 1];
  const presigned_url_response = await getPresignedURL_and_key({ path, file_format });
  if (!presigned_url_response) return;
  const { upload_url, region_name, bucket_name, key } = presigned_url_response;
  try {
    await fetch(upload_url, {
      method: "PUT",
      body: file,
    });
    return `https://${bucket_name}.s3.${region_name}.amazonaws.com/${key}`;
  } catch (error) {
    console.log(error);
    return;
  }
};

const base64ToFile = (url, filename, mimeType) => {
  mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

const getCompressedFile = async (file) => {
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.8,
      success: resolve,
      error: reject,
    });
  });
};
