import React, { memo, useEffect } from "react";
import styled from "styled-components";
import { Typography17, Button, Typography24 } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { setShowDisableMediaRecordingModal } from "redux/actions/vdocsActions";
import { useAppDispatch, useAppSelector } from "hooks";
import { setIsSTTToggledOn, setStopSTT } from "redux/actions/sttActions";
import { setIsAutoNotesButtonClickedOnce, setIsAutoNotesSettingsOpen, setStopAutoNotes, setToggleAutoNotes } from "redux/modules/autoNotesSlice";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import useWhisperSLTStore from "store/useWhisperSLTStore";

interface DisableMediaRecordingModalProps {
  clipRecordStart: () => void;
  clipRecordStop: () => void;
}

export type MediaRecordingChangeDirection = "SNIPPET_TO_SLT" | "SNIPPET_TO_AUTO_NOTES" | "SLT_TO_SNIPPET" | "SLT_TO_AUTO_NOTES" | "AUTO_NOTES_TO_SLT" | "AUTO_NOTES_TO_SNIPPET" | null;

const DisableMediaRecordingModal = memo(({ clipRecordStart, clipRecordStop }: DisableMediaRecordingModalProps) => {
  const { t } = useTranslation("Modal");
  const dispatch = useAppDispatch();
  const { isSTTActive } = useAppSelector((state) => state.sttReducer);
  const isAutoNotesButtonClickedOnce = useAppSelector((state) => state.autoNotes.isAutoNotesButtonClickedOnce);
  const { mediaRecordingChangeDirection }: { mediaRecordingChangeDirection: MediaRecordingChangeDirection } = useAppSelector((state) => state.vdocs);

  const { setIsSTTToggledOn: setIsWhisperSttToggledOn, isWhisperSLTSupported } = useWhisperSLTStore();

  const MediaNames = {
    SLT: "auto-typing",
    AUTO_NOTES: "auto-notes",
    SNIPPET: "video-snippet",
  };

  const regex = /([a-zA-Z_]+)_TO_([a-zA-Z_]+)/;
  const [, activeMediaName, inActiveMediaName] = regex.exec(mediaRecordingChangeDirection || "")!;

  const activeMedia = MediaNames[activeMediaName];
  const inActiveMedia = MediaNames[inActiveMediaName];

  const EventTrackingNames = {
    SLT: "Auto-typing",
    AUTO_NOTES: "Auto-notes",
    SNIPPET: "Recording",
  };

  const activeMediaEventTrackingName = EventTrackingNames[activeMediaName];
  const inActiveMediaEventTrackingName = EventTrackingNames[inActiveMediaName];

  useEffect(() => {
    trackEvent({
      eventType: eventTypes.show.CHANGE_ACTIVATED_FEATURE_MODAL,
      eventProperties: {
        from: activeMediaEventTrackingName,
        to: inActiveMediaEventTrackingName,
      },
    });
  }, []);

  const disableActiveMedia = () => {
    if (activeMedia === "auto-typing") {
      dispatch(setIsSTTToggledOn(false));
      if (isWhisperSLTSupported) {
        setIsWhisperSttToggledOn(false);
      }
      if (isSTTActive) dispatch(setStopSTT());
    } else if (activeMedia === "video-snippet") {
      clipRecordStop();
    } else if (activeMedia === "auto-notes") {
      dispatch(setStopAutoNotes());
    }
  };

  const handleAutoNotesButtonClick = () => {
    if (!isAutoNotesButtonClickedOnce) {
      dispatch(setIsAutoNotesButtonClickedOnce(true));
      dispatch(setIsAutoNotesSettingsOpen(true));
    } else {
      dispatch(setToggleAutoNotes(true));
    }
  };

  const enableInActiveMedia = () => {
    if (inActiveMedia === "auto-typing") {
      dispatch(setIsSTTToggledOn(true));
      if (isWhisperSLTSupported) {
        setIsWhisperSttToggledOn(true);
      }
    } else if (inActiveMedia === "video-snippet") {
      clipRecordStart();
    } else if (inActiveMedia === "auto-notes") {
      handleAutoNotesButtonClick();
    }
  };

  return (
    <ModalContainer>
      <Typography24
        text={t("DisableMediaRecordingModalTitle", { ns: "Modal", activeMedia: `${t(activeMedia, { ns: "Modal" })}`, inactiveMedia: `${t(inActiveMedia, { ns: "Modal" })}` })}
        color={`--gray17`}
        weight={700}
      />
      <Typography17
        text={t("DisableMediaRecordingModalText", { ns: "Modal", activeMedia: `${t(activeMedia, { ns: "Modal" })}`, inactiveMedia: `${t(inActiveMedia, { ns: "Modal" })}` })}
        color={`--gray15`}
        weight={400}
        marginTop={`16px`}
      />
      <ButtonWrapper>
        <Button
          text={t("DisableMediaRecordingModalSecondaryButtonText")}
          appearance={`secondary`}
          callback={() => {
            dispatch(setShowDisableMediaRecordingModal(false));
          }}
          size={`medium`}
        />
        <Button
          text={t("StartMediaRecording", { ns: "Modal", media: `${t(inActiveMedia, { ns: "Modal" })}` })}
          appearance={`primary`}
          callback={() => {
            dispatch(setShowDisableMediaRecordingModal(false));
            // TODO: handle starting the right media
            disableActiveMedia();
            enableInActiveMedia();
            trackEvent({
              eventType: eventTypes.click.START_IN_CHANGE_ACTIVATED_FEATURE_MODAL,
              eventProperties: {
                from: activeMediaEventTrackingName,
                to: inActiveMediaEventTrackingName,
              },
            });
          }}
          size={`medium`}
        />
      </ButtonWrapper>
    </ModalContainer>
  );
});

const ModalContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 36px;

  background-color: #fff;
  box-shadow: var(--boxShadow3);
  border-radius: 8px;
  min-width: 40rem;
  width: 42rem;
  z-index: 99999;

  @media screen and (max-width: 799px) {
    width: 85vw;
    min-width: initial;
    max-width: 400px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 36px;
`;

export default DisableMediaRecordingModal;
