import "./components/xy-popover.js";
import { handleCSSVariables, setDefaultColorCache, getDefaultColorCache, throttle, getCustomColorCache, setCustomColorCache, CONVERTER_BTN, CONVERTER_PANEL } from "./utils/main";

const ColorCollections = [
  "#EC4C3A",
  "#DF8B88",
  "#9B46B3",
  "#7150B8",
  "#5662B7",
  "#447DF7",
  "#5DAFF0",
  "#66C0D5",
  "#77B56C",
  "#A1C76D",
  "#D5E06A",
  "#FBE95F",
  "#F6C755",
  "#F2A74D",
  "#83675C",
  "#A9A8A8",
  "#767F88", // gray10
  "#212529", // gray17
];
class ColorPlugin extends HTMLElement {
  static get observedAttributes() {
    return ["disabled", "dir"];
  }

  constructor(options) {
    super();
    const shadowRoot = this.attachShadow({ mode: "open" });
    this.colorCollections = options.colorCollections || ColorCollections;
    this.onColorPicked = options.onColorPicked;
    this.defaultColor = handleCSSVariables(options.defaultColor || this.colorCollections[this.colorCollections.length - 1]);
    this.pluginType = options.type;
    this.hasCustomPicker = options.hasCustomPicker;
    this.customColor = getCustomColorCache(this.pluginType);

    shadowRoot.innerHTML = `
        <style>
        :host{
            display:inline-block;
            font-size:1.4rem;
            border: none;
        }
        :host([block]){
            display:block;
        }

        :host([disabled]){
            pointer-events:none;
        }

        :host(:focus-within) xy-popover,:host(:hover) xy-popover{
            z-index: 2;
        }
        xy-popover{
            width:100%;
            height:32px;
            display:block;
        }
        .color-picker-dropdown-icon{
          margin-left: 4px;
        }
        xy-popcon{
            position: absolute;
            top: 27px;
            left: -172px;
            padding: 10px;
            border-radius: 4px;
        }
        .pop-footer{
            display:flex;
            justify-content:flex-end;
            padding:0 .8em .8em;
        }
        .pop-footer xy-button{
            font-size: 1.2rem;
            margin-left: .8em;
        }
        .color-sign {
            max-width: 198px;
            display:grid;
            cursor: default;
            grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
        }
        .color-sign>button {
            position: relative;
            width: 22px;
            height: 22px;
            border: 2px solid #fff;
            border-radius: 4px;
            padding: 4px;
            outline: 0;
            opacity: 0.9;
        }
        .color-sign.marker>button:hover {
            cursor: pointer;
            opacity: 1;
            border-color: var(--gray5);
        }
        .color-sign.text>button:hover {
          cursor: pointer;
          opacity: 1;
          border-color: var(--gray7);
        }
        #text-picked-color-btn {
          border-color: var(--gray17);
        }
        #marker-picked-color-btn{
          border-color: var(--gray7);
        }
        .color-section {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .color-btn {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
        .marker-icon-wrapper{
          background-color: var(--themeColor, --gray1);
          display: flex;
          width: 24px;
          height: 24px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 4px;
        }
        .color-btn:hover {
            font-size: 1.7rem;
            font-weight: bold;
            text-shadow: 2px 0 0 #cab9b9;
            border-radius: 4px 0 0 4px;
        }
        .color-btn>svg{
          color: var(--themeColor, --gray17);
        }
        </style>
        <section class="color-section">
            <xy-popover id="popover" ${this.dir ? "dir='" + this.dir + "'" : ""}>
                <xy-button class="color-btn" id="color-btn" ${this.disabled ? "disabled" : ""}> ${
      this.pluginType === "marker"
        ? `<div class="marker-icon-wrapper"><svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="#212529"  fill-rule="evenodd" clip-rule="evenodd" d="M11.4571 1.14641C11.0666 0.755885 10.4334 0.755884 10.0429 1.14641L1.04289 10.1464C0.855357 10.3339 0.75 10.5883 0.75 10.8535V14.1464C0.75 14.6987 1.19772 15.1464 1.75 15.1464H14.75C15.0261 15.1464 15.25 14.9226 15.25 14.6464C15.25 14.3703 15.0261 14.1464 14.75 14.1464H6.45711L14.75 5.85352C15.1405 5.46299 15.1405 4.82983 14.75 4.4393L11.4571 1.14641ZM11.4064 7.78292L5.04289 14.1464H1.75V10.8535L8.11349 4.49003L11.4064 7.78292ZM12.1135 7.07581L14.0429 5.14641L10.75 1.85352L8.82059 3.78292L12.1135 7.07581Z" />
        </svg></div>`
        : `<svg width="12" height="14" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg" id="color-picker-icon"><path fill-rule="evenodd" fill="currentColor" clip-rule="evenodd" d="M6.45453 0.791673C6.37307 0.613947 6.1955 0.5 6 0.5C5.8045 0.5 5.62692 0.613947 5.54547 0.791673L0.045467 12.7917C-0.069589 13.0427 0.0406407 13.3395 0.291672 13.4545C0.542703 13.5696 0.839476 13.4594 0.954532 13.2083L2.65418 9.5H9.34582L11.0455 13.2083C11.1605 13.4594 11.4573 13.5696 11.7083 13.4545C11.9594 13.3395 12.0696 13.0427 11.9545 12.7917L6.45453 0.791673ZM8.88748 8.5L6 2.20003L3.11252 8.5H8.88748Z"></path></svg>`
    }
                <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg" class="color-picker-dropdown-icon">
<path d="M1.35355 0.146447C1.15829 -0.0488155 0.841709 -0.0488155 0.646447 0.146447C0.451184 0.341709 0.451184 0.658291 0.646447 0.853553L1.35355 0.146447ZM4 3.5L3.64645 3.85355C3.84171 4.04882 4.15829 4.04882 4.35355 3.85355L4 3.5ZM7.35355 0.853553C7.54882 0.658291 7.54882 0.341709 7.35355 0.146447C7.15829 -0.0488155 6.84171 -0.0488155 6.64645 0.146447L7.35355 0.853553ZM0.646447 0.853553L3.64645 3.85355L4.35355 3.14645L1.35355 0.146447L0.646447 0.853553ZM4.35355 3.85355L7.35355 0.853553L6.64645 0.146447L3.64645 3.14645L4.35355 3.85355Z" fill="#ADB5BD"/>
</svg></xy-button>
                <xy-popcon id="popcon">
                    <div class="color-sign ${this.pluginType}" id="colors">
                        ${(this.hasCustomPicker && `<button id="custom-picker" class="rainbow-mask"/>`) || ""}
                        ${this.colorCollections.map((el) => `<button class="color-cube" style="background-color:${el}" data-color="${el}"></button>`).join("")}
                    </div>
                </xy-popcon>
            </xy-popover>
        </section>`;
  }

  focus() {
    this.colorBtn.focus();
  }

  connectedCallback() {
    this.$popover = this.shadowRoot.getElementById("popover");
    this.popcon = this.shadowRoot.getElementById("popcon");
    this.colorBtn = this.shadowRoot.getElementById("color-btn");
    this.colors = this.shadowRoot.getElementById("colors");
    this.colors.addEventListener("click", (ev) => {
      const item = ev.target.closest("button");
      this.pickedColorBtn = this.shadowRoot.getElementById("text-picked-color-btn") || this.shadowRoot.getElementById("marker-picked-color-btn");
      if (this.pickedColorBtn) this.pickedColorBtn.removeAttribute("id");

      if (item && item.id !== "custom-picker") {
        this.nativeclick = true;
        this.value = item.dataset.color;
        this.onColorPicked(this.value);
      }
    });

    this.$popover.addEventListener("click", (ev) => {
      this.pickedColorBtn = this.shadowRoot.getElementById("text-picked-color-btn") || this.shadowRoot.getElementById("marker-picked-color-btn");
      if (this.pickedColorBtn) this.pickedColorBtn.removeAttribute("id");
      const item = ev.target.closest("button");
      if (item) {
        this.nativeclick = true;
        this.value = item.dataset.color;
        this.onColorPicked(this.value);
        item.setAttribute("id", this.pluginType === "marker" ? "marker-picked-color-btn" : "text-picked-color-btn");
      }

      this.closeConverter();
    });
    if (this.hasCustomPicker) {
      this.setupCustomPicker();
    }
    this.value = this.defaultvalue;
  }

  closeConverter() {
    const conversionOpened = document.getElementsByClassName(CONVERTER_PANEL)[0];
    if (conversionOpened) {
      const converterBtn = document.getElementsByClassName(CONVERTER_BTN)[0];
      converterBtn?.click();
    }
  }

  disconnectedCallback() {
    if (this.pickerInput) {
      document.body.removeChild(this.pickerInput);
    }
  }

  setupCustomPicker() {
    let isCustomPickerPseudoClick = false;
    this.customPicker = this.shadowRoot.getElementById("custom-picker");
    const customPicker = this.customPicker;
    customPicker.style.backgroundColor = this.customColor;
    this.customPicker.addEventListener("click", (ev) => {
      if (isCustomPickerPseudoClick) {
        isCustomPickerPseudoClick = false;
        return;
      }
      if (this.pickerInput) {
        document.body.removeChild(this.pickerInput);
      }
      this.pickerInput = document.createElement("input");
      const pickerInput = this.pickerInput;
      const rect = this.popcon.getBoundingClientRect();
      pickerInput.setAttribute("type", "color");
      pickerInput.value = this.customColor;
      pickerInput.style.position = "fixed";
      pickerInput.style.left = `${rect.x + 3}px`;
      pickerInput.style.top = `${rect.y + 10}px`;
      pickerInput.style.pointerEvents = "none";
      pickerInput.style.zIndex = "999";
      pickerInput.style.opacity = "0";
      pickerInput.addEventListener(
        "input",
        throttle((ev) => {
          this.nativeclick = true;
          this.value = handleCSSVariables(ev.target.value);
          this.onColorPicked(this.value);
          setCustomColorCache(this.value, this.pluginType);

          customPicker.style.backgroundColor = this.value;

          isCustomPickerPseudoClick = true;
          customPicker.click();
        }, 30)
      );
      document.body.appendChild(pickerInput);
      setTimeout(() => {
        pickerInput.focus();
        pickerInput.click();
      }, 0);
    });
  }

  get defaultvalue() {
    return this.defaultColor;
  }

  get value() {
    return this.$value;
  }

  get type() {
    return this.getAttribute("type");
  }

  get disabled() {
    return this.getAttribute("disabled") !== null;
  }

  get dir() {
    return this.getAttribute("dir");
  }

  set dir(value) {
    this.setAttribute("dir", value);
  }

  set disabled(value) {
    if (value === null || value === false) {
      this.removeAttribute("disabled");
    } else {
      this.setAttribute("disabled", "");
    }
  }

  set defaultvalue(value) {
    this.setAttribute("defaultvalue", value);
  }

  set value(value) {
    if (!value) return;
    this.$value = value;

    const parentTag = window.getSelection().focusNode.parentNode;
    let fontColor = parentTag.tagName === "FONT" ? parentTag.attributes.color.value : "";
    let markColor = parentTag.parentNode.tagName === "SPAN" ? parentTag.parentNode.style.backgroundColor : parentTag.style.backgroundColor;
    this.colorBtn.style.setProperty("--themeColor", this.pluginType === "marker" ? markColor : fontColor);
    if (this.nativeclick) {
      this.nativeclick = false;
      this.colorBtn.style.setProperty("--themeColor", this.value);
      this.dispatchEvent(
        new CustomEvent("change", {
          detail: {
            value: this.value,
          },
        })
      );
    } else {
      if (this.colorPane) {
        this.colorPane.value = this.value;
      } else {
        this.defaultvalue = this.value;
      }
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "disabled" && this.colorBtn) {
      if (newValue != null) {
        this.colorBtn.setAttribute("disabled", "disabled");
      } else {
        this.colorBtn.removeAttribute("disabled");
      }
    }
    if (name === "dir" && this.$popover) {
      if (newValue != null) {
        this.$popover.dir = newValue;
      }
    }
  }
}

if (!customElements.get("xy-color-picker")) {
  customElements.define("xy-color-picker", ColorPlugin);
}

export { ColorPlugin };
