// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const CloseIcon = ({ width, height }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70711 0.292893C1.31658 -0.0976312 0.683417 -0.0976312 0.292893 0.292893C-0.0976312 0.683417 -0.0976312 1.31658 0.292893 1.70711L6.58579 8L0.292893 14.2929C-0.0976312 14.6834 -0.0976312 15.3166 0.292893 15.7071C0.683417 16.0976 1.31658 16.0976 1.70711 15.7071L8 9.41421L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L9.41421 8L15.7071 1.70711C16.0976 1.31658 16.0976 0.683418 15.7071 0.292894C15.3166 -0.0976311 14.6834 -0.0976311 14.2929 0.292894L8 6.58579L1.70711 0.292893Z"
        fill="#F1F3F5"
      />
    </SvgWrapper>
  );
};
