import { MediaRecordingChangeDirection } from "components/popups/DisableMediaRecordingModal";
import { useAppDispatch } from "hooks";
import { setIsSTTToggledOn } from "redux/actions/sttActions";
import { setMediaRecordingChangeDirection, setShowDisableMediaRecordingModal } from "redux/actions/vdocsActions";

const useSLTMediaEffects = () => {
  const dispatch = useAppDispatch();

  const manageClipRecordingEffects = (direction: MediaRecordingChangeDirection) => {
    dispatch(setIsSTTToggledOn(false));
    dispatch(setMediaRecordingChangeDirection(direction));
    dispatch(setShowDisableMediaRecordingModal(true));
  };

  return { manageClipRecordingEffects };
};

export default useSLTMediaEffects;
