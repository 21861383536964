import React from "react";
import Lottie from "react-lottie";
import recording_animation from "components/animations/recording_animation.json";
import waiting_animation from "components/animations/waiting_animation.json";
import { Icon } from "@slid/slid-ips";

const recordingLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: recording_animation,
  renderer: "svg",
};

const waitingLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: waiting_animation,
  renderer: "svg",
};

const SmartLiveTextVideoNoteButtonIcon = ({ isSTTHovered, SLTButtonStatus }: { isSTTHovered: boolean; SLTButtonStatus: "default" | "recording" | "waiting" }) => {
  return (
    <>
      {isSTTHovered && SLTButtonStatus !== "default" ? (
        <Icon icon={`stopRecording28`} color={`--red7`} />
      ) : SLTButtonStatus === "default" ? (
        <Icon icon={`autoTyping28`} color={`--gray17`} />
      ) : SLTButtonStatus === "recording" ? (
        <Lottie options={recordingLottieOptions} height={28} width={28} />
      ) : SLTButtonStatus === "waiting" ? (
        <Lottie options={waitingLottieOptions} height={28} width={28} />
      ) : (
        <Icon icon={`autoTyping28`} color={`--gray17`} />
      )}
    </>
  );
};
export default SmartLiveTextVideoNoteButtonIcon;
