import React, { memo, useState, useEffect } from "react";
import { useHistory, Route } from "react-router-dom";
import { getCurrentUser, isUserAuthenticated } from "utils/auth/cognitoAuthToolkit";

/**
 * Prevents access to a route if the user is not authenticated or not an admin (@slid.cc domain).
 * @param {Object} props - All the props passed to the Route component.
 * @returns {JSX.Element} - A Route component if user is authenticated and an admin, otherwise redirects to sign-in page.
 */
const AdminProtectedRoute = memo(({ ...props }) => {
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const checkAuth = async () => {
      const isAuthenticated = await isUserAuthenticated();
      if (!isAuthenticated) {
        return history.push("/sign-in");
      } else {
        const user = await getCurrentUser();

        if (!user.attributes.email.endsWith("@slid.cc")) {
          return history.push("/sign-in");
        }
        setIsCheckingAuth(false);
      }
    };
    checkAuth();
  }, [history]);

  if (isCheckingAuth) {
    return (
      <div className="main-loading-container d-flex flex-column justify-content-center align-items-center">
        <div>
          <img className="logo" src="/src/logo/slid_logo.png" alt="" />
          <img className="logo-text" src="/src/logo/slid_logo_text.png" alt="" />
        </div>
        <div className="spinner-border mt-3" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <span className="mt-3">Loading... </span>
      </div>
    );
  }
  return <Route {...props} />;
});

export default AdminProtectedRoute;
