import React from "react";
import styled from "styled-components";
import { Menu } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { setSearchSortingOption } from "redux/actions/searchActions";
import { setSortingOption } from "redux/modules/myNotesSlice";
import { trackEvent } from "utils/eventTracking";
import { setDefaultSettingCookie } from "utils/cookies/cookies";
import { eventTypes } from "types/eventTracking";
import { SortingOptionType } from "types/search";

const SortingOptionMenu = ({ location }: { location: "My notes" | "search result" }) => {
  const { t } = useTranslation(["MyNotes"]);
  const dispatch = useAppDispatch();
  const { searchSortingOption } = useAppSelector((state) => state.search);
  const { sortingOption } = useAppSelector((state) => state.myNotes);

  const onChangeSortingOption = (option: SortingOptionType) => {
    if (location === "search result") {
      dispatch(setSearchSortingOption(option));
    } else {
      dispatch(setSortingOption(option));
      trackEvent({
        eventType: "Change My Notes sorting",
        eventProperties: { option },
      });
      setDefaultSettingCookie({
        property: "isSortingOptionExist",
        value: option,
      });
    }
  };

  const SORTING_OPTIONS = [
    {
      text: t("SortingOptionDate"),
      callback: () => onChangeSortingOption(SortingOptionType.Date),
    },
    {
      text: t("SortingOptionName"),
      callback: () => onChangeSortingOption(SortingOptionType.Name),
    },
    {
      text: t("SortingOptionUpdated"),
      callback: () => onChangeSortingOption(SortingOptionType.Updated),
    },
  ];

  return (
    <StyledMenu
      itemList={SORTING_OPTIONS}
      menuValue={t(`SortingOption${location === "My notes" ? sortingOption : searchSortingOption}`)}
      color={"--gray9"}
      onClick={() => {
        if (location === "search result") {
          trackEvent({
            eventType: eventTypes.click.SORT_BUTTON,
            eventProperties: {
              location: "search modal",
              from: "My Notes",
            },
          });
        }
      }}
    />
  );
};

const StyledMenu = styled(Menu)`
  && {
    z-index: 3;
  }
`;

export default SortingOptionMenu;
