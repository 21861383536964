import {
  showChangeToBreakNotification,
  showChangeToTrialNotification,
  showDayPassExpiredWithoutRemainingItemsNotification,
  showDayPassExpiredWithRemainingItemsNotification,
  showTrialExpiredNotification,
} from "../notificationModal/index";
// auth 2.0
import { showPaymentFailedNotification } from "../notificationModal/index";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "hooks";
import { showPlanExpiredNotification, showPricingPlanUpdateNotification } from "../notificationModal/index";
import { useModal } from "@slid/slid-ips";
import { activateDayPass } from "redux/actions/pricingActions";
import { NO_EDIT_ON_BREAK_LOCAL_STORAGE } from "components/ViewNote/OptionMenu/CannotEditOnBreakModePopUp";
import { CLICK_CLOSE_ON_BANNER_LOCAL_STORAGE } from "components/myNotes/banner/NotesSecurityNoticeBanner";
import showStudyChallengeExpiredNotificationModal from "../studyChallenge/ExpiredNotificationModal";
import { showStudyChallengeExpiredModal } from "../studyChallenge";

enum MembershipType {
  free = "free",
  starter = "starter",
  basic = "basic",
  pro = "pro",
  trial = "trial",
  break = "break",
  daypass = "daypass",
  standard = "standard",
  premium = "premium",
}

export const useNotification = () => {
  const { notifications, userStudyChallengeData, userData } = useAppSelector((state) => state.slidGlobal);
  const { showModal, closeModal } = useModal();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { applicationType } = useAppSelector((state) => state.slidGlobal);
  const isSlidPocketMobile = applicationType === "slidpocket";

  const showNotification = async () => {
    if (isSlidPocketMobile) return;
    if (notifications) {
      for (const notification of notifications) {
        const notificationProps = {
          showModal,
          closeModal,
          notification,
          history,
        };
        const { notification_type, title, body } = notification;
        switch (notification_type) {
          case "feature-added":
            if (title === "pricing-policy-update") {
              return await showPricingPlanUpdateNotification(notificationProps);
            }

            break;
          case "account-type-change":
            if (body.to === MembershipType.trial) {
              if (body.from === MembershipType.starter || body.from === MembershipType.free) {
                return await showChangeToTrialNotification(notificationProps);
              }
            }

            if (body.to === MembershipType.break) {
              window.localStorage.setItem(NO_EDIT_ON_BREAK_LOCAL_STORAGE, "true");
              window.localStorage.setItem(CLICK_CLOSE_ON_BANNER_LOCAL_STORAGE, "false");
              if (body.from === MembershipType.starter) {
                window.localStorage.setItem(CLICK_CLOSE_ON_BANNER_LOCAL_STORAGE, "true");
                return await showChangeToBreakNotification(notificationProps);
              }

              if (body.from === MembershipType.trial) {
                // if current membership is not break, don't show the expired modal:
                if (userData.payment !== MembershipType.break) {
                  return;
                }
                if (/\/pricing/.test(window.location.href)) {
                  return;
                }
                if (userStudyChallengeData) {
                  if (/\/vdocs\//.test(window.location.href)) {
                    return await showStudyChallengeExpiredModal(notificationProps);
                  }
                  return await showStudyChallengeExpiredNotificationModal(notificationProps);
                }
                return await showTrialExpiredNotification(notificationProps);
              }

              if (body.from === MembershipType.daypass) {
                // show different notification based on the remaining items
                if (body.remaining_day_passes_count > 0) {
                  return await showDayPassExpiredWithRemainingItemsNotification({
                    ...notificationProps,
                    parameters: {
                      useAnotherDayPass: () => {
                        dispatch(activateDayPass());
                      },
                    },
                  });
                }
                return await showDayPassExpiredWithoutRemainingItemsNotification(notificationProps);
              }
              if ([MembershipType.basic, MembershipType.pro, MembershipType.standard, MembershipType.premium].includes(body.from)) {
                return await showPlanExpiredNotification(notificationProps);
              }
            }
            break;

          case "payment-failed":
            if (body.error_code !== "" && body.error_code === "RPCD1001") {
              await showPaymentFailedNotification({ ...notificationProps, notificationType: body.error_code });
            } else if (body.error_code !== "" && body.error_code === "RPCD1002") {
              await showPaymentFailedNotification({ ...notificationProps, notificationType: body.error_code });
            } else if (body.error_code !== "" && body.error_code === "RPAY9998") {
              await showPaymentFailedNotification({ ...notificationProps, notificationType: body.error_code });
            } else {
              await showPaymentFailedNotification({ ...notificationProps });
            }
            break;
          default:
            break;
        }
      }
    }
  };
  return { showNotification };
};
