import { Button, Typography17, Typography24, useModal } from "@slid/slid-ips";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { trackEvent } from "utils/eventTracking";
import { SurveySelectdOptionIcon } from "components/icons/SurveySelectedOptionIcon";
import { SurveyOptionIcon } from "components/icons/SurveyOptionIcon";
import { useAppDispatch, useAppSelector } from "hooks";
import { updateUser } from "redux/actions/slidGlobalActions";
import * as Sentry from "@sentry/browser";
import { useTranslation } from "react-i18next";
import { eventTypes } from "types/eventTracking";

const InflowSurveyModal = () => {
  const { t } = useTranslation("Modal");
  const { userData } = useAppSelector((state) => state.slidGlobal);
  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = useState<null | number>(null);
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);
  const [isSelectedOptionValid, setIsSelectedOptionValid] = useState<boolean>(false);
  const textarea = useRef() as any;
  const [otherText, setOtherText] = useState<string>("");
  const { closeModal } = useModal();

  const surveyOptions = t("InflowSurveyModal.Options", { keySeparator: ".", joinArrays: "||" }).split("||");

  useEffect(() => {
    trackEvent({
      eventType: eventTypes.show.INFLOW_SURVEY_MODAL,
    });
  }, []);

  useEffect(() => {
    const isOptionSelected = selectedOption !== null;
    if (selectedOption === surveyOptions.length - 1) {
      const isOtherTextEmpty = otherText.trim() === "";
      setIsSelectedOptionValid(!isOptionSelected || isOtherTextEmpty);
    } else {
      setIsSelectedOptionValid(!isOptionSelected);
    }
  }, [selectedOption, otherText]);

  const handleResizeHeight = () => {
    if (textarea.current) {
      // Design Spec : Show scroll after 3 line
      if (textarea.current.scrollHeight > 75) return;
      //@ts-ignore
      textarea.current.style.height = "auto";
      //@ts-ignore
      textarea.current.style.height = textarea.current.scrollHeight + "px";
    }
  };

  const onSubmitSurvey = () => {
    try {
      const userDataMoreInfo = JSON.parse(userData.more_info) || {};
      let selectedSource: string = selectedOption !== null ? surveyOptions[selectedOption] : "";
      const cleanedOtherText = otherText.trim();
      if (selectedOption === surveyOptions.length - 1) {
        selectedSource = surveyOptions[selectedOption] + "(" + cleanedOtherText + ")";
      }

      const options = ["Search Engine", "Youtube Ad", "Recommendation", "SNS or Blog", "Other"];
      trackEvent({
        eventType: eventTypes.click.SAVE_IN_INFLOW_SURVEY_MODAL,
        eventProperties: {
          option: selectedOption !== null ? options[selectedOption] : "",
          others: cleanedOtherText ? cleanedOtherText : undefined,
        },
      });

      dispatch(
        updateUser({
          data: {
            ...userData,
            more_info: {
              ...userDataMoreInfo,
              inflow_source: selectedSource,
            },
          },
        })
      );
      closeModal();
    } catch {
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        scope.setExtra("message", "JSON parse error (user/more_info)");
        Sentry.captureMessage("SLID_WEB_JSON_PARSE_ERROR");
      });
    }
  };

  return (
    <InflowSurveyContainer>
      <Typography24 color="--gray17" text={t("InflowSurveyModal.Title", { keySeparator: "." })} />
      <OptionLayout>
        {surveyOptions.map((option, index) => (
          <div key={index}>
            <OptionContainer
              onClick={() => {
                setSelectedOption(index);
                if (index === surveyOptions.length - 1) {
                  setIsOtherSelected(true);
                } else {
                  setIsOtherSelected(false);
                }
              }}
            >
              {index === selectedOption ? <SurveySelectdOptionIcon /> : <SurveyOptionIcon />}
              <Typography17 color={"--gray15"} weight={400} text={option} />
            </OptionContainer>
            {index === selectedOption && isOtherSelected && (
              <OtherFieldInput
                ref={textarea}
                rows={1}
                placeholder={t("InflowSurveyModal.OtherPlaceholderText", { keySeparator: "." })}
                autoFocus
                value={otherText}
                onChange={(e) => {
                  handleResizeHeight();
                  setOtherText(e.target.value);
                }}
              />
            )}
          </div>
        ))}
      </OptionLayout>
      <SubmitButtonContainer>
        <Button
          appearance="primary"
          size="maxWidthLarge"
          text={t("InflowSurveyModal.SubmitButton", { keySeparator: "." })}
          disabled={isSelectedOptionValid}
          isDisabled={isSelectedOptionValid}
          callback={() => onSubmitSurvey()}
        />
      </SubmitButtonContainer>
    </InflowSurveyContainer>
  );
};

export default InflowSurveyModal;

const InflowSurveyContainer = styled.div`
  width: 100%;
  max-width: 472px;
  min-width: 472px;
  min-height: auto;
  padding: 36px;
  overflow-y: auto;
  border-radius: 8px;
  background-color: var(--gray1);
  box-shadow: var(--boxShadow3);
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const OptionLayout = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 36px;
`;

const OptionContainer = styled.div`
  margin-bottom: 12px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  gap: 12px;
`;

const OtherFieldInput = styled.textarea`
  display: block;
  height: auto;
  width: calc(100% - 32px);
  padding: 8px 12px;
  margin-left: 32px;
  margin-top: -4px;
  border-radius: 8px;
  background-color: var(--gray3);
  border: transparent;

  color: var(--gray8);
  font-size: 13px;
  :focus {
    outline: none;
  }
`;
