import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography13, Typography15, Typography20, useToast } from "@slid/slid-ips";
import { CloseIcon } from "components/icons/CloseIcon";
import { useDocumentAccess } from "hooks/queries";
import Papa from "papaparse";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ShareInvitationOption = () => {
  const [isToggleOn, setIsToggleOn] = useState(false);
  const [email, setEmail] = useState("");
  const { showToast } = useToast();

  const { allowedEmails, accessExists, createDocumentAccess, updateAllowedEmail } = useDocumentAccess();
  const [emailList, setEmailList] = useState<string[]>([]);

  useEffect(() => {
    if (allowedEmails) {
      setEmailList(allowedEmails);
    }
  }, [allowedEmails]);

  useEffect(() => {
    if (accessExists?.exists) {
      setIsToggleOn(true);
    }
  }, [accessExists]);

  const handleToggleChange = async () => {
    setIsToggleOn(!isToggleOn);

    if (!isToggleOn) {
      await createDocumentAccess();
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const updateEmailList = (newEmails: string[]) => {
    const updatedEmailList = [...emailList, ...newEmails];
    setEmailList(updatedEmailList);
    updateAllowedEmail(updatedEmailList); // 서버에 업데이트를 요청합니다.
  };

  const parseCsvFile = (file) => {
    Papa.parse(file, {
      complete: (result: any) => {
        // 빈 행을 제거하고, 첫 번째 열에서 유효한 이메일만 추출
        const emails = result.data[0].filter((row: any) => row.length > 0 && row[0].trim() !== ""); // 빈 행 제거
        updateEmailList(emails);
      },
      header: false,
    });
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    parseCsvFile(file);
  };

  const addEmailToList = () => {
    if (email && emailRegex.test(email)) {
      if (!emailList.includes(email)) {
        const updatedEmailList = [...emailList, email];
        setEmailList(updatedEmailList);
        updateAllowedEmail(updatedEmailList); // 서버에 업데이트를 요청합니다.
        setEmail("");
      }
    } else {
      showToast("Please enter a valid email address.");
    }
  };

  const removeEmailFromList = (emailToRemove: string) => {
    const updatedEmailList = emailList.filter((email) => email !== emailToRemove);
    setEmailList(updatedEmailList);
    updateAllowedEmail(updatedEmailList); // 서버에 업데이트를 요청합니다.
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      addEmailToList();
    }
  };

  return (
    <Container>
      <div>
        <ToggleWrapper>
          <div>
            <Typography20 text={`Share For only invited Users`} color={`--gray17`} weight={700} />
            <Typography15 text={`Only invited users can view note.`} color={`--gray17`} marginTop={"4px"} />
          </div>
          <ToggleControl className="toggle-control">
            <ToggleInput type="checkbox" checked={isToggleOn} onChange={handleToggleChange} />
            <ControlButton className="control"></ControlButton>
          </ToggleControl>
        </ToggleWrapper>
      </div>
      {isToggleOn && (
        <InputGroupWrapper>
          <CSVInput
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className="form-control"
            onChange={handleFileUpload}
            id="csv"
          />
          <CSVLabel htmlFor="csv">
            <Typography13 text={`Do you want to upload by csv file?`} color={`--gray9`} />
          </CSVLabel>
          <InputGroup>
            <EmailInput
              type="text"
              className="form-control"
              placeholder="Add Email Address"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={email}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
            />
            <EmailInputButton type="button" onClick={addEmailToList}>
              ADD
            </EmailInputButton>
          </InputGroup>
          <EmailList>
            {emailList.map((email, index) => {
              return (
                <EmailTagWrapper key={index}>
                  <EmailTag>{email}</EmailTag>
                  <CloseIconWrapper onClick={() => removeEmailFromList(email)}>
                    <CloseIcon width={16} height={16} />
                  </CloseIconWrapper>
                </EmailTagWrapper>
              );
            })}
          </EmailList>
        </InputGroupWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 24px 24px 24px 32px;

  display: flex;
  flex-direction: column;

  border-top: 1px solid var(--gray3);
`;

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputGroupWrapper = styled.div``;

const InputGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const EmailInput = styled.input``;

const EmailInputButton = styled.button`
  background-color: var(--blue8);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const EmailList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid var(--gray5);
  padding: 12px;
  overflow-y: scroll;
  height: 100px;
`;

const EmailTagWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12 px;
  background-color: var(--gray5);
`;

const EmailTag = styled.div`
  border-radius: 4px;
  overflow-x: hidden;
  padding: 4px;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
`;

export default ShareInvitationOption;

const CSVInput = styled.input`
  display: none;
`;

const CSVLabel = styled.label`
  display: flex;
  justify-content: space-between;
  background-color: var(--gray2);
  padding: 6px;
  cursor: pointer;
`;

const ToggleControl = styled.label`
  display: block;
  position: relative;
  padding-left: 48px;
  margin-bottom: 28px;
  cursor: pointer;
  font-size: 2.2rem;
  user-select: none;
`;

const ToggleInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: var(--blue8);

    &:after {
      left: 24px;
    }
  }
`;

const ControlButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 48px;
  border-radius: 34px;
  background-color: var(--gray5);
  transition: background-color 0.15s ease-in;

  &:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;
    transition: left 0.15s ease-in;
  }
`;
