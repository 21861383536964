// auth 2.0

import { uploadBase64Image as v1_uploadBase64Image, uploadFileImage as v1_uploadFileImage, uploadFile as v1_uploadFile } from "./s3InterFace.js";
import { uploadBase64Image as v2_uploadBase64Image, uploadFileImage as v2_uploadFileImage, uploadFile as v2_uploadFile } from "./new_s3InterFace";
import { uploadBase64Image as v3_uploadBase64Image, uploadFileImage as v3_uploadFileImage, uploadFile as v3_uploadFile } from "./globalOptimizedS3Interface";
import Firebase from "../../firebase/firebase";

export let uploadBase64Image;
export let uploadFileImage;
export let uploadFile;

// OPTIONS: v1=s3Interface, v2=new_s3InterFace, v3=globalOptimizedS3Interface
let CURRENT_S3_INTERFACE = "v3";

// fetch remote config and attempt to update the s3 interface
Firebase.getParameterValue("s3_interface")
  .then((s3_interface) => {
    if (s3_interface) {
      CURRENT_S3_INTERFACE = s3_interface;
    }
  })
  .catch((err) => {
    console.error(err);
  })
  .then(() => {
    // do this no matter what happened above
    switch (CURRENT_S3_INTERFACE) {
      case "v1":
        uploadBase64Image = v1_uploadBase64Image;
        uploadFileImage = v1_uploadFileImage;
        uploadFile = v1_uploadFile;
        break;
      case "v2":
        uploadBase64Image = v2_uploadBase64Image;
        uploadFileImage = v2_uploadFileImage;
        uploadFile = v2_uploadFile;
        break;
      case "v3":
        uploadBase64Image = v3_uploadBase64Image;
        uploadFileImage = v3_uploadFileImage;
        uploadFile = v3_uploadFile;
        break;
      default:
        uploadBase64Image = v3_uploadBase64Image;
        uploadFileImage = v3_uploadFileImage;
        uploadFile = v3_uploadFile;
    }
  });
