import React from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { Typography11, Typography16 } from "@slid/slid-ips";
import styled, { css, keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { SpinnerIcon } from "components/icons/SLTSpinnerIcon";
import { SLTStopIcon } from "components/icons/SLTStopIcon";
import { setIsSTTToggledOn, setStopSTT } from "redux/actions/sttActions";

const EditorSTTView = ({ data, editorAPI, currentBlock }) => {
  const { processedSTTData } = useAppSelector((state) => state.sttReducer);

  if (processedSTTData.length === 0) {
    return <SmartLiveTextBox item={{ text: "", blockId: currentBlock?.id }} index={0} editorAPI={editorAPI} />;
  }

  return processedSTTData.map((item: { text: string; blockId: string }, index) => {
    if (item.text === "") return null;
    return <SmartLiveTextBox key={item.blockId + `${index}`} item={item} index={index} editorAPI={editorAPI} />;
  });
};

export default EditorSTTView;

const SmartLiveTextBox = ({ item, index, editorAPI }) => {
  const { processedSTTData, isSTTActive } = useAppSelector((state) => state.sttReducer);
  const { t } = useTranslation("SmartLiveText");
  const isLastData = processedSTTData.length > 0 ? index === processedSTTData?.length - 1 : true;
  const dispatch = useAppDispatch();

  return (
    <SLTWrapper className={`${editorAPI.styles.block} slt_wrapper`}>
      <TitleWrapper>
        <IconWrapper showSpinner={!isLastData}>
          <Spinner>
            <SpinnerIcon />
          </Spinner>
        </IconWrapper>
        <Typography16 text={!isLastData ? t("SLTChecking") : t("SmartLiveText")} color={isLastData ? `--gray17` : `--gray10`} weight={700} type={`span`} />
      </TitleWrapper>
      <SLTContentText data-type={`slt-text`} data-content={isSTTActive ? t("SLTTyping") : t("SLTWaiting")} showPseudoData={isLastData}>
        {item.text}
      </SLTContentText>
      {isLastData && (
        <StopButton
          onClick={() => {
            dispatch(setIsSTTToggledOn(false));
            dispatch(setStopSTT());
          }}
        >
          <StopButtonIcon>
            <SLTStopIcon />
          </StopButtonIcon>
          <Typography11 text={t("StopButtonOnAutoSLTBlock")} color={`--gray17`} weight={500} />
        </StopButton>
      )}
    </SLTWrapper>
  );
};

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const blink = keyframes`
  50% {
    border-color: var(--gray1);
  }
`;

const SLTWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 1.6rem 2rem;
  flex-direction: column;
  align-items: flex-start;
  background: var(--gray1);
  border-radius: 4px;
  outline: 1px solid var(--gray3);
  margin: 1.6rem 0;
`;

const IconWrapper = styled.div<{ showSpinner: boolean }>`
  padding-right: 8px;
  display: ${({ showSpinner }) => (showSpinner ? "flex" : "none")};
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  overflow: hidden;
  animation: ${spin} 1000ms infinite linear;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SLTContentText = styled.p<{ showPseudoData: boolean }>`
  color: var(--gray8);
  font-size: 1.5rem;
  line-height: 2.3rem;
  width: 100%;
  box-shadow: none;
  white-space: inherit;
  word-wrap: normal;
  resize: none !important;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;

  min-height: 2.3rem;
  overflow-y: hidden;
  margin-top: 8px;

  ${({ showPseudoData }) =>
    showPseudoData &&
    css`
      color: var(--gray17);

      &::after {
        font-size: 1.3rem;
        padding-left: 8px;
        content: attr(data-content);
        color: var(--gray7);
        border-left: 1px solid var(--gray14);
        animation: ${blink} 0.8s infinite;
      }
    `}
`;

const StopButton = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--gray4);
  margin-top: 16px;
  cursor: pointer;
  color: var(--gray17);

  &:hover {
    background: var(--red1);
    border: 1px solid var(--red1_opacity);
    transition: all 0.2s ease-in-out;

    p {
      color: var(--red7);
      transition: all 0.2s ease-in-out;
    }
  }
`;

const StopButtonIcon = styled.div`
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
