// auth 2.0
import slidAxios from "./base";

const user_exists_endpoint = `user-exists/`;

export const ServerInterface_isSlidUser = async () => {
  try {
    const response = await slidAxios.get(user_exists_endpoint);
    const responseMessage = response.data.message;
    if (responseMessage === "User Found") {
      return true;
    }
    return false; // "User Not Found"
  } catch (error) {
    // todo: discuss what to do on this since it does not handle errors and returns just a boolean
    return false;
  }
};
