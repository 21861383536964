import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useAppSelector } from "hooks";

export const useUsageHistory = () => {
  const [usageHistory, setUsageHistory] = useState<any[]>([]);
  const { paymentsHistoryData, dayPassData } = useAppSelector((state) => state.pricing);

  useEffect(() => {
    // don't render if data is not ready:
    if (paymentsHistoryData === null || dayPassData === null) return;

    // filter out daypass data from paymentsHistoryData:
    const filteredPaymentHistoryData = paymentsHistoryData.payments
      .filter((item: any) => {
        try {
          const data = JSON.parse(item.more_info) || {};
          return !data.is_day_pass;
        } catch (err) {
          console.log(err);
          Sentry.withScope((scope) => {
            scope.setLevel("error");
            scope.setExtra("message", "JSON parse error (user/more_info)");
            Sentry.captureMessage("SLID_WEB_JSON_PARSE_ERROR");
          });
          return false;
        }
      })
      .map((item: any) => ({
        startDate: item.start_date,
        endDate: item.end_date,
        userType: item.membership,
      }));

    // filter out daypass data from dayPassData:
    const filteredDayPassData = dayPassData.usedItems.map((item: any) => ({
      startDate: item.started_at,
      endDate: item.expired_at,
      userType: "daypass",
    }));

    const newUsageHistory = [...filteredPaymentHistoryData, ...filteredDayPassData];

    // sort usageHistory by startDate in descending order:
    newUsageHistory.sort((a: any, b: any) => (a.startDate < b.startDate ? 1 : -1));

    setUsageHistory(newUsageHistory);
  }, [paymentsHistoryData, dayPassData]);

  return { usageHistory };
};
