//auth 2.0
/**
 * Build styles
 */
import "./index.css";
import store from "redux/store";
import { trackEvent } from "utils/eventTracking";
import { isTouchDevice } from "utils/utils";

/**
 * CodeTool for Editor.js
 *
 * @author CodeX (team@ifmo.su)
 * @copyright CodeX 2018
 * @license MIT
 * @version 2.0.0
 */

/**
 * Code Tool for the Editor.js allows to include code examples in your articles.
 */
export default class CodeTool {
  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to press Enter inside the CodeTool textarea
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * @typedef {object} CodeData — plugin saved data
   * @property {string} code - previously saved plugin code
   */

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} options - tool constricting options
   * @param {CodeData} options.data — previously saved plugin code
   * @param {object} options.config - user config for Tool
   * @param {object} options.api - Editor.js API
   * @param {boolean} options.readOnly - read only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.config = config;
    this.readOnly = readOnly;

    this.slidGlobal = store.getState().slidGlobal;
    this.lang = this.slidGlobal.lang;

    this.placeholder = this.api.i18n.t(config.placeholder || CodeTool.DEFAULT_PLACEHOLDER);

    this.CSS = {
      baseClass: this.api.styles.block,
      input: this.api.styles.input,
      wrapper: "ce-code",
      textarea: "ce-code__textarea",
      hoverButtonContainer: "block-hover-button__container",
      hoverButtonIconContainer: "block-hover-button__icon-container",
      hoverButtonItem: "block-hover-button__item",
      hoverButtonShown: "block-hover-button__shown",
    };

    this.nodes = {
      holder: null,
      textarea: null,
    };

    this.data = {
      code: data.code || "",
    };

    this.nodes.holder = this.drawView();
  }

  onKeyDown(event) {
    if (event.key === "Tab") {
      event.stopPropagation();
      event.preventDefault();
      const v = this.nodes.textarea.value,
        s = this.nodes.textarea.selectionStart,
        e = this.nodes.textarea.selectionEnd;
      const indentCount = 4;

      this.nodes.textarea.value = v.substring(0, s) + " ".repeat(indentCount) + v.substring(e);
      this.nodes.textarea.selectionStart = this.nodes.textarea.selectionEnd = s + indentCount;
    }
  }

  /**
   * Create Tool's view
   *
   * @returns {HTMLElement}
   * @private
   */
  drawView() {
    const wrapper = document.createElement("div"),
      textarea = document.createElement("textarea"),
      hoverButtonContainer = document.createElement("div");
    if (this.readOnly) {
      textarea.disabled = true;
    } else {
      this.onKeyDown = this.onKeyDown.bind(this);
      textarea.addEventListener("keydown", this.onKeyDown);
    }

    wrapper.classList.add(this.CSS.baseClass, this.CSS.wrapper);
    textarea.classList.add(this.CSS.textarea, this.CSS.input);
    textarea.spellcheck = false;
    textarea.textContent = this.data.code;

    textarea.placeholder = this.placeholder;
    hoverButtonContainer.classList.add(this.CSS.hoverButtonContainer);

    // add hover buttons on text block
    this.renderHoverButtons().forEach((item) => {
      hoverButtonContainer.appendChild(item);
    });

    wrapper.addEventListener("mouseover", () => {
      if (!this.readOnly) {
        hoverButtonContainer.classList.add("block-hover-button__shown");
      }
    });

    wrapper.addEventListener("mouseout", () => {
      if (!this.readOnly) {
        hoverButtonContainer.classList.remove("block-hover-button__shown");
      }
    });

    wrapper.appendChild(textarea);
    wrapper.appendChild(hoverButtonContainer);

    this.nodes.textarea = textarea;

    return wrapper;
  }

  handleClickToParagraph() {
    this.api.tooltip.hide();
    let text = escapeHtml(this.data.code);

    function escapeHtml(unsafe) {
      return unsafe.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
    }

    // keep break line
    // keep space
    // keep tab
    text = text.replaceAll("\n", "<br/>").replaceAll(" ", "&nbsp;").replaceAll("	", "&#009;");

    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex();
    this.api.blocks.delete(currentBlockIndex);
    this.api.blocks.insert("paragraph", { text }, {}, currentBlockIndex, true);
    this.api.caret.setToBlock(currentBlockIndex, "end");
  }

  renderHoverButtons() {
    let wrapper = [];
    let hoverButtonItems = [];

    hoverButtonItems.push({
      name: "paragraph",
      icon: `<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 1.375C0.25 1.09886 0.473858 0.875 0.75 0.875H7H13.25C13.5261 0.875 13.75 1.09886 13.75 1.375V3.875C13.75 4.15114 13.5261 4.375 13.25 4.375C12.9739 4.375 12.75 4.15114 12.75 3.875V1.875H7.5V12.125H9.5C9.77614 12.125 10 12.3489 10 12.625C10 12.9011 9.77614 13.125 9.5 13.125H7H4.5C4.22386 13.125 4 12.9011 4 12.625C4 12.3489 4.22386 12.125 4.5 12.125H6.5V1.875H1.25V3.875C1.25 4.15114 1.02614 4.375 0.75 4.375C0.473858 4.375 0.25 4.15114 0.25 3.875V1.375Z" /></svg>`,
      title: this.lang === "ko" ? "텍스트로 전환" : "To paragraph",
      click: () => {
        trackEvent({
          eventType: "Click to paragraph from hover button",
        });
        this.handleClickToParagraph();
      },
    });

    hoverButtonItems.push({
      name: "delete",
      icon: `<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.2 0C4.77562 0 4.35428 0.139699 4.03138 0.408783C3.70582 0.680082 3.5 1.0697 3.5 1.5V2H2.5H1C0.723858 2 0.5 2.22386 0.5 2.5C0.5 2.77614 0.723858 3 1 3H2V12.9C2 13.1652 2.08702 13.434 2.26234 13.6444C2.43987 13.8574 2.70231 14 3 14H11C11.2977 14 11.5601 13.8574 11.7377 13.6444C11.913 13.434 12 13.1652 12 12.9V3H13C13.2761 3 13.5 2.77614 13.5 2.5C13.5 2.22386 13.2761 2 13 2H11.5H10.5V1.5C10.5 1.0697 10.2942 0.680082 9.96862 0.408783C9.64572 0.139699 9.22438 0 8.8 0H5.2ZM9.5 2V1.5C9.5 1.39987 9.45296 1.28078 9.32844 1.177C9.20125 1.07101 9.01214 1 8.8 1H5.2C4.98786 1 4.79875 1.07101 4.67156 1.177C4.54704 1.28078 4.5 1.39987 4.5 1.5V2H9.5ZM4 3H3V12.9C3 12.9485 3.01537 12.9832 3.02735 13H10.9727C10.9846 12.9832 11 12.9485 11 12.9V3H10H4ZM5.5 5.5C5.77614 5.5 6 5.72386 6 6V10C6 10.2761 5.77614 10.5 5.5 10.5C5.22386 10.5 5 10.2761 5 10V6C5 5.72386 5.22386 5.5 5.5 5.5ZM9 6C9 5.72386 8.77614 5.5 8.5 5.5C8.22386 5.5 8 5.72386 8 6V10C8 10.2761 8.22386 10.5 8.5 10.5C8.77614 10.5 9 10.2761 9 10V6Z" /></svg>`,
      title: this.lang === "ko" ? "삭제" : "Delete",
      click: () => {
        this.api.tooltip.hide();
        this.api.blocks.delete();
      },
    });

    hoverButtonItems.forEach((item) => {
      const button = document.createElement("button");
      button.classList.add("block-hover-button__item");

      const iconContainer = document.createElement("div");
      iconContainer.classList.add("block-hover-button__icon-container");
      iconContainer.innerHTML = item.icon;

      button.appendChild(iconContainer);
      button.addEventListener("click", item.click.bind(this));

      if (item.title && !isTouchDevice()) {
        this.api.tooltip.onHover(button, item.title, {
          placement: "top",
        });
      }

      wrapper.push(button);
    });

    return wrapper;
  }

  renderSettings() {
    const wrapper = [];

    let settings = [];

    // convert to paragraph block
    settings.push({
      name: "paragraph",
      icon: `<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.25 1.375C0.25 1.09886 0.473858 0.875 0.75 0.875H7H13.25C13.5261 0.875 13.75 1.09886 13.75 1.375V3.875C13.75 4.15114 13.5261 4.375 13.25 4.375C12.9739 4.375 12.75 4.15114 12.75 3.875V1.875H7.5V12.125H9.5C9.77614 12.125 10 12.3489 10 12.625C10 12.9011 9.77614 13.125 9.5 13.125H7H4.5C4.22386 13.125 4 12.9011 4 12.625C4 12.3489 4.22386 12.125 4.5 12.125H6.5V1.875H1.25V3.875C1.25 4.15114 1.02614 4.375 0.75 4.375C0.473858 4.375 0.25 4.15114 0.25 3.875V1.375Z" /></svg>`,
      title: this.lang === "ko" ? "텍스트로 전환" : "To paragraph",
      click: () => {
        trackEvent({
          eventType: "Click to paragraph from edit menu",
        });
        this.handleClickToParagraph();
      },
    });

    settings.forEach((tune) => {
      let button = document.createElement("div");
      const iconContainer = document.createElement("div");
      const buttonTitle = document.createElement("span");

      iconContainer.classList.add("ce-settings__button-icon-container");
      buttonTitle.classList.add("ce-settings__button-text");
      button.classList.add("ce-settings__button");

      iconContainer.innerHTML = tune.icon;
      buttonTitle.innerHTML = tune.title;

      button.appendChild(iconContainer);
      button.appendChild(buttonTitle);
      button.addEventListener("click", tune.click.bind(this));

      wrapper.push(button);
    });

    return wrapper;
  }

  /**
   * Return Tool's view
   *
   * @returns {HTMLDivElement} this.nodes.holder - Code's wrapper
   * @public
   */
  render() {
    return this.nodes.holder;
  }

  /**
   * Extract Tool's data from the view
   *
   * @param {HTMLDivElement} codeWrapper - CodeTool's wrapper, containing textarea with code
   * @returns {CodeData} - saved plugin code
   * @public
   */
  save(codeWrapper) {
    this.data.code = codeWrapper.querySelector("textarea").value;
    return {
      code: this.data.code,
    };
  }

  /**
   * onPaste callback fired from Editor`s core
   *
   * @param {PasteEvent} event - event with pasted content
   */
  onPaste(event) {
    const content = event.detail.data;

    this.data = {
      code: content.textContent,
    };
  }

  /**
   * Returns Tool`s data from private property
   *
   * @returns {CodeData}
   */
  get data() {
    return this._data;
  }

  /**
   * Set Tool`s data to private property and update view
   *
   * @param {CodeData} data - saved tool data
   */
  set data(data) {
    this._data = data;

    if (this.nodes.textarea) {
      this.nodes.textarea.textContent = data.code;
    }
  }

  /**
   * Default placeholder for CodeTool's textarea
   *
   * @public
   * @returns {string}
   */
  static get DEFAULT_PLACEHOLDER() {
    return "Enter a code";
  }

  /**
   *  Used by Editor.js paste handling API.
   *  Provides configuration to handle CODE tag.
   *
   * @static
   * @returns {{tags: string[]}}
   */
  static get pasteConfig() {
    return {
      tags: ["pre"],
    };
  }

  /**
   * Automatic sanitize config
   *
   * @returns {{code: boolean}}
   */
  static get sanitize() {
    return {
      code: true, // Allow HTML tags
    };
  }

  static convertToPlainHTML(data) {
    return "<pre>" + data.code + "</pre>";
  }
}
