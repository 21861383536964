import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

const SvgWrapper = styled(Icon)``;

export const RedoIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper width={width ? width : 48} height={height ? height : 48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.9795 26.0103L32.9795 21.0103L28.9795 16.0103" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M28.4795 32.0103H20.2295C18.8371 32.0103 17.5017 31.4308 16.5172 30.3993C15.5326 29.3679 14.9795 27.9689 14.9795 26.5103C14.9795 25.0516 15.5326 23.6526 16.5172 22.6212C17.5017 21.5897 18.8371 21.0103 20.2295 21.0103H32.9795"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
