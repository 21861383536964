// auth 2.0

import {
  SET_SHOW_REFERRAL_POPUP,
  SET_PAYMENT_LENGTH,
  SET_PAYMENTS_HISTORY_DATA,
  SET_REFERRAL_CODE_DATA,
  SET_USER_COUNTRY,
  FETCH_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  SET_DAYPASS_DATA,
  SET_PLAN_TYPE,
  SET_PLAN_LENGTH,
  SET_MEMBERSHIP,
  SET_IS_CASHBACK_USER,
  SET_IS_ELIGIBLE_FOR_CASHBACK,
  SET_IS_CASHBACK_OPTION_CHECKED,
  SET_IS_CHECKOUT_SECTION_VISIBLE_ON_MOBILE,
  SET_EXPERT_CODE,
  SET_SURVEY_INFO,
} from "redux/actions/actionTypes";

const initialState = {
  showReferralPopup: false, // This state is related to pricing page. if true, show referral Popup
  paymentLength: "yearly",
  paymentsHistoryData: null,
  referralCodeData: null,
  subscriptionData: null,
  dayPassData: null,
  userCountry: null,

  //  S of pricing page related states
  planType: "subscription",
  planLength: "long",
  membership: "premium",

  isCashbackUser: true,
  isEligibleForCashback: false,
  isCashbackOptionChecked: true,
  isCheckoutSectionVisibleOnMobile: false,
  // E of pricing page related states

  // S of expert pricing page related states
  expertCode: undefined,
  surveyInfo: null,
  // E of expert pricing page related states
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_REFERRAL_POPUP:
      return {
        ...state,
        showReferralPopup: action.payload,
      };
    case SET_PAYMENT_LENGTH:
      return {
        ...state,
        paymentLength: action.payload,
      };
    case SET_PAYMENTS_HISTORY_DATA:
      return {
        ...state,
        paymentsHistoryData: action.payload,
      };
    case SET_REFERRAL_CODE_DATA:
      return {
        ...state,
        referralCodeData: action.payload,
      };
    case SET_USER_COUNTRY:
      return {
        ...state,
        userCountry: action.payload,
      };
    case SET_DAYPASS_DATA:
      return {
        ...state,
        dayPassData: action.payload,
      };
    case FETCH_SUBSCRIPTION:
      return {
        ...state,
        subscriptionData: action.payload,
      };
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscriptionData: action.payload,
      };

    // S of pricing page related reducers
    case SET_PLAN_TYPE:
      return {
        ...state,
        planType: action.payload,
      };
    case SET_PLAN_LENGTH:
      return {
        ...state,
        planLength: action.payload,
      };
    case SET_MEMBERSHIP:
      return {
        ...state,
        membership: action.payload,
      };
    case SET_IS_CASHBACK_USER:
      return {
        ...state,
        isCashbackUser: action.payload,
      };
    case SET_IS_ELIGIBLE_FOR_CASHBACK:
      return {
        ...state,
        isEligibleForCashback: action.payload,
      };

    case SET_IS_CASHBACK_OPTION_CHECKED:
      return {
        ...state,
        isCashbackOptionChecked: action.payload,
      };

    case SET_IS_CHECKOUT_SECTION_VISIBLE_ON_MOBILE:
      return {
        ...state,
        isCheckoutSectionVisibleOnMobile: action.payload,
      };
    // E of pricing page related reducers

    case SET_EXPERT_CODE:
      return {
        ...state,
        expertCode: action.payload,
      };

    case SET_SURVEY_INFO:
      return {
        ...state,
        surveyInfo: action.payload,
      };

    default:
      return state;
  }
};
