import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalTypes, CustomModalProps, Button, Typography17, Typography34 } from "@slid/slid-ips";
import { Trans, useTranslation } from "react-i18next";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";
import store from "redux/store";
import { markNotificationsAsViewed } from "redux/actions/slidGlobalActions";

const showStudyChallengeExpiredModal = async ({ showModal, notification }: any) => {
  const { userData } = await store.getState().slidGlobal;
  const userDataMoreInfo = JSON.parse(userData?.more_info) || {};
  const isPlainNote = window.location.pathname.includes("docs") && !!document.getElementById("editor-component");
  const isVideoNote = window.location.pathname.includes("/vdocs/");

  trackEvent({
    eventType: eventTypes.show.FREE_TRIAL_ENDED_NOTIFICATION,
    eventProperties: {
      freeTrialLength: 14,
      totalLearningTime: userDataMoreInfo.total_learning_time,
      from: isVideoNote ? "Video note page" : isPlainNote ? "Plain note page" : "My notes",
    },
  });

  const closeWithMarkNotificationAsViewed = async () => {
    await store.dispatch(
      markNotificationsAsViewed({
        notificationsData: {
          notifications: [notification],
        },
      })
    );
  };

  const modalProps: CustomModalProps = {
    type: ModalTypes.customModal,
    closableDim: false,
    customComponent: <StudyChallengeModalContainer markAsViewed={() => closeWithMarkNotificationAsViewed()} />,
  };
  showModal(modalProps);
};

const StudyChallengeModalContainer = ({ markAsViewed }: { markAsViewed: any }) => {
  const { t } = useTranslation("StudyChallenge");

  useEffect(() => {
    markAsViewed();
  }, []);

  return (
    <Container>
      <Image src={"/src/assets/study_challenge/expired_modal.webp"} />
      <Typography34
        weight={700}
        //@ts-ignore
        text={
          <Trans components={{ colored: <Typography34 weight={700} text={t("ExpiredModal.TitleColoredText", { keySeparator: "." })} color={`--blue8`} style={{ display: "inline" }} /> }}>
            {t("ExpiredModal.Title", { keySeparator: "." })}
          </Trans>
        }
        color={`--gray17`}
        textAlign={"center"}
        marginTop="24px"
      ></Typography34>
      <Typography17 weight={400} color="--gray15" marginTop="24px" text={t("ExpiredModal.Description", { keySeparator: ".", savedTime: "1" })} style={{ width: 400 }} />
      <Button
        marginTop={"48px"}
        appearance="primary"
        callback={() => {
          window.open("/pricing");
        }}
        size="medium"
        text={t("ExpiredModal.ButtonText", { keySeparator: "." })}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 48px 36px;
  background-color: var(--gray1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.img`
  width: 360px;
`;

export default showStudyChallengeExpiredModal;
