import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import { SvgIconProps } from "../types";

interface markupCanvasRectProps extends SvgIconProps {
  size: "thin" | "medium" | "thick";
}
const SvgWrapper = styled(Icon)``;

const thicknessMap = {
  thick: { x: 14, y: 9, width: 12, height: 22, rx: 4 },
  medium: { x: 16, y: 11, width: 8, height: 18, rx: 4 },
  thin: { x: 18, y: 13, width: 4, height: 14, rx: 2 },
};

export const MarkupHighLighterSizeIcon = ({ width, height, color, size }: markupCanvasRectProps) => {
  const { x, y, width: rectWidth, height: rectHeight, rx } = thicknessMap[size];

  return (
    <SvgWrapper width={width ? width : 40} height={height ? height : 40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect x={x} y={y} width={rectWidth} height={rectHeight} rx={rx} fill={color} />
      </g>
    </SvgWrapper>
  );
};
