// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const OneClickIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.36672 4.35982C8.55671 4.13182 8.83816 4 9.13494 4H14.8648C15.1616 4 15.4431 4.13182 15.633 4.35981L16.9999 6H19.5C20.8807 6 22 7.11929 22 8.5V17.5C22 18.8807 20.8807 20 19.5 20H4.5C3.11929 20 2 18.8807 2 17.5V8.5C2 7.11929 3.11929 6 4.5 6H6.99991L8.36672 4.35982ZM15.9999 13C15.9999 15.2091 14.209 17 11.9999 17C9.79077 17 7.99991 15.2091 7.99991 13C7.99991 10.7909 9.79077 9 11.9999 9C14.209 9 15.9999 10.7909 15.9999 13Z"
        fill={`${color}`}
      />
    </SvgWrapper>
  );
};
