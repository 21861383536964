import { SlidStudyChallengeNoteDocument } from "types/document";
import base from "./base";
// import { DocumentSearchResponse, FolderSearchResponse, RecentDocumentResponse } from "types/search";

interface CheckParticipationResponse {
  enrolled: boolean;
  event_name: string;
}

export const checkUserParticipatedStudyChallenge = async () => {
  return base.get<CheckParticipationResponse>(`check-participation/`);
};

export const applyStudyChallenge = async (eventName: string) => {
  return base.post(`enroll-study-challenge/${eventName}`);
};

export const getUserStudyGoal = async (eventName: string) => {
  return base.get(`user-study-goal/${eventName}`);
};

export const updateUserStudyGoal = async (eventName: string, data: { userStudyGoal: string }) => {
  return base.patch(`user-study-goal/${eventName}`, data);
};

export const checkUserSubmitStudyChallengeNote = async (eventName: string, documentKey: string) => {
  return base.get(`check-note-submission`, { params: { event_name: eventName, document_key: documentKey } });
};

export const getStudyChallengeNote = async (documentKey: string) => {
  return base.get<SlidStudyChallengeNoteDocument>(`study-challenge-notes/${documentKey}`);
};

interface StudyChallengeNotesResponse {
  date: string;
  meta: {
    is_today: boolean;
    day_label: string;
  };
  notes: SlidStudyChallengeNoteDocument[];
}

export const getStudyChallengeNotes = async (eventName: string) => {
  return base.get<StudyChallengeNotesResponse[]>(`study-challenge-notes`, { params: { event_name: eventName } });
};

export const createStudyChallengeNote = async (eventName: string, documentKey: string) => {
  return base.post("study-challenge-notes", {}, { params: { event_name: eventName, document_key: documentKey } });
};

export const updateStudyChallengeNote = async (eventName: string, documentKey: string) => {
  return base.put(`study-challenge-notes`, {}, { params: { event_name: eventName, document_key: documentKey } });
};

interface StudyChallengeStatusResponse {
  completion_rate: number;
  user_submission_days: number;
  submitted_today: boolean;
  total_days: number;
  total_periods: number;
  group_avg_completion_rate: number;
  total_members: number;
  members_submitted_today: number;
  today: number;
  today_period: number;
  recognition_time: string;
}

export const getStudyChallengeStatus = async (eventName: string) => {
  return base.get<StudyChallengeStatusResponse>(`study-challenge-status`, { params: { event_name: eventName } });
};

interface WeeklySubmission {
  [key: string]: boolean;
}

interface StudyChallengeParticipantsResponse {
  username: string;
  completion_rate: number;
  weekly_submission: WeeklySubmission;
}

export const getStudyChallengeParticipants = async (eventName: string) => {
  return base.get<StudyChallengeParticipantsResponse[]>(`study-challenge-participation`, { params: { event_name: eventName } });
};
