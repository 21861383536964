// auth 2.0
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/remote-config";
import * as Sentry from "@sentry/browser";

import env from "config/env";

const firebaseConfig = {
  apiKey: "AIzaSyCfo4lBeoLCOvyx4MDooI-G29ShJELVFYM",
  databaseURL: "https://slid-286902.firebaseio.com",
  projectId: "slid-286902",
  appId: "1:234540800401:web:f576e354cc5c308ce52230",
  authDomain: "slid-286902.firebaseapp.com",
  storageBucket: "slid-286902.appspot.com",
  messagingSenderId: "234540800401",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

const chatDataRef = env.currentEnv === "production" ? db.collection("chat_bot_messages") : db.collection("chat_bot_messages_dev");

export const makeNewChatInDb = ({ userKey, email, country, userLanguage, message }) => {
  const data = {
    created_at: firebase.firestore.FieldValue.serverTimestamp(),
    userKey: userKey,
    userLanguage: userLanguage,
    country: country,
    email: email,
    messages: [
      {
        role: message.role,
        content: message.content,
      },
    ],
  };
  return chatDataRef
    .add(data)
    .then((docRef) => {
      if (env.currentEnv === "development") console.log("Document written with ID: ", docRef.id);
      return docRef.id;
    })
    .catch((error) => {
      if (env.currentEnv === "development") console.error("Error adding document to firebase: ", error);
      throw error;
    });
};

export const appendMessageToDbChatData = ({ chatDataId, message }) => {
  const data = {
    role: message.role,
    content: message.content,
  };
  chatDataRef
    .doc(chatDataId)
    .update({
      messages: firebase.firestore.FieldValue.arrayUnion(data),
    })
    .then(() => {})
    .catch((error) => {
      console.error("Error appending message to firebase:- ", error);
      throw error;
    });
};

class Firebase {
  constructor() {
    //initialize
    var firebaseConfig = {
      apiKey: "AIzaSyCfo4lBeoLCOvyx4MDooI-G29ShJELVFYM",
      databaseURL: "https://slid-286902.firebaseio.com",
      projectId: "slid-286902",
      appId: "1:234540800401:web:f576e354cc5c308ce52230",
      authDomain: "slid-286902.firebaseapp.com",
      storageBucket: "slid-286902.appspot.com",
      messagingSenderId: "234540800401",
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  setFirestore(collection) {
    //collection name
    this.collection = collection;
  }

  saveToFirestore(data) {
    const db = firebase.firestore();
    const userRef = db.collection(this.collection).add(data);
  }

  async setRemoteConfig() {
    this.remoteConfig = firebase.remoteConfig();

    this.remoteConfig.settings = {
      // remote configs can be updated at most once every 3 minutes
      minimumFetchIntervalMillis: 1000 * 60 * 3,
    };

    return await this.remoteConfig.ensureInitialized();
  }

  async getFeatureFlags() {
    await this.remoteConfig.fetchAndActivate();
    try {
      return JSON.parse(this.remoteConfig.getValue("feature_flag")._value);
    } catch (e) {
      console.error(e, JSON.stringify(this.remoteConfig.getValue("feature_flag")));
      return {};
    }
  }

  async getStableVersions() {
    await this.remoteConfig.fetchAndActivate();
    try {
      return JSON.parse(this.remoteConfig.getValue("versions")._value);
    } catch (e) {
      console.error(e, JSON.stringify(this.remoteConfig.getValue("versions")));
      return {};
    }
  }

  async getParameterValue(parameter) {
    /*
    returns the value of any arbitrary parameter
    */
    await this.setRemoteConfig();
    await this.remoteConfig.fetchAndActivate();
    try {
      return this.remoteConfig.getValue(parameter)._value;
    } catch (e) {
      console.error(e);
      return;
    }
  }

  async getVideoRecommendationFeatureFlag() {
    await this.remoteConfig.fetchAndActivate();
    try {
      return JSON.parse(this.remoteConfig.getValue("video_recommendation_test_users")._value);
    } catch (e) {
      console.error(e, JSON.stringify(this.remoteConfig.getValue("video_recommendation_test_users")));
      return {};
    }
  }
}

export default new Firebase();
