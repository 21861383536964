// auth 2.0
import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { SvgIconProps } from "./types";

const SvgWrapper = styled(Icon)``;

export const PlayIcon = ({ width, height, color }: SvgIconProps) => {
  return (
    <SvgWrapper viewBox={`0 0 ${width} ${height}`} style={{ width, height }} fill="none">
      <path
        d="M12 27.5892C12 27.9837 12.4356 28.2228 12.7684 28.011L27.8371 18.4218C28.1459 18.2254 28.1459 17.7746 27.8371 17.5782L12.7684 7.98901C12.4356 7.77718 12 8.01629 12 8.41084V27.5892Z"
        fill="#212529"
      />
    </SvgWrapper>
  );
};
