import { SortingOption, sortByDate, sortByName, sortByUpdated } from "components/myNotes/myNotes.utils";
import { useAppSelector } from "hooks";
import { SlidFolderDocument, SlidNoteDocument } from "types/document";

export const useSortDocument = () => {
  const { sortingOption } = useAppSelector((state) => state.myNotes);

  const sortFunctions = {
    [SortingOption.Date]: sortByDate,
    Date: sortByDate,
    [SortingOption.Name]: sortByName,
    Name: sortByName,
    [SortingOption.Update]: sortByUpdated,
    Updated: sortByUpdated,
  };

  const recursiveSortDocuments = (documents: (SlidNoteDocument | SlidFolderDocument)[]) => {
    const sortFunction = sortFunctions[sortingOption] || sortByDate;

    let sortedDocuments = sortFunction(documents);

    sortedDocuments = sortedDocuments?.map((doc) => {
      if (doc.is_folder && doc.documents) {
        return {
          ...doc,
          documents: recursiveSortDocuments(doc.documents),
        };
      }
      return doc;
    });

    return sortedDocuments;
  };

  return { sortingOption, recursiveSortDocuments };
};
