// auth 2.0
import * as Sentry from "@sentry/browser";
import { Replay } from "@sentry/replay"; // replays the user's session to show where the error happened
import { BrowserTracing } from "@sentry/tracing"; // tracing performance
import env from "config/env";
import packageJson from "../../package.json";

export const initSentry = () => {
  Sentry.init({
    dsn: env.end_point_url.sentry,
    release: "slid-web-app@" + packageJson.version,
    environment: env.currentEnv,
    integrations: [
      new Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
      new BrowserTracing(),
    ],
    replaysSessionSampleRate: 0.05, // 5% of sessions will be recorded
    replaysOnErrorSampleRate: 1.0, // 100% of errors will be recorded
    tracesSampleRate: 0.05, // 5% of transactions will be used for performance monitoring
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
    beforeSend(event) {
      // Editor JS related error - Ignore until we refactor how we use Editor JS
      if (event.exception && event.exception.values[0].value.includes("Cannot read property 'holder' of undefined")) return null;
      if (event.exception && event.exception.values[0].value.includes("Cannot read property 'parentNode' of null")) return null;
      if (event.exception && event.exception.values[0].value.includes("Cannot set property 'selected' of undefined")) return null;
      if (event.exception && event.exception.values[0].value.includes("Cannot read property 'currentInput' of undefined")) return null;
      if (event.exception && event.exception.values[0].value.includes("element with ID «editor-js")) return null;
      if (event.exception && event.exception.values[0].value.includes("Pass correct holder's ID.")) return null;
      if (event.exception && event.exception.values[0].value.includes("Editor's content can not be saved in read-only mode")) return null;
      if (event.exception && event.exception.values[0].value.includes("Cannot read properties of null (reading 'parentNode')")) return null;
      if (event.exception && event.exception.values[0].value.includes("Cannot read properties of undefined (reading 'normalize')")) return null;
      if (event.exception && event.exception.values[0].value.includes(".some is not a function")) return null;

      // Firebase Remote config error - Ignore until we refactor how we use firebase
      if (event.exception && event.exception.values[0].value.includes("Remote Config: Error thrown when opening storage.")) return null;
      if (event.exception && event.exception.values[0].value.includes("Remote Config: The config fetch request timed out. ")) return null;

      // Color picker error - Ignor until we refactor image canvas
      if (event.exception && event.exception.values[0].value.includes("Cannot read properties of undefined (reading 'colorCollections')")) return null;

      // ignore chunk load errors because they resolves naturally. We don't need to track them they just happen because we deploy while users are using the app
      if (event.exception && event.exception.values[0].value.includes("Loading chunk")) return null;

      return event;
    },
  });
};
