// auth 2.0

import { sendScreenCaptureRequestToParentWindow, sendOneClickCaptureRequestToParentWindow } from "../extensionInterface/sendToExtension";

// legacy
export const screenCapture = ({ applicationType }) => {
  switch (applicationType) {
    case "extension": {
      sendScreenCaptureRequestToParentWindow();
      return;
    }
    case "web": {
      // TODO

      return;
    }
    default:
      return;
  }
};

export const oneClickScreenCapture = ({ applicationType }) => {
  switch (applicationType) {
    case "extension": {
      sendOneClickCaptureRequestToParentWindow();
      return;
    }
    case "web": {
      // TODO

      return;
    }
    default:
      return;
  }
};
