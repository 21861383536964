import React from "react";
import styled from "styled-components";
import LoadingScreenContent from "../editorLoadingStates/LoadingScreenContent";

const LoadingScreen = () => {
  return (
    <LoaderContainer>
      <LoadingScreenContent />
    </LoaderContainer>
  );
};

export default LoadingScreen;

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
