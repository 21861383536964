import { deviceType } from "react-device-detect";
import { getFormattedTime, redirectToMyNotes } from "utils/utils";
// auth 2.0
import ChannelService from "config/channel-talk";
import { EmojiModalProps, ModalTypes } from "@slid/slid-ips";
import { markNotificationsAsViewed } from "redux/actions/slidGlobalActions";
import i18n from "config/i18n/i18n";
import store from "redux/store";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "../../types/eventTracking/index";
import { History } from "history";
interface NotificationProps {
  showModal: Function;
  closeModal: Function;
  notification: any;
  history: History;
  notificationType?: string;
  parameters?: any;
}

const t = i18n.t.bind(i18n);
const isUserInVideoNote = window.location.pathname.includes("/vdocs/");
const shouldShowMobileSizeModal = deviceType === "mobile";
const onHandleNotificationViewed = async (notification: any) => {
  await store.dispatch(
    markNotificationsAsViewed({
      notificationsData: {
        notifications: [notification],
      },
    })
  );
};

export const showPlanExpiredNotification = async ({ showModal, closeModal, notification, history }: NotificationProps) => {
  if (notification?.body?.from) {
    trackEvent({
      eventType: "Show membership expired notification",
      eventProperties: {
        previousPlan: notification.body.from,
      },
    });

    const applicationType = await store.getState().slidGlobal.applicationType;
    const iframePort = await store.getState().vdocs.iframePort;

    let notificationProps: EmojiModalProps = {
      type: ModalTypes.emojiModal,
      emoji: t("PlanExpiredIcon", { ns: "Notification" }),
      title: t("PlanExpiredTitle", { ns: "Notification" }),
      text: t(shouldShowMobileSizeModal ? "PlanExpiredMobileVersionBody" : "PlanExpiredBody", { ns: "Notification" }),
      device: shouldShowMobileSizeModal ? "mobile" : "desktop",
      onDimClick: async () => {
        await onHandleNotificationViewed(notification);
        closeModal();
      },
      closableDim: isUserInVideoNote ? false : true,
      primaryButton: {
        text: t("PlanExpiredPrimaryButton", { ns: "Notification" }),
        callBack: async () => {
          await onHandleNotificationViewed(notification);
          trackEvent({
            eventType: "Click pick a plan button",
            eventProperties: {
              from: "membership expired notification",
              previousPlan: notification.body.from,
            },
          });
          if (!isUserInVideoNote) closeModal();
          window.open("/pricing");
        },
      },
    };

    // Show GoToMyNote Button as secondary button for user in video note on desktop-app, otherwise just show Close Button
    if (isUserInVideoNote) {
      if (applicationType === "desktop") {
        notificationProps = {
          ...notificationProps,
          secondaryButton: {
            text: t("PlanExpiredSecondaryMyNotesButton", { ns: "Notification" }),
            callBack: async () => {
              await onHandleNotificationViewed(notification);
              redirectToMyNotes({ history, iframePort });
            },
          },
        };
      }
    } else {
      notificationProps = {
        ...notificationProps,
        secondaryButton: {
          text: t("PlanExpiredSecondaryCloseButton", { ns: "Notification" }),
          callBack: async () => {
            await onHandleNotificationViewed(notification);
            closeModal();
          },
        },
      };
    }

    showModal(notificationProps);
  }
};

export const showPaymentFailedNotification = async ({ showModal, closeModal, notification, history, notificationType }: NotificationProps) => {
  trackEvent({
    eventType: "Show auto-renewal failed modal",
    eventProperties: {
      errorType: notificationType,
      errorMessage: notification.body.error_message,
    },
  });

  await store.dispatch(
    markNotificationsAsViewed({
      notificationsData: {
        notifications: [notification],
      },
    })
  );

  const getNotificationBody = () => {
    switch (notificationType) {
      case "RPCD1001":
        return t("PaymentFailedBody1001", { ns: "Notification" });
      case "RPCD1002":
        return t("PaymentFailedBody1002", { ns: "Notification" });
      case "RPAY9998":
        return t("PaymentFailedBody9998", { ns: "Notification" });
      default:
        return t("PaymentFailedBodyDefault", { ns: "Notification" });
    }
  };

  const notificationProps: EmojiModalProps = {
    type: ModalTypes.emojiModal,
    emoji: t("PaymentFailedIcon", { ns: "Notification" }),
    title: t("PaymentFailedTitle", { ns: "Notification" }),
    text: `${getNotificationBody()}`,
    device: shouldShowMobileSizeModal ? "mobile" : "desktop",
    primaryButton: {
      text: t("PaymentFailedPrimaryButton", { ns: "Notification" }),
      callBack: () => {
        closeModal();
        ChannelService.openChat();
      },
    },
    secondaryButton: {
      text: t("PaymentFailedSecondaryButton", { ns: "Notification" }),
      callBack: () => {
        closeModal();
      },
    },
  };
  showModal(notificationProps);
};

// Pricing Plan Notification for Basic / Pro user
export const showPricingPlanUpdateNotification = async ({ showModal, closeModal, notification, history }: NotificationProps) => {
  const userData = await store.getState().slidGlobal.userData;

  const closeModalWithCheckNotificationViewed = async () => {
    await onHandleNotificationViewed(notification);
    closeModal();
  };

  if (userData) {
    trackEvent({
      eventType: "Show pricing plan updated notification",
      eventProperties: {
        previousPlan: userData.payment,
      },
    });

    const notificationProps: EmojiModalProps = {
      type: ModalTypes.emojiModal,
      emoji: t("PlanUpdatedIcon", { ns: "Notification" }),
      title: t("PlanUpdatedTitle", { ns: "Notification" }),
      text: t("PlanUpdatedBody", { ns: "Notification" }),
      device: shouldShowMobileSizeModal ? "mobile" : "desktop",
      onDimClick: async () => await closeModalWithCheckNotificationViewed(),
      primaryButton: {
        text: t("PlanUpdatedPrimaryButton", { ns: "Notification" }),
        callBack: async () => {
          await closeModalWithCheckNotificationViewed();
          window.open("/pricing");
        },
      },
      secondaryButton: {
        text: t("PlanUpdatedSecondaryButton", { ns: "Notification" }),
        callBack: async () => await closeModalWithCheckNotificationViewed(),
      },
    };
    showModal(notificationProps);
  }
};

export const showTrialExpiredNotification = async ({ showModal, closeModal, notification, history }: NotificationProps) => {
  if (notification?.body?.from) {
    const { userData, applicationType, lang } = await store.getState().slidGlobal;
    const iframePort = await store.getState().vdocs.iframePort;

    const userDataMoreInfo = JSON.parse(userData?.more_info) || {};
    const isUserStudiedMoreThanOneMinute = !userDataMoreInfo.total_learning_time || userDataMoreInfo.total_learning_time < 60000;
    const isUserInPlainNote = window.location.pathname.includes("docs") && !!document.getElementById("editor-component");

    trackEvent({
      eventType: eventTypes.show.FREE_TRIAL_ENDED_NOTIFICATION,
      eventProperties: {
        freeTrialLength: 14,
        totalLearningTime: userDataMoreInfo.total_learning_time,
        from: isUserInVideoNote ? "Video note page" : isUserInPlainNote ? "Plain note page" : "My notes",
      },
    });

    const closeModalWithCheckNotificationViewed = async () => {
      await onHandleNotificationViewed(notification);
      closeModal();
    };

    let notificationProps: EmojiModalProps = {
      type: ModalTypes.emojiModal,
      emoji: t("TrialExpiredIcon", { ns: "Notification" }),
      title: t("TrialExpiredTitle", { ns: "Notification" }),
      text:
        shouldShowMobileSizeModal || isUserStudiedMoreThanOneMinute
          ? t("TrialExpiredBodyWithoutTime", { ns: "Notification" })
          : t("TrialExpiredBody", {
              ns: "Notification",
              username: userData?.username,
              savedTime: getFormattedTime(userDataMoreInfo.total_learning_time ? userDataMoreInfo.total_learning_time : 0, "separated", lang),
            }),
      device: shouldShowMobileSizeModal ? "mobile" : "desktop",
      closableDim: isUserInVideoNote ? false : true,
      onDimClick: async () => await closeModalWithCheckNotificationViewed(),
      primaryButton: {
        text: t(shouldShowMobileSizeModal ? "TrialExpiredMobileVersionPrimaryButton" : "TrialExpiredPrimaryButton", { ns: "Notification" }),
        callBack: async () => {
          await onHandleNotificationViewed(notification);
          trackEvent({
            eventType: eventTypes.click.GO_TO_PRCICING_PAGE_IN_FREE_TRIAL_END_NOTIFICATION,
            eventProperties: {
              from: isUserInVideoNote ? "Video note page" : isUserInPlainNote ? "Plain note page" : "My notes",
            },
          });
          if (!isUserInVideoNote) closeModal();
          window.open("/pricing");
        },
      },
    };

    // Show GoToMyNote Button as secondary button for user in video note on desktop-app, otherwise just show Close Button
    if (isUserInVideoNote) {
      if (applicationType === "desktop") {
        notificationProps = {
          ...notificationProps,
          secondaryButton: {
            text: t("TrialExpiredSecondaryMyNotesButton", { ns: "Notification" }),
            callBack: async () => {
              await onHandleNotificationViewed(notification);
              redirectToMyNotes({ history, iframePort });
            },
          },
        };
      }
    } else {
      notificationProps = {
        ...notificationProps,
        secondaryButton: {
          text: t("TrialExpiredSecondaryCloseButton", { ns: "Notification" }),
          callBack: async () => await closeModalWithCheckNotificationViewed(),
        },
      };
    }

    showModal(notificationProps);
  }
};

export const showChangeToTrialNotification = async ({ showModal, closeModal, notification, history }: NotificationProps) => {
  const closeModalWithCheckNotificationViewed = async () => {
    await onHandleNotificationViewed(notification);
    closeModal();
  };
  if (notification?.body?.from) {
    const notificationProps: EmojiModalProps = {
      type: ModalTypes.emojiModal,
      emoji: t("ChangeToTrialIcon", { ns: "Notification" }),
      title: t("ChangeToTrialTitle", { ns: "Notification" }),
      text: t("ChangeToTrialBody", { ns: "Notification" }),
      device: shouldShowMobileSizeModal ? "mobile" : "desktop",
      onDimClick: async () => await closeModalWithCheckNotificationViewed(),
      primaryButton: {
        text: t("ChangeToTrialPrimaryButton", { ns: "Notification" }),
        callBack: async () => {
          await closeModalWithCheckNotificationViewed();
          window.open("/pricing");
        },
      },
      secondaryButton: {
        text: t("ChangeToTrialSecondaryButton", { ns: "Notification" }),
        callBack: async () => await closeModalWithCheckNotificationViewed(),
      },
    };
    showModal(notificationProps);
  }
};
// Show notification when the current day-pass is expired, but user STILL HAVE unused day-passes:
export const showDayPassExpiredWithRemainingItemsNotification = async ({ showModal, closeModal, notification, parameters, history }: NotificationProps) => {
  const closeModalWithCheckNotificationViewed = async () => {
    await onHandleNotificationViewed(notification);
    closeModal();
  };

  const iframePort = await store.getState().vdocs.iframePort;

  if (notification?.body?.from) {
    trackEvent({ eventType: eventTypes.show._24H_PASS_EXPIRED_NOTIFICATION, eventProperties: { remaining24HPass: notification?.body?.remaining_day_passes_count } });

    const notificationProps: EmojiModalProps = {
      type: ModalTypes.emojiModal,
      closableDim: isUserInVideoNote ? false : true,
      emoji: t("DayPassExpiredWithRemainingItemsIcon", { ns: "Notification" }),
      title: t("DayPassExpiredWithRemainingItemsTitle", { ns: "Notification" }),
      text: t("DayPassExpiredWithRemainingItemsBody", { ns: "Notification", remainingItems: notification?.body?.remaining_day_passes_count }),
      device: shouldShowMobileSizeModal ? "mobile" : "desktop",
      onDimClick: async () => await closeModalWithCheckNotificationViewed(),
      primaryButton: {
        text: t("DayPassExpiredWithRemainingItemsPrimaryButton", { ns: "Notification" }),
        callBack: async () => {
          parameters.useAnotherDayPass && parameters.useAnotherDayPass();
          closeModalWithCheckNotificationViewed();
        },
      },
      secondaryButton: {
        text: t("DayPassExpiredWithRemainingItemsSecondaryButton", { ns: "Notification" }),
        callBack: async () => {
          await onHandleNotificationViewed(notification);
          if (!isUserInVideoNote) closeModal();
          else redirectToMyNotes({ history, iframePort });
        },
      },
    };
    showModal(notificationProps);
  }
};

// Show notification when the current day-pass is expired, and user have NO MORE day-passes:
export const showDayPassExpiredWithoutRemainingItemsNotification = async ({ showModal, closeModal, notification, history }: NotificationProps) => {
  const closeModalWithCheckNotificationViewed = async () => {
    await onHandleNotificationViewed(notification);
    closeModal();
  };

  if (notification?.body?.from) {
    trackEvent({ eventType: eventTypes.show._24H_PASS_EXPIRED_NOTIFICATION, eventProperties: { remaining24HPass: 0 } });
    const iframePort = await store.getState().vdocs.iframePort;

    let notificationProps: EmojiModalProps = {
      type: ModalTypes.emojiModal,
      closableDim: isUserInVideoNote ? false : true,
      emoji: t("DayPassExpiredWithoutRemainingItemsIcon", { ns: "Notification" }),
      title: t("DayPassExpiredWithoutRemainingItemsTitle", { ns: "Notification" }),
      text: t("DayPassExpiredWithoutRemainingItemsBody", { ns: "Notification" }),
      device: shouldShowMobileSizeModal ? "mobile" : "desktop",
      onDimClick: async () => await closeModalWithCheckNotificationViewed(),
      primaryButton: {
        text: t("DayPassExpiredWithoutRemainingItemsPrimaryButton", { ns: "Notification" }),
        callBack: async () => {
          await onHandleNotificationViewed(notification);
          if (!isUserInVideoNote) closeModal();
          window.open("/pricing");
        },
      },
    };

    // Show GoToMyNote Button as secondary button for user in video note on desktop-app, otherwise just show Close Button
    if (isUserInVideoNote) {
      notificationProps = {
        ...notificationProps,
        secondaryButton: {
          text: t("DayPassExpiredWithoutRemainingItemsSecondaryButtonInVideoNote", { ns: "Notification" }),
          callBack: async () => {
            await onHandleNotificationViewed(notification);
            redirectToMyNotes({ history, iframePort });
          },
        },
      };
    } else {
      notificationProps = {
        ...notificationProps,
        secondaryButton: {
          text: t("DayPassExpiredWithoutRemainingItemsSecondaryButtonNotInVideoNote", { ns: "Notification" }),
          callBack: async () => {
            await closeModalWithCheckNotificationViewed();
            redirectToMyNotes({ history, iframePort });
          },
        },
      };
    }

    showModal(notificationProps);
  }
};

export const showChangeToBreakNotification = async ({ showModal, closeModal, notification, history }: NotificationProps) => {
  const userData = await store.getState().slidGlobal.userData;

  const closeModalWithCheckNotificationViewed = async () => {
    await onHandleNotificationViewed(notification);
    closeModal();
  };

  if (notification?.body?.from && userData) {
    const notificationProps: EmojiModalProps = {
      type: ModalTypes.emojiModal,
      emoji: t("ChangeToBreakIcon", { ns: "Notification" }),
      title: t("ChangeToBreakTitle", { ns: "Notification" }),
      text: t("ChangeToBreakBody", { ns: "Notification", username: userData.username }),
      device: shouldShowMobileSizeModal ? "mobile" : "desktop",
      onDimClick: async () => await closeModalWithCheckNotificationViewed(),
      primaryButton: {
        text: t("ChangeToBreakPrimaryButton", { ns: "Notification" }),
        callBack: async () => {
          await onHandleNotificationViewed(notification);
          closeModal();
          window.open("/pricing");
        },
      },
      secondaryButton: {
        text: t("ChangeToBreakSecondaryButton", { ns: "Notification" }),
        callBack: async () => await closeModalWithCheckNotificationViewed(),
      },
    };
    showModal(notificationProps);
  }
};
