// auth 2.0
import { Typography17, Button, Typography34 } from "@slid/slid-ips";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

interface NoVideoThumbnailProps {
  thumbnailTitle: string;
  thumbnailText: string;
  videoLink?: string;
}

const NoVideoThumbnail = memo(({ thumbnailTitle, thumbnailText, videoLink }: NoVideoThumbnailProps) => {
  const history = useHistory();
  const { t } = useTranslation("VideoNote");
  const isMobile = useMediaQuery({ query: "(max-width:799px)" });
  return (
    <ThumbnailContainer>
      <img src="/src/icons/icon_no_logo_24.svg" alt="no video" width={isMobile ? 44 : 64} />
      {!isMobile && <Typography34 weight={700} color="--gray9" text={thumbnailTitle} marginTop={"20px"} />}
      <Typography17 weight={isMobile ? 700 : 400} color="--gray9" text={isMobile ? thumbnailTitle : thumbnailText} marginTop="8px" />
      <ButtonContainer isMobile={isMobile}>
        <Button
          appearance="primary"
          size={isMobile ? "small" : "medium"}
          text={videoLink ? t("OpenVideoLinkButton") : t("ViewWithoutVideoButton")}
          callback={() => {
            if (videoLink) {
              window.open(videoLink);
            } else {
              const docsPathname = window.location.pathname.replace("vdocs", "docs");
              history.push(docsPathname);
            }
          }}
        />
        {videoLink && (
          <Button
            appearance="line"
            size={isMobile ? "small" : "medium"}
            text={t("ViewWithoutVideoButton")}
            callback={() => {
              const docsPathname = window.location.pathname.replace("vdocs", "docs");
              history.push(docsPathname);
            }}
          />
        )}
      </ButtonContainer>
    </ThumbnailContainer>
  );
});

export default NoVideoThumbnail;

const ThumbnailContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
  background-color: var(--gray4);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: ${({ isMobile }) => (isMobile ? "12px" : "40px")};
  gap: 8px;
`;
