import { API, BlockAPI } from "@editorjs/editorjs";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import env from "config/env";
import React, { useEffect, useState } from "react";
import { remark } from "remark";
import { getUserIdToken } from "utils/auth/cognitoAuthToolkit";
import { getTranscriptLanguageCode } from "utils/stt";
import AutoNoteErrorView from "./AutoNoteErrorView";
import AutoNoteLoadingView from "./AutoNoteLoadingView";
import { parseMarkdownToCode } from "./blockTypeparsers/CodeTypeParser";
import { parseMarkdownToDelimiter } from "./blockTypeparsers/DelimiterTypeParser";
import { parseMarkdownToHeader } from "./blockTypeparsers/HeaderTypeParser";
import { parseMarkdownToList } from "./blockTypeparsers/ListTypeParser";
import { parseMarkdownToParagraph } from "./blockTypeparsers/ParagraphTypeParser";
import { AutoNotesLoaderBlockData } from "./types";
import { getApplicationType } from "utils/utils";
import store from "redux/store";

const SLID_LLM_API_URL = env.end_point_url.slid_llm_api;
// NOTE: for local testing
const SLID_LLM_API_URL_LOCAL = "http://localhost:8000/v2/auto-notes";
interface ExtendedAutoNotesLoaderBlockData extends AutoNotesLoaderBlockData {
  editorAPI: API;
  currentBlock: BlockAPI | undefined;
}

const AutoNoteBlockView = ({ newTranscriptBlock, previousTranscriptBlocks, previousNotes, language, editorAPI, currentBlock, currentDocumentKey }: ExtendedAutoNotesLoaderBlockData) => {
  const [autoNotesResult, setAutoNotesResult] = useState<string>("");
  const [error, setError] = useState<string>("");

  const applicationType = getApplicationType();

  /**
   * Converts the current auto-notes loader block's results into a list block.
   * Each line in the results is prefixed with "- " and becomes a list item. It removes the auto-notes loader block after it's done.
   *
   * @param {API} editorAPI - The Editor.js API instance.
   * @param {BlockAPI | undefined} currentBlock - The current block API instance or undefined.
   * @param {string} text - The text to be converted into list items.
   */
  const insertAutoNotesResultToEditor = async (editorAPI: API, currentBlock: BlockAPI | undefined, text: string) => {
    const blockId = currentBlock?.id;
    if (!blockId) return;
    if (!text) {
      return;
    }

    const markdownAST = remark().parse(text);

    // iterating over the pared remarkjs syntax tree and executing the json parsers
    const editorData = [];
    markdownAST.children.forEach((node, index) => {
      switch (node.type) {
        case "heading":
          //@ts-ignore
          return editorData.push(parseMarkdownToHeader(node));
        case "paragraph":
          //@ts-ignore
          return editorData.push(parseMarkdownToParagraph(node));
        case "list":
          //@ts-ignore
          return editorData.push(...parseMarkdownToList(node));
        case "thematicBreak":
          //@ts-ignore
          return editorData.push(parseMarkdownToDelimiter(node));
        case "code":
          //@ts-ignore
          return editorData.push(parseMarkdownToCode(node));
        case "blockquote":
          //@ts-ignore
          return editorData.push(parseMarkdownToQuote(node));
        default:
          break;
      }
    });

    let notesLoaderBlockIndex = editorAPI.blocks.getBlockIndex(blockId!);

    //  insertMany(
    //   blocks: OutputBlockData[],
    //   index?: number,
    // ): BlockAPI[];

    // TODO: handle negative index result here....

    const previousBlockIndex = notesLoaderBlockIndex - 1;
    if (previousBlockIndex >= 0) {
      const previousBlock = editorAPI.blocks.getBlockByIndex(previousBlockIndex);
      if (previousBlock && previousBlock.isEmpty) {
        // TODO: script must be inserted into the editor before this.
        // editorAPI.blocks.insertMany(listBlocks, previousBlockIndex);
        notesLoaderBlockIndex--;
      }
    }
    // TODO: script must be inserted into the editor before this.
    editorAPI.blocks.insertMany(editorData, notesLoaderBlockIndex);
    //NOTE: we double check to see if the original auto notes loader is still in the editor and remove it.
    const autoNotesLoaderBlockIndex = editorAPI.blocks.getBlockIndex(blockId);
    if (autoNotesLoaderBlockIndex) {
      editorAPI.blocks.delete(autoNotesLoaderBlockIndex);
    }
    editorAPI.caret.setToLastBlock(); //TODO: make sure this is not creating UX issues.
  };

  const handleDeleteBlock = () => {
    const blockId = currentBlock?.id;
    const blockIndex = editorAPI.blocks.getBlockIndex(blockId!);
    if (!blockIndex) return;
    editorAPI.blocks.delete(blockIndex);
  };

  useEffect(() => {
    if (autoNotesResult?.length) return;
    const postData = {
      previousTranscriptBlocks: [...previousTranscriptBlocks],
      newTranscriptBlock: {
        finalizedText: newTranscriptBlock.finalizedText,
        blockId: newTranscriptBlock.blockId,
      },
      previousNotes: previousNotes,
      language: getTranscriptLanguageCode(language),
      document_key: currentDocumentKey,
    };

    getUserIdToken().then((cognitoIdToken) => {
      const { userData } = store.getState().slidGlobal;
      const requestBody = { ...postData, user_id: userData.email };
      return (
        axios
          // .post(`${SLID_LLM_API_URL_LOCAL}`, requestBody, {
          .post(`${SLID_LLM_API_URL}`, requestBody, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${cognitoIdToken}`,
            },
          })
          .then((response) => {
            const llmResponse = response.data;
            const autoNotesError = llmResponse?.error;
            if (autoNotesError) {
              Sentry.withScope((scope) => {
                scope.setLevel("error");
                scope.setExtra("message", "An error occurred during auto notes generation\n" + JSON.stringify(autoNotesError));
                Sentry.captureMessage("SLID_WEB_AUTO_NOTE_GENERATION_ERROR");
              });
              setError(autoNotesError);
              return;
            }
            const final_notes = llmResponse?.result;
            const notesText = final_notes; // extractNewNotes(final_notes);
            if (!notesText || notesText.length === 0) {
              handleDeleteBlock();
            }
            setAutoNotesResult(notesText || "");
            insertAutoNotesResultToEditor(editorAPI, currentBlock, notesText || "");
          })
          .catch((error) => {
            console.error("Error fetching auto notes", error);
            if (applicationType === "web") {
              handleDeleteBlock();
            }

            Sentry.withScope((scope) => {
              scope.setLevel("error");
              scope.setExtra("message", "An error occurred when fetching auto notes. \n" + JSON.stringify(error));
              Sentry.captureMessage("SLID_WEB_AUTO_NOTE_FETCHING_ERROR");
            });
            setError(error.message);
          })
      );
    });
  }, []);

  if (error) return <AutoNoteErrorView />;

  return <AutoNoteLoadingView />;
};

export default AutoNoteBlockView;
