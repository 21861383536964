import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Icon } from "@slid/slid-ips";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { useSearch } from "hooks";
import { SearchIcon } from "components/icons/SearchIcon";
import { setSearchKeywords, setShowSearchModal } from "redux/actions/searchActions";
import { useHistory } from "react-router-dom";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { SlidFeatures } from "utils/privilegeManager";
import { updateUser } from "redux/actions/slidGlobalActions";
import { trackEvent } from "utils/eventTracking";
import { eventTypes } from "types/eventTracking";

const SearchBoxSearchInput = () => {
  const { t } = useTranslation(["Search"]);
  const dispatch = useAppDispatch();
  const { resetSearchKeywords } = useSearch();
  const { searchKeywords } = useAppSelector((state) => state.search);
  const { userData } = useAppSelector((state) => state.slidGlobal);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const [hasUserSearched, setHasUserSearched] = React.useState(false);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Escape":
        dispatch(setShowSearchModal(false));
        resetSearchKeywords();
        break;
      case "Enter":
        if (searchKeywords.length === 0) return;
        dispatch(setShowSearchModal(false));
        history.push(`/docs?keywords=${searchKeywords}`);
    }
  };

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasUserSearched) {
      setHasUserSearched(true);
      const isPlainNote = window.location.pathname.includes("docs") && !!document.getElementById("editor-component");
      trackEvent({
        eventType: eventTypes.search.NOTES,
        eventProperties: {
          location: "search modal",
          from: isPlainNote ? "Plain Note" : "My Notes",
        },
      });
    }
    /**
     * To show intro placeholder only once
     * When user make changes on search input, hide intro placeholder.
     */
    if (userData?.more_info && typeof userData.more_info === "string") {
      try {
        const parsedData = JSON.parse(userData.more_info) || {};
        if (!parsedData.has_searched) {
          dispatch(
            updateUser({
              data: {
                ...userData,
                more_info: {
                  ...parsedData,
                  has_searched: true,
                },
              },
            })
          );
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }

    if (!confirmPrivilege(SlidFeatures.noteSearching)) return showInsufficientPrivilegeModal();

    dispatch(setSearchKeywords(event.target.value));
  };

  return (
    <InputWrapper>
      <SearchIcon width={28} height={28} color={`var(--gray8)`} />
      <SearchInputContainer placeholder={t("Placeholder")} value={searchKeywords} onChange={(e) => handleSearchInput(e)} ref={searchInputRef} onKeyDown={handleKeydown} />
      {searchKeywords && (
        <ResetButton onClick={resetSearchKeywords}>
          <Icon icon={`x24`} color={`--white`} width={16} height={16} />
        </ResetButton>
      )}
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 12px;
  border-bottom: 1px solid var(--gray3);
`;

const SearchInputContainer = styled.input`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px;
  border: none;

  &::placeholder {
    color: var(--gray7);
  }

  &:focus {
    outline: none;

    &::placeholder {
      color: transparent;
    }
  }
`;

const ResetButton = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: var(--gray5);
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--gray13);
  }

  &:active {
    background-color: var(--gray9);
  }
`;

export default SearchBoxSearchInput;
