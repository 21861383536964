import { Typography13, Typography17 } from "@slid/slid-ips";
import React from "react";
import { useTranslation } from "react-i18next";
import Twemoji from "react-twemoji";
import styled from "styled-components";

const SearchBoxNoResultPlaceholder = () => {
  const { t } = useTranslation("Search");
  return (
    <PlaceholderWrapper>
      <Twemoji options={{ className: "twemoji" }}>
        <span role="img" aria-label="thinking face">
          🤔
        </span>
      </Twemoji>
      <Typography17 text={t("NoResultsFound")} color={`--gray17`} weight={700} marginTop={`24px`} />
      <Typography13 text={t("NoResultsFoundDescription")} color={`--blue2_cloudy`} marginTop={`8px`} />
    </PlaceholderWrapper>
  );
};

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px 120px;

  .twemoji {
    width: 36px;
    height: 36px;
  }
`;

export default SearchBoxNoResultPlaceholder;
