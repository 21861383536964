//auth 2.0
import { setEditorLastActiveBlockPosition } from "redux/actions/vdocsActions";
import store from "redux/store";
import { sendMessageToPrimary } from "utils/utils";
import "./index.css";
import i18n from "config/i18n/i18n";
const t = i18n.t.bind(i18n);

class AudioLoader {
  constructor({ data, config, api, readOnly = false }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = data;
    this.config = config;
  }

  render() {
    const loader = document.createElement("div");
    loader.contentEditable = false;

    const loaderPlaceHolder = document.createElement("div");
    loaderPlaceHolder.setAttribute("blocktype", "loader");
    loaderPlaceHolder.className = "audio-loader-placeholder";

    const loaderHeader = document.createElement("div");
    loaderHeader.className = "loader-header";
    const loaderRedDotIcon = document.createElement("img");
    loaderRedDotIcon.src = "/src/design/assets/slid_recording_loader_red_icon.png";
    const loaderHeaderText = document.createElement("span");
    loaderHeaderText.innerText = t("LoaderRecording", { ns: "AudioRecording" });
    loaderHeader.append(loaderRedDotIcon, loaderHeaderText);

    const loaderBody = document.createElement("div");
    loaderBody.className = "loader-body";
    const loaderCountdownText = document.createElement("span");
    let countdownNumber = 0;
    loaderCountdownText.innerText = t("CurrentCountDown", { ns: "AudioRecording", countdownNumber });
    const loaderAdditionalText = document.createElement("span");
    loaderAdditionalText.className = "loader-body-additional-text";
    loaderAdditionalText.innerText = t("MaxCountDown", { ns: "AudioRecording" });
    loaderBody.append(loaderCountdownText, loaderAdditionalText);

    loaderPlaceHolder.append(loaderHeader, loaderBody);
    loader.append(loaderPlaceHolder);

    const countdownId = setInterval(() => {
      setTimeout(() => {
        loaderCountdownText.innerText = `${countdownNumber - 1} sec`;
      }, 1000);
      countdownNumber += 1;
      if (countdownNumber === this.data.audioRecordingMaxCountdownNumber - 5) loaderCountdownText.className = "countdown-red";
      if (countdownNumber === this.data.audioRecordingMaxCountdownNumber) {
        clearInterval(countdownId);
        let iframePort = store.getState().vdocs.iframePort;
        if (iframePort) {
          sendMessageToPrimary({
            message: {
              type: "IFRAME_TO_PRIMARY_STOP_AUDIO_RECORDING",
              payload: null,
            },
            port: iframePort,
          });
        }
      }
    }, 1000);

    loader.addEventListener("click", () => {
      store.dispatch(setEditorLastActiveBlockPosition(this.api.blocks.getCurrentBlockIndex()));
    });

    loader.setAttribute("slid-cy", "editor-audio-loader");

    return loader;
  }

  save() {
    return this.data;
  }

  static get isReadOnlySupported() {
    return true;
  }
}

export default AudioLoader;
