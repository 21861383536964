import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { setIsGettingExtensionMediaPermission, setMediaRecordingChangeDirection, setShowDisableMediaRecordingModal, setShowExtensionMediaPermissionModal } from "redux/actions/vdocsActions";
import { eventTypes } from "types/eventTracking";
import { useConfirmPrivilege } from "utils/customHook/useConfirmPrivilege";
import { trackEvent } from "utils/eventTracking";
import { SlidFeatures } from "utils/privilegeManager";
import {
  setToggleAutoNotes,
  setIsAutoNotesSettingsOpen,
  setShowPermissionGuideInAutoNotesSettingsView,
  setIsGettingAutoNotesMediaPermission,
  setStopAutoNotes,
  setIsAutoNotesButtonClickedOnce,
} from "redux/modules/autoNotesSlice";
import { AutoNotesButtonStatus } from "components/smartautonotes/AutoNoteVdocsComponents/AutoNotesButtonIcon";
import { MediaRecordingChangeDirection } from "components/popups/DisableMediaRecordingModal";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import env from "config/env";

export const useAutoNotesButton = () => {
  const { isExtensionMediaPermitted, isRecordActive, isVideoPlaying } = useAppSelector((state) => state.vdocs);
  const dispatch = useAppDispatch();
  const [buttonStatus, setButtonStatus] = useState<AutoNotesButtonStatus>("default");
  const { confirmPrivilege, showInsufficientPrivilegeModal } = useConfirmPrivilege();
  const [isButtonHovered, setIsButtonHovered] = useState<boolean>(false);
  const [isMouseOnButton, setIsMouseOnButton] = useState<boolean>(false);
  const { isAutoNotesActive, isAutoNotesToggledOn, isAutoNotesButtonClickedOnce } = useAppSelector((state) => state.autoNotes);
  const { isSTTToggledOn } = useAppSelector((state) => state.sttReducer);
  const { isSTTToggledOn: isWhisperSTTToggledOn, resetTranscriptChunk, isRealTimeTranscriptSupported } = useWhisperSLTStore();

  useEffect(() => {
    const updateAutoNotesButtonUI = () => {
      if (isAutoNotesActive && isVideoPlaying) {
        //NOTE: iframe videos won't give us this information. (We won't know if the vid is playing then.)
        setButtonStatus("generating");
      } else if (isAutoNotesToggledOn) {
        //should also consider auto notes
        setButtonStatus("waiting");
      } else {
        setButtonStatus("default");
      }
    };
    updateAutoNotesButtonUI();
  }, [isAutoNotesToggledOn, isAutoNotesActive, isVideoPlaying]);

  const requestExtensionPermission = () => {
    trackEvent({
      eventType: "Check AUTO-NOTES PERMISSION in video note page",
      eventProperties: {
        is_permission_needed: !isExtensionMediaPermitted,
      },
    });

    dispatch(setShowExtensionMediaPermissionModal(true));
    dispatch(setIsGettingExtensionMediaPermission(true));
    dispatch(setIsGettingAutoNotesMediaPermission(true));
    dispatch(setShowPermissionGuideInAutoNotesSettingsView(true));
    localStorage.setItem("isAutoNotesPermissionRequested", "true");
  };

  const wakeUpRealTimeSLTServer = () => {
    fetch(`${env.end_point_url.slid_stt_server_realtime_whisper}/wake-up`, {
      method: "GET",
    })
      .then(() => {
        // The slid-realtime-whisper server is wake-up
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onMouseEnterButton = () => {
    if (isRealTimeTranscriptSupported) {
      wakeUpRealTimeSLTServer();
    }

    setIsButtonHovered(true);
    setIsMouseOnButton(true);
  };

  const onMouseLeaveButton = () => {
    setIsMouseOnButton(false);
    setIsButtonHovered(false);
  };

  const manageClipRecordingEffects = (direction: MediaRecordingChangeDirection) => {
    // when video is being recorded, stop STT and show modal
    dispatch(setToggleAutoNotes(false));
    dispatch(setMediaRecordingChangeDirection(direction));
    dispatch(setShowDisableMediaRecordingModal(true));
  };

  const handleAutoNotesButtonClick = () => {
    if (!isAutoNotesButtonClickedOnce) {
      dispatch(setIsAutoNotesButtonClickedOnce(true));
      dispatch(setIsAutoNotesSettingsOpen(true));
    } else {
      dispatch(setToggleAutoNotes(true));
    }
  };

  const onToggleAutoNotesOn = () => {
    if (isSTTToggledOn || isWhisperSTTToggledOn) {
      manageClipRecordingEffects("SLT_TO_AUTO_NOTES");
      return;
    } else if (isRecordActive) {
      manageClipRecordingEffects("SNIPPET_TO_AUTO_NOTES");
      return;
    }

    handleAutoNotesButtonClick();
    // dispatch(setIsAutoNotesSettingsOpen(true));
    if (!isExtensionMediaPermitted) {
      requestExtensionPermission();
    }
  };

  const onToggleAutoNotesOff = () => {
    dispatch(setStopAutoNotes());
  };

  const onToggleAutoNotes = async () => {
    if (isAutoNotesToggledOn) {
      onToggleAutoNotesOff();
    } else {
      if (!confirmPrivilege(SlidFeatures.autoNotes)) return showInsufficientPrivilegeModal();
      onToggleAutoNotesOn();
    }
  };

  const onClickAutoNotesMoreButton = (e) => {
    trackEvent({
      eventType: eventTypes.click.AUTO_NOTES_SETTING_IN_VIDEO_NOTE_PAGE,
    });

    e.preventDefault();
    e.stopPropagation();
    dispatch(setIsAutoNotesSettingsOpen(true));
  };

  useEffect(() => {
    if (isAutoNotesToggledOn) {
      resetTranscriptChunk();
    }
  }, [isAutoNotesToggledOn]);

  return {
    onToggleAutoNotes,
    buttonStatus,
    onMouseEnterButton,
    onMouseLeaveButton,
    isButtonHovered,
    isMouseOnButton,
    onClickAutoNotesMoreButton,
  };
};
