// auth 2.0
import {
  SET_CURRENT_VIDEO_TIME,
  SET_IS_ONBOARDING_VIDEO_PLAYING,
  SET_IS_WAITING_FOR_SIGN_IN,
  SET_ACTIVE_BUTTON_TYPE,
  SET_INSERT_SLT_BLOCKS_TO_DEMO_EDITOR,
  SET_VISIBLE_SLT_BLOCKS_ON_SLT_VIEW,
  SET_VISIBLE_SLT_BLOCKS_ON_NOTE,
  SET_VISIBLE_IMAGE_BLOCKS,
  SET_VISIBLE_GRAB_TEXT_BLOCKS,
  SET_OVERLAY_MASK_MODE,
} from "redux/actions/actionTypes";

const initialState = {
  isWaitingForSignIn: false, // This state is related to demo
  isOnboardingVideoPlaying: false,
  currentVideoTime: 0,
  insertGrabTextToDemoEditor: false,
  activeButtonType: null,
  insertSLTBlocksToDemoEditor: false,
  visibleImageBlocks: [],
  visibleGrabTextBlocks: [],
  visibleSLTBlocksOnSLTView: [],
  visibleSLTBlocksOnNote: [],
  overlayMaskMode: "INITIAL", // This shows the black overlay mask to nudge users to click some buttons
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_WAITING_FOR_SIGN_IN:
      return {
        ...state,
        isWaitingForSignIn: action.payload,
      };
    case SET_IS_ONBOARDING_VIDEO_PLAYING:
      return {
        ...state,
        isOnboardingVideoPlaying: action.payload,
      };
    case SET_CURRENT_VIDEO_TIME:
      return {
        ...state,
        currentVideoTime: action.payload,
      };
    case SET_ACTIVE_BUTTON_TYPE:
      return {
        ...state,
        activeButtonType: action.payload,
      };
    case SET_INSERT_SLT_BLOCKS_TO_DEMO_EDITOR:
      return {
        ...state,
        insertSLTBlocksToDemoEditor: action.payload,
      };
    case SET_VISIBLE_SLT_BLOCKS_ON_SLT_VIEW:
      return {
        ...state,
        visibleSLTBlocksOnSLTView: action.payload,
      };
    case SET_VISIBLE_SLT_BLOCKS_ON_NOTE:
      return {
        ...state,
        visibleSLTBlocksOnNote: action.payload,
      };
    case SET_VISIBLE_IMAGE_BLOCKS:
      return {
        ...state,
        visibleImageBlocks: action.payload,
      };
    case SET_VISIBLE_GRAB_TEXT_BLOCKS:
      return {
        ...state,
        visibleGrabTextBlocks: action.payload,
      };
    case SET_OVERLAY_MASK_MODE:
      return {
        ...state,
        overlayMaskMode: action.payload,
      };
    default:
      return state;
  }
};
