import * as Hackle from "@hackler/javascript-sdk";
import env from "config/env";
import { getApplicationType } from "./utils";
import packageJson from "../../package.json";
import store from "redux/store";
import { setHackleExperimentKeys } from "redux/actions/slidGlobalActions";

const hackleClient = Hackle.createInstance(env.api_key.hackle_key);

export const setHackleExperimentKeyList = () => {
  let experimentKeyList = {};
  hackleClient.onReady(() => {
    // Since it's not easy to check how many experiment we have and we are running, assume the maximum number of experiment as 100 for now.
    for (let i = 0; i < 100; i++) {
      const decision = hackleClient.variationDetail(i);
      if (decision.reason === "TRAFFIC_ALLOCATED" && decision.config.parameters) {
        const parameterKeyArray = Array.from(decision.config.parameters.keys());
        // For the experiments that are running but not using parameter, skip the adding experiment key part:
        if (parameterKeyArray.length !== 0) {
          const ABTestingText = parameterKeyArray[0].split("_")[0];
          experimentKeyList[ABTestingText] = i;
        }
      }
    }
    store.dispatch(setHackleExperimentKeys(experimentKeyList));
  });
};

export const sendHackleData = (eventType, eventProperties) => {
  const applicationType = getApplicationType();

  const eventKey = eventType.replaceAll(" ", "_");

  hackleClient.onReady(() => {
    hackleClient.track({ key: eventKey, properties: { ...eventProperties, version: packageJson.version, platform: applicationType, domain: "slid web app" } });
  });
};
